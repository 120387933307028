/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { AppState, getAdminMenu, getStaffMenu, isTabActive, PermissionActions, PermissionSelector } from '@panjab-digi-lib/shared';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SharedCommonService } from 'shared/src/services/common/common.service';
import { Store } from '@ngrx/store';
import { filter, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';

enum Title {
  home = 'pdl-shared.menus.homePage',
  dashboard = 'pdl-shared.menus.dashboard',
  pdlemployees = 'pdl-shared.menus.pdlEmployees',
  masterforms = 'pdl-shared.menus.masterForms',
  downloads = 'pdl-shared.menus.downloads',
  webusermanagement = 'pdl-shared.menus.webUserManagement',
  newsandevents = 'pdl-shared.menus.newsandevents',
  videogallery = 'pdl-shared.menus.videogallery',
  curatedcollections = 'pdl-shared.menus.curatedCollections',
  exhibition = 'pdl-shared.menus.exhibition',
  fundraising = 'pdl-shared.menus.fundraising',
  staff = 'pdl-shared.menus.pdlstaff',
  verifyliterature = 'pdl-shared.menus.verifyLiterature',
  generateaccessionnumber = 'pdl-shared.menus.accessionNumber',
  documentlist = 'pdl-shared.menus.documentManagement',
  assignedtasks = 'pdl-shared.menus.myAssignedTasks',
  toadopt = 'pdl-shared.menus.toAdopt',
  assignadopter = 'pdl-shared.menus.assignAdopter',
  setadoptprice = 'pdl-shared.menus.setAdoptPrice',
  setdollarprice = 'pdl-shared.menus.setDollarPrice',
  changepassword = 'pdl-shared.menus.changePassword'
}

@Component({
  selector: 'panjab-digi-lib-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  isExpanded: boolean = true;
  @Output() setTitle = new EventEmitter<string>();
  navMenu: any[] = [];
  currentUrl: string = '';
  permissions$ = this.store.select(PermissionSelector.loadPermission);
  permissionsLoaded$ = this.store.select(PermissionSelector.selectPermissionsLoaded);
  private unsubscribe$: Subject<void> = new Subject();
  subscription = new Subscription();
  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private commonService: SharedCommonService,
    private store: Store<AppState>,
    private router: Router) {

  }

  ngOnInit(): void {
    this.currentUrl = window.location.href;
    const userType = this.checkUserType(this.currentUrl);
    if (userType === 'pdlstaff') {
      this.loadUserPermissions();
    } else {
      this.navMenu = getAdminMenu;
    }

    this.setComponentTitle();
    this.subscription.add(
      this.commonService.changeComponentTitle$.subscribe((changedVal: boolean) => {
        if (changedVal) {
          this.setComponentTitle();
          this.commonService.changeComponentTitle$.next(false);
        }
      })
    )
    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.setComponentTitle();
      })
    )
  }

  setComponentTitle() {
    this.subscription.add(
      this.route.firstChild?.url.subscribe((data) => {
        // @ts-expect-error ignore
        this.setTitle.emit(this.translate.instant(Title[data[0].path.replace(/-/g, "")]));
      })
    )
  }

  /*isMenuCollaped(event: any, parentMenu: any) {
    if (event.delegateTarget.classList.contains('collapsed')) {
      this.router.navigateByUrl(parentMenu[0].url);
      setTimeout(() => {
        this.setMenuTitle(parentMenu[0].name);
      })
    }
  }*/

  setMenuTitle(title: string) {
    this.setTitle.emit(title);
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  setLastVisitedUrl(activeUrl: string, menuItem: any) {
    console.log(getAdminMenu)
    //menuItem.url = activeUrl
  }

  isMenuActive(url: string, exactMatch = true) {
    return isTabActive(this.router, url, exactMatch);
  }

  //////////// return who is logged in pdl staff or pdl admin by checking url ///////////
  checkUserType(url: string): string | null {
    const firstSlashIndex = url.indexOf('/', url.indexOf('//') + 2);
    if (firstSlashIndex !== -1) {
      const segment = url.substring(firstSlashIndex + 1).split('/')[0];
      if (segment === 'pdlstaff' || segment === 'pdladmin') {
        return segment;
      }
    }
    return null;
  }

  ////////////////////// load permissions i.e which component user can  access and delete from menu array without permission ///////////

  loadUserPermissions() {

    this.permissionsLoaded$.pipe(
      filter((loaded) => loaded == true),
      switchMap(() => this.permissions$),
      takeUntil(this.unsubscribe$)
    ).subscribe((data) => {

      this.navMenu = getStaffMenu;
      const approveProcesses: string[] = [];
      const submitProcesses: string[] = [];

      data?.permissionList.map((permission) => {
        if (permission.AccessType === 'Approve') {
          approveProcesses.push(permission.ProcessName);
        }
        if (permission.AccessType === 'Submit') {
          submitProcesses.push(permission.ProcessName);
        }
      });

      if (submitProcesses.indexOf(APP_CONSTANTS.dynamicMenu.accessionNumber) === -1) {
        this.navMenu.map(menu => {
          menu.children = menu.children.filter((child: any) => child?.name !== Title.generateaccessionnumber);
        });
      }

      if (approveProcesses.length === 0) {
        this.navMenu.map(menu => {
          menu.children = menu.children.filter((child: any) => child?.name !== Title.verifyliterature);
        });
      }

      if (submitProcesses.indexOf(APP_CONSTANTS.dynamicMenu.assignTask) === -1) {
        this.navMenu.map(menu => {
          menu.children = menu.children.filter((child: any) => child?.name !== Title.documentlist && child?.name !== Title.staff);
        });
      }
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
