/* eslint-disable no-prototype-builtins */
/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import { WebUserLibraryActions } from "../../actions/index";
import { TopicResponse } from 'shared/src/interfaces/website/user-library.interface';

export interface State extends EntityState<TopicResponse> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const adapter = createEntityAdapter<TopicResponse>({
  selectId: (e) => e.TopicID
});

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: ''
});

export const reducer = createReducer(
  initialState,
  on(WebUserLibraryActions.WebsiteUserLibrary,(state) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebUserLibraryActions.WebsiteUserLibrarySuccess, (state, action) => ({
    ...adapter.setAll(action?.topicList, {
      ...state,
      isLoading: false,
      isError: false,
    })
  })),

  on(WebUserLibraryActions.WebsiteUserLibraryError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  )
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();