import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '@panjab-digi-lib/shared';
import { UserData, LoginUserData, JwtPayload } from '../../../interfaces/auth-user.interface';
import { Observable, throwError } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from '../../common/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  rootURL: string = '';
  appURL = '';
  staffURL: string = '';
  headers: any;
  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.rootURL = environment.rootApiUrl + environment.adminApiPrefix;
    this.appURL = environment.rootApiUrl + environment.appApiPrefix;
    this.staffURL = environment.rootApiUrl + environment.staffApiPrefix;
  }
  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }

    return throwError(() => transformedError);
  }

  login(email: string, password: string) {
    return this.http.post(this.rootURL + 'login', { email, password }).pipe(map((e: any) => e));
  }

  getAccessToken(refreshToken: string) {
    return this.http.post(this.rootURL + 'token', { refreshToken }).pipe(map((e: any) => e));
  }
  forgotPasswordRequest(data: {email: string}) {
    return this.http.post<{email: string}>(this.rootURL + 'recoverPassword', data).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }
  resetAccountPasswd(payload: {token: string, password: string}) {
    return this.http.patch<any>(`${this.rootURL}resetPassword`, payload).pipe(map((e: any) => e));
  }

  validateResetPasswdRequest(data: any) {
    return this.http.post<any>(this.rootURL + 'validateResetRequest', data).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  registerUser(data: UserData, route: string): Observable<any> {
    return this.http.post<UserData>(`${this.appURL}${route}`, data).pipe(retry(0));
  }

  loginUser(data: LoginUserData) {
    return this.http.post<LoginUserData>(this.appURL + 'login', data).pipe(map((e: any) => e));
  }

  staffLogin(email: string, password: string) {
    return this.http.post(this.staffURL + 'login', { usernameOrEmail:email, password }).pipe(map((e: any) => e));
  }

  getStaffAccessToken(refreshToken: string) {
    return this.http.post(this.staffURL + 'token', { refreshToken }).pipe(map((e: any) => e));
  }

  verifyToken(param: any, route: string) {
    return this.http.get(`${this.appURL}${route}/${param}`).pipe(retry(0));
  }

  getTokenExpirationDate(token: string) {
    const decoded = jwtDecode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(key = 'token'): boolean {
    const token = this.localStorage.get(key);
    if(!token){
      return true;
    } 
    const date = this.getTokenExpirationDate(token);
    if(date === undefined || date === null) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  validateUser(data: any) {
    return this.http.post(this.appURL + 'validate-user', data).pipe(retry(0));
  }

  getUserType(token: string | null): number {
    if (token) {
      const decodedToken = jwtDecode<JwtPayload>(token);
      return decodedToken.UserType; 
    }
    return 0;
  }

  checkIfPasswordUpdateNeeded(token: string | null): boolean {
    if (token) {
      const decodedToken = jwtDecode<JwtPayload>(token);
      return decodedToken.PasswordUpdateNeeded ?? false; 
    }
    return false;
  }

  sendHttpRequest(type : string, route : string, data : any, headers?:any){
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.localStorage.get('webToken')}`
    });
    if(headers != null){
      this.headers = headers;
    }
    // remove headers in the open route requests
    if(headers == false) {
      this.headers = null;
      this.appURL = environment.rootApiUrl;
    }
    switch(type) {
      case 'POST':
        return this.http.post<any>(`${this.appURL}${route}`, data, {headers: this.headers}).pipe(retry(0));
      case 'PATCH':
        return this.http.patch<any>(`${this.appURL}${route}`, data, {headers: this.headers}).pipe(retry(0));
      case 'PUT':
        return this.http.put<any>(`${this.appURL}${route}`, data, {headers: this.headers}).pipe(retry(0));
      default:
        return this.http.get<any>(`${this.appURL}${route}`, {headers: this.headers, params: data?.params}).pipe(retry(0));
    }
  }
}
