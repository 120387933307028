import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { AddEditSubjectPayload, Subjects } from "shared/src/interfaces/master-form.interface";

export const LoadSubject = createAction(
    '[Subject] Load Subject',
    props<{payload: GenericListingPayload}>(),
)

export const LoadSubjectSuccess = createAction(
    '[Subject] Load Subject Successfully',
    props<{subjects: Subjects[], totalRecords: number}>()
)

export const LoadSubjectError = createAction(
    '[Subject] Load Subject Error',
    props<{message: string}>()
)

export const LoadParentSubject = createAction(
    '[Subject] Load Parent Subject',
    props<{subjectID: string}>()
)

export const LoadParentSubjectSuccess = createAction(
    '[Subject] Load Parent Subject Successfully',
    props<{subjects: Subjects[]}>()
)

export const LoadParentSubjectError = createAction(
    '[Subject] Load Parent Subject Error',
    props<{message: string}>()
)

export const UpdateSubjectStatus = createAction(
    '[Subject] Update Subject Status',
    props<{subjectIDs: number[], status: string, subject: Subjects}>(),
)

export const UpdateSubjectStatusSuccess = createAction(
    '[Subject] Update Subject Status Success',
    props<{status: string, message: string, subject: Subjects, subjectIDs: number[]}>(),
)

export const UpdateSubjectStatusError = createAction(
    '[Subject] Update Subject Status Error',
    props<{message: string}>(),
)

export const AddSubject = createAction(
    '[Subject] Add Subject',
    props<{payload: AddEditSubjectPayload}>(),
)

export const AddSubjectSuccess = createAction(
    '[Subject] Add Subject Success',
    props<{subject: Subjects, message: string}>(),
)

export const AddSubjectError = createAction(
    '[Subject] Add Subject Error',
    props<{message: string}>(),
)

export const EditSubject = createAction(
    '[Subject] Edit Subject',
    props<{payload: AddEditSubjectPayload, subjectId: string}>(),
)

export const EditSubjectSuccess = createAction(
    '[Subject] Edit Subject Success',
    props<{subject: Subjects, message: string}>(),
)

export const EditSubjectError = createAction(
    '[Subject] Edit Subject Error',
    props<{message: string}>(),
)

export const DeleteSubject = createAction(
    '[Subject] Delete Subject',
    props<{subjectIDs: number[]}>(),
)

export const DeleteSubjectSuccess = createAction(
    '[Subject] Delete Subject Success',
    props<{message: string}>(),
)

export const DeleteSubjectError = createAction(
    '[Subject] Delete Subject Error',
    props<{message: string}>(),
)