import { createAction, props } from "@ngrx/store";
import { GenericListingPayloadWithSearch } from "shared/src/interfaces/common.interface";
import { AddEditCuratedCollectionPayload, CuratedCollection } from "shared/src/interfaces/curated-collection.interface";

export const LoadCuratedCollectionList = createAction(
    '[CuratedCollection] Load Curated Collection List',
    props<{ payload: GenericListingPayloadWithSearch }>(),
)

export const LoadCuratedCollectionListSuccess = createAction(
    '[CuratedCollection] Load Curated Collection List Successfully',
    props<{ curatedCollection: CuratedCollection[], totalRecords: number }>()
)

export const LoadCuratedCollectionListError = createAction(
    '[CuratedCollection] Load Curated Collection List Error',
    props<{ message: string }>()
)

export const ChangeCollectionStatus = createAction(
    '[CuratedCollection] Change Curated Collection Status',
    props<{ CollectionID: number, status: number }>(),
)

export const ChangeCollectionStatusSuccess = createAction(
    '[CuratedCollection] Change Curated Collection Status Successfully',
    props<{ curatedCollection: CuratedCollection, message: string }>()
)

export const ChangeCollectionStatusError = createAction(
    '[CuratedCollection] Change Curated Collection Status Error',
    props<{ message: string }>()
)

export const AddCuratedCollection = createAction(
    '[CuratedCollection] Add CuratedCollection',
    props<{payload: AddEditCuratedCollectionPayload}>(),
)

export const AddCuratedCollectionSuccess = createAction(
    '[CuratedCollection] Add CuratedCollection Success',
    props<{curatedCollection: CuratedCollection, message: string}>(),
)

export const AddCuratedCollectionError = createAction(
    '[CuratedCollection] Add CuratedCollection Error',
    props<{message: string}>(),
)

export const EditCuratedCollection = createAction(
    '[CuratedCollection] Edit CuratedCollection',
    props<{payload: AddEditCuratedCollectionPayload, collectionID: string}>(),
)

export const EditCuratedCollectionSuccess = createAction(
    '[CuratedCollection] Edit CuratedCollection Success',
    props<{curatedCollection: CuratedCollection, message: string}>(),
)

export const EditCuratedCollectionError = createAction(
    '[CuratedCollection] Edit CuratedCollection Error',
    props<{message: string}>(),
)

export const AutoLoadCuratedCollectionList = createAction(
    '[CuratedCollection] Auto Load Curated Collection List'
)