<section class="innerpage-banner">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            Home
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="#">
            video gallery
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="page-title">
          <h1 class="heading-dark semibold mb-0">{{'pdl-website.videoGallery.titleText' | translate }}</h1>
        </div>
      </div>
    </div>

  </div>
</section>
<section class="videoGallery-section section-padding ">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="text-about-page">
          <p class="pdl-grey text-center">{{'pdl-website.videoGallery.descriptionText' | translate }}</p>
        </div>
      </div>
      <div class="col-12">
        <div class="results-and-filters d-flex align-items-center justify-content-between">
          <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
            <span>{{'pdl-shared.pagination.showing' | translate}}</span>
            <span>{{fromRecords}}</span>
            <span>{{'pdl-shared.pagination.to' | translate}}</span>
            <span>{{toRecords}}</span>
            <span>{{'pdl-shared.pagination.of' | translate}}</span>
            <span>{{totalRecords}}</span>
            <span>{{'pdl-shared.pagination.entries' | translate}}</span>
          </div>
          <div class="page-filters-dropdown">
            <div class="dropdown page-filter-dropdown d-flex">
              <img src="assets/images/icons/filters-ico.svg" alt="filtes" />
              <a class="page-filter-action btn btn-secondary dropdown-toggle" href="#" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                {{'pdl-shared.buttonText.filters' | translate}}
              </a>

              <ul class="dropdown-menu">
                <li><a class="dropdown-item cursor-pointer" [ngClass]="orderBy==='VideoOrder' ? 'active-sort' : ''"
                    (click)="sortVideos('VideoOrder')">{{'pdl-admin.download.default' | translate}}</a></li>
                <li><a class="dropdown-item cursor-pointer" [ngClass]="orderBy==='Title' ? 'active-sort' : ''"
                    (click)="sortVideos('Title')">{{'pdl-admin.download.alphabetically' | translate}}</a></li>
                <li><a class="dropdown-item cursor-pointer" [ngClass]="orderBy==='PublishedDate' ? 'active-sort' : ''"
                    (click)="sortVideos('PublishedDate')">{{'pdl-admin.download.recentlyAdded' | translate}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-12">
        <div class="videos-in-grid">
          <div class="row justify-content-start">
            @for(video of videoList;track video?.ID) {
             <div class="col-lg-3 col-md-4 col-sm-6 col-12">
              <a class="videos-card">
                <div class="block-img cursor-pointer">
                  <img src="{{imageBaseUrl}}{{video?.ThumbnailPath}}" alt="video" (error)="handleImageError($event)" (click)="redirectToVideo(video.ID)">
                </div>
                <div class="block-text-bar justify-content-between align-items-center">
                  <h6 class="pdl-title-color"><span class="text__ellipse">{{video?.Title}} </span>
                    <span class="grey-color" triggers="mouseenter:mouseleave" popoverClass="tooltip-custom-class"
                      placement="end">{{'pdl-shared.pdlChannelText' |
                      translate}} 
                  <img src='assets/images/icons/verified-channel.png' alt='check' /></span>
                  </h6>
                  <span class="grey-color d-flex colgap-5 align-items-center">
                    <span> {{video?.ViewCount}} views</span>
                    <span class="bullet_icon"></span>
                    <span> {{video?.PublishedDate}}</span>
                  </span>
                </div>
              </a>
            </div>
          }
          </div>
        </div>


      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div class="pdl-pagination d-flex align-items-center justify-content-center">
          <nav aria-label="Page navigation example">
            @if(pages?.length) {
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{disabled:pageNumber === 1}">
                        <a (click)="loadAllVideos(pageNumber - 1)" class="page-link" (keyup.enter)="loadAllVideos(pageNumber - 1)" [tabIndex]="pageNumber === 1 ? -1 : 0 ">
                            <img src="assets/images/icons/pagination-left.svg" alt="icon">
                        </a>
                    </li>
                    @for(page of pages; track page; let i = $index) {
                        <li [ngClass]="{active:pageNumber === page}" class="page-item">
                            <a *ngIf="page !== '...'" (click)="loadAllVideos(page)" class="page-link" tabindex="0" (keyup.enter)="loadAllVideos(page)">{{page}}</a>
                            <span *ngIf="page === '...'" class="page-link">{{page}}</span>
                        </li>
                    }
                    <li class="page-item next" [ngClass]="{disabled:pageNumber === totalNoOfPages}">
                        <a (click)="loadAllVideos(pageNumber + 1)" class="page-link"  (keyup.enter)="loadAllVideos(pageNumber + 1)" [tabIndex]="pageNumber === totalNoOfPages ? -1 : 0 ">
                            <img src="assets/images/icons/pagination-right.svg" alt="icon">
                        </a>
                    </li>
                  
                </ul>
            }
          </nav>
        </div>
      </div>
    </div>
  </div>

</section>
