/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { WebUserLibraryActions } from '../../actions';
import { WebUserLibraryService } from 'shared/src/services/website/user-library/user-library.service';

@Injectable()
export class WebsiteUserLibraryEffects {
  constructor(
    private actions$: Actions, 
    private store: Store<AppState>,
    private service : WebUserLibraryService
  ) {}

  /////////////////// ResearchTopics ///////////////////////////
  ResearchTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebUserLibraryActions.WebsiteUserLibrary),
      exhaustMap((action) => {
        return this.service.researchTopics(action?.payload).pipe(
          map((response) => {
            return WebUserLibraryActions.WebsiteUserLibrarySuccess({
              topicList: response
            });
          }),
          catchError((error) => {
            return of(
              WebUserLibraryActions.WebsiteUserLibraryError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );


}
