/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddEditResearchTopic } from 'shared/src/interfaces/website/user-library.interface';
import { Subscription } from 'rxjs';
import { WebUserLibraryService } from 'shared/src/services/website/user-library/user-library.service';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { requiredNoWhitespaceValidator } from 'shared/src/validators/white-space.validator';

@Component({
  selector: 'panjab-digi-lib-create-edit-topic',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
  templateUrl: './create-edit-topic.component.html',
  styleUrl: './create-edit-topic.component.scss',
})
export class CreateEditTopicComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  submitted: boolean = false;
  subscription = new Subscription();
  error: string = '';
  @Input() docId!: number;
  @Input() topicName: string = '';
  @Input() topicDescription: string = '';
  @Input() topicId!: number;
  @Input() addToNew : boolean = false;
  @Output() closeModal = new EventEmitter<boolean>();
  constructor(
    private fb: FormBuilder,
    private libraryService: WebUserLibraryService,
    readonly toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.initiateForm();
  }

  initiateForm() {
    this.form = this.fb.group({
      name: ['', [requiredNoWhitespaceValidator()]],
      description: ['', [requiredNoWhitespaceValidator(), Validators.maxLength(100)]]
    });
    if (this.topicName && this.topicDescription && this.topicId) {
      this.setFormValues();
    }
  }

  //////////////// get form control /////////////
  get f() {
    return this.form.controls;
  }

  ///////////// clear error if required field is there //////////
  onInput() {
    if (!this.f['name']?.hasError('required')) {
      this.error = '';
    }
  }

  //////////// set form values in case of edit  ////////
  setFormValues() {
    this.form.patchValue({
      name: this.topicName,
      description: this.topicDescription
    })
  }

  /////////////////////// submit form  //////////////////
  submitForm(formValue: FormGroup) {
    this.submitted = true;
    this.error = '';
    if (!this.form.valid) {
      return;
    }
    const payload: AddEditResearchTopic = {
      name : formValue.value.name.trimStart(),
      description : formValue.value.description.trimStart()
    };
    const url = !this.topicId ? 'addResearchTopic' : 'editResearchTopic';
    if (this.docId) {
      payload.docId = this.docId;
    }
    if(this.topicId) {
      payload.topicId = this.topicId;
    }
    if(this.addToNew) {
      payload.addToNew = true;
    }
    this.subscription.add(
      this.libraryService[url](payload).subscribe({
        next: (response) => {
          this.toastService.showSuccess(response?.message);
          this.close(true);
        },
        error: (error) => {
          this.error = error?.error?.error?.message;
        },
      }));
  }

  ////////////////////////close Modal ////////////
  close(created: boolean = false) {
    this.closeModal.emit(created);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
