/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AdvancedSearchSidebarComponent } from '../advanced-search-sidebar/advanced-search-sidebar.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-search-results-view',
  standalone: true,
  imports: [CommonModule, TranslateModule, AdvancedSearchSidebarComponent, RouterLink],
  templateUrl: './search-results-view.component.html',
  styleUrl: './search-results-view.component.scss',
})
export class SearchResultsViewComponent implements OnInit {
  fallbackImage = 'assets/images/icons/no-image-placeholder.svg';
  @Input() data: any = '';
  @Input() listType: any = '';
  @Input() showLikeAndMoreOptions = false;
  @Input() viewType = 'list';
  showSearch: boolean = false;
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  getRating(rating: number): number[] {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const noStars = 5 - fullStars - (halfStar ? 1 : 0);

    return [
      ...Array(fullStars).fill(1),
      ...(halfStar ? [0.5] : []),
      ...Array(noStars).fill(0),
    ];
  }

  // Method to get the appropriate star image URL
  getStarImage(star: number): string {
    switch (star) {
      case 1:
        return 'assets/images/icons/full-star.svg';
      case 0.5:
        return 'assets/images/icons/half-star.svg';
      case 0:
        return 'assets/images/icons/no-star.svg';
      default:
        return 'assets/images/icons/no-star.svg';
    }
  }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }
}
