
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { TopicResponse } from 'shared/src/interfaces/website/user-library.interface';

export const WebsiteUserLibrary = createAction(
  '[User Library] User Library Items List',
  props<{ payload: {pageNumber : number, pageSize : number} }>()
);

export const WebsiteUserLibrarySuccess = createAction(
  '[User Library] User Library Items List Successfully',
  props<{ topicList: TopicResponse[] }>()
);

export const WebsiteUserLibraryError = createAction(
  '[User Library] User Library Items List Error',
  props<{ message: string }>()
);


