<header [ngClass]="{'fullscreen-active': fullScreenActive}" class="pt-3 pb-3 sticky-top bg-white" >
  <div class="container">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <a [routerLink]="'/'" class="d-flex align-items-center  mb-lg-0 text-white text-decoration-none">
        <img src="assets/images/icons/logo-icon.svg"> <span class="logo-title">{{'pdl-website.headerPage.logoTitle' |
          translate }}</span>
      </a>

      <div class="header-right d-flex flex-wrap align-items-center">
        <!-- <div class="card homesearch-card">
          <div class="card-body">
            <form class="col-12 col-lg-auto mb-4  homecard-search d-flex " role="search">
              <label class="search-label"> <img src="assets/images/icons/search-icon.svg">
              </label>
              <input type="search" class="form-control form-control-dark homesearch-input" [(ngModel)]="searchInput"
                autocomplete="off" (input)="suggestion($event)" (keydown.enter)="searchOnEnterKey()"
                (keydown)="handleKeyDown($event)" placeholder="Type title, author..." aria-label="Search" name="search">
              @if(suggestionList.length > 0) {
              <div class="auto__suggested">
                @for (suggestion of suggestionList; track suggestion;let index=$index) {
                <p (click)="search(suggestion)" [class.selected]="index === selectedIndex">{{suggestion}}</p>
                }
              </div>
              }

              <div class="dropdown homesearch-dropdown">
                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{selectedCategory === '' ? ('pdl-website.headerSearch.searchText' | translate) :
                  selectedCategory }}
                </a>

                <ul class="dropdown-menu">
                  @if(categoryId !== 0) {
                  <li class="cursor-pointer"><a class="dropdown-item" (click)="setCategory(0,'Search all')">
                      {{'pdl-website.headerSearch.searchText' |
                      translate }}</a></li>
                  }
                  @for(category of categories$ | async; track category.categoryID) {
                  <li class="cursor-pointer"><a class="dropdown-item"
                      (click)="setCategory(category.categoryID,category.Name)">{{category.Name}}</a></li>
                  } @empty{
                  <li class="ms-2"><a class="">{{'pdl-shared.content.noCategoryEnabled' | translate}}</a></li>

                  }
                </ul>
              </div>
            </form>
           
          </div>
        </div> -->
        @if(!isHomePage()) {
        <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3 header-search d-flex d-none d-lg-block " role="search">
          <label class="search-label"> <img src="assets/images/icons/search-icon.svg">
          </label>
          <input type="search" class="form-control form-control-dark headersearch-input" [(ngModel)]="searchInput"
            autocomplete="off" (input)="suggestion($event)" (keydown.enter)="searchOnEnterKey()"
            (keydown)="handleKeyDown($event)" placeholder="Search..." aria-label="Search" name="search"
            appAllowedCharacters>
          <!-- <input type="search" class="form-control form-control-dark headersearch-input" placeholder="Search..."
            aria-label="Search"> -->
          @if(suggestionList.length > 0 && showSuggestions) {
          <div class="auto__suggested">
            @for (suggestion of suggestionList; track suggestion;let index=$index) {
            <p (click)="search(suggestion)" [class.selected]="index === selectedIndex">{{suggestion}}</p>
            }
          </div>
          }
          <div class="dropdown header-search-dropdown">
            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{selectedCategory === '' ? ('pdl-website.headerSearch.searchText' | translate) :
              selectedCategory }}
            </a>
            <ul class="dropdown-menu">
              @if(categoryId !== 0) {
              <li class="cursor-pointer"><a class="dropdown-item" (click)="setCategory(0,'Search all')">
                  {{'pdl-website.headerSearch.searchText' |
                  translate }}</a></li>
              }
              @for(category of categories$ | async; track category.categoryID) {
              <li class="cursor-pointer"><a class="dropdown-item"
                  (click)="setCategory(category.categoryID,category.Name)">{{category.Name}}</a></li>
              } @empty{
              <li class="ms-2"><a class="">{{'pdl-shared.content.noCategoryEnabled' | translate}}</a></li>

              }
            </ul>
          </div>
        </form>
        }

        @if(activeUser) {
        <div class="user__info">
          <div class="dropdown">
            <a href="#" class="d-flex align-items-center text-decoration-none dropdown-toggle" data-bs-toggle="dropdown"
              aria-expanded="false">
              <div class="user__settings">
                <span class="fname">{{activeUser.fullname}}</span>
                <span>{{activeUser.username}}</span>
              </div>
              @if(activeUser.profileImgUrl) {
              <img [src]="activeUser.profileImgUrl" alt="avatar" width="40" height="40" class="rounded-circle me-2"
                onerror="this.src='assets/images/default-avatar.svg'">
              } @else {
              <img src="assets/images/default-avatar.svg" alt="avatar" width="40" height="40"
                class="rounded-circle me-2">
              }
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <!-- menuItem 1 -->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/library-icon.png" alt="library-icon" /></span>
                  <span><a href="/user/my-library" class="link_as_menuItem">{{'pdl-website.userProfile.myLibrary' |
                      translate}}</a></span>
                </div>
              </li>
              <!-- menuItem 2 -->
              <!-- next phase todo -->
              <!-- <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/document.png" alt="document" /></span>
                  <span href="#" class="dropdown-toggle d-flex align-items-center" (click)="$event.stopPropagation();"
                    role="button" data-bs-toggle="collapse"
                    data-bs-target="#custodian_submenu">{{'pdl-website.userProfile.myCustodian' | translate}}
                  </span>
                </div>

              </li> -->
              <!-- submenu for custodian documents starts -->
              <!-- <ul id="custodian_submenu" class="collapse ">
                <li class="dropdown-item">
                  <div class="menuItem submenuItem">
                    <span><img src="/assets/images/icons/header-menu/document.png" alt="document" /></span>
                    <span>
                      <a href="#" class="link_as_menuItem">Thapar Institiue</a>
                    </span>
                  </div>
                </li>
                <li class="dropdown-item">
                  <div class="menuItem submenuItem">
                    <span><img src="/assets/images/icons/header-menu/document.png" alt="document" /></span>
                    <span>
                      <a href="#" class="link_as_menuItem">Panjab University</a>
                    </span>
                  </div>
                </li>
              </ul> -->
              <!-- submenu for custodian documents ends -->
              <!-- menuItem 3 -->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/cart.png" alt="cart" /></span>
                  <span><a href="#" [routerLink]="['/adopt-a-book/checkout']"  class="link_as_menuItem">{{'pdl-website.userProfile.myCart' | translate}}</a></span>
                </div>
              </li>
              <!-- cart submenu start -->
              <!-- <ul id="cart_submenu">
                <li class="dropdown-item">
                  <div class="menuItem submenuItem">
                    <span><img src="/assets/images/icons/header-menu/bag.png" alt="bag" /></span>
                    <span>
                      <a href="#" class="link_as_menuItem">{{'pdl-website.userProfile.cart' | translate}}</a>
                    </span>
                  </div>
                </li>
                <li class="dropdown-item">
                  <div class="menuItem submenuItem">
                    <span><img src="/assets/images/icons/header-menu/cart_checked.png" alt="cart checked" /></span>
                    <span>
                      <a href="#" class="link_as_menuItem">{{'pdl-website.userProfile.myOrders' | translate}}</a>
                    </span>
                  </div>
                </li>
              </ul> -->
              <!-- cart submenu ends -->
              <!-- menuItem 4 -->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/collections.png" alt="collections" /></span>
                  <span><a [routerLink]="['/user/my-collections']"
                      class="link_as_menuItem">{{'pdl-website.userProfile.myCollections' | translate}}</a></span>
                </div>
              </li>
              <!-- menuItem 5 -->
              <!-- next phase todo -->
              <!-- <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/chat.png" alt="chat" /></span>
                  <span><a [routerLink]="['/user/my-comments']" class="link_as_menuItem">{{'pdl-website.userProfile.myComments' |
                      translate}}</a></span>
                </div>
              </li> -->
              <!-- menuItem 6 -->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/bookmark.png" alt="bookmark" /></span>
                  <span><a [routerLink]="['/user/adopted-books']"
                      class="link_as_menuItem">{{'pdl-website.userProfile.adoptedBooks' | translate}}</a></span>
                </div>
              </li>
              <!-- menuItem 7 -->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/donations.png" alt="donations" /></span>
                  <span><a [routerLink]="['/user/donations']"
                      class="link_as_menuItem">{{'pdl-website.userProfile.donationTitle' | translate}}</a></span>
                </div>
              </li>
              <!-- menuItem 8 -->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/my-profile.png" alt="My Profile" /></span>
                  <span><a [routerLink]="['/profile/me']" class="link_as_menuItem">{{'pdl-website.userProfile.TitleText'
                      | translate}}</a></span>
                </div>
              </li>
              <!--- menuItem 9-->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/document.png" alt="document" /></span>
                  <!-- <a href="#" class="link_as_menuItem">My Custodian Documents</a> -->
               <span><a [routerLink]="['/user/upload-history']" class="link_as_menuItem">{{'pdl-website.userProfile.uploadedDocuments' | translate}}</a></span>

                </div>

              </li>
              <!-- menuItem 10 -->
              <li class="dropdown-item ">
                <div class="menuItem">
                  <span><img src="/assets/images/icons/header-menu/logout.png" alt="Logout" /></span>
                  <span><a href="javascript:void(0)" (click)="signOut()"
                      class="link_as_menuItem">{{'pdl-website.userProfile.logout' | translate}}</a></span>
                </div>
              </li>
              <!-- <li><a class="dropdown-item" [routerLink]="['/profile/me']">Profile</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item" [routerLink]="['/user/donations']">{{'pdl-website.userProfile.donationTitle' | translate}}</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="javascript:void(0)" (click)="signOut()">Sign out</a></li> -->
            </ul>
          </div>
        </div>
        } @else {
        <div class="text-end">
          <!-- <button type="button" class="btn btn-outline-light me-2">Login</button> -->
          <a [routerLink]="'/login-user'" type="button" class="btn pdl-btn-login fixed-border-width">
            {{'pdl-website.headerLogin.loginText' | translate }}
          </a>
        </div>
        }
      </div>
    </div>
  </div>
</header>
<div class="topbar-toggle">
  <div class="container-fluid">
    <div class="row">
      <panjab-digi-lib-topbar></panjab-digi-lib-topbar>
    </div>
  </div>

</div>
