import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent, AppState, CountryActions, CountrySelector, environment  } from '@panjab-digi-lib/shared';
import { SocialLoginComponent } from '../common/social-login/social-login.component';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaComponent } from 'ng-recaptcha';
import { SocialUser } from '@abacritt/angularx-social-login';
import { UserService } from '../../../common/services/user.service';
import { AuthSelector } from '@panjab-digi-lib/shared';
import { ConfirmationModalService } from 'shared/src/services/confirmation-modal/confirmation-modal.service';
import { AuthActions } from '@panjab-digi-lib/shared';
import { ResetPasswordValidator } from 'shared/src/validators/reset-password.validator';

const VALIDATE_TYPE = {
  USERNAME: 1,
  EMAIL: 2
}
@Component({
  selector: 'panjab-digi-lib-register-user',
  standalone: true,
  imports: [CommonModule, TranslateModule, SocialLoginComponent, BreadcrumbsComponent, ReactiveFormsModule, NgbTooltipModule, RouterLink, AlertsComponent, SpinnerButtonComponent, RecaptchaModule, RecaptchaFormsModule],
  templateUrl: './register-user.component.html',
  styleUrl: './register-user.component.scss'
})
export class RegisterUserComponent implements OnInit, AfterViewInit, OnDestroy {
  pageTitle = 'Signup';
  userSignUpForm!: FormGroup;
  countryList$!: Observable<any>;
  availableUsernames!:string;
  successMsg = false;
  isSubmitted = false;
  sitekey = environment.recaptcha.siteKey;
  isMobile = false;
  subscription = new Subscription();
  @ViewChild('userInput') userInput!: ElementRef;
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private router: Router,
    private localStorage: LocalStorageService,
    private el: ElementRef,
    private alertService: AlertService,
    private renderer: Renderer2,
    // private socialAuthService: SocialAuthService,
    private userService: UserService,
    private confirmationModalService: ConfirmationModalService
  ) {

    this.getCountriesList();
    this.initSignUpForm();
  }

  ngOnInit() {
    this.resetSignUpForm();
    this.detectFormChanges();
    if(this.localStorage.get('webToken')) {
      this.router.navigate(['/']);
    }
    // change recaptcha size when it is on mobile view
    if(window.innerWidth < 370) {
      this.isMobile = true;
    }
    this.subscription.add(
      this.store.select(AuthSelector.selectWebToken).subscribe((token: string) => {
        if(token) {
          this.router.navigate(['/']);
        }
      })
    );
    this.subscription.add(
      this.store.select(AuthSelector.selectError).subscribe((error: any) => {
        if (error && error.message) {
          if(error?.message?.indexOf('Your account is deactivated') !== -1) {
            this.confirmationModalService.confirm(
              this.translate.instant('pdl-website.forms.labels.reactivateAccConfirm.title'), 
              this.translate.instant('pdl-website.forms.labels.reactivateAccConfirm.desc'), 
              this.translate.instant('pdl-shared.buttonText.yes'), 
              this.translate.instant('pdl-shared.buttonText.no'),  
              'md').then((confirmed) => {
              if(confirmed) {
                if(error.type && error.type == 'social_login') {
                  // social login
                  const payloads = {...error.user};
                  this.userService.handleSocialLogin(payloads, true);
                }
              }
              this.store.dispatch(AuthActions.resetState());
            });
          } else {
            this.alertService.error(error?.message);
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    this.renderer.listen(this.userInput.nativeElement, 'blur', () => {
      this.validateOnBlur(VALIDATE_TYPE.USERNAME);
    });
    this.renderer.listen(this.emailInput.nativeElement, 'blur', () => {
      this.validateOnBlur(VALIDATE_TYPE.EMAIL);
    });
  }

  getCountriesList() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({payload}));
    this.countryList$ = this.store.select(CountrySelector.getCountries);
  }

  validateUser(
    type: number,
    control: AbstractControl
  ): void {
    let requestType;
    if(type == VALIDATE_TYPE.USERNAME) {
      requestType = {
        username: control.value
      }
    } else if(type == VALIDATE_TYPE.EMAIL) {
      requestType = {
        email: control.value
      }
    }
    if(requestType) {
      this.authService.validateUser(requestType).subscribe({
        next: (res) => {
          //
        },
        error: (err) => {
          if(err.status == 400) {
            if(err.error?.error?.message == 'Username already taken') {
              this.updateErrorState('username');
              this.availableUsernames = `${err.error.error.details.availableUsernames.join(', ')}`
            } else if(err.error?.error?.message == 'Email already taken') {
              this.updateErrorState('email');
            }
          }
        }
      })
    }
  }

  initSignUpForm() {
    this.userSignUpForm = this.fb.group(
      {
        email: ['', {
          validators: [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)],
          updateOn: 'blur'
        }],
        username: ['', {
          validators: [Validators.required, Validators.minLength(4), Validators.maxLength(30), Validators.pattern('^[a-zA-Z0-9]+(?:[.])*(?:[a-zA-Z0-9])+$')],
          updateOn: 'blur'
        }],
        fullname: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^[a-zA-Z]+(?:\s[a-zA-Z0-9]+)*(?:\s[a-zA-Z0-9]+)?$/)]],
        password: ['', [
          Validators.required, 
          Validators.minLength(6), 
          Validators.maxLength(30), 
          Validators.pattern('^[a-zA-Z0-9$@#^!%*?&_]+$'),
          ResetPasswordValidator.verifyStrength()
        ]],
        confirmpassword: ['', [Validators.required]],
        country: ['', Validators.required],
        state: [''],
        address1: [''],
        address2: [''],
        cpt: [null, Validators.required]
      }
    );
  }

  detectFormChanges() {
    // detect password changes
    this.userSignUpForm.get('password')?.valueChanges.subscribe(() => {
      this.updateErrorState('cpassword');
    });
    // detect confirm password changes
    this.userSignUpForm.get('confirmpassword')?.valueChanges.subscribe(() => {
      this.updateErrorState('cpassword');
    });
  }

  validateOnBlur(type: number) {
    if(type == VALIDATE_TYPE.USERNAME) {
      const username = this.userSignUpForm.get('username');
      if(username && username.value?.length > 4) {
        this.validateUser(type, username);
      }
    } else if(type == VALIDATE_TYPE.EMAIL) {
      const email = this.userSignUpForm.get('email');
      if(email && email?.valid) {
        this.validateUser(type, email);
      }
    }
  }

  updateErrorState(field: string) {
    if(field === 'cpassword') {
      const password = this.userSignUpForm.controls['password'].value;
      const cpassword = this.userSignUpForm.controls['confirmpassword'].value;
      if(cpassword !== password) {
        this.userSignUpForm.controls['confirmpassword'].setErrors({ 'invalid': true });
      } else {
        this.userSignUpForm.controls['confirmpassword'].setErrors(null);
      }
    } else {
      this.userSignUpForm.controls[field].setErrors({ 'invalidField': true });
    }
  }

  resetSignUpForm() {
    this.availableUsernames = '';
    this.userSignUpForm.reset();
  }

  focusOnInvalidField(): void {
    for (const key of Object.keys(this.userSignUpForm.controls)) {
      if (this.userSignUpForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }

  moveToFirstElement(): void {
    this.el.nativeElement.querySelector('[formcontrolname="email"]').focus();
  }

  resolveCaptcha(captchaResponse: string | null) {
    if(captchaResponse) {
      this.userSignUpForm.patchValue({'cpt': captchaResponse})
    } else {
      this.userSignUpForm.controls['cpt'].setErrors({'invalid': true});
    }
  }

  resetReCaptcha() {
    this.captchaRef.reset();
  }

  signInWithFB(): void {
    this.userService.handleFBSignIn();
  }

  handleSocialLogin(user: SocialUser): void {
    this.userService.handleSocialLogin(user);
  }

  handleBadRequestError(err: any): void {
    if(err.error?.error?.message == 'Username already taken') {
      this.updateErrorState('username');
      this.availableUsernames = `${err.error.error.details.availableUsernames.join(', ')}`
    } else if(err.error?.error?.message == 'Email already taken') {
      this.updateErrorState('email');
    } else {
      this.alertService.error(err.error?.error?.message);
    }
  }

  handleFormSubmission() {
    const password = this.userSignUpForm.controls['password'].value;
    const confirmpassword = this.userSignUpForm.controls['confirmpassword'].value;
    // update error state if password doesn't match with confirm password
    if(confirmpassword !== password) {
      this.updateErrorState('cpassword');
    } else {
      this.isSubmitted = true;
      this.authService.registerUser(this.userSignUpForm.value, 'register').subscribe({
        next: (res:any) => {
          this.successMsg = true;
          this.alertService.success(this.translate.instant('pdl-website.forms.user.successMsg'));
          this.resetSignUpForm();
          this.moveToFirstElement();
          this.isSubmitted = false;
          this.resetReCaptcha();
        },
        error: (err) => {
          this.userSignUpForm.controls['username'].setErrors(null);
          this.userSignUpForm.controls['email'].setErrors(null);
          if(err.status == 400) {
            this.handleBadRequestError(err);
          }
          this.isSubmitted = false;
          this.resetReCaptcha();
        }
      });
    }
  }

  onSubmit() {
    this.alertService.clear();
    this.userSignUpForm.markAllAsTouched();
    if(this.userSignUpForm.valid) {
      this.handleFormSubmission();
    } else {
      this.focusOnInvalidField();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
