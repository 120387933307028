export const APP_CONSTANTS = {
  UserType: {
    ADMIN: 1,
    STAFF: 2,
    WebUser: 3,
  },
  AdopterType: {
    INDIVIDUAL: 1,
    ORGANIZATION: 2,
  },
  OrderStatus: {
    PENDING: 1,
    COMPLETED: 2,
    CANCELLED: 3,
    REFUNDED: 4,
    PARTIALLY_REFUNDED: 5,
  },
  OrderStatusNames: {
    1: 'Pending',
    2: 'Completed',
    3: 'Cancelled',
    4: 'Refunded',
    5: 'Partially Refunded',
  },
  GatewayType: {
    1: 'Razor Pay',
    2: 'Paypal',
  },
  DefaultStaffRoute: '/dashboard',
  StaffUpdatePwdRoute: '/update-password',
  defaultErrMsg: 'Something went wrong! Please try again later.',
  maxSize: 10,
  maxSizeMobile: 5,
  pageSize: 20,
  mergedCount: 15,
  maxReadMoreSize: 350,
  defaultDateFormat: 'dd-MM-yyyy',
  documentStatus: {
    ONLINE: 1,
    WEB_READY: 2,
    OFFLINE: 3,
  },
  dynamicMenu: {
    accessionNumber: 'Accession Number',
    assignTask: 'Assign Task',
    approveTask: 'Approve',
  },
  volunteerReqOptions: [
    {
      type: 'organize',
      value: 'Organize - events, exhibitions, campaigns',
    },
    {
      type: 'fundraise',
      value: 'Fundraise - grants, corporate and individual gifts',
    },
    {
      type: 'digitize',
      value: 'Digitize - scan, edit images and text, translate',
    },
    {
      type: 'recruit',
      value: 'Recruit - screen, interview, train, and develop personnel',
    },
    {
      type: 'develop',
      value: 'Develop - strategy and plans (PR, Legal, IT, HR, Fund)',
    },
    {
      type: 'host',
      value:
        'Host - provide accommodation and hospitality for volunteers, and staff',
    },
  ],
  regExp: {
    alphaNumericOnly: /^[a-zA-Z]+(?:\s[a-zA-Z0-9]+)*(?:\s[a-zA-Z0-9]+)?$/,
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    numberOnly: /^\d+$/,
    url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
  },
  volunteerRequestStatus: {
    APPROVED: 1,
    REJECTED: 2,
    PENDING: 3,
  },
  userPortalAccess: {
    YES: 1,
    NO: 2,
  },
  IdleUserTimes: {
    IDLE_TIME: 295,
    TIMEOUT: 5,
  },
  STATIC_STAFF_ID: 999999,
  TASK_STATUS: {
    Approved: 1,
    By_Passed: 2,
    Rejected: 3,
    Pending_Approval: 4,
    In_Progress: 5,
    Pending: 6,
  },
  TASK_NAME: {
    Metadata: 'Metadata',
    Upload: 'Upload',
  },
  UPLOAD_TYPES: {
    THUMBNAILS: 'Thumbnails',
    IMAGES: 'Images',
    PDF: 'Pdf',
    DOWNLOAD: 'Downloads',
    CONTENT: 'Content',
    VIDEOS: 'Videos',
    AUDIOS: 'Audios',
  }
};
