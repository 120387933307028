import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { catchError, map, Observable, throwError } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { MetadataPerCategory, MetadataOfCategory, SaveAllocationPayload, SaveAccessPayload, MetaPerCatDisplayConfig, MetaPerCatDisplayConfigPayload } from "shared/src/interfaces/master-form.interface";
import { GenericReponse } from 'shared/src/interfaces/category.interface';
@Injectable({
    providedIn: 'root'
})
export class MetadataPerCategoryService {
    rootURL = '';
    constructor(private http: HttpClient) {
        this.rootURL = environment.rootApiUrl;
    }

    loadMetadataPerCategory(): Observable<{ categories: MetadataPerCategory[] }> {
        return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.default}`).pipe(map((e: any) => e.body));
    }

    loadMetadataOfCategory(payload: GenericListingPayload, categoryID: number): Observable<{ metadata: MetadataOfCategory[], totalCount: number }> {
        return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.getMetadataForCategory}`.replace('{id}', (categoryID).toString()), { params: { ...payload } }).pipe(map((e: any) => e.body));
    }

    saveAllocations(payload: SaveAllocationPayload, categoryID: number): Observable<GenericReponse> {
        return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.saveAllocation}`.replace('{id}', (categoryID).toString()), payload).pipe(map((e: any) => e));
    }

    saveAccess(payload: SaveAccessPayload[], categoryID: number): Observable<GenericReponse> {
        return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.saveAccess}`.replace('{id}', (categoryID).toString()), { 'metadata': payload }).pipe(map((e: any) => e));
    }

    getTemplateConfig(categoryID: number): Observable<{ templateConfig: MetaPerCatDisplayConfig }> {
        return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.getTemplateConfig}`.replace('{id}', (categoryID).toString())).pipe(map((e: any) => e.body));
    }

    saveTemplateConfig(payload: MetaPerCatDisplayConfigPayload, categoryID: number): Observable<GenericReponse> {
        return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.saveTemplateConfig}`.replace('{id}', (categoryID).toString()), { ...payload }).pipe(
            map((e: any) => e),
            catchError(this.handleError)
        );
    }

    downloadMetadataOfCategory(categoryID: number, viewType: string) {
        return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.downloadMetadataOfCategory}`.replace('{id}', (categoryID).toString())+`?viewType=${viewType}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
    }

    updateIsRequired(id: number, status: boolean) {
        return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.metadataPerCategory.isRequiredStatus}`.replace('{id}', (id).toString()), {status: status}).pipe(map((e: any) => e));
    }
    
    private handleError(error: HttpErrorResponse) {
        let transformedError: HttpErrorResponse;

        if (error.error && error.error.error) {
        transformedError = error.error.error;
        }

        return throwError(() => transformedError);
    }
}
