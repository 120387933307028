/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule,NgbDropdownModule, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'panjab-digi-lib-year-picker-modal',
  standalone: true,
  imports: [CommonModule,NgbModule,NgbDropdownModule,NgbDropdownToggle,FormsModule],
  templateUrl: './year-picker-modal.component.html',
  styleUrl: './year-picker-modal.component.scss',
})
export class YearPickerModalComponent  {
  currentYear: number = new Date().getFullYear();
  minYear: number = 1900;
  maxYearsToShow: number = 10;
  decadeStart1!: number;
  decadeEnd1!: number;
  decadeStart2!: number;
  decadeEnd2!: number;
  years1: number[] = [];
  years2: number[] = [];
  selectedYear1: number | null = null;
  selectedYear2: number | null = null;
  inputYear1: string = '';
  inputYear2: string = '';
  isDropdownOpen1: boolean = false;
  isDropdownOpen2: boolean = false;


  ngOnInit() {
    this.initializeYears();
  }

  initializeYears() {
    this.updateDecade(this.currentYear, 1);
    this.updateDecade(this.currentYear, 2);
  }

  updateDecade(year: number, picker: number) {
    const decadeStart = Math.floor(year / 10) * 10;
    const decadeEnd = decadeStart + 10;

    if (picker === 1) {
      this.decadeStart1 = Math.max(decadeStart, this.minYear);
      this.decadeEnd1 = decadeEnd;
      this.years1 = this.generateYearsList(this.decadeStart1, this.decadeEnd1);
      this.selectedYear1 = null;
      this.inputYear1 = '';
    } else {
      this.decadeStart2 = Math.max(decadeStart, this.selectedYear1 ?? this.minYear);
      this.decadeEnd2 = decadeEnd;
      this.years2 = this.generateYearsList(this.decadeStart2, this.decadeEnd2);
      this.selectedYear2 = null;
      this.inputYear2 = '';
    }
  }

  generateYearsList(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      if (year <= this.currentYear && year >= this.minYear) {
        years.push(year);
      }
    }
    return years;
  }

  showPreviousDecade(picker: number) {
    if (picker === 1) {
      this.decadeStart1 -= this.maxYearsToShow;
      this.decadeEnd1 -= this.maxYearsToShow;
      this.decadeStart1 = Math.max(this.decadeStart1, this.minYear);
      this.years1 = this.generateYearsList(this.decadeStart1, this.decadeEnd1);
    } else {
      this.decadeStart2 -= this.maxYearsToShow;
      this.decadeEnd2 -= this.maxYearsToShow;
      // Ensure picker 2's start year does not go below selected year in picker 1
      if (this.selectedYear1 !== null) {
        this.decadeStart2 = Math.max(this.decadeStart2, this.selectedYear1);
      }
      this.decadeStart2 = Math.max(this.decadeStart2, this.minYear);
      this.years2 = this.generateYearsList(this.decadeStart2, this.decadeEnd2);
    }
  }

  showNextDecade(picker: number) {
    if (picker === 1) {
      if (this.decadeEnd1 < this.currentYear) {
        this.decadeStart1 += this.maxYearsToShow;
        this.decadeEnd1 += this.maxYearsToShow;
        this.decadeStart1 = Math.max(this.decadeStart1, this.minYear);
        this.years1 = this.generateYearsList(this.decadeStart1, this.decadeEnd1);
      }
    } else {
      if (this.decadeEnd2 < this.currentYear) {
        this.decadeStart2 += this.maxYearsToShow;
        this.decadeEnd2 += this.maxYearsToShow;
        this.decadeStart2 = Math.max(this.decadeStart2, this.minYear);
        this.years2 = this.generateYearsList(this.decadeStart2, this.decadeEnd2);
      }
    }
  }

  isForwardDisabled(picker: number): boolean {
    return picker === 1 ? this.decadeEnd1 >= this.currentYear : this.decadeEnd2 >= this.currentYear;
  }

  isPreviousDisabled(picker: number): boolean {
    if (picker === 1) {
      // Picker 1 can always go down to minYear
      return this.decadeStart1 <= this.minYear;
    } else {
      // Picker 2 should be restricted based on selected year in Picker 1
      return this.decadeStart2 <= (this.selectedYear1 ?? this.minYear);
    }
  }

  onInputChange(picker: number) {
    const inputYear = picker === 1 ? this.inputYear1 : this.inputYear2;
    const year = parseInt(inputYear, 10);

    if (!isNaN(year) && year <= this.currentYear && year >= this.minYear) {
      this.updateDecade(year, picker);
      this.selectYear(year, picker);
    } else {
      this.updateDecade(this.currentYear, picker);
    }
  }

  validateInput(event: any, pickerNumber: number) {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    if (numericValue.length <= 4) {
      event.target.value = numericValue;
    } else {
      event.target.value = numericValue.slice(0, 4);
    }
    if (pickerNumber === 1) {
      this.inputYear1 = event.target.value;
    } else {
      this.inputYear2 = event.target.value;
    }
    this.onInputChange(pickerNumber);
  }

  selectYear(year: number, picker: number) {
    if (picker === 1) {
      this.selectedYear1 = year;
      this.inputYear1 = year.toString();
      this.isDropdownOpen1 = false;
      // Update picker 2's decade based on selected year in picker 1
      this.updateDecade(year, 2);
    } else {
      this.selectedYear2 = year;
      this.inputYear2 = year.toString();
      this.isDropdownOpen2 = false;
    }
    if (picker === 1 && this.isDropdownOpen2) {
      this.isDropdownOpen2 = false;
    }
    if (picker === 2 && this.isDropdownOpen1) {
      this.isDropdownOpen1 = false;
    }
  }

  toggleDropdown(picker: number) {
    if (picker === 1) {
      this.isDropdownOpen1 = !this.isDropdownOpen1;
    } else {
      this.isDropdownOpen2 = !this.isDropdownOpen2;
    }
  }
  handleClick(event: Event) {
    event.stopPropagation();
  }

  onKeydown(event: KeyboardEvent, picker: number) {
    if (event.key === 'Enter') {
      this.onInputChange(picker);
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      this.onInputChange(picker);
    }
  }

  isToggleButtonVisible(picker: number): boolean {
    const inputYear = picker === 1 ? this.inputYear1 : this.inputYear2;
    const inputLength = inputYear.length;
    return inputLength === 0 || (inputLength >= 4 && this.isValidYear(inputYear, picker));
  }

  isValidYear(inputYear: string, picker: number): boolean {
    const year = parseInt(inputYear, 10);
    if (isNaN(year)) return false;
    if (picker === 1) {
      return year >= this.decadeStart1 && year <= this.decadeEnd1;
    } else {
      return year >= this.decadeStart2 && year <= this.decadeEnd2;
    }
  }
}

