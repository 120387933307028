import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { ModalPopupService } from 'shared/src/services/common/modal-popup.service';
import { SharedCommonService } from 'shared/src/services/common/common.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-modal-popup',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './modal-popup.component.html',
  styleUrl: './modal-popup.component.scss',
})
export class ModalPopupComponent implements OnInit, OnDestroy {
  @Input() title: string | undefined;
  @Input() message: string | undefined;
  @Input() btnOkText: string | undefined;
  @Input() btnCancelText: string | undefined;
  @Input() autoClose: boolean = true;
  @Input() onSubmitCallback: any;
  @Input() onCancelCallback: any;
  @Input() formGroup: FormGroup | undefined;
  @ViewChild('modalContent', { read: ViewContainerRef, static: true }) modalContent!: ViewContainerRef;
  btnsDisabled$: Observable<boolean> = this.modalPopupService.modalBtnsDisabled$;
  btnsLoading$: Observable<boolean> = this.modalPopupService.modalBtnsLoading$;
  isLoading = false;
  isExpanded: boolean = false;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private activeModal: NgbActiveModal, 
    private modalPopupService: ModalPopupService,
    private readonly sharedService: SharedCommonService
  ) {}

  ngOnInit(){
  }

  public decline() {
    this.activeModal.close(false);
    if(this.onCancelCallback) {
      this.onCancelCallback();
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
    if(this.onCancelCallback) {
      this.onCancelCallback();
    }
  }

  public onSubmit() {
    if(this.formGroup) {
      if (this.formGroup.valid) {
        this.emitSubmitCallback();  
      } else {
        this.formGroup?.markAllAsTouched();
      }
    } else {
      this.emitSubmitCallback();
    }
  }

  emitSubmitCallback() {
    if (this.onSubmitCallback) {
      this.onSubmitCallback();
    }
    if(this.autoClose) {
      this.activeModal.close(true);
    }
  }

  loadTemplate(template: TemplateRef<any>) {
    this.modalContent.clear();
    this.modalContent.createEmbeddedView(template);
  }

  //////////////// show read more or read less /////////////
  toggleMessageContent() {
    this.isExpanded = !this.isExpanded;
  }
 //////////////// show limited text upto defined characters ////////
  getTruncatedText(description: string) {
    return this.sharedService.getTruncatedText(description);
  }
  ///////////// should show read more or not /////////////
  shouldShowReadMore(description: string): boolean {
    return this.sharedService.shouldShowReadMore(description);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
