<div class="modal-wrapper">
  <div class="modal_container">
    <div class="closebtn cursor-pointer" (click)="close()">
      X
    </div>
    <form [formGroup]="form" (submit)="submitForm(form)">
    <div class="pt-5 pb-3 ">
      <div class="semibold text-center heading mb-2">{{!topicId ? ('pdl-website.forms.userLibrary.createTopic' | translate) : ('pdl-website.forms.userLibrary.editResearchTopic' | translate) }}</div>
      <div class="subheading text-center mb-3">{{'pdl-website.forms.userLibrary.researchTopicName' | translate }}</div>
      <div class="px-5 mb-3 field__section">
        <input type="text" formControlName="name" class="form-control search__input disabled" id="exampleFormControlInput1"
         [ngClass]="{'is-invalid': submitted && !f['name'].valid}" name="name" (input)="onInput()"
          placeholder="Research Topic"
          [maxlength]="64">
          @if(submitted && f['name'].errors?.['required']) {
          <div id="helpText" class="form-text err__msg">
            {{'pdl-shared.formValidationMsgs.required' | translate}}
         </div>   
          }
          @if(submitted && !f['name'].errors?.['required'] && error) {
          <div id="helpText" class="form-text err__msg">
          {{error}}
         </div> 
         } 
      </div>
      <div class="px-5 mb-5 field__section">
        <textarea formControlName="description" class="form-control search__input disabled" id="exampleFormControlInput1" rows="5"
          [ngClass]="{'is-invalid': submitted && !f['description'].valid}"
          placeholder="Note" [maxlength]="1000" name="description"></textarea>
          <div class="form-text info__msg">{{'pdl-shared.content.characterCount' | translate}}: {{
            (form.get('description')?.value) ? (form.get('description')?.value.length) : 0 }}/1000</div>
          <div id="helpText" class="form-text err__msg" *ngIf="submitted && f['description'].errors?.['required']">
            {{'pdl-shared.formValidationMsgs.required' | translate}}
        </div>  
      </div>
      <div class="text-center d-flex mx-auto mt-3 justify-content-center col-gap-12">
        <button class="btn btn__secondary" type="button"  (click)="close()">{{'pdl-shared.content.cancel' | translate}}</button>
        <button class="btn btn__primary">{{!topicId ? ('pdl-website.forms.userLibrary.createButton' | translate) : ('pdl-website.forms.userLibrary.updateButton' | translate)}}</button>
      </div>
    </div>
    </form>
  </div>
</div>
