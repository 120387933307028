/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListModalComponent } from '../list-modal/list-modal.component';
import {
  NgbCalendar,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { YearPickerModalComponent } from '../year-picker-modal/year-picker-modal.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonService } from 'apps/panjab-digi-lib/src/common/services/common.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
//import { ModalPopupService } from 'shared/src/services/common/modal-popup.service';
import { ModalPopupService } from '../../../../common/services/modal-popup.service';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WebSearchService } from 'shared/src/services/website/search/search.service';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalService } from 'shared/src/services/confirmation-modal/confirmation-modal.service';
import { SharedCommonService } from '@panjab-digi-lib/shared';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';

@Component({
  selector: 'panjab-digi-lib-advanced-search-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    ListModalComponent,
    YearPickerModalComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './advanced-search-sidebar.component.html',
  styleUrls: ['./advanced-search-sidebar.component.scss'],
})
export class AdvancedSearchSidebarComponent implements OnInit, OnDestroy {
  @Input() filters: Record<string, any> = {};
  selectedIds: Record<string, number[]> = {};
  selectedYear: number | null = null;
  model!: NgbDateStruct;
  subscription = new Subscription();
  saveSearchForm!: FormGroup;
  savedSearchFormError: boolean = false;
  errorMessage: string = '';
  savedSearch: string = '';
  years: number[] = [];
  dateSelections: {
    [key: string]: { fromYear: number | null; toYear: number | null };
  } = {};
  rangeSubject = new Subject<{ key: string; value: string }>();
  categoryId: string = '';
  query: string = '';
  textSearch: boolean = false;
  strictMode: string = 'false';
  isUserLoggedIn: boolean = false;
  @ViewChild('saveSearchModal') saveSearchModal!: TemplateRef<any>;
  @Output() savedSearchMessage = new EventEmitter<string>();

  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private calendar: NgbCalendar,
    private fb: FormBuilder,
    private modalPopupService: ModalPopupService,
    private translate: TranslateService,
    private searchService: WebSearchService,
    readonly toastService: ToastService,
    private route: Router,
    private confirmationModalService: ConfirmationModalService,
    private sharedService: SharedCommonService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService
  ) {
    const currentYear = new Date().getFullYear();
    this.years = Array.from(
      { length: currentYear - 1900 + 1 },
      (_, i) => 1900 + i
    );
    this.rangeSubject
      .pipe(debounceTime(300))
      .subscribe(({ key, value }: { key: string; value: string }) => {
        this.updateStringUrl(key, value);
        this.commonService.updateQueryParams({ [key]: [0, value] });
      });
  }

  ngOnInit(): void {
    this.initSaveSearchForm();
    this.subscription.add(
      this.commonService.modalSelection$.subscribe((params) =>
        this.handleModalSelection(params)
      )
    );
    this.savedSearch = this.getQueryParams();
    setTimeout(() => {
      this.initializeDateFilters();
    }, 0);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.categoryId = params['categoryId'];
      this.query = params['query'];
      this.textSearch = params['DocumentSearchableContent'] && this.filters['Categories'].allowedOCR ? true : false;
      if(params['DocumentSearchableContent'] && !this.filters['Categories'].allowedOCR) {
        this.includeTextSearch(false);
      }
    const categoryIndex = this.filters['Categories'].records.findIndex((rec:any) => rec.categoryID == this.sharedService.decodeFromBase64(this.categoryId));
    if(categoryIndex === -1) {
    const key = 'categoryId';    
    this.textSearch = false;
    this.updateStringUrl(key, this.sharedService.encodeToBase64(0));
    this.commonService.updateQueryParams({
      [key]: this.sharedService.encodeToBase64(0),
    });
    this.includeTextSearch(false);
    }
    this.strictMode = this.sharedService.decodeFromBase64(
        params['strictMode']
      );
    });
    this.isUserLoggedIn = this.authService.isTokenExpired('webToken') == false;
  }

  /////////////////// set initial values in datepicker in case of reload ///////////////////
  initializeDateFilters() {
    for (const key in this.filters) {
      if (this.filters[key].type === 'date') {
        this.ensureKeyInitialized(key);
        this.dateSelections[key].fromYear = this.filters[key]?.records
          ? this.filters[key]?.records[0]
          : null;
        this.dateSelections[key].toYear = this.filters[key]?.records
          ? this.filters[key]?.records[1]
          : null;
      }
    }
  }

  /////////// initialize add new form  ////////////

  initSaveSearchForm() {
    this.saveSearchForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(300)]],
    });
  }

  selectToday(): void {
    this.model = this.calendar.getToday();
  }

  openYearPicker(): void {
    const modalRef = this.modalService.open(YearPickerModalComponent);
    modalRef.result.then((year) => {
      this.selectedYear = year;
    });
  }
  //////////// filter updation on textbox changes /////////////////
  filterUpdation(key: string, value: string) {
    const filtersCopy = JSON.parse(JSON.stringify(this.filters));
    filtersCopy[key].records = value;
    this.filters = filtersCopy;
    if (key === 'Content search for') {
      key = 'DocumentSearchableContent';
    }
    if (key !== 'DocumentSearchableContent' && key !== 'ExcludedWords') {
      this.updateStringUrl(key, value);
      this.commonService.updateQueryParams({ [key]: value });
    } else {
      if (this.textSearch) {
        this.updateStringUrl(key, value);
        this.commonService.updateQueryParams({ [key]: value });
      }
    }
  }

  ///////////////// update on checkbox checked/unchecked  ///////////////////
  queryUpdation(key: string, id: number, value: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.updateSelectedIds(key, id, isChecked);
    this.updateFilters(key, id, value, isChecked);
    this.updateUrlParams();
    this.commonService.updateQueryParams(this.selectedIds);
  }

  getFilterCountText = (val:number) => ` (${val})`
  ///////////////// open modal in case of see more //////////////////////
  openModal(collection: string, data: any): void {
    const modalRef = this.modalService.open(ListModalComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    });

    const firstLetter = this.getFirstAlphabetLetter(data.records);
    modalRef.componentInstance.modalData = {
      collection,
      searchField: data.searchField,
      firstLetter,
      totalRecords: data.totalCount,
    };
  }

  ///////////////// get all keys of filter to display /////////////
  getFilterKeys(): string[] {
    return Object.keys(this.filters);
  }

  ///////////////// check if given value is array or not //////////////
  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  /////////////////// selection made in modal ////////////////////////
  handleModalSelection(params: {
    key: string;
    value: { id: number; isSelected: boolean; value: string };
  }): void {
    const { key, value } = params;
    if (key in this.filters) {
      this.updateSelectedIds(key, value.id, value.isSelected);
      this.updateFilters(key, value.id, value.value, value.isSelected, true);
    }
  }

  ///////////////////////////// update filters based on checkboxes/checked unchecked ////////////////////////
  updateFilters(
    key: string,
    id: number,
    value: string,
    isSelected: boolean,
    fromModal: boolean = false
  ): void {
    if (key in this.filters) {
      const filtersCopy = JSON.parse(JSON.stringify(this.filters));

      if (isSelected) {
        const findIndex = filtersCopy[key].records.findIndex(
          (record: any) => record.id === id
        );
        if (findIndex === -1) {
          filtersCopy[key].records.unshift({
            id,
            value,
            isSelected,
            fromModal,
          });
        } else {
          filtersCopy[key].records[findIndex].isSelected = true;
        }
      } else {
        const findIndex = filtersCopy[key].records.findIndex(
          (record: any) => record.id === id
        );
        if (findIndex > -1) {
          if (filtersCopy[key].records[findIndex].fromModal) {
            filtersCopy[key].records.splice(findIndex, 1);
          } else {
            filtersCopy[key].records[findIndex].isSelected = false;
          }
        }
      }
      this.filters = filtersCopy;
    } else {
      console.log(`Key "${key}" does not exist in filters.`);
    }
  }

  /////////////////////// update selected items to passed to search query ////////////////////////
  updateSelectedIds(key: string, id: number, isSelected: boolean): void {
    if (!this.selectedIds[key]) {
      this.selectedIds[key] = [];
    }

    let currentSelectedIds = [...this.selectedIds[key]];
    if (isSelected) {
      if (!currentSelectedIds.includes(id)) {
        currentSelectedIds.push(id);
      }
    } else {
      currentSelectedIds = currentSelectedIds.filter(
        (existingId) => existingId !== id
      );
    }
    this.selectedIds[key] = currentSelectedIds;
  }

  //////////////////// update url based on selection /////////////////////////
  updateUrlParams(): void {
    const urlParams = new URLSearchParams(window.location.search);
    for (const key in this.selectedIds) {
      if (this.selectedIds[key].length) {
        urlParams.set(key, JSON.stringify(this.selectedIds[key]));
      } else {
        urlParams.delete(key);
      }
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }

  //////////////// update string url params for textboxes /////////////////
  updateStringUrl(key: string, value: string): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (value) {
      urlParams.set(key, value);
    } else {
      urlParams.delete(key);
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }

  /////////////////// update query updation for non dependent fields such as height etc/////////////
  nonDependentQueryUpdation(key: string, value: string, event: Event): void {
    const urlParams = new URLSearchParams(window.location.search);
    const checked = (event.target as HTMLInputElement).checked;
    let existingValues: string[] = [];
    const currentValues = urlParams.get(key);
    if (currentValues) {
      existingValues = JSON.parse(currentValues);
    }
    if (checked) {
      if (!existingValues.includes(value)) {
        existingValues.push(value);
        urlParams.set(key, JSON.stringify(existingValues));
      }
    } else {
      if (existingValues.length > 0) {
        const updatedValues = existingValues.filter((val) => val !== value);
        if (updatedValues.length > 0) {
          urlParams.set(key, JSON.stringify(updatedValues));
        } else {
          urlParams.delete(key);
        }
        existingValues = [...updatedValues];
      }
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
    this.commonService.updateQueryParams({ [key]: existingValues });
  }

  /////////////////// update date url /////////////
  updateDateUrl(key: string, value: (number | null)[]): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (value[0] === null && value[1] === null) {
      urlParams.delete(key);
    } else {
      urlParams.set(key, JSON.stringify(value));
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
    this.commonService.updateQueryParams({ [key]: value });
  }

  //////////////////////// get first letter from records //////////////////
  getFirstAlphabetLetter(records: any[]): string | null {
    const concatenatedValues = records.map((record) => record.value).join('');
    const match = concatenatedValues.match(/[A-Za-z]/);
    return match ? match[0] : null;
  }

  /////////////////////// open save search modal ////////////////////////
  openSaveSearchModal() {
    this.saveSearchForm.controls['name'].patchValue('');
    this.errorMessage = '';
    this.modalPopupService.open(
      this.translate.instant('pdl-website.forms.advancedSearch.saveSearch'),
      this.translate.instant(
        'pdl-website.forms.advancedSearch.savedSearchMessage'
      ),
      this.saveSearchModal,
      this.translate.instant('pdl-staff.metadata.create'),
      this.translate.instant('pdl-staff.metadata.cancel'),
      this.handleOnSubmit.bind(this),
      this.handleOnCancel.bind(this),
      'md'
    );
  }

  ////////////////////////// save search /////////////////////////////
  handleOnSubmit() {
    if (!this.saveSearchForm.valid) {
      this.savedSearchFormError = true;
      return;
    }
    const payload = {
      Name: this.saveSearchForm.controls['name'].value,
      Path: this.getQueryParams(),
    };
    this.errorMessage = '';
    this.searchService.saveUserSearch(payload).subscribe({
      next: (response) => {
        const updatedFilters = { ...this.filters };
        if (!updatedFilters['SavedSearches']) {
          updatedFilters['SavedSearches'] = { records: [] };
        }
        const updatedRecords = [
          ...updatedFilters['SavedSearches'].records,
          response?.body,
        ];
        updatedFilters['SavedSearches'] = {
          ...updatedFilters['SavedSearches'],
          records: updatedRecords,
        };
        this.filters = updatedFilters;
        this.savedSearch = response?.body?.Path;
        this.modalPopupService.dismiss();
        this.savedSearchMessage.emit(response?.message);
      },
      error: (error) => {
        this.errorMessage = error?.error?.error?.message;
        this.toastService.showError(
          error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
        );
      },
    });
  }

  ///////////////////// get url aster search? to save as user search ////////////////
  getQueryParams() {
    const exactUrl = window.location.href;
    const queryString = exactUrl.split('search?')[1];
    if (queryString) {
      const paramsArray = queryString.split('&');
      const outputArray = paramsArray.map((param) => {
        const [key, value] = param.split('=');
        return `${decodeURIComponent(key)}=${decodeURIComponent(value)}`;
      });
      const output = outputArray.join('&');
      return output;
    }
    return '';
  }

  ///////////////// reset form on cancel or close ///////////////
  handleOnCancel() {
    this.saveSearchForm.reset();
  }

  ////////////////// from year //////////////////////
  onFromYearChange(event: Event, key: string) {
    const selectElement = event.target as HTMLSelectElement;
    if (selectElement.value !== 'null') {
      const selectedYear = Number(selectElement.value);
      this.ensureKeyInitialized(key);
      this.dateSelections[key].fromYear = selectedYear;
      if (
        this.dateSelections[key].toYear !== null &&
        selectedYear > this.dateSelections[key].toYear!
      ) {
        this.dateSelections[key].toYear = null;
      }
    } else {
      this.dateSelections[key].fromYear = null;
    }
    this.dateKeyValues(key);
  }

  /////////////////////// to year /////////////////////
  onToYearChange(event: Event, key: string) {
    const selectElement = event.target as HTMLSelectElement;
    if (selectElement.value !== 'null') {
      const selectedYear = Number(selectElement.value);
      this.ensureKeyInitialized(key);
      this.dateSelections[key].toYear = selectedYear;
      if (
        this.dateSelections[key].fromYear !== null &&
        this.dateSelections[key].fromYear! > selectedYear
      ) {
        this.dateSelections[key].fromYear = null; // Reset From Year if it is greater
      }
    } else {
      this.dateSelections[key].toYear = null;
    }
    this.dateKeyValues(key);
  }

  /////////////////// get from date and to date value ////////////
  dateKeyValues(key: string) {
    let value;
    if (
      !this.dateSelections[key].fromYear &&
      !this.dateSelections[key].toYear
    ) {
      value = [null, null];
    } else {
      value = [
        this.dateSelections[key].fromYear,
        this.dateSelections[key].toYear,
      ];
    }
    this.updateDateUrl(key, value);
  }

  //////////////////// initialize date from/to year keys //////////////////
  ensureKeyInitialized(key: string) {
    if (!this.dateSelections[key]) {
      this.dateSelections[key] = { fromYear: null, toYear: null };
    }
  }
  /////////////////// on range slider change ////////////////
  onRangeChange(key: string, event: Event): void {
    const input:any = event.target as HTMLInputElement;
    const value = input.value;
    const filtersCopy = JSON.parse(JSON.stringify(this.filters));
    filtersCopy[key].records = value;
    this.filters = filtersCopy;
    this.rangeSubject.next({ key, value });
    const rangeValue = (input.value - input.min) / (input.max - input.min) * 100;
    input.style.background = `linear-gradient(to right, var(--pdl-green) ${rangeValue}%, #ddd ${rangeValue}%)`;
  }

  /////////////////////// open saved search ///////////////////
  openSavedSearch(path: string) {
    this.savedSearch = path;
    this.route.navigateByUrl('/search?' + path);
  }

  ////////////////  delete saved search //////////////////////
  deleteSavedSearch(id: number, event: Event) {
    event.stopPropagation();
    this.confirmationModalService
      .confirm(
        this.translate.instant('pdl-website.forms.advancedSearch.delete'),
        this.translate.instant(
          'pdl-website.forms.advancedSearch.confirmDeleteText'
        ),
        this.translate.instant('pdl-shared.buttonText.yesSure'),
        this.translate.instant('pdl-shared.buttonText.notSure'),
        'md'
      )
      .then((confirmed) => {
        if (confirmed) {
          this.searchService.deleteUserSavedSearch(id).subscribe({
            next: (response) => {
              const findIndex = this.filters['SavedSearches'].records.findIndex(
                (record: any) => record.SearchID === id
              );
              const updatedFilters = { ...this.filters };
              if (
                updatedFilters['SavedSearches'] &&
                updatedFilters['SavedSearches'].records
              ) {
                const updatedRecords = updatedFilters[
                  'SavedSearches'
                ].records.filter(
                  (_: any, index: number) => index !== findIndex
                );
                updatedFilters['SavedSearches'] = {
                  ...updatedFilters['SavedSearches'],
                  records: updatedRecords,
                };
                this.filters = updatedFilters;
              }
              this.toastService.showSuccess(response?.message);
            },
            error: (error) => {
              this.toastService.showError(
                error?.error?.error?.message ||
                  String(APP_CONSTANTS.defaultErrMsg)
              );
            },
          });
        }
      });
  }

  ///////////// remove key from url ///////////
  removeKeyFromUrl(key: string) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(key);
    const filtersCopy = JSON.parse(JSON.stringify(this.filters));
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    if (filtersCopy[key]) {
      filtersCopy[key].records = '0';
    }
    this.filters = filtersCopy;
    window.history.replaceState({}, '', newUrl);
    this.commonService.updateQueryParams({ [key]: null });
  }

  ///////////////////// update category /////////////////
  updateCategory(event: Event) {
    const value = (event.target as HTMLSelectElement).value;
    const key = 'categoryId';
    this.textSearch = false;
    this.updateStringUrl('pageNumber','');
    this.updateStringUrl(key, this.sharedService.encodeToBase64(value));
    this.commonService.updateQueryParams({
      [key]: this.sharedService.encodeToBase64(value),
    });
    this.includeTextSearch(false);
  }
  ///////////// check which category is selected ////////////////
  isSelected(categoryID: number | undefined): boolean {
    return this.categoryId === this.sharedService.encodeToBase64(categoryID);
  }

  ////////////////// include ocr data search or not ////////////////
  includeTextSearch(value: boolean) {
    const key = 'DocumentSearchableContent';
    this.textSearch = value;
    if (value) {
      const filtersCopy = JSON.parse(JSON.stringify(this.filters));
      filtersCopy['Content search for'].records =
        this.sharedService.decodeFromBase64(this.query);
      this.filters = filtersCopy;
      this.updateStringUrl(
        key,
        this.sharedService.decodeFromBase64(this.query)
      );
      this.commonService.updateQueryParams({
        [key]: this.sharedService.decodeFromBase64(this.query),
      });
    } else {
      this.removeKeyFromUrl(key);
    }
  }

  ////////////// update strict Mode ///////////////
  updateStrictMode(value: string) {
    const key = 'strictMode';
    this.strictMode = value;
    this.updateStringUrl(
      key,
      this.sharedService.encodeToBase64(this.strictMode)
    );
    this.commonService.updateQueryParams({
      [key]: this.sharedService.encodeToBase64(this.strictMode),
    });
  }

  ////////////////// clear all filters ///////////////
  clearAllFilter() {
    this.route.navigate(['/search'], {
      queryParams: {
        query: this.query,
        categoryId: this.categoryId,
        strictMode: this.sharedService.encodeToBase64(this.strictMode),
      },
    });
    const filtersCopy = JSON.parse(JSON.stringify(this.filters));
    filtersCopy['Content search for'].records = '';
    this.filters = filtersCopy;
    this.commonService.updateQueryParams({ ['clear']: '' });
  }

  /////////////// remove spaces from keys //////////

  removeSpace(key: string) {
    return key.replace(/\s+/g, '');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
