import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthSelector, BreadcrumbsComponent, WebUserActions, WebUserSelector } from '@panjab-digi-lib/shared';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '@panjab-digi-lib/shared';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { ResetPasswordValidator } from 'shared/src/validators/reset-password.validator';

@Component({
  selector: 'panjab-digi-lib-reset-password',
  standalone: true,
  imports: [CommonModule, TranslateModule, BreadcrumbsComponent, RouterLink, ReactiveFormsModule, NgbTooltipModule, AlertsComponent],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  pageTitle = 'Reset Password';
  resetPasswdForm!: FormGroup;
  errMsg = '';
  successMsg = '';
  invalidReq = '';
  subscription = new Subscription();
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.initForm();
    if(!this.route.snapshot.params['token'] || this.localStorage.get('webToken')) {
      this.router.navigate(['/']);
    }
    this.subscription.add(
      this.store.select(AuthSelector.selectWebToken).subscribe((token: string) => {
        if(token) {
          this.router.navigate(['/']);
        }
      })
    );
    this.subscription.add(
      this.store.select(WebUserSelector.selectError).subscribe((error: any) => {
        if(error && error.details?.error === 'invalid_req') {
          this.invalidReq = error.message;
        }
        else if (error && error.message) {
          this.invalidReq = ''
          this.alertService.error(error.message);
        }
      })
    );
    // validate token in request url
    // ask user to create new request if token timeline is expired
    this.validateRequestUrl();
    this.detectFormChanges();
  }

  initForm() {
    this.resetPasswdForm = this.fb.group({
      password: ['', [
        Validators.required, 
        Validators.minLength(6), 
        Validators.maxLength(30), 
        Validators.pattern('^[a-zA-Z0-9$@#^!%*?&_]+$'),
        ResetPasswordValidator.verifyStrength()
      ]],
      confirmpassword: ['', [Validators.required]],
    });
  }

  validateRequestUrl() {
    const payload = {
      token: this.route.snapshot.params['token']
    }
    this.store.dispatch(WebUserActions.validateResetPasswdRequest(payload));
  }

  updateErrorState(field: string) {
    if(field === 'cpassword') {
      const password = this.resetPasswdForm.controls['password'].value;
      const cpassword = this.resetPasswdForm.controls['confirmpassword'].value;
      if(cpassword !== password) {
        this.resetPasswdForm.controls['confirmpassword'].setErrors({ 'invalid': true });
      } else {
        this.resetPasswdForm.controls['confirmpassword'].setErrors(null);
      }
    } else {
      this.resetPasswdForm.controls[field].setErrors({ 'invalidField': true });
    }
  }

  detectFormChanges() {
    // detect password changes
    this.resetPasswdForm.get('password')?.valueChanges.subscribe(() => {
      this.updateErrorState('cpassword');
    });
    // detect confirm password changes
    this.resetPasswdForm.get('confirmpassword')?.valueChanges.subscribe(() => {
      this.updateErrorState('cpassword');
    });
  }

  onSubmit() {
    this.alertService.clear();
    this.resetPasswdForm.markAllAsTouched();
    if(this.resetPasswdForm.valid) {
      const payloads = {
        token: this.route.snapshot.params['token'],
        password: this.resetPasswdForm.controls['password'].value
      }
      this.store.dispatch(WebUserActions.resetAccountPasswd(payloads));
      this.subscription.add(
        this.store.select(WebUserSelector.selectSuccess).subscribe((message: any) => {
          if (message) {
            this.alertService.success(message);
          }
        })
      );
      this.resetPasswdForm.reset();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
