/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  BreadcrumbsComponent,
  AppState,
  CountryActions,
  CountrySelector,
  environment,
} from '@panjab-digi-lib/shared';
import { SocialLoginComponent } from '../common/social-login/social-login.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  NgbDateStruct,
  NgbModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { SpinnerButtonComponent } from 'shared/src/lib/components/web/spinner-button/spinner-button.component';
import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RecaptchaComponent,
} from 'ng-recaptcha';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { UtilService } from '../../../common/services/util.service';
import { TrimmedTextDirective } from '../../../common/directives/trimmed-text.directive';
import { NumberonlyDirective } from '../../../common/directives/numberonly.directive';
import { ContactUsService } from 'shared/src/services/website/contact-us/contact-us.service';
import { AllowedCharactersDirective } from 'shared/src/lib/directives/allowed-character.directive';
import { GoogleMapsService } from 'shared/src/services/common/google-maps.service';
declare const google: any;
@Component({
  selector: 'panjab-digi-lib-contact-us',
  standalone: true,
  imports: [CommonModule, TranslateModule, SocialLoginComponent, BreadcrumbsComponent, ReactiveFormsModule, NgbTooltipModule, RouterLink, AlertsComponent, SpinnerButtonComponent, RecaptchaModule,
     RecaptchaFormsModule, TrimmedTextDirective, NumberonlyDirective,NgbModule,AllowedCharactersDirective],
  providers: [DatePipe],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent implements OnInit, OnDestroy {
  pageTitle = 'Signup';
  contactReqForm!: FormGroup;
  successMsg = false;
  isSubmitted = false;
  sitekey = environment.recaptcha.siteKey;
  isMobile = false;
  subscription = new Subscription();
  minDate!: NgbDateStruct;
  formattedStartDate: string = '';
  formattedEndDate: string = '';
  reasonList!: { ID: number; Reason: string }[];
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent;
  @ViewChild('moveToTop', { static: false }) moveToTop!: ElementRef;
  @ViewChild('mapContainer') mapContainer!: ElementRef;

  map!: google.maps.Map;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private el: ElementRef,
    private alertService: AlertService,
    private renderer: Renderer2,
    private utilService: UtilService,
    private service: ContactUsService,
    private mapService: GoogleMapsService
  ) {
    this.getReasonList();
    this.initContactForm();
  }

  ngOnInit() {
    // change recaptcha size when it is on mobile view
    if (window.innerWidth < 370) {
      this.isMobile = true;
    }
  }
  
  ngAfterViewInit(): void {
    this.mapService.loadMapsApi().then(() => {
      const location = { lat: 30.7225503, lng: 76.8041402 };
      const mapOptions = {
        center: {
          ...location,
        },
        zoom: 14,
      };
      this.map = new google.maps.Map(
        this.mapContainer.nativeElement,
        mapOptions
      );
      new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Our Location'
      });
    });
  }
  getReasonList() {
    this.subscription.add(
      this.service.reasonList().subscribe({
        next: (res) => {
          this.reasonList = res?.body;
        },
        error: () => {
          console.log('error fetching contact us reason list');
        },
      })
    );
  }

  initContactForm() {
    this.contactReqForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern(APP_CONSTANTS.regExp.alphaNumericOnly),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(APP_CONSTANTS.regExp.numberOnly),
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
      email: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern(APP_CONSTANTS.regExp.email),
          ],
          updateOn: 'blur',
        },
      ],
      reason: [null, [Validators.required]],
      message: ['', [Validators.required, Validators.maxLength(1000)]],
      cpt: [null, Validators.required],
    });
  }

  resetForm() {
    this.contactReqForm.reset();
    this.moveToFirstElement();
    this.resetReCaptcha();
  }

  moveToFirstElement(): void {
    this.renderer.selectRootElement(this.moveToTop.nativeElement).focus();
  }

  resolveCaptcha(captchaResponse: string | null) {
    if (captchaResponse) {
      this.contactReqForm.patchValue({ cpt: captchaResponse });
    } else {
      this.contactReqForm.controls['cpt'].setErrors({ invalid: true });
    }
  }

  resetReCaptcha() {
    this.captchaRef.reset();
  }

  onSubmit() {
    this.alertService.clear();
    this.contactReqForm.markAllAsTouched();
    if (this.contactReqForm.valid) {
      this.isSubmitted = true;
      const formData = this.contactReqForm.value;
      delete formData.cpt;
      this.service.request(formData).subscribe({
        next: (res) => {
          this.successMsg = true;
          this.alertService.success(res.message);
          this.resetForm();
          this.isSubmitted = false;
        },
        error: () => {
          this.isSubmitted = false;
          this.resetReCaptcha();
        },
      });
    } else {
      this.utilService.focusOnInvalidField(this.contactReqForm, this.el);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
