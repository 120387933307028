import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from '../static/staticPageHead/staticPageHead.component';
import { AdoptionService } from 'shared/src/services/website/adoption/adoption.service'
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { CategoryDropdownList, GenericListingPayloadWithCategoryFilter, SortOrder } from 'shared/src/interfaces/common.interface';
import { debounceTime, distinctUntilChanged, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { AdoptionBooks, Cart } from 'shared/src/interfaces/website/adoption.interface';
import { AppState, CartActions, CartSelector, CountryActions, CountrySelector, SharedCommonService, SpinnerComponent } from '@panjab-digi-lib/shared';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Country } from 'shared/src/interfaces/master-form.interface';
import { ModalPopupService } from 'shared/src/services/common/modal-popup.service';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';

@Component({
  selector: 'panjab-digi-lib-adopt-a-book',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    ReactiveFormsModule,
    RouterModule,
    TranslateModule, 
    SpinnerComponent, 
    StaticPageHeadComponent,
    NgbPaginationModule, 
  ],
  templateUrl: './adopt-a-book.component.html',
  styleUrl: './adopt-a-book.component.scss',
})
export class AdoptABookComponent implements OnInit, OnDestroy {
  
  pageNo = 1;
  pageSize = APP_CONSTANTS.pageSize;
  maxSize = APP_CONSTANTS.maxSize;
  maxSizeMobile = APP_CONSTANTS.maxSizeMobile;
  sortOrder: SortOrder = {orderBy: 'Title', order: 'ASC'};
  sortBy: string = 'title';
  isMobile = false;

  documentList: AdoptionBooks[] = [];
  totalRecords: number = 0;
  searchText: string = '';
  selectedCategoryID: string = '';
  selectedCountryName: string = '';
  countryList: Country[] = [];
  switchLocationForm!: FormGroup;

  private searchTerms = new Subject<string>();
  private unsubscribe$: Subject<void> = new Subject();
  isUpdatingCart: boolean = false;
  isLoading: boolean = true;
  categories: CategoryDropdownList[] = [];
  cartList: Cart[] = [];
  updatingCartWithDocID!: number;
  currencyCode!: string;
  country!: string;
  showChangeCountry: boolean = false;

  @ViewChild('switchLocationModal') switchLocationModal!: TemplateRef<any>;
  @ViewChild('resultsAndFilters') resultsAndFilters!: ElementRef;
  
  constructor(
    private localStorageService: LocalStorageService,
    private adoptionService: AdoptionService,
    private sharedCommonService: SharedCommonService,
    private modalPopupService: ModalPopupService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.checkIfMobile();
    this.getCategories();
    this.trackSearchChanges();
    this.getCountries();
    this.initiateSwitchLocationForm();
    
    this.store.select(CartSelector.isUpdating).pipe(takeUntil(this.unsubscribe$))
    .subscribe((isUpdating) => {
      this.isUpdatingCart = isUpdating;
      if(!isUpdating) {
        this.updatingCartWithDocID = 0;
      }
    });

    const locationInfo = this.localStorageService.get('locationInfo');
    if (locationInfo) {
      this.currencyCode = locationInfo.currencyCode;
      this.selectedCountryName = locationInfo.countryName;
      this.switchLocationForm.controls['country'].setValue(locationInfo.countryName);
      this.getBooksForAdoption();
    } else {
      this.getUserLocationInfo();
    }
  }

  getUserLocationInfo() {
    this.adoptionService.getUserCurrency().pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        if(response.status == "success") {
          this.currencyCode = response.currency;
          this.selectedCountryName = response.country;
          this.switchLocationForm.controls['country'].setValue(response.country);
          
          const locationInfo = {
            'currencyCode': this.currencyCode,
            'countryCode': response.countryCode,
            'countryName': this.selectedCountryName
          }
          this.localStorageService.set('locationInfo', locationInfo);
          this.getBooksForAdoption();
        }
      }, error: (error) => {
        this.isLoading = false;
        console.log('error', error);
      }
    })
  }

  getCartList() {
    this.store.dispatch(CartActions.LoadCartList({currencyCode: this.currencyCode}));

    this.store.select(CartSelector.onCartChange).pipe(takeUntil(this.unsubscribe$))
    .subscribe(({ cartList, isUpdating }) => {
      if (isUpdating) {
        return;
      }
      
      if (cartList.length === 0) {
        this.cartList = [];
        this.documentList = this.documentList.map(document => {
          return {
            ...document,
            isInCart: false
          };
        })
      } else {

        this.cartList = cartList.filter((cart): cart is Cart => cart !== undefined);

        if (this.cartList.length > 0 && this.documentList.length > 0) {
          this.documentList = this.documentList.map(document => {
            return {
              ...document,
              isInCart: this.cartList.some((cart) => cart.DocID === document.DocID),
            };
          });
        }
      }
    })

  }

  getCountries() {
    const payload = {};
    this.store.dispatch(CountryActions.LoadActiveCountryList({ payload }));
    this.store
      .select(CountrySelector.getCountries)
      .subscribe((countryList: any) => {
        this.countryList = countryList;
      });
  }

  getCategories() {
    this.sharedCommonService.getCategories().pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.categories = response;
      }, error: (error) => {
        console.log('error', error);
        this.categories = [];
      }
    })
  }

  getBooksForAdoption(): void {
    
    let payload: GenericListingPayloadWithCategoryFilter = { 
      ...this.sortOrder, 
      pageNumber: this.pageNo, 
      pageSize: this.pageSize,
      searchText: this.searchText,
      categoryID: this.selectedCategoryID 
    };
    this.isLoading = true;
    if (this.resultsAndFilters) {
      this.resultsAndFilters.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    this.adoptionService.getBooksForAdoption(payload, this.currencyCode).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.documentList = response.documentList;
        this.totalRecords = response.totalRecords;
        this.isLoading = false;
        this.getCartList();
      }, error: (error) => {
        console.log('error', error);
        this.documentList = [];
        this.totalRecords = 0;
        this.isLoading = false;
      }
    })
  }
  
  sortBooks(orderBy: string, order: string, sortBy: string) {
    this.sortOrder = {orderBy, order};
    this.sortBy = sortBy;
    this.getBooksForAdoption();
  }

  onCategoryChange(event: Event): void {
    this.selectedCategoryID = (event.target as HTMLSelectElement).value;
    this.getBooksForAdoption();
  }
  
  onCountryChange(event: Event): void {
    this.selectedCountryName = (event.target as HTMLSelectElement).value;
    // this.getBooksForAdoption();
  }

  changeCountry(event: Event): void {
    event.preventDefault();
    this.showChangeCountry = true;
  }

  initiateSwitchLocationForm() {
    this.switchLocationForm = this.fb.group({
      country: ['', Validators.required],
    });
  }

  openSwitchLocationModal(event: Event) {
    event.preventDefault();
    this.modalPopupService.modalBtnsDisabled$.next(false);
    this.modalPopupService.open(
      this.translate.instant('pdl-website.adoptABook.swtichLocation'),
      '',
      this.switchLocationModal,
      this.translate.instant('pdl-shared.buttonText.update'),
      this.translate.instant('pdl-shared.buttonText.cancel'),
      this.handleOnSubmit.bind(this),
      this.handleOnCancel.bind(this),
      'md',
      undefined,
      false
    );
  }

  handleOnSubmit() {
    this.modalPopupService.modalBtnsDisabled$.next(true);
    this.modalPopupService.modalBtnsLoading$.next(true);
    const country = this.switchLocationForm.controls['country'].value;
    this.adoptionService.getCurrencyOfCountry(encodeURI(country)).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.currencyCode =  Object.keys(response.currencies)[0];
        this.selectedCountryName = this.switchLocationForm.controls['country'].value;
        const locationInfo = {
          'currencyCode': this.currencyCode,
          'countryCode': response.cca2,
          'countryName': this.selectedCountryName
        }
        this.localStorageService.set('locationInfo', locationInfo);
        
        this.getBooksForAdoption();
        this.modalPopupService.dismiss();
        this.modalPopupService.modalBtnsDisabled$.next(true);
        this.modalPopupService.modalBtnsLoading$.next(false);
      }, error: (error) => {
        console.log('error', error);
        this.modalPopupService.modalBtnsDisabled$.next(false);
        this.modalPopupService.modalBtnsLoading$.next(false);
      }
    })
  }
  
  handleOnCancel() {
  }

  onSearchChange(searchValue: string): void {
    this.searchTerms.next(searchValue);
  }

  adoptNow(document: AdoptionBooks, index: number) {
    this.documentList[index].isAdoptingNow = true;
    this.addToCart(document, true);
  }

  updateCart(document: AdoptionBooks) {
    // this.documentList[index].isUpdatingCart = true;
    if(document.isInCart) {
      this.removeFromCart(document);
    } else {
      this.addToCart(document);
    }
  }

  addToCart(document: AdoptionBooks, checkout: boolean=false) {
    const cart: Cart = {
      CartID: document.DocID,
      DocID: document.DocID,
      Title: document.Title,
      AccessionNo: document.AccessionNo,
      Author: document.Author,
      CategoryName: document.Category.Name,
      AdoptionPrice: document.BookPrice,
      AuthorNames: ''
    };
    this.store.dispatch(CartActions.AddToCartList({document: cart, currencyCode: this.currencyCode, checkout}));
    this.updatingCartWithDocID = document.DocID;
  }

  removeFromCart(document: AdoptionBooks) {
    const cart: Cart = {
      CartID: document.DocID,
      DocID: document.DocID,
      Title: document.Title,
      AccessionNo: document.AccessionNo,
      Author: document.Author,
      CategoryName: document.Category.Name,
      AdoptionPrice: document.BookPrice,
      AuthorNames: ''
    };
    this.store.dispatch(CartActions.RemoveFromCartList({document: cart}));
    this.updatingCartWithDocID = document.DocID;
  }
  
  trackSearchChanges() {

    this.searchTerms.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.unsubscribe$))
    .subscribe((text) => {
        this.searchText = text;
        this.getBooksForAdoption();
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkIfMobile();
  }

  private checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
