import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { Store } from '@ngrx/store';
import { AuthActions } from 'shared/src/store/actions';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ISocialUser } from '@panjab-digi-lib/shared';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private modalConfirmStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public modalConfirmStatus$: Observable<boolean> = this.modalConfirmStatusSubject.asObservable();
  constructor(private http: HttpClient, private localStorage: LocalStorageService, private store: Store, private authService: AuthenticationService, private socialAuthService: SocialAuthService) {}

  clearUserSession() {
    this.localStorage.remove('webToken');
    this.localStorage.remove('webRefreshtoken');
    this.localStorage.remove('socialProvider');
    this.localStorage.remove('webUser');
  }

  logOutWebUser() {
    this.store.dispatch(AuthActions.webLogout());
  }

  handleSocialLogin(user: ISocialUser, login?: boolean): void {
    if(!user) return;
    const authToken = user.provider === 'FACEBOOK' ? user.authToken : user.idToken;
    this.authService.sendHttpRequest('POST', 'social-login', {
      token: authToken,
      provider: user.provider,
      ia: user.ia,
      login
    }).subscribe({
      next: (response) => {
        this.localStorage.set('socialProvider', user.provider);
        this.store.dispatch(AuthActions.webSocialLogin({webToken: response.body.token, webRefreshtoken: response.body.refreshToken, user: response.body.user}));
      },
      error: (err) => {
        this.store.dispatch(AuthActions.webLoginFailure({ error: { message: err?.error?.error?.message, type: 'social_login', user} }));
        // if(err?.error?.error?.message?.indexOf('Your account is') !== -1) {
        //   this.socialAuthService.signOut();
        // }
      }
    });
  }

  refreshGoogleToken(): void {
    this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  handleFBSignIn(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.handleSocialLogin(user);
    });
  }

  changePassword({oldPasswd, newPasswd, retypePasswd}:any): Observable<any> {
    return this.authService.sendHttpRequest('PATCH', 'change-password', {oldPasswd, newPasswd, retypePasswd}).pipe(map((e: any) => e));
  }

  updateAccountStatus(status:number, type = 0): Observable<any> {
    return this.authService.sendHttpRequest('PATCH', 'update-status', {status, type}).pipe(map((e: any) => e));
  }

  updateConfirmModalStatus(status: boolean): void {
    this.modalConfirmStatusSubject.next(status);
  }

  getConfirmModalStatus(): Observable<boolean> {
    return this.modalConfirmStatus$;
  }
}
