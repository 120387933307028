<panjab-digi-lib-static-page-head [pageName]="'pdl-website.infoForCustodians.titleText' | translate"
  pagePath="/info-for-custodians">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container container-768">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <p class="text">
      As digital technology advances and finds new applications in our world, people are constantly initiating projects
      to digitize collections of books, manuscripts, photographs and newspapers. This has created huge repositories of
      information which their communities are subsequently using to effect change ahead of time. At this juncture, it
      becomes our responsibility to do the same for preserving our collections and making them accessible for research
      and education.
    </p>
    <p class="text">Unfortunately, Panjab is still in deep slumber. Our scholars are not able to reach out and achieve
      many of the desired results despite great thought. This is due, in part, to the fact that we do not have a
      credible central repository to rely on in Panjab. Currently no individual or institution is working to this end.
      For the purpose, PDL is providing both digitization and Web access services to interested individuals. In this
      huge task we seek the support and contribution of all the authors, scholars, thinkers and publishers.</p>
    <p class="text">
      Under the project, we are providing online access to documents related to Sikhs and Panjab, with full text search
      capabilities. This will allow interested people from around the world to find relevant books more easily, and thus
      increase their visibility and sales. One will simply enter keywords in the search box and the Web site will find
      all pages whose content matches the search criteria. Once a book has been found, the individual will have the
      ability to: search the contents for further matches and read it online for free if it is in public domain. More
      features like buy the book from the publisher, learn about the author and the publisher will be added soon.
      Because we must respect copyright law and the tremendous effort authors put into their work, unless explicitly
      permitted, people will only see information about the book, plus a few sample pages if the book is not considered
      part of public domain. Researchers may prefer to buy the books, as a hard copy is sometime handier than an online
      version. We feel that this arrangement will benefit everyone, authors, publishers and readers alike. Publishers
      and authors can avail this service by sending in their book for digitization and uploading in our searchable
      format. This free service will absolve the publishers and authors from the burdensome cost and effort associated
      with digitization, including keeping employees, buying equipment, spending time, money, energy, etc.
    </p>

    <p class="text">
      With genuine concern for Panjab's heritage, this venture is meant to save the legacy of history, information and
      culture under the agenda of a non-profit. If any funding is generated, it will be used to further the library
      project, or as specified under an agreement reached for a particular book with an author and/or publisher.
    </p>

    <p class="text">
      With genuine concern for Panjab's heritage, this venture is meant to save the legacy of history, information and
      culture under the agenda of a non-profit. If any funding is generated, it will be used to further the library
      project, or as specified under an agreement reached for a particular book with an author and/or publisher.
    </p>
    <p class="text">Help your books get discovered! After all, if a book isn't discovered, it won't be bought. If you
      wish your works to be included in this digital library, please fill out the requisite form available <a href=""
        target="_blank" class="email cursor-pointer text">here</a>.</p>

    }
    <div class="d-flex justify-content-center">
      <div class="centered-btn d-flex align-items-center justify-content-center">
        <!-- /list-of-custodians -->
        <a 
        href="#"
         class="btn pdl-btn">{{'pdl-website.infoForCustodians.listOfCustodianBtnText' |
          translate
          }}
          <svg width="25" height="25" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.3262 11.375C12.3262 10.7656 12.7949 10.25 13.4512 10.25H15.7012C16.3105 10.25 16.8262 10.7656 16.8262 11.375V13.625C16.8262 14.2812 16.3105 14.75 15.7012 14.75H13.4512C12.7949 14.75 12.3262 14.2812 12.3262 13.625V11.375ZM12.3262 18.875C12.3262 18.2656 12.7949 17.75 13.4512 17.75H15.7012C16.3105 17.75 16.8262 18.2656 16.8262 18.875V21.125C16.8262 21.7812 16.3105 22.25 15.7012 22.25H13.4512C12.7949 22.25 12.3262 21.7812 12.3262 21.125V18.875ZM15.7012 25.25C16.3105 25.25 16.8262 25.7656 16.8262 26.375V28.625C16.8262 29.2812 16.3105 29.75 15.7012 29.75H13.4512C12.7949 29.75 12.3262 29.2812 12.3262 28.625V26.375C12.3262 25.7656 12.7949 25.25 13.4512 25.25H15.7012Z"
              fill="#FFFFFF" />
            <path opacity="0.4"
              d="M19.0762 12.5C19.0762 11.7031 19.7324 11 20.5762 11H34.0762C34.873 11 35.5762 11.7031 35.5762 12.5C35.5762 13.3438 34.873 14 34.0762 14H20.5762C19.7324 14 19.0762 13.3438 19.0762 12.5ZM19.0762 20C19.0762 19.2031 19.7324 18.5 20.5762 18.5H34.0762C34.873 18.5 35.5762 19.2031 35.5762 20C35.5762 20.8438 34.873 21.5 34.0762 21.5H20.5762C19.7324 21.5 19.0762 20.8438 19.0762 20ZM34.0762 26C34.873 26 35.5762 26.7031 35.5762 27.5C35.5762 28.3438 34.873 29 34.0762 29H20.5762C19.7324 29 19.0762 28.3438 19.0762 27.5C19.0762 26.7031 19.7324 26 20.5762 26H34.0762Z"
              fill="#FFFFFF" />
          </svg>
        </a>

      </div>
    </div>
  </div>
</section>
