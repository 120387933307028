/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CreateEditTopicComponent } from '../create-edit-topic/create-edit-topic.component';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { WebUserLibraryService } from 'shared/src/services/website/user-library/user-library.service';
import { Subscription } from 'rxjs';
import { AddResearchTopicDocument } from 'shared/src/interfaces/website/user-library.interface';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';

@Component({
  selector: 'panjab-digi-lib-assign-literature',
  standalone: true,
  imports: [CommonModule, TranslateModule, CreateEditTopicComponent],
  templateUrl: './assign-literature.component.html',
  styleUrl: './assign-literature.component.scss',
})
export class AssignLiteratureComponent implements OnInit, OnDestroy {
  topics: { TopicID: number, TopicName: string }[] = [];
  @Input() docId!: number;
  @Input() addToNew : boolean = false;
  @Input() topicId! :number
  @Output() closeModal = new EventEmitter<boolean>();
  selectedTopicId!: number;
  subscription = new Subscription();
  createNewTopic: boolean = false;
  constructor(
    private libraryService: WebUserLibraryService,
    readonly toastService: ToastService
  ) {

  }

  ngOnInit(): void {
    this.fetchUserResearchTopics()
  }

  //////////////// fetch user research Topics /////////////
  fetchUserResearchTopics() {
    this.subscription.add(this.libraryService.userResearchTopics().subscribe({
      next: (response) => {
        this.topics = response?.body;
      },
      error: (error) => {
        this.toastService.showError(error?.error?.error?.message || APP_CONSTANTS.defaultErrMsg);
      }
    }))
  }

  ////////// select topic id /////////////
  selectResearchTopic(event: Event) {
    this.selectedTopicId = parseInt((event.target as HTMLSelectElement).value);
  }

  /////////////////// assign to research Topic /////////////////
  assign() {
    if(!this.selectedTopicId) {
      return;
    }
    const payload: AddResearchTopicDocument = {
      docId: this.docId,
      topicId: this.selectedTopicId
    };
    if(this.addToNew) {
      payload.addToNew = true
    }
    this.subscription.add(
      this.libraryService.assignDocumentToTopic(payload).subscribe({
        next: (response) => {
          this.toastService.showSuccess(response?.message);
          this.close(true);
        },
        error: (error) => {
          this.close();
          this.toastService.showError(error?.error?.error?.message || APP_CONSTANTS.defaultErrMsg);
        },
      }));
  }

  /////////// close modal ///////////
  close(created: boolean = false) {
    this.closeModal.emit(created);
  }

  //////////// close create topic modal ////////
  closeCreateTopicModal(event: boolean) {
    this.createNewTopic = false;
    if (event) {
      this.close(event);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
