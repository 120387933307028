import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { forkJoin, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { AppState, WebAdoptedBooksActions, WebAdoptedBooksSelector, SpinnerComponent, environment, SharedCommonService } from '@panjab-digi-lib/shared';
import { NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { UserCommentsService } from 'shared/src/services/website/user-comments/user-comments.service';
import { CategoryCount, UserComment } from 'shared/src/interfaces/website/user-comments.interface';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { GenericListingPayloadWithCategoryFilter } from 'shared/src/interfaces/common.interface';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'panjab-digi-lib-my-comments',
  standalone: true,
  imports: [
    CommonModule, 
    NgbPaginationModule, 
    NgbPopoverModule, 
    TranslateModule, 
    RouterLink, 
    SpinnerComponent,
    FormsModule
  ],
  templateUrl: './my-comments.component.html',
  styleUrl: './my-comments.component.scss',
})
export class MyCommentsComponent implements OnInit {

  pageNo = 1;
  pageSize = APP_CONSTANTS.pageSize;
  totalRecords = 0;

  UserComments: UserComment[] = [];
  CategoryCount: CategoryCount[] = [];
  isLoading = true;
  isMobile = false;
  selectedCategoryID = 0;
  isExpandedMap: { [key: number]: boolean } = {};

  constructor(
    private readonly userCommentsService: UserCommentsService,
    private readonly sharedService: SharedCommonService
  ) { }

  ngOnInit(): void {

    this.isMobile = window.innerWidth <= 768;
    this.loadComments();
  }

  loadComments(category?: number) {
    this.isLoading = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const payload: GenericListingPayloadWithCategoryFilter = {
      pageNumber: this.pageNo,
      pageSize: this.pageSize
    };

    if (category) {
      payload.categoryID = String(category);
    }

    const userComments$ = this.userCommentsService.getUserComments(payload);

    let categoryCount$ = of(this.CategoryCount); // Default to existing data

    if (this.CategoryCount.length === 0) {
      categoryCount$ = this.userCommentsService.getCategoryCount(); // Fetch if empty
    }

    forkJoin({ userComments: userComments$, categoryCount: categoryCount$ })
      .pipe(take(1))
      .subscribe({
        next: ({ userComments, categoryCount }) => {
          this.isLoading = false;
          this.UserComments = userComments.commentList;
          this.totalRecords = userComments.totalRecords;
          this.CategoryCount = categoryCount;
        },
        error: (error) => {
          this.isLoading = false;
          this.UserComments = [];
          this.totalRecords = 0;
          this.CategoryCount = [];
          console.log('error', error);
        }
      });
  }

  changeCategory(categoryId: number) {
    this.pageNo = 1;
    this.pageSize = APP_CONSTANTS.pageSize;
    this.loadComments(Number(categoryId));
    this.selectedCategoryID = categoryId;
  }

  toggleComment(commentId: number) {
    this.isExpandedMap[commentId] = !this.isExpandedMap[commentId];
  }

  getTruncatedText(comment: string) {
    return this.sharedService.getTruncatedText(comment);
  }
  
  shouldShowReadMore(comment: string): boolean {
    return this.sharedService.shouldShowReadMore(comment);
  }
}
