/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { WebSearchActions } from '../../actions';
import { WebSearchService } from 'shared/src/services/website/search/search.service';

@Injectable()
export class WebsiteSearchEffects {
  constructor(
    private actions$: Actions, 
    private store: Store<AppState>,
    private service : WebSearchService
  ) {}

  /////////////////// search ///////////////////////////
  Search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WebSearchActions.WebsiteSearch),
      exhaustMap((action) => {
        return this.service.search(action?.payload).pipe(
          map((response) => {
            return WebSearchActions.WebsiteSearchSuccess({
              searchList: response?.searchList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              WebSearchActions.WebsiteSearchError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

    /////////////////// Get Advanced Filters ///////////////////////////
    Filters$ = createEffect(() =>
      this.actions$.pipe(
        ofType(WebSearchActions.WebsiteGetFilters),
        exhaustMap((action) => {
          return this.service.filters(action?.payload).pipe(
            map((response) => {
              return WebSearchActions.WebsiteGetFiltersSuccess({
                filtersList: response?.body
              });
            }),
            catchError((error) => {
              return of(
                WebSearchActions.WebsiteGetFiltersError({
                  message: error?.error?.error?.message,
                })
              );
            })
          );
        })
      )
    );

}
