import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class DocumentFilesService {
  private rootURL: string;

  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
  /////////////// Fetch files by DocID ///////////////
  getFilesByDocId(docId: number, systemTime: number): Observable<any> {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.metadata.getFiles}`;

    // Set headers with system time
    const headers = new HttpHeaders().set('request-time', systemTime.toString());

    return this.http.get(url, {
      params: { DocID: docId.toString() },
      headers: headers
    }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error?.error.error) {
      transformedError = error.error.error;
    }


    return throwError(() => transformedError);
  }
}
