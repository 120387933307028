<section class="innerpage-banner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                    <a class="breadcrumb-link font-regular" href="#">
                        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                        Home
                    </a> 
                    <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg">
                    </span>
                    <a class="breadcrumb-link font-regular" href="javascript:void(0)">
                        Downloads
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="page-title">
                    <h1 class="heading-dark semibold mb-0">{{'pdl-website.downloadsPage.pageTitle' | translate }}</h1>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="downloads-section section-padding ">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="text-about-page">
                    <p class="pdl-grey text-center">{{'pdl-website.downloadspageDescription.descriptionText' | translate }}</p>
                </div>
            </div>
            <div class="col-12">
                @if(pages?.length) {
                    <div class="results-and-filters d-flex align-items-center justify-content-between">
                        <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
                            <span>{{'pdl-shared.pagination.showing' | translate}}</span>
                            <span>{{fromRecords}}</span>
                            <span>{{'pdl-shared.pagination.to' | translate}}</span>
                            <span>{{toRecords}}</span>
                            <span>{{'pdl-shared.pagination.of' | translate}}</span>
                            <span>{{totalRecords}}</span>
                            <span>{{'pdl-shared.pagination.entries' | translate}}</span>
                        </div>
                        <div class="page-filters-dropdown">
                            <div class="dropdown page-filter-dropdown">
                                <a class="page-filter-action btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{'pdl-admin.download.sortBy' | translate}}
                                </a>
                            
                                <ul class="dropdown-menu">
                                <li><a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='default' ? 'active-sort' : ''" (click)="sortImages('default')">{{'pdl-admin.download.default' | translate}}</a></li>
                                <li><a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='title' ? 'active-sort' : ''" (click)="sortImages('title')">{{'pdl-admin.download.alphabetically' | translate}}</a></li>
                                <li><a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='recent' ? 'active-sort' : ''" (click)="sortImages('recent')">{{'pdl-admin.download.recentlyAdded' | translate}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div class="row">
            
            <div class="col-12">
                <div class="downloads-in-grid">
                    <div class="row justify-content-center">
                        @for(image of allDownloads; track image) {
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <a  class="downloads-card">                                
                                <div class="block-img cursor-pointer" data-bs-toggle="modal" data-bs-target="#staticBackdrop" (click)="initSlider(image)">
                                    <img  src="{{imageBaseUrl}}{{image?.DownloadImagePath}}" (error)="handleImageError($event)">
                                </div>
                                <div class="block-text-bar d-flex justify-content-between align-items-start">
                                    <!-- <h6 class="pdl-title-color">{{image?.Title}}
                                    </h6> -->
                                    <h6 class="pdl-title-color"><span class="text__ellipse text-wrap text-break">{{image?.Title}}</span>
                                        @if(image?.Title?.length > 50) {
                                    <span class="grey-color" [ngbPopover]="bookTitle" triggers="mouseenter:mouseleave" popoverClass="tooltip-custom-class" placement="end">{{'pdl-shared.content.readMoreText' | translate}}</span>
                                        }
                                    </h6>
                                    <ng-template #bookTitle>{{image?.Title}}</ng-template>

                                    <a type="button"  class="number-of-users mb-0">
                                        <img src="assets/images/icons/download-ico.svg" alt="icon" (click)="downloadImage(imageBaseUrl + image?.DownloadImagePath, false)">
                                    </a>
                                </div>
                            </a>
                        </div>
                    }

                    </div>
                </div>


            </div>
            
        </div>
        <div class="row">
            <div class="col-12">
                @if(pages?.length) {
                    <div class="pdl-pagination d-flex align-items-center justify-content-center">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <!-- <li class="page-item" [ngClass]="{disabled:pageNumber === 1}">
                                    <a (click)="loadDownloadImageRecords(1)" class="page-link" (keyup.enter)="loadDownloadImageRecords(1)"  [tabIndex]="pageNumber === 1 ? -1 : 0 " >First</a>
                                </li> -->
                                <li class="page-item" [ngClass]="{disabled:pageNumber === 1}">
                                    <a (click)="loadDownloadImageRecords(pageNumber - 1)" class="page-link" (keyup.enter)="loadDownloadImageRecords(pageNumber - 1)" [tabIndex]="pageNumber === 1 ? -1 : 0 ">
                                        <img src="assets/images/icons/pagination-left.svg" alt="icon">
                                    </a>
                                </li>
                                @for(page of pages; track page; let i = $index) {
                                    <li [ngClass]="{active:pageNumber === page}" class="page-item">
                                        <a *ngIf="page !== '...'" (click)="loadDownloadImageRecords(page)" class="page-link" tabindex="0" (keyup.enter)="loadDownloadImageRecords(page)">{{page}}</a>
                                        <span *ngIf="page === '...'" class="page-link">{{page}}</span>
                                    </li>
                                }
                                <li class="page-item next" [ngClass]="{disabled:pageNumber === totalNoOfPages}">
                                    <a (click)="loadDownloadImageRecords(pageNumber + 1)" class="page-link"  (keyup.enter)="loadDownloadImageRecords(pageNumber + 1)" [tabIndex]="pageNumber === totalNoOfPages ? -1 : 0 ">
                                        <img src="assets/images/icons/pagination-right.svg" alt="icon">
                                    </a>
                                </li>
                                <!-- <li class="page-item" [ngClass]="{disabled:pageNumber === totalNoOfPages}">
                                    <a (click)="loadDownloadImageRecords(totalNoOfPages)" class="page-link"  (keyup.enter)="loadDownloadImageRecords(totalNoOfPages)" [tabIndex]="pageNumber === totalNoOfPages ? -1 : 0 ">Last</a>
                                </li> -->
                            </ul>
                        </nav>
                    </div>
                }
            </div>
        </div>
    </div>

</section>

  
  <!-- Modal -->
  <div class="modal fade downloads-popup" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-fullscreen">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
        <div class="modal-body">
            <div class="downloads-slider">
                @if (showSlider) {
                <ngx-slick-carousel class="carousel mb-0" #slickModal="slick-carousel" [config]="slideConfig"
                    (init)="slickInit($event)" (afterChange)="afterChange($event)">
                    @for(image of sliderInfo?.ImagePath;track image) {
                    <div ngxSlickItem class="slide downloads-slide pe-4">
                        <div class="downloads-slide-card">
                            <div class="block-img">  
                                <img src="{{image}}" alt="img">
                            </div>
                            <div class="block-text-bar">
                                <h6 class="mb-0 semibold">
                                    <span class="text__ellipse">{{sliderInfo?.Title}}</span>
                                    @if(sliderInfo?.Title?.length > 100) {
                                    <span class="grey-color" [ngbPopover]="bookTitle" triggers="mouseenter:mouseleave" popoverClass="tooltip-custom-class" placement="top">{{'pdl-shared.content.readMoreText' | translate}}</span>
                                    }
                                </h6>
                                <ng-template #bookTitle>{{sliderInfo?.Title}}</ng-template>
                            </div>                            
                        </div>
                    </div>
                }
                </ngx-slick-carousel>
            }
            </div>
            <div class="pdl-btns-row d-flex align-items-center justify-content-center flex-wrap">
                <a type="button"  data-bs-dismiss="modal" class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start" (click)="closeModal()"> Cancel <img src="assets/images/icons/cancel-btn.svg"></a>
                <a class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start" (click)="downloadImage(currentSlide, true)"> Download <img src="assets/images/icons/download-btn.svg"></a>
            </div>
        </div>
        
      </div>
    </div>
  </div>