<ng-template #createNewForumModal>
  <hr class="mb-4" />
  <div class="modal-body">
    <div class="text-center mb-4">
      <p class="subtitle">
        {{ 'pdl-website.createNewForumPopup.subtitle' | translate }}
      </p>
    </div>
    <div class="container mt-4">
      <form [formGroup]="forumForm">
        <!-- Forum Title -->
        <div class="mb-3">
          <div class="d-flex justify-content-between">
            <label for="forumTitle" class="form-label field-title">
              {{ 'pdl-website.createNewForumPopup.createForumTitle' | translate }} <span class="text-danger">*</span>
            </label>
            <small class="char-counter">{{ forumForm.controls['forumTitle'].value?.length || 0 }} / 150</small>
          </div>
          <div class="d-flex">
            <input
              type="text"
              id="forumTitle"
              class="form-control px-3 input-field"
              placeholder="Write here"
              maxlength="150"
              formControlName="forumTitle"
            />
          </div>
        </div>

        <!-- Description -->
        <div class="mb-3">
          <div class="d-flex justify-content-between">
            <label for="description" class="form-label field-title">
              {{ 'pdl-website.createNewForumPopup.description' | translate }} <span class="text-danger">*</span>
            </label>
            <small class="char-counter">{{ forumForm.controls['description'].value?.length || 0 }} / 250</small>
          </div>

          <quill-editor
            [modules]="editorConfigiration"
            formControlName="description"
            [style]="{ height: '300px', width: '100%' }"
            (onContentChanged)="onContentChanged($event)"
          >
          </quill-editor>
        </div>

        <!-- Add Members -->
        <div class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <label class="form-label field-title">Add Member</label>
            <span class="add-member-desc">
              {{ 'pdl-website.createNewForumPopup.addMemberDesc' | translate }}
            </span>
          </div>
          <div class="chip-container">
            <div class="chip" *ngFor="let item of items; let i = index">
              {{ item }}
              <button type="button" class="close-btn" (click)="removeItem(i)">
                <img src="assets/images/icons/cross-outline-gray.svg" alt="remove"/>
              </button>
            </div>

            <input
              type="text"
              class="chip-input"
              placeholder="Add emails..."
              (keydown)="addItem($event)"
            />
          </div>
          <p class="add-member-info">
            {{ 'pdl-website.createNewForumPopup.addMemberInfo' | translate }}
          </p>
        </div>
      </form>
    </div>
  </div>
</ng-template>
