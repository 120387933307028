<footer class="pt-4 pb-4">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="footer-top d-flex justify-content-between pb-4 mb-4">
                    <div class="footer-logo">
                        <a href="/" class="d-flex align-items-center text-white text-decoration-none">
                            <img src="assets/images/icons/logo-icon.svg"> <span class="logo-title">
                                {{'pdl-website.headerPage.logoTitle' | translate }}
                            </span>
                        </a>
                    </div>
                    <div class="footer-social d-flex align-items-center">
                        <span class="me-3 d-flex d-none d-lg-block">{{'pdl-website.footerSocial.socialText' | translate
                            }}</span>
                        <ul class="ps-0 list-style-none d-flex align-items-center justify-content-start mb-0">
                            <li class="me-3">
                                <a href="https://www.facebook.com/PanjabDigiLib.org" target="_blank">
                                    <img src="assets/images/icons/facebook-ico.svg">
                                </a>
                            </li>
                            <li class="me-3">
                                <a href="https://x.com/panjabdigilib" target="_blank">
                                    <img src="assets/images/icons/x-icon.svg">
                                </a>
                            </li>
                            <li class="me-3">
                                <a href="https://www.instagram.com/panjabdigitallibrary" target="_blank">
                                    <img src="assets/images/icons/instagram-ico.svg">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            @for (header of getAllHeaders(); track header){
            <div class="col-pdl-lg-20 col-pdl-md-33 col-pdl-sm-6 d-flex justify-content-start mb-3">
                <div class="footer-links-column">
                    <h4 class="semibold row_heading">{{'pdl-website.'+header +'.titleText' | translate }}</h4>
                    <ul class="ps-0 mb-0 list-style-none">
                        @for (item of getCorrespondingLinks(header); track item) {
                        <li> 
                            <a [routerLink]="'pdl-website.'+item+'.linkhref'
                            | translate ">{{'pdl-website.'+item+'.linkText'
                                | translate }}
                            </a>
                        </li>

                        }
                    </ul>
                </div>
            </div>
            }
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <div class="copyright-block">
                    <span class="d-flex justify-content-center">&#169; {{currentYear}} Panjab Digital Library | All rights
                        reserved</span>
                </div>
            </div>
        </div>

    </div>
</footer>
