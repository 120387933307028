/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
@Injectable({
  providedIn: 'root',
})
export class IdleUserService {
  constructor(private _idle: Idle, private keepalive: Keepalive) {
    this._idle.setIdle(APP_CONSTANTS.IdleUserTimes.IDLE_TIME);
    this._idle.setTimeout(APP_CONSTANTS.IdleUserTimes.TIMEOUT);
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }
  startWatching(name: string) {
    this._idle.watch();
    localStorage.setItem(name, 'Started');
  }

  stopWatching(name: string) {
    this._idle.stop();
    localStorage.removeItem(name);
  }
}
