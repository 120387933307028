/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { SaveUserSearchPayload, Search } from 'shared/src/interfaces/website/search.interface';

@Injectable({
  providedIn: 'root'
})
export class WebSearchService {
  rootURL = '';
  sharedApiURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
    this.sharedApiURL = environment.rootApiUrl + environment.sharedApiPrefix;

  }

  ///////////////// search with text /////////////////
  search(payload: any) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.search.default}`;
    return this.http
      .post<Search>(url, payload)
      .pipe(map((e: Search) => e?.body));
  }

  ///////////////// advanced filters /////////////////
  filters(payload: any) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.search.filters}`;
    return this.http
      .post(url, payload)
      .pipe(map((e: any) => e));
  }

  ///////////////// find in collection /////////////////
  findInCollection(payload: any) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.search.searchCollection}`;
    return this.http
      .post(url, payload)
      .pipe(map((e: any) => e));
  }

  /////////////////// save search ////////////////////
  saveUserSearch(payload: SaveUserSearchPayload) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.search.save}`;
    return this.http
      .post(url, payload)
      .pipe(map((e: any) => e));
  }

  ////////////////// update saved search /////////////////
  updateUserSavedSearch(payload: SaveUserSearchPayload, id: number) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.search.update}`.replace('{id}', id.toString());
    return this.http
      .put(url, payload)
      .pipe(map((e: any) => e));
  }

  ////////////////// delete saved search /////////////////
  deleteUserSavedSearch(id: number) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.search.delete}`.replace('{id}', id.toString());
    return this.http
      .delete(url)
      .pipe(map((e: any) => e));
  }
}
