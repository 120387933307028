import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink } from '@angular/router';
import { HomeCategories } from 'shared/src/interfaces/homepage.interface';
import { AppState, environment, WebCategoriesSelector, WebHomePageActions, SharedCommonService } from '@panjab-digi-lib/shared';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { APP_CONSTANTS } from '../../../common/constants/app.constants';

@Component({
  selector: 'panjab-digi-lib-categories',
  standalone: true,
  imports: [CommonModule,RouterLink,TranslateModule],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss',
})
export class CategoriesComponent implements OnInit {
  categories$!: Observable<HomeCategories[]>;
  imageBaseURL = environment.azureCloudStrorage.publicUrl;
  fallbackImage = APP_CONSTANTS.categoryFallbackImage;
  constructor(
    private readonly translate: TranslateService, 
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly commonService : SharedCommonService 
  ) {}

  ngOnInit() {
    this.store.dispatch(WebHomePageActions.LoadWebCategories());
    this.categories$ = this.store.select(WebCategoriesSelector.getCategories);
  }
  
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  generateSearchUrl(categoryId: number) {
    this.router.navigate(['/search'], {
      queryParams: { query: '', categoryId : this.commonService.encodeToBase64(categoryId)}
    });
  }
}
