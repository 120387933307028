/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Banner, GeneralMessage, HomeCategories, Videos, TrendingKeywords } from 'shared/src/interfaces/homepage.interface';
import { map, Observable } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { HomepageVideo } from 'shared/src/interfaces/video-gallery.interface';
import { COMMON_API_ENDPOINTS } from '../../common/app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {
  rootURL = '';
  sharedApiURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
    this.sharedApiURL = environment.rootApiUrl + environment.sharedApiPrefix;

  }

  loadWebBanners(): Observable<Banner[]> {
    return this.http.get(`${this.rootURL}${WEB_API_ENDPOINTS.homePage.getBanners}`).pipe(map((e: any) => e.body));
  }

  loadWebTrendingKeywords(): Observable<TrendingKeywords> {
    return this.http.get(`${this.rootURL}${WEB_API_ENDPOINTS.homePage.getTrendingKeywords}`).pipe(map((e: any) => e.body));
  }
  
  loadWebCategories(): Observable<HomeCategories[]> {
    return this.http.get(`${this.sharedApiURL}${COMMON_API_ENDPOINTS.shared.getCategoryList}`).pipe(map((e:any) => e.body));
  }

  loadWebDownloads() {
    return this.http.get(`${this.rootURL}${WEB_API_ENDPOINTS.homePage.getDownloads}`);
  }

  ///////////////// load homepage videos /////////////////
  loadHomepageVideos() {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.homePage.videos}`;
    return this.http
      .get<Videos>(url)
      .pipe(map((e: Videos) => e));
  }

  ///////////// update views count ///////////////////////

  updateVideoCount(id: number) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.videos.video}`.replace('{id}', (id ?? '').toString());
    return this.http
    .patch<GeneralMessage>(url,{})
    .pipe(map((e:GeneralMessage) => e?.body));
  }

  ////////////////// autocomplete search  ///////////////

  suggestion(id:number, query: string) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.homePage.search}`.replace('{categoryId}', (id ?? '').toString()).replace('{query}', query);
    return this.http
    .get(url)
    .pipe(map((e:any) => e.body));
  }

}
