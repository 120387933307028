@if(isSavingOrderInfo) {
  <div class="container checkout-success">
    <p class="text-center mb-5">
      {{'pdl-website.adoptABook.processingOrderTxt' | translate}}
    </p>
    <div class="spinner-border" role="status"></div>
  </div>
} @else if(checkoutCompleted) {
  <div class="container checkout-success my-5">
    @if(savedOrderInfo.orderStatus == OrderStatuses.COMPLETED) {
      <h4 class="text-center mb-4">
        {{'pdl-website.adoptABook.thankForAdoption' | translate}}
      </h4>

      <p>
        {{'pdl-website.adoptABook.adoptionCompleteMsg' | translate}}
      </p>

      <ng-container *ngTemplateOutlet="adoptedTable; context: { docs: savedOrderInfo.adoptedDocs, statusLabel: 'Adopted' }"></ng-container>

      <p>
        {{'pdl-website.adoptABook.appreciateTxt' | translate}} 
        <a [routerLink]="['/adopt-a-book']">
          {{'pdl-website.adoptABook.browse' | translate}}
        </a> 
        {{'pdl-website.adoptABook.adoptMoreBooks' | translate}}
      </p>
    }

    @if(savedOrderInfo.orderStatus == OrderStatuses.PARTIALLY_REFUNDED) {
      <h4 class="text-center mb-4">
        {{'pdl-website.adoptABook.thankForPayment' | translate}}
      </h4>

      <p>{{savedOrderInfo.adoptedDocs.length}} {{'pdl-website.adoptABook.successAdoptionMsg' | translate}}</p>

      <ng-container *ngTemplateOutlet="adoptedTable; context: { docs: savedOrderInfo.adoptedDocs, statusLabel: 'Adopted' }"></ng-container>
      
      <p class="mt-4">{{savedOrderInfo.refundedDocs.length}} {{'pdl-website.adoptABook.refundMsg' | translate}}</p>

      <ng-container *ngTemplateOutlet="adoptedTable; context: { docs: savedOrderInfo.refundedDocs, statusLabel: 'Refund Initiated' }"></ng-container>

      <p>
        {{'pdl-website.adoptABook.appreciateTxt' | translate}} 
        <a [routerLink]="['/adopt-a-book']">
          {{'pdl-website.adoptABook.browse' | translate}}
        </a> 
        {{'pdl-website.adoptABook.adoptMoreBooks' | translate}}
      </p>
    }

    @if(savedOrderInfo.orderStatus == OrderStatuses.REFUNDED) {
      <h4 class="text-center mb-4">{{'pdl-website.adoptABook.adoptionUnavailableTitle' | translate}}</h4>
      
      <p>
        {{'pdl-website.adoptABook.adoptionUnavailableTxt' | translate}}
        {{'pdl-website.adoptABook.totalAdoptionAmount' | translate}} 
        <b>{{savedOrderInfo.refundAmount | currency: currencyCode}}</b> 
        {{'pdl-website.adoptABook.refundedShortly' | translate}}
      </p>
      
      <ng-container *ngTemplateOutlet="adoptedTable; context: { docs: savedOrderInfo.refundedDocs, statusLabel: 'Refund Initiated' }"></ng-container>

      <p>
        {{'pdl-website.adoptABook.appreciateAndEncourage' | translate}} 
        <a [routerLink]="['/adopt-a-book']">
          {{'pdl-website.adoptABook.browse' | translate}}
        </a> 
        {{'pdl-website.adoptABook.appreciateAndEncourage2' | translate}}
        {{'pdl-website.adoptABook.thankForUnderstanding' | translate}}
      </p>
    }
  </div>
} @else {
<section class="d-flex">
  <div class="form">
    <div class="innerpage-banner" style="background: none;">
      <form [formGroup]="adoptBookForm">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            Home
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" [routerLink]="['/adopt-a-book']">
            {{'pdl-website.adoptABook.title' | translate}}
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular">
            {{'pdl-website.adoptABook.checkout' | translate}}
          </a>
        </div>
        <div class="greenBorderText mb-2 heading"><b>{{'pdl-website.adoptABook.title' | translate}}</b> </div>
        <div class="pl-12 text mb-5">{{'pdl-website.adoptABook.formInfo' | translate}} 
          <br /><sup style="color: red">*</sup>
          {{'pdl-website.adoptABook.indicateRequired' | translate}}</div>
        <div class="seperatorLine"></div>

        <div class="pl-12 heading mb-4"><b>{{'pdl-website.adoptABook.yourSelectionBelow' | translate}}:</b></div>
        <div class="que mb-2 pl-12">{{'pdl-website.adoptABook.yourStatus' | translate}}<sup class="err__ast">*</sup></div>
        <div class="pl-12 d-flex col-gap-56 flex-wrap mb-4">
          <div class="text-start form-check">
            <input formControlName="type" [value]="1" type="radio" id="individual" class="form-check-input" name="type" aria-label="Individual">
            <label for="individual" class="cursor-pointer">{{'pdl-shared.content.individual' | translate}}</label>
          </div>
          <div class="text-start form-check">
            <input formControlName="type" [value]="2" type="radio" id="organization" class="form-check-input" name="type" aria-label="Organization">
            <label for="organization" class="cursor-pointer">{{'pdl-shared.content.organization' | translate}}</label>
          </div>
        </div>
        <div class="mb-4 d-grid">
          <div class="d-grid eq-grid">
            <div class="d-flex flex-column">
              <div class="que mb-2 pl-12" for="adopterName">
                {{'pdl-website.adoptABook.formFields.name' | translate}}
                <sup class="err__ast">*</sup>
              </div>
              <div class="plr-12">
                <input formControlName="name" type="text" class="form-control search__input disabled"
                [ngClass]="{'is-invalid': !adoptBookForm.get('name')?.valid && adoptBookForm.get('name')?.touched}"
                  id="adopterName" placeholder="">
                @if(!adoptBookForm.get('name')?.valid && adoptBookForm.get('name')?.touched) {
                  @if(adoptBookForm.get('name')?.errors?.['required']) {
                    <div class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</div>
                  }
                }
              </div>
            </div>

            <div class="d-flex flex-column">
              <div class="que mb-2 pl-12">
                {{'pdl-website.adoptABook.formFields.email' | translate}}
                <sup class="err__ast">*</sup>
              </div>
              <div class="plr-12">
                <input formControlName="email" type="text" class="form-control search__input disabled"
                  [ngClass]="{'is-invalid': !adoptBookForm.get('email')?.valid && adoptBookForm.get('email')?.touched}"
                  id="exampleFormControlInput1" placeholder="">
                @if(!adoptBookForm.get('email')?.valid && adoptBookForm.get('email')?.touched) {
                  @if(adoptBookForm.get('email')?.errors?.['required']) {
                    <div class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</div>
                  } @else if(adoptBookForm.get('email')?.errors?.['pattern']) {
                    <div class="err__msg">{{'pdl-shared.formValidationMsgs.invalidEmail' | translate}}</div>
                  }
                }
              </div>
            </div>
          </div>
        </div>

        @if(adoptBookForm.get('type')?.value != individualAdopter) {
        <div class="mb-4">
          <div class="que mb-2 pl-12">
            {{'pdl-website.adoptABook.formFields.website' | translate}}
            <sup class="err__ast">*</sup>
          </div>
          <div class="plr-12">
            <input formControlName="website" type="text" class="form-control search__input disabled"
              [ngClass]="{'is-invalid': !adoptBookForm.get('website')?.valid && adoptBookForm.get('website')?.touched}"
              id="exampleFormControlInput1" placeholder="">
            @if(!adoptBookForm.get('website')?.valid && adoptBookForm.get('website')?.touched) {
              @if(adoptBookForm.get('website')?.errors?.['required']) {
                <div class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</div>
              } @else if(adoptBookForm.get('website')?.errors?.['pattern']) {
                <div class="err__msg">{{'pdl-shared.formValidationMsgs.invalidWebsite' | translate}}</div>
              }
            }
          </div>
        </div>
        }

        <div class="mb-4">
          <div class="que mb-2 pl-12">
            {{'pdl-website.adoptABook.formFields.state' | translate}}
            <sup class="err__ast">*</sup>
          </div>
          <div class="plr-12">
            <input formControlName="state" type="text" class="form-control search__input disabled"
            [ngClass]="{'is-invalid': !adoptBookForm.get('state')?.valid && adoptBookForm.get('state')?.touched}"
              id="exampleFormControlInput1" placeholder="">
            @if(!adoptBookForm.get('state')?.valid && adoptBookForm.get('state')?.touched) {
              @if(adoptBookForm.get('state')?.errors?.['required']) {
                <div class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</div>
              }
            }
          </div>
        </div>
        <div class="mb-4">
          <div class="que mb-2 pl-12">
            {{'pdl-website.adoptABook.formFields.country' | translate}}
            <sup class="err__ast">*</sup>
          </div>
          <div class="plr-12">
            <select formControlName="country" class="form-control form-select search__input sort__filter" 
            [ngClass]="{'is-invalid': !adoptBookForm.get('country')?.valid && adoptBookForm.get('country')?.touched}"
            aria-label="Default select example">
              <option value="" disabled>Select</option>
              @for(country of countries; track country.CountryID) {
              <option [value]="country?.CountryID">{{country?.Name}}</option>
              }
            </select>
            @if(!adoptBookForm.get('country')?.valid && adoptBookForm.get('country')?.touched) {
              @if(adoptBookForm.get('country')?.errors?.['required']) {
                <div class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</div>
              }
            }
          </div>
        </div>
        @if(adoptBookForm.get('type')?.value == individualAdopter) {
        <div class="mb-4">
          <div class="que mb-2 pl-12">
            {{'pdl-website.adoptABook.formFields.remarks' | translate}}
            <sup class="err__ast">*</sup>
          </div>
          <div class="plr-12 pos-rel">
            <textarea formControlName="remarks" type="text" class="form-control search__input disabled " [maxLength]="1000"
            [ngClass]="{'is-invalid': !adoptBookForm.get('remarks')?.valid && adoptBookForm.get('remarks')?.touched}"
              id="exampleFormControlInput1" placeholder="">
            </textarea>
            <div class="form-text info__msg" [ngClass]="{'with-error': !adoptBookForm.get('remarks')?.valid && adoptBookForm.get('remarks')?.touched}">
              {{'pdl-shared.content.characterCount' | translate}}: {{ f['remarks'].value ? f['remarks'].value.length : 0 }}/1000
            </div> 
            @if(!adoptBookForm.get('remarks')?.valid && adoptBookForm.get('remarks')?.touched) {
              @if(adoptBookForm.get('remarks')?.errors?.['required']) {
                <div class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</div>
              }
            }
          </div>
        </div>
        } @else {
          <div class="mb-4">
            <div class="que mb-2 pl-12">
              {{'pdl-website.adoptABook.formFields.aboutOrg' | translate}}
              <sup class="err__ast">*</sup>
            </div>
            <div class="plr-12 pos-rel">
              <textarea formControlName="aboutOrg" class="form-control search__input disabled " cols="30" [maxLength]="1000"
              [ngClass]="{'is-invalid': !adoptBookForm.get('aboutOrg')?.valid && adoptBookForm.get('aboutOrg')?.touched}">
              </textarea>
              <div class="form-text info__msg" [ngClass]="{'with-error': !adoptBookForm.get('aboutOrg')?.valid && adoptBookForm.get('aboutOrg')?.touched}">
                {{'pdl-shared.content.characterCount' | translate}}: {{ f['aboutOrg'].value ? f['aboutOrg'].value.length : 0 }}/1000
              </div> 
              @if(!adoptBookForm.get('aboutOrg')?.valid && adoptBookForm.get('aboutOrg')?.touched) {
                @if(adoptBookForm.get('aboutOrg')?.errors?.['required']) {
                  <div class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</div>
                }
              }
            </div>
          </div>
        }
        
      </form>
    </div>
  </div>
  <div class="cart desktop-only innerpage-banner">
    <div class="cart-wrapper no-scrollbar" [ngClass]="{'loadingCart': loadingCart}">
      @if(loadingCart) {
        <div class="spinner-border mt-5" role="status"></div>
      } @else {
        <!-- // loop through cart itmes here -->
        @for (cart of cartList; track cart.CartID; let index=$index) {

        <div class="cart-item">
          <div class="d-flex col-gap-12">
            <div class="img">
              <img class="img" 
              src="{{imageBaseURL}}{{cart.CategoryName}}/{{cart.AccessionNo}}/Thumbnails/{{cart.AccessionNo}}_90.jpg"
              onerror="this.src='assets/images/icons/no-image-placeholder.svg'" alt="" />
              <div class="bin-icon">
                <img (click)="removeFromCart(cart)" class="bin-icon cursor-pointer" src="/assets/images/icons/bin-green.svg" alt="">
              </div>
            </div>
            <div class="info d-flex flex-column ">
              <span [routerLink]="['/metadata/'+cart.DocID]" class="mb-2 cursor-pointer">
                {{cart.Title}}
              </span>
              <div class="d-flex flex-row flex-wrap justify-content-between custom-gap">
                <div class="meta-btn">
                  <span>
                    <img src="/assets/BookReader/icons/book-icon.svg" alt="" />
                    {{cart.CategoryName}}
                  </span>
                </div>
                @if(cart.AuthorNames) {
                <div class="d-flex flex-grow-1 align-items-center justify-content-start">
                  <img src="/assets/images/icons/pen-ico.svg" alt="" />
                  <span class="author_name ">
                    {{cart.AuthorNames}}
                  </span>
                </div>
                }
              </div>
              @if(cart.AlreadyAdopted) {
                <div class="adoption__err err__msg">
                  {{'pdl-website.adoptABook.alreadyAdopted' | translate}}
                </div>
              }
            </div>
            <div class="price  flex-grow-1  d-flex justify-content-end">{{cart.AdoptionPrice | currency: currencyCode}}</div>
          </div>
        </div>
        }
      
      <!-- // loop ends here   -->
      <!-- // total block starts -->
      
      <div class="total_block">
        @if(totalAmount > 0) {
        <div class="d-flex justify-content-between mb-5">
          <span class="semibold fs-20">{{'pdl-website.adoptABook.totalAmount' | translate}}</span>
          <div class="price  flex-grow-1  d-flex justify-content-end">{{totalAmount | currency: currencyCode}}</div>
        </div>
        <div class="btn_wrapper">
          @if(currencyCode == 'INR') {
          <button (click)="createOrder(currencyCode, '')" class="btn btn__primary px-5" [disabled]="isCreatingOrder">
            @if(isCreatingOrder) {
            <div class="spinner-border" role="status"></div>
            } @else {
              {{'pdl-website.adoptABook.payNow' | translate}}
            }
          </button>
          }

          @if(!isMobile) {
            <div id="paypal-button-container"></div>
          }
        </div>  
        } @else {
          {{'pdl-website.adoptABook.addBooks' | translate}}
          <a [routerLink]="['/adopt-a-book']">{{'pdl-website.adoptABook.clickHere' | translate}} </a>
          {{'pdl-website.adoptABook.seeAvailable' | translate}}
        }
      </div>
      }
      <!-- total block ends -->
    </div>
  </div>
</section>
}

@if(!checkoutCompleted && !isSavingOrderInfo) {
<!-- @if (!mobileFullCartView) { -->
<div class="mob-only mob-cart" [hidden]="mobileFullCartView">
  <div class="d-flex justify-content-center pt-2" (click)="toggleCartView()">
    <img src="/assets/images/icons/chevron-double-up.svg" class="updown" />
  </div>

  <div class="d-flex bottom_cart_overview align-items-center justify-content-between">
    <h3 class="semibold">{{cartList.length}} items (Total {{totalAmount | currency: currencyCode}})</h3>
    <h3 class="semibold cursor-pointer" (click)="toggleCartView()">
      {{'pdl-website.adoptABook.checkout' | translate}} 
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
        fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
        <path fill-rule="evenodd"
          d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
      </svg> -->
    </h3>
  </div>
</div>
<!-- } @else { -->
<!-- //repeated for mobile -->
<div class=" mob-only opened-mobile-cart-wrapper-container" [hidden]="!mobileFullCartView">
  <div class="opened-mobile-cart mb-3">
    <div class="d-flex justify-content-center pt-2" (click)="toggleCartView()">
      <img src="/assets/images/icons/chervon-double-down.svg" class="updown" />
    </div>

    <div class="d-flex bottom_cart_overview align-items-center plr-12 justify-content-between">
      <h3 class="semibold">{{cartList.length}} items (Total {{totalAmount | currency: currencyCode}})</h3>
      <h3 class="semibold cursor-pointer" (click)="toggleCartView()">
        {{'pdl-website.adoptABook.checkout' | translate}} 
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
          fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
          <path fill-rule="evenodd"
            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
        </svg> -->
      </h3>
    </div>
  </div>
  <div class="cart-wrapper opened-mobile-cart-wrapper no-scrollbar">
    <!-- // loop through cart itmes here -->
    @for (cart of cartList; track cart.CartID) {
    <div class="cart-item">
      <div class="d-flex col-gap-12">
        <div class="img">
          <img class="img" 
          src="{{imageBaseURL}}{{cart.CategoryName}}/{{cart.AccessionNo}}/Thumbnails/{{cart.AccessionNo}}_90.jpg"
          onerror="this.src='assets/images/icons/no-image-placeholder.svg'" alt="" />
          <div class="bin-icon">
            <img (click)="removeFromCart(cart)" class="bin-icon cursor-pointer" src="/assets/images/icons/bin-green.svg" alt="">
          </div>
        </div>
        <div class="info d-flex flex-column ">
          <span class="semibold mb-2">{{cart.Title}}</span>
          <div class="">
            <div class="meta-btn">
              <span>
                <img src="/assets/BookReader/icons/book-icon.svg" alt=""/>
                {{cart.CategoryName}}
              </span>
            </div>
            @if(cart.AuthorNames) {
              <div class="d-flex flex-grow-1 align-items-center ">
                <img src="/assets/images/icons/pen-ico.svg" alt=""/>
                <span class="author_name ">{{cart.AuthorNames}}</span>
              </div>      
            }
          </div>
        </div>
        @if(cart.AlreadyAdopted) {
          <div class="adoption__err err__msg">{{'pdl-website.adoptABook.alreadyAdopted' | translate}}</div>
        }
        <div class="price  flex-grow-1  d-flex justify-content-end">{{cart.AdoptionPrice | currency: currencyCode}}</div>
      </div>
    </div>
    }
    <!-- // loop ends here   -->
    <!-- // total block starts -->
    @if(totalAmount > 0) {
    <div class="total_block">
      <div class="d-flex justify-content-between mb-5">
        <span class="semibold fs-20">{{'pdl-website.adoptABook.totalAmount' | translate}}</span>
        <div class="price  flex-grow-1  d-flex justify-content-end">{{totalAmount | currency: currencyCode}}</div>
      </div>
      <div class="btn_wrapper">
        @if(currencyCode == 'INR') {
          <button (click)="createOrder(currencyCode, '')" class="btn btn__primary px-5" [disabled]="isCreatingOrder">
            @if(isCreatingOrder) {
            <div class="spinner-border" role="status"></div>
            } @else {
              {{'pdl-website.adoptABook.payNow' | translate}}
            }
          </button>
          }
          @if(isMobile) {
          <div id="paypal-button-container"></div>
          }
      </div>
    </div>
    } @else {
      {{'pdl-website.adoptABook.addBooks' | translate}}
      <a [routerLink]="['/adopt-a-book']">{{'pdl-website.adoptABook.clickHere' | translate}} </a>
      {{'pdl-website.adoptABook.seeAvailable' | translate}}
    }
    <!-- total block ends -->
  </div>
</div>
<!-- } -->
}

<div id="razor-pay-checkout-wrapper"></div>
<div id="paypal-wrapper"></div>

<ng-template #adoptedTable let-docs="docs" let-status="statusLabel">
  <div class="table-responsive">
    <table class="adopted_book_table table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Accession Number</th>
          <th>Amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        @for(book of docs; track book.DocID) {
        <tr>
          <td>
            <a [routerLink]="['/metadata', book.DocID]">
              {{book.Title}}
            </a>
          </td>
          <td>{{book.AccessionNo}}</td>
          <td>{{book.Amount | currency: currencyCode}}</td>
          <td>{{status}}</td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</ng-template>
