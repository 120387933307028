import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAdminCuratedCollection from "../reducers/admin-curated-collection.reducers";

export const selectCuratedCollectionState =
  createFeatureSelector<fromAdminCuratedCollection.State>('adminCuratedCollection');

export const getCuratedCollectionList = createSelector(
    selectCuratedCollectionState,
    fromAdminCuratedCollection.selectAll
);

export const getTotalRecords = createSelector(
  selectCuratedCollectionState,
  (state) => state.totalRecords
);

export const getCollectionByID = (props: {id: string}) => createSelector(
  selectCuratedCollectionState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectCuratedCollectionState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectCuratedCollectionState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectCuratedCollectionState,
  (state) => state.isLoading,
);