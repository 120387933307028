@if(topicDetail) {
<section class="innerpage-banner" style="background: none;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" routerLink="/user/my-library">
            {{'pdl-website.forms.userLibrary.myResearchTopic' | translate}}
          </a> 
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular">
           {{topicDetail.TopicName}}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="container">
  <div class=" mt-3 d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center col-gap-12">
      <span class="heading bold">{{topicDetail.TopicName}}</span><span class="editbtn cursor-pointer"
        (click)="editTopic = true">{{'pdl-website.forms.userLibrary.edit' | translate}}</span>
    </div>
    <div class="deletebtn">
      <img class="cursor-pointer" (click)="deleteResearchTopic(topicDetail.TopicID)" src="/assets/images/icons/delete-icon.svg" />
    </div>
  </div>
  <div class="d-flex flex-wrap collection_overview_wrapper mb-4">
    @for(key of getCategoryKeys();track key) {
      <div class="d-flex">
        <img src="/assets/images/icons/pdl-guestbook-ico.svg" /> {{topicDetail.Count[key]}} {{key}}
      </div>
    }
  </div>
  <p class="text mb-4">{{topicDetail.Description}}<span class="editbtn cursor-pointer ms-2"
    (click)="editTopic = true">{{'pdl-website.forms.userLibrary.edit' | translate}}</span>
  </p>

  @if(isLoading) {
    <panjab-digi-lib-spinner></panjab-digi-lib-spinner>
   }
  <div class="list_wrapper mb-5">
    @for(topic of topicDetail.Data;track topic.DocID) {
    <div class="list_item_wrapper">
      <div class="list_item d-flex mb-1">
        <img class="img" [src]="(topic?.Thumbnail) ? topic.Thumbnail : 'assets/images/icons/no-image-placeholder.svg'"
        (error)="handleImageError($event)" alt="image" />
        <div class="content flex-grow-1">
          <div class="semibold mb-2 d-flex justify-content-between "><span>{{topic.Title}} </span>

            <div class="likeandOptionWrapper p-absolute d-flex col-gap-12 flex-wrap">
              <button class="btn btn-primary btn__primary ms-3" (click)="docId = topic.DocID ;addToTopic = true;">{{'pdl-website.forms.userLibrary.addToAnotherTopic' | translate}} <img src="assets/images/icons/add-icon.svg" class="img-fluid"></button>

              <span class="cursor-pointer" (click)="removeDocumentFromTopic(topicDetail.TopicID, topic.DocID)"> <img src="/assets/images/icons/heart-green.svg"
                  aria-label="dislike" /></span> 
            </div>
          </div>
          <div class="d-flex flex-wrap collection_overview_wrapper mb-4">
            <div class="d-flex">
              <img src="/assets/images/icons/pdl-guestbook-ico.svg" /> {{topic.CategoryName}}
            </div>
            @for(author of topic.Author;track author) {
            <div class="d-flex">
              <img src="/assets/images/icons/pen-ico.svg" /> {{author}}
            </div>
          }
            <div class="d-flex">
              <img src="/assets/images/icons/calendar-ico.svg" /> {{topic.Year}}
            </div>
          </div>
          <div class="hyper-text mb-2">
            {{topic.Bookmarks.length}} {{'pdl-website.forms.userLibrary.pageBookmarks' | translate}}
          </div>  
          <div class="notes_dpdwn">
            My Notes <span class="total_text">(Total {{topic.Notes.length}} Notes)</span>
            @if(topic.Notes.length > 0) {
            <img
            class="cursor-pointer ms-2"
            (click)="toggleShowNotes(topic.DocID)"
            [src]="showNotes[topic.DocID] ? '/assets/images/icons/chevron-up.svg' : '/assets/images/icons/chevron-down.svg'"
          />
            }
          </div>
        </div>


      </div>
      @if (showNotes[topic.DocID]) {
      <div class="notes">
      @for(notes of topic.Notes;track notes) {
      <div class="expanded_notes_wrapper">
        <div class="noteItem mb-2">
          <div class="d-flex col-gap-12 align-items-center justify-content-between mb-2">
            <div class="d-flex align-items-center">
              <span class="semibold">{{'pdl-website.forms.userLibrary.page' | translate}} {{notes.PageNumber}}</span>
              <button class="view_btn ms-3">{{'pdl-website.forms.userLibrary.readerView' | translate}}</button>
            </div>
           
            <div class="d-flex">
              <img src="/assets/images/icons/calendar-ico.svg" class="me-2"/> {{notes.Date | date: 'dd MMM yyyy'}}
            </div>
          </div>
          <div class="note">{{notes.Note}}</div>
        </div>
      </div>
    }
  </div>

      }
    </div>
  } @empty {
    <span class="text-center"> {{'pdl-shared.content.noRecordsFound' | translate}}</span>
  }

  @if(totalRecords > pageSize) {
  <div class="row mb-5">
    <div class="col-12">
       <div class="pdl-pagination d-flex align-items-center justify-content-center webPagination__wrapper mt-5">
          <nav aria-label="Page navigation example">
             <ngb-pagination class="donations__pagination" [collectionSize]="totalRecords"
             [(page)]="pageNumber" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
             (pageChange)="fetchTopicDetail(pageNumber)" [maxSize]="maxPageNumber" [ellipses]="true" />
          </nav>
       </div>
    </div>
 </div>
}
  </div>

</section>
}

@if(editTopic) {
  <panjab-digi-lib-create-edit-topic (closeModal)="closeCreateTopicModal($event)" [topicId]="topicId" [topicName]="topicDetail.TopicName" [topicDescription]="topicDetail.Description"></panjab-digi-lib-create-edit-topic>
}
@if(addToTopic) {
  <panjab-digi-lib-assign-literature [docId]="docId" [addToNew]="true" [topicId]="topicId" (closeModal)="closeAssignModal($event)"></panjab-digi-lib-assign-literature>
}
<router-outlet></router-outlet>
