/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { API_ENDPOINTS } from '../app.endpoints';
import { ChildExhibitionPayload, ExhibitionResponse, SaveExhibitionPayload, SaveExhibitionResponse } from 'shared/src/interfaces/exhibition.interface';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { GenericListingPayload, GenericListingPayloadWithCategoryFilter } from 'shared/src/interfaces/common.interface';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { AddEditCuratedCollectionPayload } from 'shared/src/interfaces/curated-collection.interface';

@Injectable({
  providedIn: 'root'
})
export class CuratedCollectionService {
  rootURL = environment.rootApiUrl;
  endPointUrl = API_ENDPOINTS.curatedCollection;
  constructor(private http: HttpClient) { }

  getCuratedCollections(payload: GenericListingPayload) {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.default}`, {params: {...payload}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  changeCollectionStatus(CollectionID: number, status: number) {  
    return this.http.patch(`${this.rootURL}${this.endPointUrl.changeStatus}`, { CollectionID, status })
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }
  
  changeDcoumentStatus(CDID: number, status: number) {  
    return this.http.patch(`${this.rootURL}${this.endPointUrl.changeDocumentStatus}`, { CDID, status })
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }
  
  addCuratedCollection(payload: AddEditCuratedCollectionPayload) {  
    return this.http.post(`${this.rootURL}${this.endPointUrl.default}`, { ...payload })
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }
  
  editCuratedCollection(payload: AddEditCuratedCollectionPayload, collectionID: string) {  
    return this.http.put(`${this.rootURL}${this.endPointUrl.default}/${collectionID}`, { ...payload })
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  getDocumentList(payload: GenericListingPayloadWithCategoryFilter, CollectionID: string) {
    return this.http.get(`${this.rootURL}${this.endPointUrl.document.default}/${CollectionID}`, {params: {...payload}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  searchDocByAccessionNo(CollectionID: string, AccessionNo: string) {
    return this.http.get(`${this.rootURL}${this.endPointUrl.document.search}/${CollectionID}?accessionNo=${AccessionNo}`)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  addDocumentToCollection(CollectionID: number, DocID: number) {  
    return this.http.post(`${this.rootURL}${this.endPointUrl.document.add}`, { CollectionID, DocID })
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  removeDocumentFromCollection(CDID: number) {
    return this.http.delete(`${this.rootURL}${this.endPointUrl.document.default}/${CDID}`)
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }


    return throwError(() => transformedError);
  }
}