<section class="innerpage-banner mb-5">
  <div class="row align-items-center">
    <div class="col-lg-12 ">
      <div class="page-title m-auto">
        <h1 class="heading-dark semibold mb-0">{{'pdl-website.userProfile.TitleText' | translate }}</h1>
      </div>
    </div>
  </div>
</section>
<section class="info-section">
  <div class="profile_information m-auto" [ngClass]="{'user_form': isReadOnly}">
    @if(isReadOnly) {
    <div class="edit_icon d-flex justify-content-end mb-2 above_desktop">
      <img src="/assets/images/icons/edit-icon-new.svg" (click)="onEditClick()" alt="edit"
        style="margin: 25px 25px 0 0;" />
    </div>
    }
    <div class="d-flex justify-content-center heading-dark mb-5 fs-4"
      [ngStyle]="{'margin-top': !isReadOnly ? '55px': 0}">
      <span class="title-text">{{'pdl-website.forms.user.profileInfo' | translate }}</span>
      @if(isReadOnly) {
      <div class="edit_icon d-flex justify-content-start align-items-center below_desktop">
        <img src="/assets/images/icons/edit-icon-new.svg" (click)="onEditClick()" alt="edit" />
      </div>
      }
    </div>
    <form [formGroup]="userProfileForm" (ngSubmit)="onSubmit()" class="form-items mb-4 m-auto"
      style="max-width: 713px; ">
      @if(showAlert) {
      <!-- show alert -->
      <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
      <!-- /show alert -->
      }
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="form-label"> {{'pdl-website.forms.labels.email' | translate }}
          </label>
          <span class="flex-grow-1"></span>
          <div class="text-with-border">{{userProfileForm.controls['email'].value}}</div>
          <!-- <input type="email" formControlName="email" class="form-control form-control-dark" placeholder="" aria-label="Email"> -->
        </div>
        <div class="col-md-6">
          <label class="form-label"> {{'pdl-website.forms.labels.desiredUsername' | translate }}
          </label>
          <div class="text-with-border">{{userProfileForm.controls['username'].value}}</div>
          <!-- <input type="text" formControlName="username" class="form-control form-control-dark" placeholder="" aria-label="Desired Username"> -->
        </div>
      </div>
      <div class="mt-3 row">
        <div class="col-12">
          <div class="d-flex">
            <label class="form-label required"
              [ngClass]="{'text-danger': userProfileForm.controls['fullname'].invalid && userProfileForm.controls['fullname'].touched}">{{'pdl-website.forms.labels.fullname'
              | translate }}</label>
            <span class="flex-grow-1"></span>
            @if(userProfileForm.controls['fullname'].invalid && userProfileForm.controls['fullname'].touched) {
            <ng-container
              *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.fullname' | translate }"></ng-container>
            }
          </div>
          <input type="text" formControlName="fullname" class="form-control form-control-dark" [readonly]="isReadOnly"
            placeholder="" aria-label="Full Name"
            [ngClass]="{'border-danger': userProfileForm.controls['fullname'].invalid && userProfileForm.controls['fullname'].touched}">
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6 field__section">
          <div class="d-flex">
            <label for="country" class="form-label required"
              [ngClass]="{'text-danger': userProfileForm.controls['country'].invalid && userProfileForm.controls['country'].touched}">{{'pdl-website.forms.labels.country'
              | translate }}</label>
            <span class="flex-grow-1"></span>
            @if(userProfileForm.controls['country'].invalid && userProfileForm.controls['country'].touched) {
            <ng-container
              *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.required' | translate }"></ng-container>
            }
          </div>
          <select id="country" formControlName="country" class="form-select form__field sort__filter select__grey"
            aria-label="country"
            [ngClass]="{'border-danger': userProfileForm.controls['country'].invalid && userProfileForm.controls['country'].touched}">
            <option ngValue="">Select</option>
            @for(country of countryList$ | async; track country.CountryID) {
            <option [ngValue]="country?.CountryID">{{country?.Name}}</option>
            }
          </select>
        </div>
        <div class="col-md-6">
          <label class="form-label">{{'pdl-website.forms.labels.state' | translate }}</label>
          <input type="text" formControlName="state" [readonly]="isReadOnly" class="form-control form-control-dark"
            placeholder="" aria-label="State">
        </div>
      </div>
      <div class="row">
        <div class="mt-3 col-md-6">
          <label class="form-label"> {{'pdl-website.forms.labels.address1' | translate }}
          </label>
          <input type="text" formControlName="address1" [readonly]="isReadOnly" class="form-control form-control-dark"
            placeholder="" aria-label="Address1">
        </div>
        <div class="mt-3 col-md-6">
          <label class="form-label"> {{'pdl-website.forms.labels.address2' | translate }}
          </label>
          <input type="text" formControlName="address2" [readonly]="isReadOnly" class="form-control form-control-dark"
            placeholder="" aria-label="Address2">
        </div>
      </div>
      @if(!isReadOnly) {
      <div class="btn_section mt-5 d-flex flex-wrap justify-content-center" style="column-gap: 20px; width: 100%;">
        <div class="text-center">
          <button style="width: 125px;" (click)="onCancel()" class="btn btn__secondary"
            [disabled]="isSubmitted">{{'pdl-website.forms.labels.cancel' | translate }}</button>
        </div>
        <div class=" text-center">
          <button style="width: 125px;" type="submit" [disabled]="isSubmitted" class="btn btn-primary btn__primary">
            @if(isSubmitted) {
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            } @else {
            <span>{{'pdl-website.forms.labels.submit' | translate }}</span>
            }
          </button>
        </div>
      </div>
      }
    </form>

  </div>
  <div class="profile_information m-auto">
    <div class="action_section">
      <div class="change_password pt-3 pb-3 col-12 flex-wrap  d-flex justify-content-between m-auto"
        style="max-width: 713px;">
        <p class="page-title">{{'pdl-website.forms.user.changePasswordBtn' | translate }}</p>
        <div class="d-flex align-items-center content">
          <div class="content_text">
            {{'pdl-website.forms.user.changePasswordText' | translate }}
          </div>
          @if(enableChangePassword) {
          <div class="text-center">
            <button type="submit" (click)="openChangePasswordModal()" class="btn btn-primary btn__primary">
              {{'pdl-website.forms.user.changePasswordBtn' | translate }}
            </button>
          </div>
          } @else {
          <div class="text-center">
            <button class="btn btn-primary btn__primary cursor__auto" [ngbTooltip]="disableChangePword">
              {{'pdl-website.forms.user.changePasswordBtn' | translate }}
            </button>
            <ng-template #disableChangePword>{{'pdl-website.forms.toolTip.changePasswdNotAllowed' | translate
              }}</ng-template>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="profile_information m-auto">
    <div class="action_section">
      <div class="delete_account pt-3 pb-3 col-12 flex-wrap d-flex justify-content-between m-auto"
        style="max-width: 713px;">
        <p class="page-title">{{'pdl-website.forms.user.deleteOrDeactivateTitleTxt' | translate }}</p>
        <div class="d-flex align-items-center content">
          <div>
            <p class="content_text">{{'pdl-website.forms.user.deleteOrDeactivateTxt1' | translate }}</p>
            <p class="content_text">{{'pdl-website.forms.user.deleteOrDeactivateTxt2' | translate }}</p>
          </div>
          <div class="text-center">
            <button type="submit" (click)="openDeleteDeactivateModal()" class="btn btn-primary btn__primary">
              {{'pdl-website.forms.user.deleteOrDeactivateBtn' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Template - invalid field error -->
<ng-template #invalidError let-msg="msg">
  <span class="text-danger">
    <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
    <ng-template #tipContent>{{msg}}</ng-template>
  </span>
</ng-template>
<!-- change password modal-->
<panjab-digi-lib-change-password (handleParentAlert)="handleAlerts($event)"></panjab-digi-lib-change-password>
<!-- delete or deactivate account modal-->
<panjab-digi-lib-delete-deactivate-account></panjab-digi-lib-delete-deactivate-account>
