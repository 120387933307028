import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPopupService } from "shared/src/services/common/modal-popup.service";
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from 'shared/src/services/website/alert.service';
import { AlertsComponent } from 'shared/src/lib/components/web/alerts/alerts.component';
import { ConfirmationModalService } from 'shared/src/services/confirmation-modal/confirmation-modal.service';
import { UserService } from '../../../../common/services/user.service';
import { UserDownloadsService } from 'shared/src/services/website/downloads/user-downloads.service';
import { SharedCommonService, SpinnerComponent } from '@panjab-digi-lib/shared';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DownloadLimitInfo } from 'shared/src/interfaces/website/user-downloads.interface';
import { ToastService } from 'shared/src/services/toast/toast.service';

@Component({
  selector: 'panjab-digi-lib-download-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, AlertsComponent, SpinnerComponent],
  templateUrl: './download-modal.component.html',
  styleUrl: './download-modal.component.scss',
})
export class DownloadModalComponent implements OnInit, OnDestroy {
  deleteDeactivateForm!: FormGroup;
  downloadLimits!: DownloadLimitInfo;
  privacyPolicies: Array<{title: string, text: string}> = [];
  private unsubscribe$: Subject<void> = new Subject();
  @ViewChild('downloadModal') downloadModal!: TemplateRef<any>;
  checkingDownloadLimit: boolean = true;
  privacyPolicyAgreed: boolean = false;
  isDownloading$: Observable<boolean> = this.modalPopupService.modalBtnsLoading$;
  downloadLimitReachedTxt: string = '';
  downloadLimitTxt: string = '';
  constructor(
    private modalPopupService: ModalPopupService,
    private translate: TranslateService,
    private userDownloadsService: UserDownloadsService,
    private sharedCommonService: SharedCommonService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.modalPopupService.modalBtnsDisabled$.next(true);
    this.translate.get('pdl-website.downloadPopup.privacyPolicies').pipe(takeUntil(this.unsubscribe$))
    .subscribe((privacyPolicies) => {
      this.privacyPolicies = privacyPolicies;
    });
  }

  onPrivacyPolicyChange() {
    this.privacyPolicyAgreed = !this.privacyPolicyAgreed;
    this.modalPopupService.modalBtnsDisabled$.next(!this.privacyPolicyAgreed);
  }

  handleOnSubmit(docId: number, type: string, title: string) {
    this.modalPopupService.modalBtnsDisabled$.next(true);
    this.modalPopupService.modalBtnsLoading$.next(true);
    this.userDownloadsService.download(docId, type).subscribe({
      next: (response) => {
        type = type == 'TXT' ? 'pdf' : type;
        this.sharedCommonService.downloadFile(response, `${title}.${type.toLowerCase()}`);
        this.modalPopupService.dismiss();
        this.modalPopupService.modalBtnsDisabled$.next(true);
        this.modalPopupService.modalBtnsLoading$.next(false);
        this.privacyPolicyAgreed = false;
      }, error: (error) => {
        console.log('error', error);
        this.toastService.showError(error.message);
        this.modalPopupService.modalBtnsDisabled$.next(false);
        this.modalPopupService.modalBtnsLoading$.next(false);
      }
    })
  }

  handleOnCancel() {
    this.isDownloading$.pipe(takeUntil(this.unsubscribe$)).subscribe((isDownloading) => {
      if(!isDownloading) {
        this.privacyPolicyAgreed = false;
        this.modalPopupService.modalBtnsDisabled$.next(true);
      }
    });
  }
  
  openModalPopup(docId: number, type: string, title: string) {
    this.userDownloadsService.checkDownloadLimit().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response) => {
        this.checkingDownloadLimit = false;
        this.downloadLimits = response;
        if(this.downloadLimits.downloadLimitReached) {
          this.downloadLimitReachedTxt = this.translate.instant('pdl-website.downloadPopup.downloadLimitReachedTxt').replace('<downloadLimit>', this.downloadLimits.downloadLimit);
        } else {
          this.downloadLimitTxt = this.translate.instant('pdl-website.downloadPopup.downloadLimitTxt')
          .replace('<downloadCount>', this.downloadLimits.downloadCount)
          .replace('<remainingCount>', this.downloadLimits.remainingCount);
        }
        this.modalPopupService.open(
          title,
          '',
          this.downloadModal,
          this.translate.instant('pdl-shared.buttonText.continue'),
          this.translate.instant('pdl-shared.buttonText.cancel'),
          () => this.handleOnSubmit(docId, type, title),
          () => this.handleOnCancel(),
          'lg',
          undefined, // formgroup
          false // auto close
        );
      },
      error: (error) => {
        this.checkingDownloadLimit = false;
        console.log('error', error);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
