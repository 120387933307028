import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { WebCuratedCollectionService } from 'shared/src/services/website/curated-collection/curated-collection.service';
import { Subject, takeUntil } from 'rxjs';
import { CuratedCollection } from 'shared/src/interfaces/website/curated-collection.interface';
import { environment } from '@panjab-digi-lib/shared';
import { GlobalDividerComponent } from '../../divider/global-divider.component';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-curated-collections',
  standalone: true,
  imports: [
    CommonModule, 
    SlickCarouselModule, 
    RouterModule, 
    TranslateModule, 
    GlobalDividerComponent
  ],
  templateUrl: './curated-collections.component.html',
  styleUrl: './curated-collections.component.scss',
})
export class CuratedCollectionsComponent implements OnInit, OnChanges, OnDestroy {

  pageNo = 1;
  pageSize = 3;
  imageBaseURL = environment.azureCloudStrorage.publicUrl;

  @Input() isForHomePage!: boolean;
  @Input() excludeCollectionID!: number;
  @Input() collectionDetailsUrl: string = '/collection';
  @Input() heading: string = '';
  featuredCollectionList!: CuratedCollection[];

  private unsubscribe$: Subject<void> = new Subject();
  
  constructor(
    private translate: TranslateService,
    private curatedCollectionService: WebCuratedCollectionService,
    private router: Router
  ) {}

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      }
    ]
  };

  slickInit(e: any) {
    console.log('slick initialized');
  }

  ngOnInit(): void {
    this.getFeaturedCollection();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['excludeCollectionID'] &&
      !changes['excludeCollectionID'].firstChange &&
      changes['excludeCollectionID'].currentValue !== changes['excludeCollectionID'].previousValue
    ) {
      this.getFeaturedCollection();
    }
  }
  
  changeCollection(collectionID: number) {
    this.router.navigateByUrl('/', {skipLocationChange: true})
    .then(()=>this.router.navigateByUrl(`${this.collectionDetailsUrl}/${collectionID}`)); 
  }
  
  getFeaturedCollection() {
    
    const payload = {
      pageNumber: this.pageNo, 
      pageSize: this.pageSize, 
      excludeID: this.excludeCollectionID
    }
    
    this.curatedCollectionService.getFeaturedCollection(payload).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (response) => {
          this.featuredCollectionList = response.collections;
        }, error: (error) => {
          console.log('error', error);
          this.featuredCollectionList = [];
        }
      })
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
