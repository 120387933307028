<panjab-digi-lib-static-page-head [pageName]="'pdl-website.dataMining.titleText' | translate" pagePath="/data-mining">
</panjab-digi-lib-static-page-head>

<section class="static-section section-padding ">
  <div class="container-1000">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/data-mining.png" /></div>
      <div>
        <h1 class="heading mb-3">Data Mining</h1>
        <p class="text">
          Data Mining refers to extracting required information or data from an archived collection of manuscripts,
          books, newspapers, magazines, etc... The extracted information is then passed on to the requestor in the
          required format. Researchers and scholars are often in need of such specific data. Before digitization, the
          data had to be mined manually from thousands of pages, altogether process which sometimes took years to
          complete. Now PDL, with its millions of digitized pages, will be able to mine information from its database
          and supply it to the user for a small fee. PDL also holds copies of newspapers since 1970, and we can provide
          complete information on any specific issue from contemporary history. For example, we could provide news data
          regarding how many times Governor's rule was imposed on Punjab since independence or how many people died in
          accidents on the roads of Panjab since 1970.
        </p>
        <p class="text">For more information write to <a href="mailto:librarian@panjabdigilib.org" target="_blank"
            class="email cursor-pointer text">librarian&#64;panjabdigilib.org</a></p>
      </div>
    </div>
    }
  </div>
</section>
