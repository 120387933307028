/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminNewsAndArticlesActions } from '../actions';
import {
  catchError,
  exhaustMap,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AdminNewsAndArticlesSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';
import { NewsAndArticlesService } from 'shared/src/services/admin/news-and-articles/news-and-articles.service';

@Injectable()
export class AdminNewsAndArticlesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: NewsAndArticlesService,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}

  //////////////// get list ////////////
  LoadNewsAndArticlesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminNewsAndArticlesActions.LoadRecords),
      exhaustMap((action) => {
        return this.service.loadNewsAndArticles(action?.payload).pipe(
          map((response) => {
            return AdminNewsAndArticlesActions.LoadRecordsSuccess({
              recordsList: response?.recordsList,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminNewsAndArticlesActions.LoadRecordsError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add detail ////////////
  AddNewsAndArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminNewsAndArticlesActions.AddNewRecord),
      exhaustMap((action) => {
        return this.service.addNewsAndArticle(action?.payload).pipe(
          map((response) => {
            return AdminNewsAndArticlesActions.RecordAddedSuccess({
              record: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminNewsAndArticlesActions.AddRecordError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update detail  ////////////
  UpdateNewsAndArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminNewsAndArticlesActions.UpdateRecord),
      exhaustMap((action) => {
        return this.service
          .updateDetail(action?.payload, action?.id)
          .pipe(
            map((response) => {
              return AdminNewsAndArticlesActions.RecordUpdatedSuccess({
                record: response?.body,
                message: response?.message,
              });
            }),
            catchError((error) =>
              of(
                AdminNewsAndArticlesActions.UpdateRecordError({
                  message: error?.error?.error?.message,
                })
              )
            )
          );
      })
    )
  );

  //////////////// update status ////////////
  UpdateNewsAndArticlesStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminNewsAndArticlesActions.UpdateRecordStatus),
      exhaustMap((action) => {
        return this.service.updateStatus(action?.payload).pipe(
          map((response) => {
            return AdminNewsAndArticlesActions.RecordStatusUpdatedSuccess({
              record: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminNewsAndArticlesActions.UpdateRecordStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////////// redirect after add new record /////////////////////
  AddNewsAndArticlesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminNewsAndArticlesActions.RecordAddedSuccess,
        AdminNewsAndArticlesActions.RecordUpdatedSuccess
      ),
      withLatestFrom(
        this.store.pipe(select(AdminNewsAndArticlesSelector.loadNewsAndArticlesList))
      ),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/news-and-articles').then(() => {
          window.location.reload();
        });
        const pageNumber =
          action.type === AdminNewsAndArticlesActions.RecordAddedSuccess.type
            ? 1
            : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize,type : 'all' };
        return of(AdminNewsAndArticlesActions.LoadRecords({ payload }));
      })
    )
  );
}
