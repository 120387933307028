<!-- social media buttons -->
<div class="d-flex gap-20 social-icon flex-wrap">
    <div class="social1 text-sm-end text-center">
        <a href="javascript:void(0);" type="button" (click)="loginWithFB()" class="fb-icon rounded-1 d-inline-flex justify-content-cente align-items-center">
            <img src="../../../assets/images/icons/fb-icon.svg" alt="facebook" />
            <span>{{'pdl-shared.socialLogin.connectWithFb' | translate }}</span>
        </a>
    </div>
    <div class="social2 text-sm-start text-center">
        <asl-google-signin-button class="google" type='standard' size='large'  text="continue_with">
        </asl-google-signin-button>
    </div>
</div>
