<div class="spinner-outer-container">
  <div class="spinner-container">
    <output class="spinner-grow text-success" [style.width]="'7px'" [style.height]="'7px'" aria-live="polite">
      <span class="visually-hidden">Loading...</span>
    </output>
    @if (showSecond) {
      <output class="spinner-grow text-success" [style.width]="'10px'" [style.height]="'10px'" aria-live="polite">
        <span class="visually-hidden">Loading...</span>
      </output>
    }
    @if (showThird) {
      <output class="spinner-grow text-success" [style.width]="'15px'" [style.height]="'15px'" aria-live="polite">
        <span class="visually-hidden">Loading...</span>
      </output>
    }
  </div>
</div>