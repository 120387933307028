import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions/index';

export interface State {
  token: string | null;
  refreshToken: string | null;
  error: any;
  isLoading: boolean;
  user: any;
}

const initialState: State = {
  token: null,
  refreshToken: null,
  error: null,
  isLoading: false,
  user: null
};

export const authReducer = createReducer(initialState,
  on(AuthActions.login, state => ({ ...state, isLoading: true })),
  on(AuthActions.loginSuccess, (state, { token, refreshToken }) => ({ ...state, token, refreshToken, isLoading: false, error: null })),
  on(AuthActions.tokenExpired, (state) => ({ ...state, token: null, isLoading: false })),
  on(AuthActions.loginFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
  on(AuthActions.logout, (state) => ({ ...state, token: null, error: null, isLoading: false })),
  on(AuthActions.webLogin, state => ({ ...state, isLoading: true })),
  on(AuthActions.webLoginSuccess, (state, { webToken, webRefreshtoken, user }) => ({ ...state, webToken, webRefreshtoken, user, isLoading: false })),
  on(AuthActions.webTokenExpired, (state) => ({ ...state, webToken: null, isLoading: false })),
  on(AuthActions.webLoginFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
  on(AuthActions.webLogout, (state) => ({ ...state, webToken: null, token: null, user: null, error: null, isLoading: false })),
  on(AuthActions.webSocialLogin, state => ({ ...state, isLoading: true })),
  on(AuthActions.resetState, state => ({...initialState}))
);