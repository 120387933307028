import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, environment, WebCategoriesSelector, WebHomePageActions, SharedCommonService } from '@panjab-digi-lib/shared';
import { HomeCategories } from 'shared/src/interfaces/homepage.interface';
import { map, Observable } from 'rxjs';
import { APP_CONSTANTS } from '../../../../common/constants/app.constants';

@Component({
  selector: 'panjab-digi-lib-categories',
  standalone: true,
  imports: [CommonModule,TranslateModule,SlickCarouselModule,RouterLink],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss',
})
export class CategoriesComponent implements OnInit {
  
  categories$!: Observable<HomeCategories[]>;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  fallbackImage = APP_CONSTANTS.categoryFallbackImage;
  constructor(
    private readonly translate: TranslateService, 
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly commonService: SharedCommonService
  ) {

  }

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          dots: false
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          dots: false
        }
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          dots: false
        }
      }
    ]
  };

  slickInit(e: any) {
    // console.log('slick initialized');
  }

  ngOnInit(): void {
    
    this.store.dispatch(WebHomePageActions.LoadWebCategories());
    
    this.categories$ = this.store.select(WebCategoriesSelector.getCategories).pipe(
      map((categories) => 
        categories
          .filter((category) => category.ShowOnHome)
          .sort((a, b) => a.HomeCategoriesOrder - b.HomeCategoriesOrder)
      )
    );
  }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  generateSearchUrl(categoryId: number) {
    this.router.navigate(['/search'], {
      queryParams: { query: '', categoryId : this.commonService.encodeToBase64(categoryId)}
    });
  }

}
