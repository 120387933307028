import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AppState, WebAdoptedBooksActions, WebAdoptedBooksSelector, SpinnerComponent, environment } from '@panjab-digi-lib/shared';
import { Adopter } from 'shared/src/interfaces/fundraising.interface';
import { Store } from '@ngrx/store';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { GenericListingPayload, SortOrder } from 'shared/src/interfaces/common.interface';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-adopted-books',
  standalone: true,
  imports: [CommonModule, NgbPaginationModule, NgbPopoverModule, TranslateModule, RouterLink, SpinnerComponent],
  templateUrl: './adopted-books.component.html',
  styleUrl: './adopted-books.component.scss',
})
export class AdoptedBooksComponent implements OnInit, OnDestroy {
  pageNo = 1;
  pageSize = APP_CONSTANTS.pageSize;
  totalRecords = 0;
  sortOrder: SortOrder = { orderBy: 'DateAdopted', order: 'DESC' };
  defaultBookImg = '../../../../assets/images/default-book.png';
  defaultBookName = '1.jpg';
  docStatus = APP_CONSTANTS.documentStatus;
  
  isLoading$: Observable<boolean> = this.store.select(WebAdoptedBooksSelector.isLoading);
  adoptedBooks$!: Observable<Adopter[]>;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) {
    StoreDevtoolsModule.instrument({});
  }

  ngOnInit(): void {
    this.loadAdoptedBooks();  
  }

  loadAdoptedBooks() {
    const payload: GenericListingPayload = { ...this.sortOrder, "pageNumber": this.pageNo, "pageSize": this.pageSize };
    this.store.dispatch(WebAdoptedBooksActions.GetAdoptedBooksList({payload}));
    this.adoptedBooks$ = this.store.select(WebAdoptedBooksSelector.getAdoptedBooks).pipe(takeUntil(this.unsubscribe$));
    this.store.select(WebAdoptedBooksSelector.getTotalRecords).pipe(takeUntil(this.unsubscribe$))
    .subscribe((totalRecords) => {
      this.totalRecords = totalRecords;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = this.defaultBookImg;
  }
}
