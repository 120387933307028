import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HomepageService } from 'shared/src/services/website/home/homepage.service';
import { Subscription } from 'rxjs';
import { PopularReadList } from 'shared/src/interfaces/popular-read.interface';
import { environment } from '@panjab-digi-lib/shared';
import { RouterModule } from '@angular/router';
import { GlobalDividerComponent } from '../../divider/global-divider.component';

@Component({
  selector: 'panjab-digi-lib-home-forum',
  standalone: true,
  imports: [
    CommonModule,
    SlickCarouselModule,
    TranslateModule,
    RouterModule,
    GlobalDividerComponent,
  ],
  templateUrl: './forum.component.html',
  styleUrl: './forum.component.scss',
})
export class ForumComponent implements OnInit, OnDestroy {
  topics = [
    { name: 'Manuscripts', count: 4149 },
    { name: 'Books', count: 46149 },
    { name: 'Magazines', count: 4149 },
    { name: 'Newspapers', count: 234 },
    { name: 'Photographs', count: 678 },
    { name: 'Pamphlets', count: 234 },
  ];

  results = [
    {
      timestamp: '12:00PM. 15 June 2020',
      title: 'RURAL REHABILITATION IN EAST PUNJAB',
      category: 'BOOKS',
      creator: 'eonline cheap',
      description:
        'Weekly Vol. 2, no. 1 (June 25, 1954)-v. 17, no. 32 (Feb. 11, 1971). Published as: San Miguel forum, frequently between May 24, 1957-Jan. 21, 1971, with separate numbering, and: Forum and the San Miguel forum, Feb. 11, 1971. Published also for Norwood and Telluride. San Miguel Basin...',
      imageUrl: 'https://placehold.co/400',
      views: 125,
      comments: 15,
    },
    {
      timestamp: '1:00PM, 16 June 2020',
      title: 'SZKOLENIE PLAKAT - FORUM POLONIA',
      category: 'MANUSCRIPTS',
      creator: 'another_user',
      description: 'Details about the manuscript...',
      imageUrl: 'https://placehold.co/400',
      views: 200,
      comments: 20,
    },
    {
      timestamp: '2:00PM, 17 June 2020',
      title: 'BITCHUTE - defending-gibraltar_truther-forum (2023)',
      category: 'PHOTOGRAPHS',
      creator: 'photo_lover',
      description: 'A collection of historical photographs...',
      imageUrl: 'https://placehold.co/400',
      views: 300,
      comments: 25,
    },
  ];
  subscription = new Subscription();
  popularReads: PopularReadList[] = [];
  imageBaseURL = environment.azureCloudStrorage.publicUrl;
  slideConfig = {
    centerMode: false,
    centerPadding: '40px',
    autoplay: false,
    draggable: true,
    autoplaySpeed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
    ],
  };
  constructor(
    private translate: TranslateService,
    private homepageService: HomepageService
  ) {}

  ngOnInit(): void {
    this.fetchMostPopularReads();
  }

  onTopicSelect(event: Event) {
    const selectedTopic = (event.target as HTMLSelectElement).value;
    console.log('Selected Topic:', selectedTopic);
    // Add any additional logic you want to handle the selected topic
  }

  /////////////// list of most popular reads /////////////////
  fetchMostPopularReads() {
    this.subscription.add(
      this.homepageService.popularReads().subscribe({
        next: (response) => {
          this.popularReads = response?.popularReadList;
        },
        error: (error) => {
          console.log('popular read error', error);
        },
      })
    );
  }

  slickInit(e: any) {
    console.log('slick initialized');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
