import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  inject,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ModalPopupService } from 'shared/src/services/common/modal-popup.service';

@Component({
  selector: 'panjab-digi-lib-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, CommonModule, FormsModule],
})
export class PrintModalComponent implements OnInit {
  @ViewChild('printModal') printModal!: TemplateRef<any>;

  printForm!: FormGroup;
  bookNotAvailable = true;

  private modalPopupService = inject(ModalPopupService);
  private translate = inject(TranslateService);

  ngOnInit(): void {
    this.printForm = new FormGroup({
      printOption: new FormControl(null),
      bindingOption: new FormControl({ value: null, disabled: true }) 
    });
  
    this.printForm.get('printOption')?.valueChanges.subscribe((value) => {
      this.printForm.get('bindingOption')?.reset(); 
      this.printForm.get('bindingOption')?.enable();
    });
  
    this.printForm.get('bindingOption')?.valueChanges.subscribe((bindingValue) => {
      if (!bindingValue) return;
  
      const selectedPrintOption = this.printForm.get('printOption')?.value;
  
      if (
        (bindingValue === 'softBind' || bindingValue === 'hardBind') && selectedPrintOption !== 'newBook'
      ) {
        this.printForm.patchValue({ printOption: 'newBook' }, { emitEvent: false });
      } else if (
        (bindingValue === 'softBindPrint' || bindingValue === 'hardBindPrint') && selectedPrintOption !== 'printOnDemand'
      ) {
        this.printForm.patchValue({ printOption: 'printOnDemand' }, { emitEvent: false });
      }
    });
  }
  

  openPrintModal() {
    this.modalPopupService.open(
      this.translate.instant('pdl-website.printPopup.title'),
      '',
      this.printModal,
      this.translate.instant('pdl-website.printPopup.addToCart'),
      this.translate.instant('pdl-website.printPopup.cancel'),
      this.handleAddToCart.bind(this),
      this.handleOnCancel.bind(this),
      'lg',
      undefined,
      true
    );
  }

  private handleAddToCart(): void {
    console.log('Form values:', this.printForm.value);
  }

  private handleOnCancel(): void {}
}
