/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminScriptActions } from '../actions';
import { catchError, exhaustMap, map, of, switchMap, take, withLatestFrom } from 'rxjs';
import { ScriptService } from 'shared/src/services/admin/master-form/script.service';
import { Store, select } from '@ngrx/store';
import { AdminScriptSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';


@Injectable()
export class AdminScriptEffects {
  constructor(private actions$: Actions, private service: ScriptService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all Scripts ////////////
  LoadScriptList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminScriptActions.LoadScripts),
      exhaustMap((action) => {
        return this.service.loadScripts(action?.payload).pipe(
          map((response) => {
            return AdminScriptActions.LoadScriptSuccessfully({
              scriptList: response?.data,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminScriptActions.LoadScriptError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add Script ////////////
  AddLoadScript$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminScriptActions.AddScript),
      exhaustMap((action) => {
        return this.service.addScript(action?.payload).pipe(
          map((response) => {
            return AdminScriptActions.AddScriptSuccessfully({
              script: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminScriptActions.AddScriptError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Script ////////////
  UpdateLoadScript$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminScriptActions.UpdateScript),
      exhaustMap((action) => {
        return this.service.updateScript(action?.payload).pipe(
          map((response) => {
            return AdminScriptActions.UpdateScriptSuccessfully({
              script: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminScriptActions.UpdateScriptError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update Script status////////////
  UpdateLoadScriptStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminScriptActions.UpdateScriptStatus),
      exhaustMap((action) => {
        return this.service.updateScriptStatus(action?.payload).pipe(
          map((response) => {
            return AdminScriptActions.UpdateScriptStatusSuccessfully({
              script: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminScriptActions.UpdateScriptStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete Script ///////////////////////
  DeleteScript$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminScriptActions.DeleteScript),
      exhaustMap((action) =>
        this.service.deleteScript(action?.payload).pipe(
          map((response) => {
            return AdminScriptActions.DeleteScriptSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminScriptActions.DeleteScriptError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

  //////////////// merge Scripts ////////////
  MergeScript$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminScriptActions.MergeScript),
      exhaustMap((action) => {
        return this.service.mergeScript(action?.payload).pipe(
          map((response) => {
            return AdminScriptActions.MergeScriptSuccessfully({
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminScriptActions.AddScriptError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

 ///////////////////// redirect after adding/deleting/merge Script /////////////////////     
 AddDeleteMergeScriptSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminScriptActions.AddScriptSuccessfully, AdminScriptActions.DeleteScriptSuccessfully, AdminScriptActions.MergeScriptSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminScriptSelector.loadScriptList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/master-forms/manage/scripts');
        const pageNumber = (action.type === AdminScriptActions.AddScriptSuccessfully.type || action.type === AdminScriptActions.MergeScriptSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, sortBy: state?.sortBy };
        return of(AdminScriptActions.LoadScripts({payload}));
      })
    )
  )
}
