@if(isLoading) {
  <div class="loader__wrapper">
    <panjab-digi-lib-spinner [isFullScreen]="false"></panjab-digi-lib-spinner>
  </div>
} @else if(collectionDetails) {
<section class="innerpage-banner" style="background: none;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          @if(isMyCollection) {
            <a class="breadcrumb-link font-regular" href="javascript:void(0)" [routerLink]="'/user/my-collections'">
              {{'pdl-website.userProfile.myCollections' | translate }}
            </a>
          } @else {
            <a class="breadcrumb-link font-regular" href="#">
              <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
              {{'pdl-website.homePage.title' | translate}}
            </a>
            <span class="chevron-arrow font-regular">
              <img src="assets/images/icons/arrow-right.svg" alt="">
            </span>
            <a class="breadcrumb-link font-regular" href="javascript:void(0)" [routerLink]="'/all-collections'">
              {{'pdl-website.collectionsSection.sectionTitle' | translate }}
            </a>
          }
          
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="">
          </span>
          <a class="breadcrumb-link font-regular" href="javascript:void(0)" [routerLink]="'./'">
            {{collectionDetails.Title}}
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex justify-content-between mt-4 mb-2">
          <h1 class="heading-dark semibold mb-0">
            {{collectionDetails.Title}}
          </h1>
          <div class="copy-container" [autoClose]="false"
          [ngbTooltip]="'pdl-website.curatedCollections.linkCopied' | translate"
          triggers="manual"
          #t="ngbTooltip"
          (click)="showTooltip(t)">
            <input #linkInput type="text" [value]="link" readonly hidden />
            <img class="cursor-pointer" src="/assets/images/icons/copy-icon.svg" alt="copy" (click)="copyLink(linkInput)">
          </div>
        </div>
        <div class="d-flex flex-wrap collection_overview_wrapper mb-4">
          @for(category of categoryCounts;track category.CategoryID) {
            <div class="d-flex">
              <img src="/assets/images/icons/pdl-guestbook-ico.svg" alt="category"/> 
              {{category.DocumentCount}} {{category.CategoryName}}
            </div>
          }
        </div>
        <p class="text mb-4">
          {{collectionDetails.Description}}
        </p>
        @if(isUserLoggedIn) {
          @if(collectionDetails.UserCollectionID) {
            <button class="btn btn__primary" (click)="removeFromMyCollection()" [disabled]="isAddingRemoving">
              <span class="me-3 text_with_image">
                {{'pdl-website.curatedCollections.removeFromMyCollection' | translate}}
              </span>
              <img src="/assets/images/icons/heart-icon.svg" alt="heart">
            </button>
          } @else {
            <button class="btn btn__primary" (click)="addToMyCollection()" [disabled]="isAddingRemoving">
              <span class="me-3 text_with_image">
                {{'pdl-website.curatedCollections.addToMyCollection' | translate}}
              </span>
              <img src="/assets/images/icons/heart-icon.svg" alt="heart">
            </button>
          }
        }
      </div>
    </div>

  </div>
</section>

<section class="pt-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="documents-in-grid mt-2">
          <div class="row justify-content-center">
              @for (document of documentList; track document.DocID;) {
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <a [href]="'/metadata/'+document.DocID" class="search-card mb-4">
                  <div class="block-img">
                    <div class="image-overlay">
                      <span class="category-title">
                        {{document.CategoryName}}
                      </span>
                      <span class="ratings d-none">
                        @for (star of getRating(document.UserRating); track star) {
                        <ng-container>
                          <span>
                            <img [src]="getStarImage(star)" alt="star icon" />
                          </span>
                        </ng-container>
                        }
                      </span>
                    </div>
                    <img alt="Thumbimage" class="img-fluid"
                      src="{{imageBaseURL}}{{document.CategoryName}}/{{document.AccessionNo}}/Thumbnails/{{document.AccessionNo}}_90.jpg"
                      onerror="this.src='assets/images/icons/no-image-placeholder.svg'">
                  </div>
                  <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
                    <h6 class="pdl-title-color medium-font font-14 no-scrollbar" [title]="document.Title"
                      style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden;">{{document?.Title}}</h6>
                    <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                      <span class="font-10 d-flex">{{document?.Script}}</span>
                      <span class="font-10 d-flex">{{document?.TotalPages}} PAGES</span>
                    </div>
                  </div>
                </a>
              </div>
              } @empty {
              <div class="no-results d-flex">
                {{'pdl-shared.content.noRecordsFound' | translate}}
              </div>
              }
          </div>
        </div>
      </div>


    </div>
  </div>
</section>
}

<div>
  <panjab-digi-lib-curated-collections 
    [isForHomePage]="false" 
    [collectionDetailsUrl]="isMyCollection ? '/user/collection' : '/collection'" 
    [excludeCollectionID]="collectionID"
    [heading]="'pdl-website.curatedCollections.otherCollections' | translate">
  </panjab-digi-lib-curated-collections>
</div>