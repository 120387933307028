<section class="mailinglist-section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="mailinglist-content">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class=" d-flex align-items-center justify-content-between">
                                <div class="title-block me-4 mb-3">                        
                                    <h2 class="pdl-title-color semibold d-flex justify-content-start mb-3">{{'pdl-website.mailinglistSection.sectionTitle' | translate }}</h2>
                                    <p class="font-regular mb-0 f-18">{{'pdl-website.mailinglistDescription.descriptionText' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="mailing-email">
                                <form class="mailing-email-input mb-3">
                                    <button type="button" class="pdl-btn">{{'pdl-website.mailinglistButton.buttonText' | translate }}</button>
                                    <input type="email" class="form-control mailing-input-type">
                                </form>
                                <p class="font-regular mb-0 text-center">
                                    <span><img src="assets/images/icons/grey-checked.svg" alt="icon"></span>
                                    <span>{{'pdl-website.mailinglistbuttonDescription1.descriptionText' | translate }}</span>
                                    <span class="grey-color">{{'pdl-website.mailinglistbuttonDescription2.descriptionText' | translate }}</span>
                                    <span>{{'pdl-website.mailinglistbuttonDescription3.descriptionText' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
