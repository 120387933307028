import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { environment, SpinnerComponent } from '@panjab-digi-lib/shared';
import { Subject, takeUntil } from 'rxjs';
import { APP_CONSTANTS } from '../../../../common/constants/app.constants';
import { CategoryCount, CollectionDetails, CollectionDocument, CuratedCollection } from 'shared/src/interfaces/website/curated-collection.interface';
import { WebCuratedCollectionService } from 'shared/src/services/website/curated-collection/curated-collection.service';
import { GenericListingPayload, SortOrder } from 'shared/src/interfaces/common.interface';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CuratedCollectionsComponent } from '../../home/curated-collections/curated-collections.component';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { ToastService } from 'shared/src/services/toast/toast.service';

@Component({
  selector: 'panjab-digi-lib-collection-details',
  standalone: true,
  imports: [
    CommonModule, 
    RouterLink, 
    RouterModule, 
    NgbTooltipModule, 
    TranslateModule, 
    NgbPaginationModule, 
    SpinnerComponent,
    CuratedCollectionsComponent
  ],
  templateUrl: './collection-details.component.html',
  styleUrl: './collection-details.component.scss',
})
export class CollectionDetailsComponent implements OnInit, OnDestroy {

  pageNo = 1;
  pageSize = APP_CONSTANTS.pageSize;
  sortOrder: SortOrder = {orderBy: 'Title', order: 'ASC'};
  link: string = window.location.href;
  copyMessage: string | null = null;

  private unsubscribe$: Subject<void> = new Subject();

  collectionDetails!: CollectionDetails;
  categoryCounts!: CategoryCount[];
  documentList: CollectionDocument[] = [];
  imageBaseURL = environment.azureCloudStrorage.publicUrl;
  fallbackImage = APP_CONSTANTS.categoryFallbackImage;
  totalRecords: number = 0;
  isLoading: boolean = true;
  collectionID!: number;
  isUserLoggedIn: boolean = !this.authService.isTokenExpired('webToken');
  isAddingRemoving: boolean = false;
  isMyCollection: boolean = false;

  constructor(
    private curatedCollectionService: WebCuratedCollectionService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isMyCollection = this.router.url.includes('/user/collection');
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.collectionID = params['id'];
      this.loadCollectionDetails();  
      this.loadCollectionDocuments();
    });
  }

  loadCollectionDetails() {
    this.curatedCollectionService.getCollectionDetails(this.collectionID).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.collectionDetails = response.collectionDetails;
        this.categoryCounts = response.categoryCounts;
      }, error: (error) => {
        this.collectionDetails = {} as CollectionDetails;
        this.categoryCounts = [];
        console.log('error', error);
      }
    });
  }
  
  loadCollectionDocuments() {
    let payload: GenericListingPayload = { 
      ...this.sortOrder, 
      pageNumber: this.pageNo, 
      pageSize: this.pageSize,
    };
    this.isLoading = true;
    this.curatedCollectionService.getCollectionDocuments( payload, this.collectionID).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.isLoading = false;
        this.documentList = response.documents;
        this.totalRecords = response.totalRecords;
      }, error: (error) => {
        this.isLoading = false;
        this.documentList = [];
        this.totalRecords = 0;
        console.log('error', error);
      }
    });
  }

  addToMyCollection(): void {
    this.isAddingRemoving = true;
    this.curatedCollectionService.addToMyCollection(this.collectionID).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.isAddingRemoving = false;
        this.collectionDetails.UserCollectionID = response.body.addedCollection.UserCollectionID;
        this.toastService.showSuccess(String(response.message));
      }, error: (error) => {
        this.isAddingRemoving = false;
        console.log('error', error);
        let message = error?.message ? error.message : APP_CONSTANTS.defaultErrMsg;
        this.toastService.showError(String(message));
      }
    })
  }

  removeFromMyCollection(): void {
    this.isAddingRemoving = true;
    this.curatedCollectionService.removeFromMyCollection(this.collectionID).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.isAddingRemoving = false;
        this.collectionDetails.UserCollectionID = null;
        this.toastService.showSuccess(String(response.message));
      }, error: (error) => {
        this.isAddingRemoving = false;
        console.log('error', error);
        let message = error?.message ? error.message : APP_CONSTANTS.defaultErrMsg;
        this.toastService.showError(String(message));
      }
    })
  }
  
  copyLink(inputElement: HTMLInputElement) {
    inputElement.select(); // Select the input text
    inputElement.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(inputElement.value).then(() => {
      this.copyMessage = 'Link copied to clipboard!';
      setTimeout(() => (this.copyMessage = null), 2000); // Clear the message after 2 seconds
    }).catch(() => {
      this.copyMessage = 'Failed to copy the link.';
    });
  }

  showTooltip(tooltip: any): void {
    tooltip.open(); // Open the tooltip
    setTimeout(() => tooltip.close(), 5000); // Close after 5 seconds
  }

  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  getRating(rating: number): number[] {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const noStars = 5 - fullStars - (halfStar ? 1 : 0);
    return [
      ...Array(fullStars).fill(1),
      ...(halfStar ? [0.5] : []),
      ...Array(noStars).fill(0),
    ];
  }

  // Method to get the appropriate star image URL
  getStarImage(star: number): string {
    switch (star) {
      case 1:
        return 'assets/images/icons/full-star.svg';
      case 0.5:
        return 'assets/images/icons/half-star.svg';
      case 0:
        return 'assets/images/icons/no-star.svg';
      default:
        return 'assets/images/icons/no-star.svg';
    }
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }  
}
