/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EventsCampaignsService } from 'shared/src/services/website/events-campaigns/events-campaigns.service';
import { Subscription } from 'rxjs';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { EventsCampaignsItem } from 'shared/src/interfaces/events-campaigns.interface';
import { environment, SharedCommonService, SpinnerComponent } from '@panjab-digi-lib/shared';
import { NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DateRangePickerComponent } from 'shared/src/lib/components/date-range-picker/date-range-picker.component';
import { GooglePlace } from 'shared/src/interfaces/common.interface';
import { GooglePlaceAutocompleteComponent } from 'shared/src/lib/components/google-place-autocomplete/google-place-autocomplete.component';
import { ModalPopupService } from 'shared/src/services/common/modal-popup.service';
@Component({
  selector: 'panjab-digi-lib-events-campaigns',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbPaginationModule,
    SpinnerComponent,
    RouterModule,
    DateRangePickerComponent,
    GooglePlaceAutocompleteComponent,
    NgbPopoverModule,
  ],
  templateUrl: './events-campaigns.component.html',
  styleUrl: './events-campaigns.component.scss',
})
export class EventsCampaignsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  pageSize = APP_CONSTANTS.pageSize;
  pageNumber: number = 1;
  recordsList: EventsCampaignsItem[] = [];
  totalRecords: number = 0;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  maxPageNumber: number = APP_CONSTANTS.maxSize;
  fromRecords: number = 0;
  toRecords: number = 0;
  isLoading: boolean = false;
  payload: any = {};
  startDate!: string;
  endDate!: string;
  showLocationFilter: boolean = false;
  selectedEvent!: EventsCampaignsItem;
  @ViewChild('moreEventsModal') moreEventsModal!: TemplateRef<any>;

  constructor(
    private readonly service: EventsCampaignsService,
    private readonly toastService: ToastService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private modalPopupService: ModalPopupService,
    private readonly commonService: SharedCommonService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['query']) {
        this.payload.filters = params['query'];
      }
    });
    this.payload.pageSize = this.pageSize;
    this.getPayload(this.pageNumber);
  }

  /////////////////// payload which need to send in request ////////////////////
  getPayload(pageNumber: number) {
    this.payload.pageNumber = pageNumber;
    this.loadAllRecords();
  }

  //////////////////// list all records ///////////////////
  loadAllRecords() {
    this.isLoading = true;
    this.subscription.add(
      this.service.getRecords(this.payload).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.recordsList = response?.body?.recordsList;
          this.totalRecords = response?.body?.totalRecords;
          this.calculatePagination(this.recordsList);
        },
        error: (error) => {
          this.isLoading = false;
          this.toastService.showError(
            error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
          );
        },
      })
    );
  }
  /////////////// pagination //////////////////////
  calculatePagination(data: Array<object>) {
    if (this.pageNumber === 1) {
      if (data?.length > 0) {
        this.fromRecords = 1;
      } else {
        this.fromRecords = 0;
      }
    } else {
      this.fromRecords = (this.pageNumber - 1) * this.pageSize + 1;
    }
    this.toRecords = (this.pageNumber - 1) * this.pageSize + data?.length;
  }

  ///////////////// add filters ///////////////////
  addFilter(filter: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      if (this.payload.filters) {
        if (!this.payload.filters.split(',').includes(filter)) {
          this.payload.filters += `,${filter}`;
        }
      } else {
        this.payload.filters = filter;
      }
    } else if (!isChecked && this.payload.filters) {
      this.payload.filters = this.payload.filters
        .split(',')
        .filter((f: any) => f !== filter)
        .join(',');
      if (this.payload.filters === '') {
        this.payload.filters = null;
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['events-campaigns']));
      }
    }
    this.getPayload(1);
  }

  ////////////// date picker start and end date /////////
  selectedRange(event: {
    startDate: string;
    endDate: string;
    checked?: boolean;
  }) {
    if (event.checked === false && event.startDate) {
      this.startDate = '';
      this.endDate = '';
    } else {
      this.startDate = event.startDate;
      this.endDate = event.endDate;
      this.payload.startDate = this.startDate;
      this.payload.endDate = this.endDate;
      this.getPayload(1);
    }
  }

  getAddress(place: GooglePlace) {
    const location = place.geometry?.location;
    const latitude = location ? location.lat() : null;
    const longitude = location ? location.lng() : null;
    this.payload.latitude = latitude;
    this.payload.longitude = longitude;
    this.getPayload(1);
  }

  ////////////////// show/hide location ///////////
  toggleLocation(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.showLocationFilter = true;
    } else {
      this.showLocationFilter = false;
      this.payload.latitude = null;
      this.payload.longitude = null;
      this.getPayload(1);
    }
  }

  ////////// navigate to detail //////////
  navigateToDetail(item: EventsCampaignsItem) {
    this.router.navigateByUrl(`/events-campaigns/${item.ID}`);
  }

  openMoreEventsModal(event: Event, item: EventsCampaignsItem) {
    event.preventDefault();
    this.modalPopupService.modalBtnsDisabled$.next(false);
    this.selectedEvent = item;
    this.modalPopupService.open(
      item.Title,
      item.Description,
      this.moreEventsModal,
      '',
      '',
      this.handleOnSubmit.bind(this),
      this.handleOnCancel.bind(this),
      'md',
      undefined,
      false
    );
  }

  handleOnCancel() {}
  handleOnSubmit() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
