import { createAction, props } from '@ngrx/store';

export const loadDocumentFiles = createAction(
  '[Document] Load Document Files',
  props<{ docID: number }>()
);

export const loadDocumentFilesSuccess = createAction(
  '[Document] Load Document Files Success',
  props<{ pdfFiles: any[]; imageFiles: any[] }>()
);

export const loadDocumentFilesError = createAction(
  '[Document] Load Document Files Error',
  props<{ message: string }>()
);
