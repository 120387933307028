@if(!isMyCollectionPage) {
<section class="innerpage-banner">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            {{'pdl-website.homePage.title' | translate}}
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="">
          </span>
          <a class="breadcrumb-link font-regular" href="javascript:void(0)" [routerLink]="'/all-collections'">
            {{'pdl-website.collectionsSection.sectionTitle' | translate }}
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="page-title">
          <h1 class="heading-dark semibold mb-0">
            {{'pdl-website.collectionsSection.sectionTitle' | translate }}
          </h1>
        </div>
      </div>
    </div>

  </div>
</section>
}

<section class="section-padding" [ngClass]="{'pt-0': isMyCollectionPage}">
  <div class="container">
    <div class="row">
      <div class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-between mb-4">
        <div class="results-on-page d-flex flex-wrap align-items-center justify-content-start">
          <span>{{'pdl-shared.pagination.showing' | translate}} </span>
          <span>{{ totalRecords > 0 ? (pageNo - 1) * pageSize + 1 : 0 }} </span>
          <span>{{'pdl-shared.pagination.to' | translate}}</span>
          <span>{{ pageNo * pageSize < totalRecords ? pageNo * pageSize : totalRecords }} </span>
              <span>{{'pdl-shared.pagination.of' | translate}} </span>
              <span>{{totalRecords}} </span>
              <span>{{'pdl-shared.pagination.entries' | translate}} </span>
        </div>
        <div class="page-filters-dropdown">
          <div class="dropdown page-filter-dropdown">
            <a class="page-filter-action btn btn-secondary dropdown-toggle" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              {{'pdl-admin.download.sortBy' | translate}}
            </a>

            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='title' ? 'active-sort' : ''"
                  (click)="sortCollections('Title', 'ASC', 'title')">
                  {{'pdl-website.curatedCollections.title' | translate}}
                </a>
              </li>
              <li>
                <a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='featured' ? 'active-sort' : ''"
                  (click)="sortCollections('IsFeatured', 'DESC', 'featured')">
                  {{'pdl-website.curatedCollections.featured' | translate}}
                </a>
              </li>
              <li>
                <a class="dropdown-item cursor-pointer" [ngClass]="sortBy==='documentCount' ? 'active-sort' : ''"
                  (click)="sortCollections('DocumentCount', 'DESC', 'documentCount')">
                  {{'pdl-website.curatedCollections.documentCount' | translate}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="categories-in-grid">
          <div class="row justify-content-center">
            @if(curatedCollectionList.length == 0 && isLoading) {
            <div class="loader__wrapper">
              <panjab-digi-lib-spinner [isFullScreen]="false"></panjab-digi-lib-spinner>
            </div>
            } @else {
            @for(collection of curatedCollectionList; track collection.CollectionID) {
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="collection-card">
                @if(collection.IsFeatured) {
                <span class="featured__tag">{{'pdl-website.curatedCollections.featured' | translate}}</span>
                }
                <div class="block-img"
                  [ngClass]="{'threeImgCollection': collection.Documents.length === 3,'twoImgCollection': collection.Documents.length === 2 }">
                  @for(document of collection.Documents; track document.DocID) {
                  <img alt="Thumbimage" class="img-fluid"
                    src="{{imageBaseURL}}{{document.CategoryName}}/{{document.AccessionNo}}/Thumbnails/{{document.AccessionNo}}_90.jpg"
                    onerror="this.src='assets/images/icons/no-image-placeholder.svg'">
                  }
                </div>
                <div class="block-text">
                  <h5 class="medium-font">
                    {{collection.Title}}
                  </h5>
                  <span class="number-of-docs">
                    <img src="assets/images/icons/folder-icon.svg" alt="folder-icon">
                    {{collection.DocumentCount}} {{'pdl-website.numberofDocs.docsText' | translate }}
                  </span>
                  <p class="description-text">
                    <span class="text__ellipse text-wrap text-break">{{collection.Description}}</span>
                  </p>
                  <div class="line-btn-block">
                    <a href="#"
                      [routerLink]="[isMyCollectionPage ? '/user/collection' : '/collection', collection.CollectionID]"
                      class="btn pdl-btn line-btn medium-font">
                      {{'pdl-website.collectionsButton.buttonText' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            } @empty {
            <div class="text-center my-5">
              {{'pdl-shared.content.noRecordsFound' | translate}}
              @if(isMyCollectionPage) {
              <br>
              {{'pdl-website.curatedCollections.startExplore' | translate}}
              <a [routerLink]="['/all-collections']">
                {{'pdl-website.curatedCollections.allAvailableCollections' | translate}}
              </a>
              {{'pdl-website.curatedCollections.findIntrests' | translate}}
              }
            </div>
            }
            }
          </div>
        </div>
      </div>

      <div class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4">
        <ngb-pagination class="donations__pagination" [collectionSize]="totalRecords" [(page)]="pageNo"
          [pageSize]="pageSize" [rotate]="true" [ellipses]="true" 
          [boundaryLinks]="true"
          [directionLinks]="true"
          (pageChange)="loadCuratedCollections()" />
      </div>

    </div>
  </div>
</section>
