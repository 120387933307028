<panjab-digi-lib-static-page-head [pageName]="'pdl-website.currentProjects.titleText' | translate"
  pagePath="/current-projects">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container container-1000">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/current-projects.png" /></div>
      <div>
        <h1 class="heading mb-3">Institutions</h1>
        <ul class="text">
          <li>Department of Culture, Punjab</li>
          <li>Punjab State Archives, Chandigarh</li>
          <li>Kanya Maha Vidyalya, Jalandhar</li>
          <li>Dev Samaj, Chandigarh</li>
        </ul>
        <h1 class="heading mb-3">Personal Libraries</h1>
        <ul class="text">
          <li>Ashok Malik, Chandigarh</li>
          <li>Gurdev Singh Sidhu, Mohali</li>
          <li>Hardev Singh Virk, Mohali</li>
          <li>Jaswant Singh Neki, New Delhi</li>
          <li>Jugraj Singh Gill, Chandigarh</li>
          <li>Raja Budhishwar Pal, Chandigarh</li>
          <li>Surinder Pal Singh, Chandigarh</li>
        </ul>
      </div>
    </div>
    }
  </div>
</section>
