import { UsersEffects } from './users.effects';
import { BannersEffects } from './banners.effects';
import { AuthEffects } from './auth.effects';
import { CategoriesEffects } from './categories.effects';
import { AdminKeywordEffects } from './admin-keywords.effects';
import { ServicesEffects } from './services.effects';
import { MetaDataEffects } from './metadata.effects';
import { ProcessEffects } from './process.effects';
import { SubjectEffects } from './subject.effects';
import { CurrencyEffects } from './currency.effects';
import { SectionEffects } from './section.effects';
import { DesignationEffects } from './designation.effects';
import { SubjectsPerCategoryEffects } from './subjectsPerCategory.effects';
import { ScriptsPerLanguageEffects } from './scriptsPerLanguage.effects';
import { MetadataPerCategoryEffects } from './metadataPerCategory.effects';
import { DonorEffects } from './donor.effects';
import { ViewDonationEffects } from './view-donation.effects';
import { AdminTrendingEffects } from './admin-trending.effects';
import { WebHomePageEffects } from './web-homepage.effects';
import { AdminDownloadEffects } from './admin-download-effects';
import { WebDownloadsEffects } from './web-download.effects';
import { AdminLanguageEffects } from './admin-language-effects';
import { AdminScriptEffects } from './admin-script-effects';
import { AdminPublisherEffects } from './admin-publisher.effects';
import { CountryEffects } from './country.effects';
import { AdminTranslatorEffects } from './admin-translator.effects';
import { AdminAuthorEffects } from './admin-author.effects';
import { DepartmentEffects } from './department.effects';
import {AdminContributorEffects} from './admin-contributor.effects';
import { AdminEditorEffects } from './admin-editor.effects';
import { WebUserEffects } from './web-user.effects';
import { ArticleCommentEffects } from './admin-article-comments.effects';
import {AdminVideoGalleryEffects} from './admin-video-gallery.effects';
import { MetadataCommentEffects } from './admin-metadata-comments.effects';
import { WebVideosEffects } from './web-videos-effects';
import { WebDonationEffects } from './web-donation.effects';
import { AdoptedByEffects } from './admin-adopted-by.effects';
import { ToAdoptEffects } from './admin-to-adopt.effects';
import { AdoptedBooksEffects } from './admin-adopted-books.effects';
import { AdoptPriceEffects } from './admin-adopt-price.effects';
import { WebAuthUserEffects } from './web-authuser.effects';
import { AdminSponsorshipEffects } from './admin-sponsorship.effects';
import { AdminEmployeeEffects } from './admin-employee.effects';
import { WebAdoptedBooksEffects } from './web-adopted-books.effects';
import { StaffAuthEffects } from './staff/auth.effects';
import { ProcessPerCategoryEffects } from './processPerCategory.effects';
import { AdminWebDocMngrEffects } from './admin-web-doc-manager.effects';
import { PermissionEffects } from './permission.effects';
import { VolunteerEffects } from './admin-volunteer.effects';
import {AdminUserServiceEffects} from './admin-user-services.effects';
import {AdminVolunteersRequestEffects} from './admin-volunteer-requests.effects';
import {UserTypesEffects} from './user-types.effects';
import {AdminCuratedCollectionEffects} from './admin-curated-collection.effects';
import * as StaffEffects from './staff';
import * as WebsiteEffects from './website';

export const effects = [
    UsersEffects,
    BannersEffects,
    AuthEffects,
    CategoriesEffects,
    AdminKeywordEffects,
    ServicesEffects,
    MetaDataEffects,
    ProcessEffects,
    SubjectEffects,
    CurrencyEffects,
    CountryEffects,
    DepartmentEffects,
    SectionEffects,
    DesignationEffects,
    SubjectsPerCategoryEffects,
    ScriptsPerLanguageEffects,
    MetadataPerCategoryEffects,
    DonorEffects,
    ViewDonationEffects,
    AdminTrendingEffects,
    WebHomePageEffects,
    AdminDownloadEffects,
    WebDownloadsEffects,
    AdminLanguageEffects,
    AdminScriptEffects,
    AdminPublisherEffects,
    AdminTranslatorEffects,
    CountryEffects,
    VolunteerEffects,
    AdminAuthorEffects,
    AdminContributorEffects,
    AdminEditorEffects,
    WebUserEffects,
    ArticleCommentEffects,
    AdminVideoGalleryEffects,
    MetadataCommentEffects,
    WebVideosEffects,
    WebDonationEffects,
    AdoptedByEffects,
    AdoptPriceEffects,
    WebAuthUserEffects,
    StaffAuthEffects,
    ToAdoptEffects,
    AdoptedBooksEffects,
    AdminSponsorshipEffects,
    AdminEmployeeEffects,
    WebAdoptedBooksEffects,
    ProcessPerCategoryEffects,
    AdminWebDocMngrEffects,
    PermissionEffects,
    AdminUserServiceEffects,
    AdminVolunteersRequestEffects,
    UserTypesEffects,
    AdminCuratedCollectionEffects,
    ...Object.values(StaffEffects),
    ...Object.values(WebsiteEffects)

]
