import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HighlightTextDirective } from 'apps/panjab-digi-lib/src/common/directives/highlight-text';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '@panjab-digi-lib/shared';

declare const BookReader: any;
declare const document: any;
@Component({
  selector: 'panjab-digi-lib-image-reader',
  standalone: true,
  imports: [CommonModule, FormsModule, HighlightTextDirective, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrl: './image-reader.component.scss',
  templateUrl: './image-reader.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ImageReaderComponent implements OnChanges {
  private br: any;
  @Input() images: any[] = [];
  @Input() accessionNo: string = '';
  @Input() metadata: any = {};
  bookmarks: number[] = [];
  settingsMenuVisible = false;
  fontSize = 16;
  bgColor = '#ffffff';
  currentPageText = '';
  openFullImmersionTheater = false;
  searchTerm: string= '';

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images'] && changes['images'].currentValue && changes['images'].currentValue?.length > 0) {
      setTimeout(() => {
        this.getImageDimension().subscribe(dimensions => {
          this.initializeBookReader(dimensions);
        });
      }, 100);
    }
  }

  getImageDimension(): Observable<any> {
    return new Observable(observer => {
        const img = new Image();
        img.onload = function (event) {
            const loadedImage: any = event.currentTarget;
            const width = loadedImage.width;
            const height = loadedImage.height;
            observer.next({height, width});
            console.log(height, width);
            observer.complete();
        }
        img.src = this.images[4].url;
    });
  }
  initializeBookReader(dimensions: any): void {
    const options = {
      getNumLeafs: () => this.images.length -2,
      getPageWidth: () => dimensions.width ? dimensions.width : 2000,
      getPageHeight: () => dimensions.height ? dimensions.height : 3000,
      getPageURI: (index: number) => this.images[index + 2]?.url || '',
      getPageSide: (index: number) => (index % 2 === 0 ? 'R' : 'L'),
      pageProgression: 'lr',
      getSpreadIndices: function (pindex: number) {
        const spreadIndices: (number | null)[] = [null, null];
        if (this.pageProgression === 'rl') {
          if (this.getPageSide(pindex) === 'R') {
            spreadIndices[1] = pindex;
            spreadIndices[0] = pindex + 1;
          } else {
            spreadIndices[0] = pindex;
            spreadIndices[1] = pindex - 1;
          }
        } else {
          if (this.getPageSide(pindex) === 'L') {
            spreadIndices[0] = pindex;
            spreadIndices[1] = pindex + 1;
          } else {
            spreadIndices[1] = pindex;
            spreadIndices[0] = pindex - 1;
          }
        }
        return spreadIndices;
      },
      getPageNum: (index: number) => index + 1,
      bookTitle: this.metadata.Title,
      thumbnail: this.images[0].url,
      metadata: [{ label: 'Title', value: this.metadata.Title }],
      ui: 'full',
      enableExperimentalControls: true,
      showToolbar: false,
      showNavbar: true,
      protected: true,
      search: 'visible',
      sideBar: 'visible',
      bookmarks: 'visible',
      share: 'visible',
      enableSearch: true,
      enableBookmark: true,
      enableFullscreen: true,
      enableShare: true,
      enableSidebar: true,
      enableDownload: true,
      searchInsidePostTag: "}}}",
      searchInsidePreTag: "{{{",
      searchInsideProtocol: "https",
      searchInsideUrl: environment.reader.iiifSearchPath,
      initialSearchTerm: "",
      bookId: this.accessionNo
    };
  
    this.br = new BookReader(options);
    this.br.server = environment.reader.server;
    setTimeout(() => {
      this.br.bookId = this.accessionNo;
      this.br.init();
    }, 1000);
  }

  addBookmark(page: number): void {
    if (!this.bookmarks.includes(page)) {
      this.bookmarks.push(page);
    }
  }

  removeBookmark(page: number): void {
    this.bookmarks = this.bookmarks.filter(b => b !== page);
  }

  downloadPage(page: number): void {
    const imageUrl = this.br.getPageURI(page);
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `Page-${page}.jpg`;
    link.click();
  }

  adjustFontSize(size: number): void {
    this.fontSize = size;
  }

  adjustBackgroundColor(color: string): void {
    this.bgColor = color;
  }

  sharePage(page: number): void {
    const pageUrl = window.location.origin + `/reader?page=${page}`;
    navigator.clipboard.writeText(pageUrl);
    alert('Page link copied to clipboard!');
  }

  toggleSettingsMenu(): void {
    this.settingsMenuVisible = !this.settingsMenuVisible;
  }
}
