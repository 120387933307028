import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { AddEditCurrencyPayload, AddEditProcessPayload, AddEditSubjectPayload, Currency, Process, Subjects } from 'shared/src/interfaces/master-form.interface';
import { AddEditMetaDataPayload, MetaData } from 'shared/src/interfaces/metadata.interface';
import { Service } from 'shared/src/interfaces/services.interface';
import { API_ENDPOINTS } from "../app.endpoints";

@Injectable({
  providedIn: 'root'
})
export class MasterFormsService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
  
  addCategory(formData: FormData) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.category.addCategory}`, formData).pipe(map((e: any) => e));
  }
  
  checkPreCode(preCode: string) {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.category.checkPreCode}/${preCode}`).pipe(map((e: any) => e.body));
  }
  
  getUploadTypes() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.category.uploadTypes}`).pipe(map((e: any) => e.body));
  }
  
  getUploadTypesOfCategory(categoryID: string) {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.category.uploadTypes}/${categoryID}`).pipe(map((e: any) => e.body));
  }
  
  editCategory(formData: FormData, categoryId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.category.editCategory}/${categoryId}`, formData).pipe(map((e: any) => e));
  }
  
  updateCategoryStatus(categoryIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.category.toggleStatus}`, {categoryIDs, status}).pipe(map((e: any) => e));
  }
  
  downloadCategories() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.category.downloadCategories}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  

  loadServices(payload: GenericListingPayload): Observable<{services: Service[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.service.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  loadServiceThumbnails() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.service.getServiceThumbnails}`).pipe(map((e: any) => e.body));
  }
  
  addService(formData: FormData) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.service.default}`, formData).pipe(map((e: any) => e));
  }
  
  editService(formData: FormData, serviceID: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.service.default}/${serviceID}`, formData).pipe(map((e: any) => e));
  }
  
  updateServiceStatus(serviceIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.service.updateServiceStatus}`, {id: serviceIDs, status}).pipe(map((e: any) => e));
  }
  
  deleteService(serviceIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.service.default}`, {body: {id: serviceIDs}}).pipe(map((e: any) => e));
  }
  
  downloadServices() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.service.downloadServices}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }

  enabledServices() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.service.enabledServices}`).pipe(map((e: any) => e.body));
  }
  
  loadMetaData(payload: GenericListingPayload): Observable<{metaData: MetaData[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.metaData.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  
  updateMetaDataStatus(MetaDataIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.metaData.updateMetaDataStatus}`, {id: MetaDataIDs, status}).pipe(map((e: any) => e));
  }
  
  addMetaData(payload: AddEditMetaDataPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.metaData.default}`, payload).pipe(map((e: any) => e));
  }
  
  editMetaData(payload: AddEditMetaDataPayload, metaDataId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.metaData.default}/${metaDataId}`, payload).pipe(map((e: any) => e));
  }
  
  deleteMetaData(MetaDataIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.metaData.default}`, {body: {id: MetaDataIDs}}).pipe(map((e: any) => e));
  }
  
  downloadMetadata() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.metaData.downloadMetadata}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }

  metadataMasterFormLabels() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.metaData.masterFormLabels}`).pipe(map((e: any) => e.body));
  }

  loadProcess(payload: GenericListingPayload): Observable<{processes: Process[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.process.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }
  
  updateProcessStatus(processIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.process.updateProcessStatus}`, {id: processIDs, status}).pipe(map((e: any) => e));
  }
  
  addProcess(payload: AddEditProcessPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.process.default}`, payload).pipe(map((e: any) => e));
  }
  
  editProcess(payload: AddEditProcessPayload, processId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.process.default}/${processId}`, payload).pipe(map((e: any) => e));
  }
  
  deleteProcess(processIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.process.default}`, {body: {id: processIDs}}).pipe(map((e: any) => e));
  }

  downloadProcess() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.process.downloadProcess}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }

  sortProcess(id: number, toOrder: number) {
    const url = `${this.rootURL}${API_ENDPOINTS.masterForm.process.sort}`.replace('{id}', (id ?? '').toString());
    return this.http
      .put(url, { toOrder: toOrder })
      .pipe(map((e) => e));
  }

  loadSubject(payload: GenericListingPayload): Observable<{subjects: Subjects[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.subject.default}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }  

  loadParentSubject(subjectID: string): Observable<{subjects: Subjects[]}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.subject.getParent}?subjectID=${subjectID}`).pipe(map((e: any) => e.body));
  }  

  addSubject(payload: AddEditSubjectPayload) {
    return this.http.post(`${this.rootURL}${API_ENDPOINTS.masterForm.subject.default}`, payload).pipe(map((e: any) => e));
  }
  
  editSubject(payload: AddEditSubjectPayload, subjectId: string) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.masterForm.subject.default}/${subjectId}`, payload).pipe(map((e: any) => e));
  }

  updateSubjectStatus(subjectIDs: number[], status: string) {
    return this.http.patch(`${this.rootURL}${API_ENDPOINTS.masterForm.subject.updateSubjectStatus}`, {id: subjectIDs, status}).pipe(map((e: any) => e));
  }
  
  deleteSubject(subjectIDs: number[]) {
    return this.http.delete(`${this.rootURL}${API_ENDPOINTS.masterForm.subject.default}`, {body: {id: subjectIDs}}).pipe(map((e: any) => e));
  }
  
  downloadSubjects() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.masterForm.subject.downloadSubjects}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
  
  downloadFile(data: Blob, filename: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}