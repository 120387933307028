<div class="modal__style">
  <button type="button" class="btn close btn__close" aria-label="Close" (click)="decline()">
    <img
      src="assets/images/icons/cross-close-icon.svg"
      alt="Close"
      class="img-fluid"
    />
  </button>
  <div class="modal-header justify-content-center" *ngIf="title">
    <h4 class="modal-title">{{ title }}</h4>
  </div>
  <div class="modal-body pt-0 pb-4 word-break">
    <span [innerHTML]="isExpanded ? message : message && getTruncatedText(message)"></span>
    @if(message && shouldShowReadMore(message)) { 
    <p class="title cursor-pointer" (click)="toggleMessageContent()">
       {{ isExpanded ? ('pdl-shared.content.readLessText' | translate) : ('pdl-shared.content.readMoreText' | translate) }}
    </p>
    }  
    <ng-template #modalContent></ng-template>
  </div>
  @if(btnOkText && btnCancelText) {
    <div class="modal-footer justify-content-center gap-2">
      <button type="button" [disabled]="btnsLoading$ | async" class="btn btn-default btn__secondary" (click)="dismiss()">{{ btnCancelText }}</button>
      <button type="button" [disabled]="btnsDisabled$ | async" class="btn btn-primary btn__primary btn__with__loader" (click)="onSubmit()">
        @if(btnsLoading$ | async) {
          <div class="spinner-border btn__loader" role="status"></div>
        } @else {
          {{ btnOkText }}
        }
      </button>
    </div>
  }
</div>
  