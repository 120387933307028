import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CategoryDropdownList } from 'shared/src/interfaces/common.interface';
import { COMMON_API_ENDPOINTS } from './app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class SharedCommonService {
  changeComponentTitle$ = new BehaviorSubject(false);
  rootURL = '';
  sharedApiURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
    this.sharedApiURL = environment.rootApiUrl + environment.sharedApiPrefix;
  }

  getAllCountries() {
    return this.http.get(`${this.rootURL}admin/country`).pipe(map((e:any) => e.body));
  }
  
  getCategories(): Observable<CategoryDropdownList[]> {
    return this.http.get(`${this.sharedApiURL}${COMMON_API_ENDPOINTS.shared.getCategoryList}`).pipe(map((e:any) => e.body));
  }
  
  getContributors() {
    return this.http.get(`${this.sharedApiURL}${COMMON_API_ENDPOINTS.shared.getContributorList}`).pipe(map((e:any) => e.body));
  }

  getCategoryProcess(id: string) {
    return this.http.get(`${this.sharedApiURL}${COMMON_API_ENDPOINTS.shared.categoryProcess}`.replace('{id}',id)).pipe(map((e:any) => e.body));
  }

  downloadFile(data: Blob, filename: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  generateAlphabets(): string[] {
    return Array.from({length: 26}, (_, i) => String.fromCharCode(65 + i))
  }

  encodeToBase64(str:any) {
    const utf8Bytes = new TextEncoder().encode(str);
    let binaryString = '';
    const bytes = new Uint8Array(utf8Bytes);
    bytes.forEach(byte => binaryString += String.fromCharCode(byte));
    return btoa(binaryString);
  }

  decodeFromBase64(base64Str: any) {
    const binaryString = atob(base64Str);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new TextDecoder().decode(bytes);
  }
}
