<div class="multiselectwrapper">
  <div class="inputwrapper" [ngStyle]="addStyle">
    <div
      class="input_box"
      (click)="$event.stopPropagation(); !readonly ? toggleOptions() : ''"
    >
      <div
        *ngIf="getValueOptions().length"
        class="selected_options d-flex flex-wrap col-gap-12"
      >
        @for(valueItem of getValueOptions(); track valueItem; let index =
        $index) { 
          @if (index < 2 ) {
        <div
          class="chip d-flex col-gap-6 align-items-center"
          [ngClass]="valueItem.addedBy === staticStaffId ? 'user_added' : ''"
        >
          <span
            [attr.title]="
              valueItem.addedBy === staticStaffId
                ? 'Added by user'
                : valueItem.value
            "
            >{{ valueItem.value }}</span
          >
          @if(!readonly) {
          <img
            src="assets/images/icons/cross-small.svg"
            (click)="$event.stopPropagation(); select(valueItem)"
          />
          }
        </div>
        } @else { @if (index === 2) {
        <div class="chip" [attr.title]="getAdditionalItemsTooltip()">
          + {{ getValueOptions().length - 2 }}
        </div>
        } } }
      </div>
      @if(!getValueOptions().length) {
      <span class="placeholder_text">{{ placeholder }}</span>
      }
      <img
        (click)="$event.stopPropagation(); toggleOptions()"
        src="/assets/images/icons/chevron-down-ico.svg"
        alt
        class="down_up"
      />
    </div>
  </div>
  @if(showOptions) {
  <div class="optionsWrapper no-scrollbar">
    <div class="search_wrapper no-scrollbar">
      <input
        type="text"
        class="searchField"
        [ngClass]="searchText ? 'no_indent' : ''"
        (input)="setSearch($event)"
        [(ngModel)]="searchText"
        placeholder="{{ searchBoxPlaceholder }}"
      />
      <img
        class="cross_search cursor-pointer"
        [ngClass]="searchText ? 'cross_right' : ''"
        (click)="clearSearch()"
        [src]="
          searchText
            ? '/assets/images/icons/cross-gray-20.svg'
            : 'assets/images/icons/search-grey.svg'
        "
      />
    </div>
    <div class="options no-scrollbar mt-2">
      @for(option of visibleOptions;track option) {
      <div
        class="option cursor-pointer d-flex col-gap-12 align-items-center mb-1"
      >
        <input
          type="checkbox"
          class="form-check-input check__box__light cursor-pointer"
          [checked]="selectedValues.has(getNormalizedKey(option))"
          (change)="onCheckboxChange($event, option)"
          [disabled]="
            isMaxLimit() && !selectedValues.has(getNormalizedKey(option))
          "
        />
        {{ option.value }}
      </div>
      } @empty {
         @if(!allowAdd) {
      <div class="text-center no__record">
        {{ 'pdl-shared.content.noRecordsFound' | translate }}
      </div>
       }
     }
    @if(searchText) {
    <div class="text-center no__record add_record" (click)="openAddModal()">
      {{ 'pdl-staff.metadata.createNew' | translate }} {{ label }}
    </div>
    }
    </div>
  </div>
  }
</div>

<ng-template #addModal>
  <div class="action__form mt-2 m-auto">
    <form [formGroup]="addNewForm">
      <div class="d-flex flex-direction-column align-items-start flex-column">
        <label class="semibold mb-2">{{
          'pdl-staff.metadata.name' | translate
        }}</label>
        <input
          type="text"
          maxlength="64"
          class="form-control search__input"
          formControlName="name"
          placeholder="Enter /dynamic input 1/"
          id="exampleFormControlInput1"
          placeholder="{{ 'pdl-staff.metadata.name' | translate }}"
        />
        @if(!addNewForm.get('name')?.valid && addNewForm.get('name')?.touched) {
        @if(addNewForm.get('name')?.errors?.['required']) {
        <p class="err__msg">
          {{ 'pdl-shared.formValidationMsgs.required' | translate }}
        </p>
        } }
      </div>
      @if(countryExists && countryList && countryList.length>1 ) {
      <div
        class="d-flex flex-direction-column align-items-start flex-column mt-3"
      >
        <label class="semibold mb-2">{{
          'pdl-staff.metadata.country' | translate
        }}</label>
        <select
          class="form-select sort__filter country__select"
          aria-label="Default select example"
          formControlName="countryId"
        >
          @for(country of countryList;track country.CountryID;let i=$index) {
          <option value="{{ country?.CountryID }}">{{ country.Name }}</option>
          }
        </select>
      </div>
      }
    </form>
  </div>
</ng-template>
