export const FooterLinks = {
  explore: [
    // 'manuScripts',
    // 'books',
    // 'magazines',
    // 'newspapers',
    // 'photographs',
    // 'pamphlets',
    // 'files',
    'newsAndArticles'
  ],
  aboutUs: [
    'history',
    'policies',
    // 'mediaRoom',
    // 'newsLetters',
    'workingGroups',
    'currentProjects',
    // 'behindTheScenes',
  ],
  pdl: [
    'infoForCustodians',
    'privacyStatement',
    'FAQs',
    // 'feedback',
    'disclaimer',
    'footerforms',
    'metadataSchema',
  ],
  services: [
    'forum',
    'digitization',
    'dataMining',
    'interlibrary',
    'exhibitions',
    'preservation',
    'uploadadocument',
    'digitizationTraining',
  ],
  generalInfo: [
    // 'jobs',
    'team',
    'volunteer',
    // 'collections',
    // 'downloads',
    'caseStudies',
    'donorLevels',
  ],
};
