<section class="register-user-form">
    <div class="container reg-container">
        <div class="row d-flex">
          <!-- Column 1 - Static Content -->
          <div class="col-md-5 column-1">
            <div class="content fw-normal text-justify">
                <!-- Hide breadcrumbs in mobile view -->
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-md-block d-none">
                    <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                </div>
                <h5 class="fw-bold">{{'pdl-website.forms.user.pdl' | translate }}</h5>
                <div class="static-txt" [innerHtml]="'pdl-website.forms.user.pdlText' | translate"></div>
            </div>
          </div>
          <!-- Column 2 - Signup Form -->
          <div class="col-md-7 column-2">
            <div class="right-section">
                <div class="top-content">
                    <div class="header-title">
                        <!-- Show breadcrumbs in mobile view -->
                        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap d-block d-md-none">
                            <panjab-digi-lib-breadcrumbs [pageName]="pageTitle"></panjab-digi-lib-breadcrumbs>
                        </div>
                        <div class="title-txt mt-4">
                            <h5 class="fw-bolder">{{'pdl-website.forms.user.signup' | translate }}</h5>
                        </div>
                    </div>
                    <div class="header-body">
                        <p class="fw-light" [innerHtml]="'pdl-website.forms.user.requiredFldsText' | translate"></p>
                    </div>
                </div>
                <div class="form-section">
                    <!-- show alert -->
                    <panjab-digi-lib-alerts></panjab-digi-lib-alerts>
                    <!-- /show alert -->
                    <p class="fw-bold">{{'pdl-website.forms.user.formHeader' | translate }}</p>
                    <form [formGroup]="userSignUpForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <label for="email" class="form-label reqd" [ngClass]="{'text-danger': userSignUpForm.controls['email'].invalid && userSignUpForm.controls['email'].touched}">{{'pdl-website.forms.labels.email' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(userSignUpForm.controls['email'].touched && userSignUpForm.controls['email'].errors && (userSignUpForm.controls['email']['errors']['required'] || userSignUpForm.controls['email']['errors']['invalidField'])) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                    }
                                    @else if(userSignUpForm.controls['email'].touched && userSignUpForm.controls['email'].errors && userSignUpForm.controls['email']['errors']['pattern']) {
                                        <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidEmail' | translate }"></ng-container>
                                    }
                                    @if(userSignUpForm.controls['email'].valid && userSignUpForm.controls['email'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                    }
                                </div>
                                <input type="email" #emailInput class="form-control" formControlName="email" id="email" [ngClass]="{'border-danger': userSignUpForm.controls['email'].invalid && userSignUpForm.controls['email'].touched}">
                                @if(userSignUpForm.controls['email'].errors && userSignUpForm.controls['email']['errors']['invalidField']) {
                                    <div class="err-msg">
                                        {{'pdl-website.forms.user.emailExist' | translate}}
                                    </div>
                                }
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <label for="username" class="form-label reqd" [ngClass]="{'text-danger': userSignUpForm.controls['username'].invalid && userSignUpForm.controls['username'].touched}">{{'pdl-website.forms.labels.username' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(userSignUpForm.controls['username'].touched && userSignUpForm.controls['username'].errors && userSignUpForm.controls['username']['errors']['pattern']) {
                                        <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.username' | translate }"></ng-container>
                                    }
                                    @else if(userSignUpForm.controls['email'].touched && userSignUpForm.controls['username']['errors']?.['invalidField']) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                    }
                                    @if(userSignUpForm.controls['username'].valid && userSignUpForm.controls['username'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                    }
                                </div>
                                <input type="text" #userInput class="form-control" formControlName="username" name="username" id="username" [ngClass]="{'border-danger': userSignUpForm.controls['username'].invalid && userSignUpForm.controls['username'].touched}">
                                @if(userSignUpForm.controls['username'].errors && userSignUpForm.controls['username'].errors['invalidField']) {
                                <div>
                                    <div class="err-msg">
                                        {{'pdl-website.forms.user.usernameExist' | translate}}
                                    </div>
                                    <div class="fs-13">
                                        {{'pdl-website.forms.user.availableUsernames' | translate}} <span class="available-users">{{availableUsernames}}</span> 
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex">
                                    <label for="fullname" class="form-label reqd" [ngClass]="{'text-danger': userSignUpForm.controls['fullname'].invalid && userSignUpForm.controls['fullname'].touched}">{{'pdl-website.forms.labels.fullname' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(userSignUpForm.controls['fullname'].invalid && userSignUpForm.controls['fullname'].touched) {
                                        <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.fullname' | translate }"></ng-container>
                                    }
                                    @if(userSignUpForm.controls['fullname'].valid && userSignUpForm.controls['fullname'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                    }
                                </div>
                                <input type="text" class="form-control" formControlName="fullname" id="fullname" [ngClass]="{'border-danger': userSignUpForm.controls['fullname'].invalid && userSignUpForm.controls['fullname'].touched}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <label for="password" class="form-label reqd" [ngClass]="{'text-danger': userSignUpForm.controls['password'].invalid && userSignUpForm.controls['password'].touched}">{{'pdl-website.forms.labels.password' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(userSignUpForm.controls['password'].invalid && userSignUpForm.controls['password'].touched) {
                                        <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.password' | translate }"></ng-container>
                                    }
                                    @if(userSignUpForm.controls['password'].valid && userSignUpForm.controls['password'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                    }
                                </div>
                                <input type="password" class="form-control" formControlName="password" id="password" [ngClass]="{'border-danger': userSignUpForm.controls['password'].invalid && userSignUpForm.controls['password'].touched}">
                                <!-- <div id="passwordHelpText" class="form-text info__msg">{{'pdl-website.userRegister.helpText.password' | translate}}</div> -->
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <label for="confirmpassword" class="form-label reqd" [ngClass]="{'text-danger': userSignUpForm.controls['confirmpassword'].invalid && userSignUpForm.controls['confirmpassword'].touched}">{{'pdl-website.forms.labels.retypePassword' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(userSignUpForm.controls['confirmpassword'].invalid && userSignUpForm.controls['confirmpassword'].touched) {
                                        <ng-container *ngTemplateOutlet="invalidError; context: { msg: 'pdl-website.forms.toolTip.invalidConfirmPasswd' | translate }"></ng-container>
                                    }
                                    @if(userSignUpForm.controls['confirmpassword'].valid && userSignUpForm.controls['confirmpassword'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                    }
                                </div>
                                <input type="password" class="form-control" formControlName="confirmpassword" [ngClass]="{'border-danger': userSignUpForm.controls['confirmpassword'].invalid && userSignUpForm.controls['confirmpassword'].touched}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <label for="country" class="form-label reqd" [ngClass]="{'text-danger': userSignUpForm.controls['country'].invalid && userSignUpForm.controls['country'].touched}">{{'pdl-website.forms.labels.country' | translate }}</label>
                                    <span class="flex-grow-1"></span>
                                    @if(userSignUpForm.controls['country'].invalid && userSignUpForm.controls['country'].touched) {
                                        <ng-container *ngTemplateOutlet="requiredError"></ng-container>
                                    }
                                    @if(userSignUpForm.controls['country'].valid && userSignUpForm.controls['country'].touched) {
                                        <ng-container *ngTemplateOutlet="validFld"></ng-container>
                                    }
                                </div>
                                <select class="form-select" formControlName="country" id="country" [ngClass]="{'border-danger': userSignUpForm.controls['country'].invalid && userSignUpForm.controls['country'].touched}">
                                    <option ngValue="">Select</option>
                                    @for(country of countryList$ | async; track country.CountryID) {
                                        <option [ngValue]="country?.CountryID">{{country?.Name}}</option>
                                    }
                                </select>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <label for="state" class="form-label">{{'pdl-website.forms.labels.state' | translate }}</label>
                                </div>
                                <input type="text" class="form-control" formControlName="state" id="state">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="address1" class="form-label">{{'pdl-website.forms.labels.address1' | translate }}</label>
                                <input type="text" class="form-control" formControlName="address1" id="address1" >
                            </div>
                            <div class="col-md-6">
                                <label for="address2" class="form-label">{{'pdl-website.forms.labels.address2' | translate }}</label>
                                <input type="text" class="form-control" formControlName="address2" id="address2" >
                            </div>
                        </div>
                        <!-- recaptch -->
                        <div class="row mt-4">
                            <div class="col-12">
                                <re-captcha #captchaRef (resolved)="resolveCaptcha($event)" formControlNme="cpt" siteKey="{{sitekey}}" [size]="isMobile ? 'compact' : 'normal'"></re-captcha>
                            </div>
                        </div>
                        <!-- /recaptch -->
                        <!-- or -->
                        <div class="row">
                            <div class="col-12 or-option text-center fw-normal">-Or-</div>
                        </div>
                        <!-- social media buttons -->
                        <panjab-digi-lib-social-login (handleLoginEvent)="handleSocialLogin($event)" (handleFBLoginEvent)="signInWithFB()"></panjab-digi-lib-social-login>
                        <!-- submit and reset buttons -->
                        <div class="row gx-1">
                            <div class="col-6 text-end">
                                <button type="reset" class="btn btn-secondary btn__secondary">{{'pdl-website.forms.labels.resetBtn' | translate }}</button>
                            </div>
                            <div class="col-6 text-start">
                                @if(isSubmitted) {
                                    <panjab-digi-lib-spinner-button [name]="'pdl-shared.loaderState.pleaseWait' | translate"></panjab-digi-lib-spinner-button>
                                } @else {
                                    <button [disabled]="userSignUpForm.invalid" type="submit" class="btn btn-primary btn__primary">
                                        {{'pdl-website.forms.labels.registerBtn' | translate }}
                                    </button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div class="pdl-user f-14 text-center pb-4">
                    <span>{{'pdl-website.forms.user.alreadyOnPdl' | translate }} </span>
                    <a href="javascript:void(0);" [routerLink]="'/login-user'" class="fw-bold">{{'pdl-website.forms.labels.signIn' | translate }}</a>
                </div>
            </div>
          </div>
        </div>
    </div>
    <!-- Template - required field error -->
    <ng-template #requiredError>
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" alt="error" />
        </span>
    </ng-template>
    <!-- Template - invalid field error -->
    <ng-template #invalidError let-msg="msg">
        <span class="text-danger">
            <img src="../../../assets/images/icons/error-icon.svg" [ngbTooltip]="tipContent" alt="error" />
            <ng-template #tipContent>{{msg}}</ng-template>
        </span>
    </ng-template>
    <!-- Template - valid field -->
    <ng-template #validFld>
        <span class="text-success">
            <img src="../../../assets/images/icons/checked-icon.svg" alt="success" />
        </span>
    </ng-template>
</section>
