import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'panjab-digi-lib-news-and-article-detail',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './news-and-article-detail.component.html',
  styleUrl: './news-and-article-detail.component.scss',
})
export class NewsAndArticleDetailComponent {
  getRandomNumber = () => {
    return new Array(20).fill(1);
  };
}
