import { createAction, props } from '@ngrx/store';


export const LoadTrendingKeywords = createAction(
    '[Trending] Load Trending Keywords List',
    props<{payload: {pageSize:number,pageNumber: number, type:string, query?: string,sortBy:string, orderBy:string}}>(),
);

export const LoadTrendingKeywordsSuccessfully = createAction(
    '[Trending] Load Trending Keywords Successfully',
    props<{ trendingList: [],totalRecords: number }>()
);

export const LoadTrendingKeywordsError = createAction(
    '[Trending] Load Trending Keywords Error',
    props<{ message: string }>()
);

export const UpdateKeywordShowOnApplicationStatus = createAction(
    '[Trending] Show/Hide keyword on application',
    props<{payload: {id:Array<number>,status: boolean}}>()
);

export const UpdateKeywordShowOnApplicationStatusSuccessfully = createAction(
    '[Trending] Update keyword Status Success',
    props<{ trendingItem: any,message: string }>()
);

export const UpdateKeywordShowOnApplicationStatusError = createAction(
    '[Trending] Update keyword Status Error',
    props<{ message: string }>()
);
