<panjab-digi-lib-static-page-head [pageName]="'pdl-website.disclaimer.titleText' | translate" pagePath="/disclaimer">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container container-768">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <p class="text">Your use of the Panjab Digital Library (PDL) Website is subject to the terms and conditions on this
      page and the pages linked to it.Although any information appearing on the pages of Panjab Digital Library on the
      Internet is presented and maintained with the greatest possible care and attention, PDL does not guarantee that
      this information is either complete or accurate.</p>
    <p class="text">
      The PDL therefore does not accept any responsibility for any eventual consequences, such as personal damages or
      loss of profit, which might ensue from any actions taken as a result of, or related to, information published on
      the PDL Web site.
    </p>
    <p class="text">
      PDL intends to make its Web site accessible at all times, however, it cannot be held responsible for any
      consequences should the Web site be temporarily or permanently inaccessible at any time.Even as the PDL makes
      every effort to ensure that the site is up to date and accurate, we do not guarantee its accuracy. The
      panjabdigilib.org expressly excludes liability for loss or damage arising from your use of this site. The term
      "Use of this site" includes, but is not limited to, the following:
    </p>
    <ul class="text">
      <li>Reliance placed upon information on the site;</li>
      <li>The downloading of materials from the site;</li>
      <li>The accessing of other parts of the Internet or sites from links on this site;</li>
      <li>In the case of downloading of materials it is your responsibility to ensure that material is virus checked by
        any up-to-date anti-virus software.</li>
    </ul>
    <p class="text">
      This Web site also contains a "Comments" section for every document posted, within which some users have published
      their thoughts on that particular document. Though PDL makes every effort to ensure that the comments published in
      this section of the site do not contain any defamatory or discriminatory material, we do not guarantee that this
      may not happen occasionally. PDL takes no responsibility for any loss or damage suffered as a result of the
      comments published.
    </p>
    <p class="text">
      From time to time this Web site may include links to other third party Web sites. These links are used to provide
      further information and are not intended to signify that PDL endorses such Web sites and/or their content. PDL
      takes no responsibility for any loss or damage suffered as a result of using any information published on any of
      the pages of the linked third party
    </p>
    }
  </div>
</section>
