<ng-template #printModal>
  <div class="modal-body">
    <div class="text-center mb-4">
      <p class="subtitle">
        {{ 'pdl-website.printPopup.chooseOption' | translate }}
      </p>
    </div>

    <hr class="mb-4" />

    <form [formGroup]="printForm">
      <div class="section-container mb-4">
        <!-- New Book Option -->
        <div class="section-container mb-4">
          <div class="form-check d-flex align-items-start mb-3">
            <input
              class="form-check-input mt-2"
              type="radio"
              formControlName="printOption"
              id="newBook"
              value="newBook"
            />
            <label
              class="form-check-label ms-2 d-flex flex-column align-items-start"
              for="newBook"
            >
              <span class="section-title mb-1">
                {{ 'pdl-website.printPopup.newBook' | translate }}
              </span>
              <span class="section-text m-0">
                *{{ 'pdl-website.printPopup.mrpNote' | translate }}
              </span>
            </label>
          </div>

          <!-- Binding Options for New Book -->
          <div class="form-sub-option">
            <div class="form-check custom-radio d-flex align-items-center mb-3">
              <input
                class="form-check-input mt-1"
                type="radio"
                formControlName="bindingOption"
                id="softBind"
                value="softBind"
              />
              <label class="form-check-label d-flex ms-2 w-100" for="softBind">
                <span class="price-text">
                  {{ 'pdl-website.printPopup.softBind' | translate }}
                </span>
                <span class="price-text amount">(₹ 369)</span>
              </label>
            </div>
            <div class="form-check custom-radio d-flex align-items-center">
              <input
                class="form-check-input mt-1"
                type="radio"
                formControlName="bindingOption"
                id="hardBind"
                value="hardBind"
              />
              <label class="form-check-label d-flex ms-2 w-100" for="hardBind">
                <span class="price-text">
                  {{ 'pdl-website.printPopup.hardBind' | translate }}
                </span>
                <span class="price-text amount">(₹ 466)</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Sorry Message -->
      @if(bookNotAvailable) {
      <div class="alert border mb-4">
        <p class="section-title">
          {{ 'pdl-website.printPopup.sorry' | translate }}
        </p>
        <p class="mb-0 section-text">
          {{ 'pdl-website.printPopup.notAvailable' | translate }}
        </p>
      </div>
      }

      <!-- Print on Demand Section -->
      <div class="section-container">
        <div class="form-check d-flex align-items-start mb-3">
          <input
            class="form-check-input mt-2"
            type="radio"
            formControlName="printOption"
            id="printOnDemand"
            value="printOnDemand"
          />
          <label
            class="form-check-label ms-2 d-flex flex-column align-items-start"
            for="printOnDemand"
          >
            <span class="section-title mb-1">
              {{ 'pdl-website.printPopup.printOnDemand' | translate }}
            </span>
            <span class="section-text m-0">
              *{{ 'pdl-website.printPopup.pageNote' | translate }}
            </span>
            <span class="section-text m-0">
              {{ 'pdl-website.printPopup.shippingNote' | translate }}
            </span>
          </label>
        </div>

        <!-- Binding Options for Print on Demand -->
        <div class="form-sub-option">
          <div class="form-check custom-radio mb-3">
            <input
              class="form-check-input"
              type="radio"
              formControlName="bindingOption"
              id="softBindPrint"
              value="softBindPrint"
            />
            <label class="form-check-label d-flex" for="softBindPrint">
              <span class="price-text">
                {{ 'pdl-website.printPopup.softBind' | translate }}
              </span>
              <span class="price-text amount">(₹ 345)</span>
            </label>
          </div>

          <div class="form-check custom-radio">
            <input
              class="form-check-input"
              type="radio"
              formControlName="bindingOption"
              id="hardBindPrint"
              value="hardBindPrint"
            />
            <label class="form-check-label d-flex" for="hardBindPrint">
              <span class="price-text">
                {{ 'pdl-website.printPopup.hardBind' | translate }}
              </span>
              <span class="price-text amount">(₹ 447)</span>
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
