/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @nx/enforce-module-boundaries */
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SearchResultsViewComponent } from '../../search-results/search-results-view/search-results-view.component';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import { Store } from '@ngrx/store';
import { AppState, environment, SpinnerComponent, WebUserLibraryActions, WebUserLibrarySelector } from '@panjab-digi-lib/shared';
import { skip, Subscription } from 'rxjs';
import { SuggestedReads, TopicResponse } from 'shared/src/interfaces/website/user-library.interface';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AssignLiteratureComponent } from './assign-literature/assign-literature.component';
import { CreateEditTopicComponent } from './create-edit-topic/create-edit-topic.component';
import { WebUserLibraryService } from 'shared/src/services/website/user-library/user-library.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'panjab-digi-lib-my-library',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SearchResultsViewComponent,
    RouterLink,
    RouterOutlet,
    NgbPaginationModule,
    AssignLiteratureComponent,
    CreateEditTopicComponent,
    SpinnerComponent,
    SlickCarouselModule
  ],
  templateUrl: './my-library.component.html',
  styleUrl: './my-library.component.scss',
})
export class MyLibraryComponent implements OnInit, OnDestroy {
  pageNumber: number = 1;
  pageSize = APP_CONSTANTS.pageSize;
  fromRecords: number = 0;
  toRecords: number = 0;
  subscription = new Subscription();
  researchTopics: TopicResponse[] = [];
  suggestedReads : SuggestedReads[] = [];
  maxPageNumber = APP_CONSTANTS.defaultPaginationSize;
  assignLiterature: boolean = false;
  createNewTopic: boolean = false;
  docId!: number;
  nonUnassignedResearchTopics: { TopicID: number, TopicName: string }[] = [];
  researchTopicDetail :  boolean = false;
  isLoading: boolean = true;
  @ViewChildren('listItem') listItems!: QueryList<ElementRef>;
  imageBaseURL = environment.azureCloudStrorage.publicUrl.replace(/\/$/, '');
  slideConfig = {
    centerMode: false,
    centerPadding: '40px',
    autoplay: true,
    draggable: true,
    autoplaySpeed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          infinite: true
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      }
    ]
  };
  constructor(
    private store: Store<AppState>,
    private router : Router,
    private service: WebUserLibraryService
  ) {
  }

  ngOnInit(): void {
    this.fetchUserResearchTopics(this.pageNumber);
    this.fetchSuggestedReads();
  }

  ///////////////////////// fetch user research topics ////////////////////////////
  fetchUserResearchTopics(pageNumber: number, scroll: boolean = false) {
    const payload = {
      pageNumber: pageNumber,
      pageSize: this.pageSize
    };
    this.store.dispatch(WebUserLibraryActions.WebsiteUserLibrary({ payload }));
    this.subscription.add(
      this.store.select(WebUserLibrarySelector.topicList).pipe(skip(1)).subscribe((response) => {
        this.researchTopics = response?.topicList;
        const unassignedData = this.researchTopics.find(topic => topic.TopicID === null)?.Data || [];
        this.calculatePagination(unassignedData);
        this.getTopicsExceptUnassigned();
        if(scroll) {
          this.scrollToUnassignedLiterature();
        }
      })
    );
  }

  ///////////////////////////// suggested reads ////////////////////////////
  fetchSuggestedReads() {
    this.subscription.add(this.service.suggestedReads().subscribe({
      next: (response) => {
        this.suggestedReads = response?.body;
        this.isLoading = false;
      },
      error: (error) => {
      this.isLoading = false;
       console.log('Error fetching suugested reads',error)
      },
    }));
  }

  //////////////////// calculate and show pagination //////////////////////////
  calculatePagination(data: Array<object>) {
    this.fromRecords =
      this.pageNumber === 1 && data?.length > 0
        ? 1
        : this.pageNumber === 1 && data?.length === 0
          ? 0
          : (this.pageNumber - 1) * this.pageSize + 1;
    this.toRecords = (this.pageNumber - 1) * this.pageSize + data?.length;
  }

  ////////////////// get all topics name and id ////////////////////

  getTopicsExceptUnassigned() {
    this.nonUnassignedResearchTopics = this.researchTopics
      .filter(item => item.TopicID !== null)
      .map(item => ({
        TopicID: item.TopicID,
        TopicName: item.TopicName
      }));
  }

  ////////////////// rating star //////////////////////////
  getRating(rating: number): number[] {
    const fullStars = Math.floor(rating);
    const halfStar = (rating % 1) >= 0.5;
    const noStars = 5 - fullStars - (halfStar ? 1 : 0);

    return [
      ...Array(fullStars).fill(1),
      ...(halfStar ? [0.5] : []),
      ...Array(noStars).fill(0)
    ];
  }

  // Method to get the appropriate star image URL
  getStarImage(star: number): string {
    switch (star) {
      case 1:
        return 'assets/images/icons/full-star.svg';
      case 0.5:
        return 'assets/images/icons/half-star.svg';
      case 0:
        return 'assets/images/icons/no-star.svg';
      default:
        return 'assets/images/icons/no-star.svg';
    }
  }
  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = APP_CONSTANTS.fallbackImage;
    imgElement.classList.add('ofc')
  }

  ////////////// move to research topic /////////////////
  moveToResearchTopic(docId: number) {
    this.docId = docId;
    this.assignLiterature = true;
  }

  ////////////////// close modal //////////////////////
  closeModal(event: boolean) {
    this.assignLiterature = false;
    if (event) {
      this.fetchUserResearchTopics(this.pageNumber);
    }
  }

  //////////// close create topic modal ////////
  closeCreateTopicModal(event: boolean) {
    this.createNewTopic = false;
    if (event) {
      this.fetchUserResearchTopics(this.pageNumber);
    }
  }

  //////////// move to metadata detail page /////////
  moveToMetadata(docId:number) {
    this.router.navigateByUrl(`/metadata/${docId}`);
  }

 /////////// scroll into view for unassigned literature ////////////
  scrollToUnassignedLiterature(): void {
    const lastItem = this.listItems.last;
    if (lastItem) {
      lastItem.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
