import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { catchError, map, throwError, from, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserDownloadsService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
  
  getDownloadTypes(DocID: number) {
    return this.http.get(`${this.rootURL}${WEB_API_ENDPOINTS.userDownloads.downloadTypes}`.replace('{id}',  DocID.toString())).pipe(
      map((e: any) => e.body)
    );
  }
  
  checkDownloadLimit() {
    return this.http.get(`${this.rootURL}${WEB_API_ENDPOINTS.userDownloads.checkLimit}`).pipe(
      map((e: any) => e.body)
    );
  }
  
  download(DocID: number, type: string) {
    return this.http.post(`${this.rootURL}${WEB_API_ENDPOINTS.userDownloads.download}`.replace('{type}', type).replace('{id}', DocID.toString()), {}, { responseType: 'blob' }).pipe(
      map((e: Blob) => e),
      catchError((error) => this.handleError(error))
    );
  }
  
  private handleError(error: HttpErrorResponse) {
    // Check if the error response is a Blob and likely contains JSON
    if (error.error instanceof Blob && error.error.type === "application/json") {
      return new Observable<any>((observer) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            // Parse JSON error from the blob content
            const errorMessage = JSON.parse(reader.result as string);
            observer.error(new Error(errorMessage.error.message || "An error occurred"));
          } catch (e) {
            observer.error(new Error("An unexpected error occurred"));
          }
        };
        reader.onerror = () => observer.error(new Error("An unexpected error occurred"));
        reader.readAsText(error.error);
      });
    } else {
      // If error is not a Blob, handle it normally
      const errorMessage = error.error?.error?.message || "An unexpected error occurred";
      return throwError(() => new Error(errorMessage));
    }
  }
}
