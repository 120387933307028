@if(isLoading) {
<panjab-digi-lib-spinner></panjab-digi-lib-spinner>
} @if(!isLoading) {
<div class="overflow-x-hidden">
  <section class="top_section">
    <img
      src="{{ imageBaseUrl }}{{ itemDetail.ImagePath }}"
      class="banner_image"
      alt="event-img"
    />
    <div class="innerpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              class="breadcrumb d-flex align-items-center justify-content-start flex-wrap"
            >
              <a class="breadcrumb-link font-regular" href="#">
                <img
                  class="home-icon"
                  src="assets/images/icons/homepage-ico.svg"
                  alt="icon"
                />
                {{ 'pdl-shared.content.eventsCampaigns.home' | translate }}
              </a>
              <span class="chevron-arrow font-regular">
                <img src="assets/images/icons/arrow-right.svg" alt="icon" />
              </span>
              <a
                class="breadcrumb-link font-regular"
                [routerLink]="'/events-campaigns'"
              >
                {{ 'pdl-website.eventsCampaigns.title' | translate }}
              </a>
              @if (itemDetail && itemDetail.Title) {
              <span class="chevron-arrow font-regular">
                <img src="assets/images/icons/arrow-right.svg" alt="icon" />
              </span>
              <a class="breadcrumb-link font-regular">
                <span>{{ fixLength(itemDetail.Title, 20) }}</span>
              </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top_section_bottom">
      <div class="content">
        <p class="heading">
          {{ itemDetail.Title }}
        </p>
        <div class="subheading">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap"
          >
            <div>
              <div class="d-flex align-items-center">
                {{ itemDetail.EventDates[0].Date | date : 'dd MMMM YYYY' }} |
                {{
                  '1970-01-01T' + itemDetail.EventDates[0].StartTime
                    | date : 'h:mm a'
                }}
                -
                {{
                  '1970-01-01T' + itemDetail.EventDates[0].EndTime
                    | date : 'h:mm a'
                }}

                @if(itemDetail.EventDates.length > 1) {
                  <button
                  class="btn pdl-btn btn-more-event medium-font d-flex align-items-center justify-content-start ms-2"
                  (click)="openMoreEventsModal($event, itemDetail)"
                >
                    {{
                    itemDetail.EventDates.length > 2
                    ? 'Check ' + (itemDetail.EventDates.length - 1) + ' more Times'
                    : itemDetail.EventDates.length === 2
                    ? 'Check 1 more Time'
                    : ''
                    }}          
                  </button>
                }
              </div>

              <div class="mb-3">
                {{ itemDetail.Location }}
              </div>
              <!-- @if(itemDetail.OtherLocations && itemDetail.OtherLocations.length > 0) {
                            <button class="check_more_locations cursor-pointer">Check
                                {{itemDetail.OtherLocations.length}}
                                More
                                {{itemDetail.OtherLocations.length > 1 ? 'Locations' : 'Location'}} 
                            </button>
                            } -->
            </div>
            <!-- <div class="btn_wrapper">
                            <a href="javascript:void(0)"
                                class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.eventsCampaigns.getDirections'
                                | translate }} <img src="assets/images/icons/directions-ico.svg"
                                    alt="direction-icon" class="ps-2">
                            </a>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-------------------------- google map section  ----------------------->
  <section class="container exhibition_detail mt-5">
    <div class="mapouter mb-5 mt-5 d-flex justify-content-center">
      <div class="gmap_canvas">
        <div #mapContainer style="width: 100%; height: 400px"></div>
      </div>
    </div>
  </section>
  <div class="tab-content">
    <div
      id="event-campaign_detail"
      role="tabpanel"
      class="tab-pane fade show active"
    >
      <section class="container event-campaign_detail mt-5 desc_content">
        <p class="heading">{{ itemDetail.Title }}</p>
        <div [innerHTML]="sanitizeHTML(itemDetail.Description)"></div>
        <div class="mb-5 mt-5">
          <panjab-digi-lib-global-divider />
        </div>
      </section>
    </div>
  </div>
</div>
}

<ng-template #moreEventsModal>
  <div class="action__form mt-4 m-auto">
    <div class="d-flex align-items-start flex-row">
      <svg
        width="20"
        height="30"
        viewBox="0 0 27 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 0C6 0 0 6.02308 0 13.5519C0 21.0808 6 27.1038 13.5 36.1385C21 27.1038 27 21.0808 27 13.5519C27 6.02308 21 0 13.5 0ZM13.5 9.03461C15.9898 9.03461 18 11.0526 18 13.5519C18 16.0513 15.9898 18.0692 13.5 18.0692C11.0102 18.0692 9 16.0513 9 13.5519C9 11.0526 11.0102 9.03461 13.5 9.03461Z"
          fill="#477958"
        />
      </svg>
      <span class="location-name ms-2">
        {{ selectedEvent.Location }}
      </span>
    </div>
    <div class="divider mt-3"></div>

    <div class="event-list d-flex flex-wrap mt-3">
      @for(item of selectedEvent.EventDates;track item;let i= $index;let last =
      $last) { 
      @if(i > 0) {
      <div class="w-100">
        <div class="pair w-100">
          <img src="assets/images/icons/watch.svg" alt="time" />
          <span
            >{{ item.Date | date : 'dd MMM YYYY' }} |
            {{ '1970-01-01T' + item.StartTime | date : 'h:mm a' }} -
            {{ '1970-01-01T' + item.EndTime | date : 'h:mm a' }}</span
          >
        </div>
        @if(!last) {
        <div class="divider"></div>
        }
      </div>
    }
      <div class="divider mt-3"></div>

       }
    </div>
  </div>
</ng-template>
