import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export function requiredNoWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value || '';
    const isEmpty = value.trim().length === 0;
    const isRequired = Validators.required(control);

    return isEmpty || isRequired ? { 'required': true, 'whitespace': true } : null;
  };
}
