<panjab-digi-lib-static-page-head [pageName]="'pdl-website.interlibrary.titleText' | translate" pagePath="/intelibrary">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container container-768">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <h1 class="heading mb-3">Interlibrary</h1>

    <p class="text">Though we will make our best efforts to meet the book/manuscript requests on Sikhs and Panjab,
      having all the books in a single library is neither possible nor feasible. In many cases one library has to make a
      request to another member library under an interlibrary loan system to provide a particular material. But since
      there is no formal understanding for this between libraries in the region at the moment, getting some books will
      not always be possible at times. You may still place a request at <a href="mailto:librarian@panjabdigilib.org"
        target="_blank" class="cursor-pointer text email">librarian&#64;panjabdigilib.org</a> and we will try our
      best to honor it. If you know the location of manuscript/book that you need, share it with us and support its
      digitization and it is possible that document could be made available online in a matter of days.</p>
    <h1 class="heading mb-3">Panjab Inter-Library Cooperation (PILC)</h1>
    <p class="text">
      It is logically impossible for a single library to contain all the books in the world and do all of the work by
      itself. Therefore, Panjab Inter-Library Cooperation (PILC) is a much needed opportunity for partnership, allowing
      libraries to meet the information requirements of their users. Under the PILC system, two or more libraries
      cooperate to share their resources which can be in the form of their books, journals, catalogs, etc... This
      sharing can be done in two ways: one is through on-loan physical delivery of a source to another library and the
      second is through online digital libraries that share each other's assets electronically through P2P technology.
      Whichever route is adopted, inter-library sharing and cooperation service can be considered an extremely
      beneficial option. Interlibrary service makes libraries cost effective, manageable and efficient. Whatever a
      library does not possess can be made available to its users by borrowing the same from another library. Thus,
      interlibrary service is reciprocal and mutually beneficial. As internet services increase, and more and more
      libraries become digital, P2P technology will come to be a primary source of information sharing among libraries.
      Under this technology, each digital library will have a server which will store its complete database and will be
      interconnected to the servers of other member libraries of the PILC sharing system. In case a library server is
      unable to meet the demand for a particular source, it will pass it on to the server of another member library,
      which may be able to supply the required information. In this way, the user will finally get the required
      information electronically through the servers of these libraries, at a certain cost and within a certain period
      of time. Thus, it is PDL's hope that the interlibrary service will grow to be the desired goal of all offline and
      online libraries of Panjab.
    </p>
    <p class="text">
      Panjab Digital Library (PDL), which has a significant collection of media and text on the Sikh and Panjab
      heritage, is willing to cooperate with other libraries in the region on a reciprocal basis structured by agreed
      upon guidelines and principles. Major libraries in the developed world already have a well-developed interlibrary
      service network that can serve as a model for PILC. Let us follow suit and increase the frontiers of knowledge.
    </p>
    <p class="text">If your institution in interested in joining, please write to <a
        href="mailto:librarian@panjabdigilib.org" target="_blank"
        class="cursor-pointer text email">librarian&#64;panjabdigilib.org</a>
    </p>
    }
  </div>
</section>
