<div class="input-group">
  @if(inputBox) {
  <input 
    class="form-control form__field" 
    placeholder="Select date range" 
    (click)="toggleDatepicker()" 
    [value]="dateRange" 
    readonly>
  } @else{
   @if(checkBox) { 
  <input class="form-check-input" type="checkbox" id="switchShow" (change)="showHideDatePicker($event)"> 
  <span (click)="givenRange.startDate ? toggleDatepicker() : ''" [ngClass]="checkBox ? 'ms-2' : ''">Date range</span>
   }@else {
   <span (click)="toggleDatepicker()" [ngClass]="checkBox ? 'ms-2' : ''">Date range</span>
   }
  }
</div>
@if(isDatepickerOpen) {
<ng-container>
  <ngb-datepicker 
    #dp 
    (dateSelect)="onDateSelection($event)" 
    [displayMonths]="2" 
    [dayTemplate]="t" 
    outsideDays="hidden"
    [startDate]="startMonth"
    datepickerClass="datepicker">
  </ngb-datepicker>
</ng-container>
}

<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>