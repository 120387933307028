/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient } from '@angular/common/http';
import { Adopter, UpdateAdopters } from 'shared/src/interfaces/fundraising.interface';

@Injectable({
  providedIn: 'root'
})
export class AdoptedByService {
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  loadAdopters(payload: GenericListingPayload, contributor: string): Observable<{adoptersList: Adopter[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.fundraising.adoptedBy.default}`, {params: {...payload, contributor}}).pipe(map((e: any) => e.body));
  }

  updateAdopter(data: UpdateAdopters ) {
    return this.http.put(`${this.rootURL}${API_ENDPOINTS.fundraising.adoptedBy.default}/${data.id}`, data).pipe(map((e: any) => e));
  }

  getAdoptedBooks(payload: GenericListingPayload): Observable<{adoptedBooksList: Adopter[], totalCount: number}> {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.fundraising.adoptedBy.getAdoptedBooks}`, {params: {...payload}}).pipe(map((e: any) => e.body));
  }

  getPaymentDetails(DocID: number) {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.fundraising.adoptedBy.getPaymentDetails}/${DocID}`).pipe(map((e: any) => e.body));
  }
  
  downloadAdopters() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.fundraising.adoptedBy.downloadAdopters}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
  
  getContributor() {
    return this.http.get(`${this.rootURL}${API_ENDPOINTS.fundraising.adoptedBy.downloadAdopters}`, { responseType: 'blob' }).pipe(map((data: Blob) => data));
  }  
}
