import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchModalResponse } from 'shared/src/interfaces/website/search.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  queryParamsSubject = new BehaviorSubject<{ [key: string]: any }>({});
  queryParams$ = this.queryParamsSubject.asObservable();
  modalSelection$ = new BehaviorSubject<any>({});

  updateQueryParams(params: { [key: string]: any }) {
    this.queryParamsSubject.next(params);
  }

  constructor() { }
}
