/* eslint-disable no-prototype-builtins */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from '../static/staticPageHead/staticPageHead.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchResultsViewComponent } from './search-results-view/search-results-view.component';
import { AdvancedSearchSidebarComponent } from './advanced-search-sidebar/advanced-search-sidebar.component';
import { WebSearchSelector } from 'shared/src/store/selectors';
import { Store } from '@ngrx/store';
import {
  AppState,
  SharedCommonService,
  WebSearchActions,
} from '@panjab-digi-lib/shared';
import { skip, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import {
  Search,
  SearchResponse,
} from 'shared/src/interfaces/website/search.interface';
import { CommonService } from 'apps/panjab-digi-lib/src/common/services/common.service';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { SpinnerComponent } from '@panjab-digi-lib/shared';

@Component({
  selector: 'panjab-digi-lib-search-results',
  standalone: true,
  imports: [
    CommonModule,
    StaticPageHeadComponent,
    TranslateModule,
    SearchResultsViewComponent,
    AdvancedSearchSidebarComponent,
    NgbPaginationModule,
    SpinnerComponent,
  ],
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private toastService: ToastService,
    private sharedService: SharedCommonService
  ) {}

  data: SearchResponse[] = [];
  filters: any;
  showSidebar = false;
  payload: { [key: string]: any } = {};
  pageNumber: number = 1;
  pageSize: number = APP_CONSTANTS.pageSize;
  maxPageNumber: number = APP_CONSTANTS.defaultPaginationSize;
  fromRecords: number = 0;
  toRecords: number = 0;
  totalRecords: number = 0;
  pageInitialized: boolean = true;
  sortBy: string = '_score';
  decodedQuery: string = '';
  isLoading: boolean = false;
  viewType: 'list' | 'card' = 'list';
  sortingTypes = [
    {
      key: 'Relevance',
      value: '_score',
    },
    {
      key: 'Most Views',
      value: 'DocumentViewCount',
    },
    {
      key: 'Date Published',
      value: 'PublisherYear',
    },
    {
      key: 'Rating',
      value: 'UserRating',
    },
  ];
  getSortingKey = (val: string) => {
    return this.sortingTypes.find((type: any) => type.value === val)?.key;
  };
  ngOnInit() {
    const keysToExclude = [
      'categoryId',
      'pageNumber',
      'pageSize',
      'query',
      'sortBy',
      'strictMode',
      'filters',
    ];
    this.commonService.queryParams$.subscribe((params) => {
      const newPayload: any = { ...this.payload };
      if(this.showSidebar) {
        newPayload.advancedSearch = true;
      }
      const paramKeys = Object.keys(params);
      if (paramKeys.length > 0 && paramKeys[0] !== 'clear') {
        if (paramKeys.length > 0 && keysToExclude.includes(paramKeys[0])) {
          newPayload[paramKeys[0]] = params[paramKeys[0]];
        }
        newPayload.filters = newPayload.filters
          ? { ...newPayload.filters }
          : {};
        paramKeys.forEach((key) => {
          if (
            !keysToExclude.includes(key) &&
            newPayload.filters.hasOwnProperty(key)
          ) {
            delete newPayload.filters[key];
          }
        });

        paramKeys.forEach((key) => {
          if (
            !keysToExclude.includes(key) &&
            params[key] &&
            params[key].length > 0
          ) {
            newPayload.filters[key] = params[key];
          }
        });
        if (Object.keys(newPayload.filters).length === 0) {
          delete newPayload.filters;
        }
        if(params['categoryId']) {        ///// if already on any other page than first then reset to first page  
          newPayload.pageNumber = 1;
          this.pageNumber = 1;
          this.fetchAdvancedFilters(newPayload);
        }
        this.payload = newPayload;
        this.fetchSearchResults(this.payload);
      } else if (paramKeys.length > 0 && paramKeys[0] === 'clear') {
        delete newPayload['filters'];
        this.payload = newPayload;
        this.fetchSearchResults(this.payload);
        this.fetchAdvancedFilters(this.payload);
      }
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.payload = { ...params };
      this.payload['filters'] = {};
      const transformedPayload = { ...this.transformPayload(this.payload) };
      for (const key in transformedPayload) {
        if (!keysToExclude.includes(key)) {
          this.payload['filters'][key] = transformedPayload[key];
          delete this.payload[key];
        }
      }

      if (this.payload['pageNumber']) {
        this.pageNumber = parseInt(this.payload['pageNumber']);
      }
      if (this.payload['sortBy']) {
        this.sortBy = this.payload['sortBy'];
      }
      this.decodedQuery = this.payload['query'] ? this.sharedService.decodeFromBase64(this.payload['query']) : '';
      this.fetchSearchResults(this.payload);
      this.fetchAdvancedFilters(this.transformPayload({ ...params }));
    });
  }

  ///////////////// transform payload for filters //////////////////
  transformPayload(payload: { [key: string]: any } = {}): {
    [key: string]: any;
  } {
    return Object.entries(payload).reduce((acc, [key, value]) => {
      if (
        typeof value === 'string' &&
        value.startsWith('[') &&
        value.endsWith(']')
      ) {
        try {
          acc[key] = JSON.parse(value.replace(/,\s*$/, ''));
        } catch (e) {
          console.error(`Failed to parse value for key "${key}":`, e);
          acc[key] = value;
        }
      } else {
        acc[key] = value;
      }
      return acc;
    }, {} as { [key: string]: any });
  }

  //////////////sort records by given ////////////////////
  sortRecords(sortBy: string) {
    this.sortBy = sortBy;
    this.fetchSearchResults(this.payload);
    this.updateStringUrl('sortBy', this.sortBy);
  }

  //////////////// fetch search results //////////////
  fetchSearchResults(payload: { [key: string]: any }) {
    const updatedPayload = {
      ...payload,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortBy: this.sortBy,
    };
    this.store.dispatch(
      WebSearchActions.WebsiteSearch({ payload: updatedPayload })
    );
    this.isLoading = true;
    this.subscription.add(
      this.store
        .select(WebSearchSelector.searchList)
        .pipe(skip(3))
        .subscribe((response: Search['body']) => {
          this.isLoading = false;
          this.data = response?.searchList;
          this.totalRecords = response?.totalRecords;
          this.calculatePagination(this.data);
          setTimeout(() => this.handleSidebarClick());
        })
    );
  }

  /////////////////// fetch advanced search filters ///////////////////
  fetchAdvancedFilters(payload: { [key: string]: any }) {
    this.store.dispatch(WebSearchActions.WebsiteGetFilters({ payload }));
    this.subscription.add(
      this.store
        .select(WebSearchSelector.filterList)
        .pipe(skip(1))
        .subscribe((response: any) => {
          this.filters = response;
          if(this.payload['query'] === '') {
            const query = this.sharedService.decodeFromBase64(
              this.payload['categoryId']
            )
            this.decodedQuery = this.filters.Categories.records.find((item: any) => item.categoryID === Number(query))?.Name;
          }
          // setTimeout(() => this.handleSidebarClick());
        })
    );
  }

  ///////////// update url based on selected checkbox ///////////////
  updateCheckboxUrl(key: string, selectedIds: number[]) {
    const params = new URLSearchParams(window.location.search);
    if (selectedIds.length) {
      params.set(key, JSON.stringify(selectedIds));
    } else {
      params.delete(key);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }

  //////////// calculate Pagination ////////////////

  calculatePagination(data: Array<object>) {
    this.fromRecords =
      this.pageNumber === 1 && data?.length > 0
        ? 1
        : this.pageNumber === 1 && data?.length === 0
        ? 0
        : (this.pageNumber - 1) * this.pageSize + 1;
    this.toRecords = (this.pageNumber - 1) * this.pageSize + data?.length;
  }

  //////////////// update url based on page number /////////////////
  updatePaginationUrl(pageNumber: number): void {
    this.pageInitialized = false;
    if (!this.pageInitialized) {
      this.pageNumber = pageNumber;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('pageNumber', pageNumber.toString());
      const newUrl = `${window.location.pathname}?${urlParams}`;
      window.history.replaceState({}, '', newUrl);
      this.fetchSearchResults(this.payload);
      window.scroll(0, 0);
    }
  }

  //////////////// update string url params for textboxes /////////////////
  updateStringUrl(key: string, value: string): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (value) {
      urlParams.set(key, value);
    } else {
      urlParams.delete(key);
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }

  /////////////// show Message received from advanced search sidebar ///////////////
  showMessage(message: string) {
    this.toastService.showSuccess(message);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  handleSidebarClick = () => {
    const resultView: any = document.querySelector('#results_view');
    const sidebarWrapper: any = document.querySelector('.sidebar_wrapper');
    sidebarWrapper.style.overflowY = 'auto';
    sidebarWrapper.style.overflowX = 'hidden';
    const height = this.showSidebar
      ? resultView?.offsetHeight + 200 || 'auto'
      : 60;
    sidebarWrapper.style.height = `${height}px`;
    sidebarWrapper.style.maxHeight = `${height}px`;
  };
  toggleSideBar = () => {
    this.showSidebar = !this.showSidebar;
    this.handleSidebarClick();
  };
  toggleViewType = (type: 'card' | 'list') => {
    this.viewType = type;
  };
}
