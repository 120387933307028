/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { catchError, map, throwError } from 'rxjs';
import { GenericListingPayloadWithCategoryFilter } from 'shared/src/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class UserCommentsService {

  rootURL = environment.rootApiUrl;
  endPointUrl = WEB_API_ENDPOINTS.userComments;
  constructor(private http: HttpClient) { }

  getUserComments(payload: GenericListingPayloadWithCategoryFilter) {  
    
    return this.http.get(`${this.rootURL}${this.endPointUrl.getComments}`, {params: {...payload}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  getCategoryCount() {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.getCategoryCount}`)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }


    return throwError(() => transformedError);
  }
}
