import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Login] User Login',
  props<{ email: string, password: string }>()
);

export const loginSuccess = createAction(
  '[Login] Login Success',
  props<{ token: string, refreshToken: string }>()
);

export const loginFailure = createAction(
  '[Login] Token Expired',
  props<{ error: any }>()
);

export const tokenExpired = createAction(
  '[Login] Login Failure'
);

export const logout = createAction(
  '[Logout]'
);

export const webLogin = createAction(
  '[WebLogin] Web User Login',
  props<{ username: string, password: string, login?: boolean }>()
);

export const webLoginSuccess = createAction(
  '[WebLogin] Web User Login Success',
  props<{ webToken: string, webRefreshtoken: string, user: any }>()
);

export const webLoginFailure = createAction(
  '[WebLogin] Web User Token Expired',
  props<{ error: any }>()
);

export const webTokenExpired = createAction(
  '[WebLogin] Web User Login Failure'
);

export const webLogout = createAction(
  '[WebLogout]'
);

export const webSocialLogin = createAction(
  '[WebLogin] Web Social Login',
  props<{ webToken: string, webRefreshtoken: string, user: any }>()
);

export const resetState = createAction(
  '[WebLogin] Reset Login'
);

