/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterLink } from '@angular/router';
import { AppState, WebHomePageActions, WebVideosSelector, environment } from '@panjab-digi-lib/shared';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Video } from 'shared/src/interfaces/video-gallery.interface';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';

@Component({
  selector: 'panjab-digi-lib-video-gallery',
  standalone: true,
  imports: [CommonModule, TranslateModule, SlickCarouselModule, RouterLink],
  templateUrl: './video-gallery.component.html',
  styleUrl: './video-gallery.component.scss',
})
export class VideoGalleryComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  videoList!: Video[];
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  videoDetail = { videoUrl: '', videoId: 0, videoThumb: '',videoTitle: ''};
  lastPlayedVideoId: number = 0;
  fallbackImage = APP_CONSTANTS.videoFallbackImage;
  @ViewChild('video') videoElement: any;
  updateCountApi : boolean = false;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
    infinite: true,
    responsive: [
      {
        breakpoint: 767.5,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600.5,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 575.5,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 380.5,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}


  ngOnInit(): void {
    this.loadVideosList();
  }


  slickInit(e: any) {
    console.log('slick initialized');
  }

  //////////////////// fetch homepoage videos ///////////////
  loadVideosList() {
    this.store.dispatch(WebHomePageActions.LoadWebHomepageVideos());
    this.subscription.add(
      this.store.select(WebVideosSelector.getVideos).subscribe((response) => {
        this.videoList = response?.videoList;
        const videoObject = this.videoList[0];
        this.videoDetail.videoId = videoObject?.ID;
        this.videoDetail.videoUrl = videoObject?.VideoPath || '';
        this.videoDetail.videoThumb = videoObject?.ThumbnailPath;
        this.videoDetail.videoTitle = videoObject?.Title;
      })
    );
  }

   ////////////////// update count on video play and if video played for 20 sec ///////////////

    onVideoTimeUpdate(videoId: number) {
      const video = this.videoElement.nativeElement;
      if (video.currentTime >= 20 && !this.updateCountApi) {
        this.subscription.add(
          this.store.dispatch(
            WebHomePageActions.UpdateVideoCount({ payload: videoId })
          )
        );
        this.updateCountApi = true;
     }
    }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
