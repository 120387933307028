/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TrendingKeywordsComponent } from './trending/trending-keywords.component';
import { GlobalDividerComponent } from '../divider/global-divider.component';
import { MostPopularComponent } from './most-popular/most-popular.component';
import { OverviewComponent } from './overview/overview.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CategoriesComponent } from './categories/categories.component';
import { CuratedCollectionsComponent } from './curated-collections/curated-collections.component';
import { ExhibitionsComponent } from './exhibitions/exhibitions.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';
import { MailingListComponent } from './mailing-list/mailing-list.component';

import { Store } from '@ngrx/store';
import {
  AppState,
  WebHomePageActions,
  environment,
  WebBannersSelector,
  WebCategoriesSelector,
  WebSearchActions,
  SharedCommonService,
} from '@panjab-digi-lib/shared';
import {
  Banner,
  HomeCategories,
} from 'shared/src/interfaces/homepage.interface';
import {
  debounceTime,
  filter,
  Observable,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { DonateComponent } from './donate/donate.component';
import { HttpFormatPipe } from 'shared/src/pipes/httpFormat.pipe';
import { HomepageService } from 'shared/src/services/website/home/homepage.service';
import { FormsModule } from '@angular/forms';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';

@Component({
  selector: 'panjab-digi-lib-home',
  standalone: true,
  imports: [
    CommonModule,
    SlickCarouselModule,
    TrendingKeywordsComponent,
    GlobalDividerComponent,
    MostPopularComponent,
    OverviewComponent,
    TranslateModule,
    CategoriesComponent,
    CuratedCollectionsComponent,
    ExhibitionsComponent,
    DownloadsComponent,
    VideoGalleryComponent,
    MailingListComponent,
    RouterLink,
    DonateComponent,
    HttpFormatPipe,
    FormsModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  bannerSlides$!: Observable<Banner[]>;
  categories$!: Observable<HomeCategories[]>;
  categoryId: number = 0;
  searchTerm = new Subject<string>();
  searchInput: string = '';
  subscription = new Subscription();
  suggestionList: Array<string> = [];
  selectedCategory: string = '';
  pageSize = APP_CONSTANTS.pageSize;
  selectedIndex: number = -1;
  rootURL = environment.rootApiPublicUrl;
  imageBasUrl = environment.azureCloudStrorage.publicUrl;

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private homePageService: HomepageService,
    private router: Router,
    private commonService: SharedCommonService
  ) {}

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 300,
        // settings: "unslick" // destroys slick
      },
    ],
  };

  slickInit(e: any) {
    // console.log('slick initialized');
  }

  ngOnInit(): void {
    this.store.dispatch(WebHomePageActions.LoadWebBanners());
    this.bannerSlides$ = this.store.select(WebBannersSelector.getBanners);
    this.categories$ = this.store.select(WebCategoriesSelector.getCategories);
    this.searchTerm
      .pipe(
        filter((term) => term.length >= 3),
        debounceTime(300),
        switchMap(async () => this.findSuggestions())
      )
      .subscribe();
  }

  ///////////////// set category //////////////////
  setCategory(categoryId: number, categoryName: string) {
    this.categoryId = categoryId;
    this.selectedCategory = categoryName;
    this.findSuggestions();
  }

  ////////////////// autocomplete based on input keyup ////////////////
  suggestion(e: Event) {
    const enteredKeyword = (e.target as HTMLInputElement).value;
    if (enteredKeyword === '') {
      this.suggestionList = [];
    }
    this.selectedIndex = -1;
    this.searchTerm.next(enteredKeyword);
  }

  ////////// find suggestions with entered text ///////////////

  findSuggestions() {
    this.homePageService
      .suggestion(this.categoryId, this.searchInput)
      .subscribe((data) => {
        this.suggestionList = data;
      });
  }

  //////////// search data ///////////////////////////
  search(text: string, strictMode = true) {
    this.searchInput = text;
    this.router.navigate(['/search'], {
      queryParams: {
        query: this.commonService.encodeToBase64(text),
        categoryId: this.commonService.encodeToBase64(this.categoryId),
        strictMode: this.commonService.encodeToBase64(strictMode),
      },
    });
  }

  ///////////////////// search on enter key ///////////////
  searchOnEnterKey() {
    if (this.searchInput.length >= 3) {
      if (this.selectedIndex > -1) {
        this.search(this.suggestionList[this.selectedIndex], true);
      } else {
        this.search(this.searchInput.trim(), false);
      }
    } else {
      console.log('Please enter at least 3 characters to search.');
    }
  }

  /////////////////// mouse keys arrows ////////////////
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.selectedIndex = Math.min(
        this.selectedIndex + 1,
        this.suggestionList.length - 1
      );
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
      event.preventDefault();
    } else if (event.key === 'Enter') {
      this.searchOnEnterKey();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
