/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from "@ngrx/store";
import { GenericListingPayload } from "shared/src/interfaces/common.interface";
import { Adopter, UpdateAdopters } from "shared/src/interfaces/fundraising.interface";

export const LoadAdopter = createAction(
    '[Adopter] Load Adopted by',
    props<{payload: GenericListingPayload, contributor: string}>(),
)

export const LoadAdopterSuccess = createAction(
    '[Adopter] Load Adopted by Successfully',
    props<{adoptersList: Adopter[], totalRecords: number}>()
)

export const LoadAdopterError = createAction(
    '[Adopter] Load Adopted by Error',
    props<{message: string}>()
)

export const UpdateAdopter = createAction(
    '[Adopter] Update Adopted by Status',
    props<{payload: UpdateAdopters}>(),
)

export const UpdateAdopterSuccess = createAction(
    '[Adopter] Update Adopted by Status Success',
    props<{adopterUpdates: any, message: string}>(),
)

export const UpdateAdopterError = createAction(
    '[Adopter] Update Adopted by Status Error',
    props<{message: string}>(),
)