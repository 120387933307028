<panjab-digi-lib-static-page-head
  pageName="Adopt A Book"
  pagePath="/adopt-a-book"
></panjab-digi-lib-static-page-head>
<section class="adoptbook mb-5">
  <div class="container">
    <div class="d-flex justify-content-center">
      <p class="text my-5">
        {{ 'pdl-website.adoptABook.headingText' | translate }}
      </p>
    </div>

    @if(currencyCode) {
    <p class="currency-info">
      {{ 'pdl-website.adoptABook.showingPricesin' | translate }}
      {{ currencyCode }} {{ 'pdl-website.adoptABook.for' | translate }}
      {{ selectedCountryName }}.
      <a href="#" (click)="openSwitchLocationModal($event)">{{
        'pdl-website.adoptABook.swtichLocation' | translate
      }}</a>
      {{ 'pdl-website.adoptABook.toViewDifferentPrice' | translate }}
    </p>
    }

    <div
      #resultsAndFilters
      class="results-and-filters webPagination__wrapper align-items-center justify-content-between mb-4"
      [ngClass]="{ 'd-flex': !isMobile }"
    >
      <div
        class="results-on-page d-flex flex-wrap align-items-center justify-content-start"
      >
        @if(documentList.length > 0 && !isLoading) {
        <span>{{ 'pdl-shared.pagination.showing' | translate }} </span>
        <span>{{ totalRecords > 0 ? (pageNo - 1) * pageSize + 1 : 0 }} </span>
        <span>{{ 'pdl-shared.pagination.to' | translate }}</span>
        <span
          >{{
            pageNo * pageSize < totalRecords ? pageNo * pageSize : totalRecords
          }}
        </span>
        <span>{{ 'pdl-shared.pagination.of' | translate }} </span>
        <span>{{ totalRecords }} </span>
        <span>{{ 'pdl-shared.pagination.entries' | translate }} </span>
        }
      </div>
      <div class="d-flex tool_wrapper flex-wrap row-gap-15">
        <div class="page-filters-dropdown">
          <div class="dropdown page-filter-dropdown">
            <a
              class="page-filter-action btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ 'pdl-admin.download.sortBy' | translate }}
            </a>

            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item cursor-pointer"
                  [ngClass]="sortBy === 'title' ? 'active-sort' : ''"
                  (click)="sortBooks('Title', 'ASC', 'title')"
                  >{{
                    'pdl-website.adoptABook.tableColumns.bookName' | translate
                  }}</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item cursor-pointer"
                  [ngClass]="sortBy === 'category' ? 'active-sort' : ''"
                  (click)="sortBooks('Category', 'ASC', 'category')"
                  >{{
                    'pdl-website.adoptABook.tableColumns.category' | translate
                  }}</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item cursor-pointer"
                  [ngClass]="sortBy === 'bookPrice' ? 'active-sort' : ''"
                  (click)="sortBooks('BookPrice', 'ASC', 'bookPrice')"
                  >{{
                    'pdl-website.adoptABook.tableColumns.budget' | translate
                  }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="search_with_glass_at_end padding-12">
          <label class="search-label" for="search"
            ><img src="assets/images/icons/search-icon.svg" alt="search"
          /></label>
          <input
            type="text"
            class="form-control search__input disabled"
            id="search"
            placeholder="Search By name, category..."
            (ngModelChange)="onSearchChange($event)"
            [(ngModel)]="searchText"
          />
        </div>
        <span class="btn btn-default btn__secondary p-0 ms-3 filter__wrapper">
          <select
            class="form-select sort__filter"
            aria-label="Default select example"
            [(ngModel)]="selectedCategoryID"
            (change)="onCategoryChange($event)"
          >
            <option value="">
              {{ 'pdl-shared.content.allCategory' | translate }}
            </option>
            @for(category of categories; track category.categoryID) {
            <option [value]="category.categoryID">{{ category.Name }}</option>
            }
          </select>
        </span>
      </div>
    </div>

    <button
      [routerLink]="['/adopt-a-book/checkout']"
      class="btn btn__primary checkBtn"
      [disabled]="cartList.length == 0"
    >
      {{ 'pdl-website.adoptABook.checkout' | translate }}
    </button>

    <!-- // table for desktop  -->
    <div class="table-responsive mt-4 desktop-only">
      <table
        class="adopt_a_book_table table"
        aria-describedby=""
        [ngClass]="{ 'data-loading': isLoading }"
      >
        <thead class="">
          <tr class="">
            <th>
              {{
                'pdl-website.adoptABook.tableColumns.bookThumbnail' | translate
              }}
            </th>
            <th>
              {{ 'pdl-website.adoptABook.tableColumns.bookName' | translate }}
            </th>
            <th>
              {{ 'pdl-website.adoptABook.tableColumns.category' | translate }}
            </th>
            <th>
              {{ 'pdl-website.adoptABook.tableColumns.budget' | translate }}
            </th>
            <th>
              {{ 'pdl-website.adoptABook.tableColumns.adoption' | translate }}
            </th>
          </tr>
        </thead>
        @if(isLoading) {
        <panjab-digi-lib-spinner
          [isFullScreen]="false"
        ></panjab-digi-lib-spinner>
        } @else {
        <tbody>
          @for(document of documentList; track document.DocID; let index=$index)
          {
          <tr class="">
            <td>
              <img
                class="thumbnail"
                [src]="document.Thumbnails['90']"
                alt="adopt"
                onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
              />
            </td>
            <td>
              <div class="mb-3">
                <a
                  class="doc__link"
                  [href]="'/metadata/' + document.DocID"
                  target="_blank"
                >
                  <b>{{ document.Title }}</b>
                </a>
              </div>
              <div class="desc">
                <span class="text__ellipse text-wrap text-break">{{
                  document.Description
                }}</span>
              </div>
            </td>
            <td>{{ document.Category.Name }}</td>
            <td>
              {{ document.BookPrice | currency : currencyCode }}
            </td>
            <td>
              <button
                class="adopt_btn btn me-2"
                [ngClass]="{
                  'btn-secondary removeBtn': document.isInCart,
                  'btn__primary addBtn': !document.isInCart
                }"
                (click)="updateCart(document)"
              >
                @if(isUpdatingCart && document.DocID == updatingCartWithDocID) {
                <div class="spinner-border" role="status"></div>
                } @else {
                {{
                  document.isInCart
                    ? ('pdl-website.adoptABook.removeFromCart' | translate)
                    : ('pdl-website.adoptABook.addToCart' | translate)
                }}
                }
              </button>
              @if(!document.isInCart) {
              <a
                class="adopt_btn btn btn__primary"
                (click)="adoptNow(document, index)"
              >
                @if(document.isAdoptingNow) {
                <div class="spinner-border" role="status"></div>
                } @else {
                {{ 'pdl-website.adoptABook.adoptNow' | translate }}
                }
              </a>
              }
            </td>
          </tr>
          } @empty {
          <tr>
            <td colspan="5" class="text-center">
              <p>{{ 'pdl-shared.content.noRecordsFound' | translate }}</p>
            </td>
          </tr>
          }
        </tbody>
        }
      </table>
      <div
        class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4"
      >
        <ngb-pagination
          class="donations__pagination"
          [collectionSize]="totalRecords"
          [(page)]="pageNo"
          [pageSize]="pageSize"
          [rotate]="true"
          [maxSize]="maxSize"
          [ellipses]="true"
          [rotate]="true"
          [boundaryLinks]="true"
          [directionLinks]="true"
          (pageChange)="getBooksForAdoption()"
        />
      </div>
    </div>

    <!-- lsiting for mobile -->
    <!-- // item 1 -->
    <div class="listWrapper adopt_a_book_table no-scrollbar mb-1 mob-only">
      @if(isLoading) {
      <div class="loader-wrappper pb-5 mb-5">
        <panjab-digi-lib-spinner
          [isFullScreen]="false"
        ></panjab-digi-lib-spinner>
      </div>
      } @else { @for(document of documentList; track document.DocID; let
      index=$index) {
      <div class="list">
        <div class="image">
          <img
            class="thumbnail"
            [src]="document.Thumbnails['90']"
            alt="adopt"
            onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
          />
        </div>
        <div class="meta-data">
          <div class="d-flex flex-wrap col-gap-12">
            <div class="meta-btn">
              <span>
                {{ document.Category.Name }}
              </span>
            </div>
          </div>

          <div class="name mt-3">{{ document.Title }}</div>
          <div class="desc">
            <span class="text__ellipse text-wrap text-break">{{
              document.Description
            }}</span>
          </div>
          <div>
            {{ document.BookPrice | currency : currencyCode }}
          </div>
          <div>
            <!-- <a [routerLink]="['/adopt-a-book/checkout']" class="adopt_btn btn btn__primary">
                Adopt&nbsp;&nbsp; <img src="/assets/images/icons/adopt-book-icon.svg" alt="adopt"/>
              </a> -->
            <button
              class="adopt_btn btn my-2 me-2"
              [ngClass]="{
                'btn-secondary removeBtn': document.isInCart,
                'btn__primary addBtn': !document.isInCart
              }"
              (click)="updateCart(document)"
            >
              @if(isUpdatingCart && document.DocID == updatingCartWithDocID) {
              <div class="spinner-border" role="status"></div>
              } @else {
              {{
                document.isInCart
                  ? ('pdl-website.adoptABook.removeFromCart' | translate)
                  : ('pdl-website.adoptABook.addToCart' | translate)
              }}
              }
            </button>
            @if(!document.isInCart) {
            <a
              class="adopt_btn btn btn__primary"
              (click)="adoptNow(document, index)"
            >
              @if(document.isAdoptingNow) {
              <div class="spinner-border" role="status"></div>
              } @else {
              {{ 'pdl-website.adoptABook.adoptNow' | translate }}
              }
            </a>
            }
          </div>
        </div>
      </div>
      } @empty {
      <p class="text-center">
        {{ 'pdl-shared.content.noRecordsFound' | translate }}
      </p>
      } }
      <div
        class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4"
      >
        <ngb-pagination
          class="donations__pagination"
          [collectionSize]="totalRecords"
          [(page)]="pageNo"
          [pageSize]="pageSize"
          [rotate]="true"
          [maxSize]="maxSizeMobile"
          [ellipses]="true"
          [boundaryLinks]="true"
          [directionLinks]="true"
          (pageChange)="getBooksForAdoption()"
        />
      </div>
    </div>
  </div>
</section>

<ng-template #switchLocationModal>
  <div class="action__form mt-2 m-auto">
    <form [formGroup]="switchLocationForm">
      <div class="d-flex flex-direction-column align-items-start flex-column">
        <span class="btn btn-default btn__secondary p-0 ms-3 filter__wrapper">
          <select
            formControlName="country"
            class="form-select sort__filter"
            aria-label="Default select example"
          >
            @for(country of countryList; track country.CountryID) {
            <option [value]="country.Name">{{ country.Name }}</option>
            }
          </select>
        </span>
      </div>
    </form>
  </div>
</ng-template>
