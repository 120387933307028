<section class="video-gallery section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-4">{{'pdl-website.videogallerySection.sectionTitle'
                    | translate }}</h2>
                <p class="title-description text-center mb-4 pdl-grey">
                    {{'pdl-website.videogalleryDescription.descriptionText' | translate }}
                </p>
            </div>
            <div class="col-12">
                <div class="videos-in-grid">
                    <div class="row justify-content-center">

                        <div class="col-12">
                            <div class="pill-tabls-design text-center">

                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="vid1-pane" role="tabpanel"
                                        aria-labelledby="vid1" tabindex="0">

                                        <div class="video-block">
                                            <div class="video-title hide-on-video-hover d-flex justify-content-start">
                                                <a href="#" class="medium-font">{{(videoDetail.videoId) ?
                                                    videoDetail.videoTitle : ''}}</a>
                                            </div>
                                            @if(videoDetail.videoId) {
                                            <video width="100%" height="400px" controls
                                                poster="{{imageBaseUrl}}{{videoDetail.videoThumb}}" #video
                                                (timeupdate)="onVideoTimeUpdate(videoDetail.videoId)">
                                                <source src="{{videoDetail.videoUrl}}" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-tabs hidden-after-991">
                                    @for(video of videoList;track video?.ID;let i= $index) {
                                    @if(i > 0) {
                                    <li class="nav-item">
                                        <a [routerLink]="'video/'+ video.ID" class="nav-link">
                                            <div class="vid-trigger d-flex flex-column">
                                                <div class="thumbnail">
                                                    <img alt="thumbnail" src="{{imageBaseUrl}}{{video?.ThumbnailPath}}"
                                                        (error)="handleImageError($event)">
                                                </div>
                                                <div
                                                    class="text-thumb justify-content-start d-flex flex-column align-items-start">
                                                    <h6 class="medium-font pdl-title-color">
                                                        {{video?.Title }}
                                                    </h6>
                                                    <span
                                                        class="record-by pdl-light-grey font-12 font-regular d-flex align-items-center justify-content-start">{{'pdl-website.videoblockRecord1.recordText'
                                                        | translate }}
                                                        <span class="verified">
                                                            <img alt="icon" src="assets/images/icons/verified-ico.svg">
                                                        </span>
                                                    </span>
                                                    <span
                                                        class="reach pdl-light-grey font-12 font-regular d-flex align-items-center justify-content-start">
                                                        <span>{{video?.ViewCount}} views</span> <span
                                                            class="dot-span">.</span>
                                                        <span>{{video?.PublishedDate}}</span>
                                                    </span>
                                                </div>
                                            </div>

                                        </a>
                                    </li>
                                    }
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 ">
                @if (videoList.length) {
                <div class="videos-in-slider visible-below-991">
                    <ngx-slick-carousel class="carousel nav nav-tabs" id="myTab" role="tablist"
                        #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
                        @for (video of videoList;track video?.ID;let i= $index) {
                        @if(i>0) {

                        <div ngxSlickItem class="slide vidgallery-slide pe-4 " role="presentation">
                            <a href="#" class="nav-link">

                                <div class="vid-trigger d-flex flex-column">
                                    <div class="thumbnail">
                                        <img alt="thumbnail" src="{{imageBaseUrl}}{{video?.ThumbnailPath}}"
                                            (error)="handleImageError($event)">
                                    </div>
                                    <div class="text-thumb justify-content-start d-flex flex-column align-items-start">
                                        <h6 class="medium-font pdl-title-color">{{video?.Title }}</h6>
                                        <span
                                            class="record-by pdl-light-grey font-12 font-regular d-flex align-items-center justify-content-start">{{'pdl-website.videoblockRecord1.recordText'
                                            | translate }}
                                            <span class="verified">
                                                <img alt="icon" src="assets/images/icons/verified-ico.svg">
                                            </span>
                                        </span>
                                        <span
                                            class="reach pdl-light-grey font-12 font-regular d-flex align-items-center justify-content-start">
                                            <span>{{video?.ViewCount}} views</span> <span class="dot-span">.</span>
                                            <span>{{video?.PublishedDate}}</span>
                                        </span>
                                    </div>
                                </div>

                            </a>
                        </div>
                        }
                        }


                    </ngx-slick-carousel>
                </div>
                }
            </div>
            <div class="col-12">
                <div class="centered-btn d-flex align-items-center justify-content-center">
                    <a href="javascript:void(0);" [routerLink]="'/video-gallery'"
                        class="btn pdl-btn">{{'pdl-website.collectionsmainButton.buttonText' | translate }}</a>
                </div>
            </div>

        </div>
    </div>
</section>
