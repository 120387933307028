/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import {
  addScriptPayload,
  getScriptPayload,
  getScriptResponse,
  addScriptResponse,
  editScriptPayload,
  editScriptResponse,
  updateScriptStatusPayload,
  updateScriptStatusResponse,
  deleteScriptPayload,
  deleteScriptResponse,
  mergeScriptPayload,
  mergeScriptsResponse,
} from 'shared/src/interfaces/script.interface';
import { API_ENDPOINTS } from '../app.endpoints';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.masterForm.script;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
  ////////// load Scripts /////////
  loadScripts(payload: getScriptPayload) {
    let url = `${this.rootURL}${this.endPointUrl.default}`;
    url = url
      .replace('{pageSize}', (payload?.pageSize ?? 1).toString())
      .replace('{pageNumber}', (payload?.pageNumber ?? 1).toString())
      .replace('{sortBy}', payload?.sortBy ?? 'name');

    if (payload?.query) {
      url += `&query=${payload?.query}`;
    }
    return this.http
      .get<getScriptResponse>(url)
      .pipe(map((e: getScriptResponse) => e?.body));
  }
  ////////// add Script /////////
  addScript(payload: addScriptPayload) {
    const url = `${this.rootURL}${this.endPointUrl.script}`;
    return this.http
      .post<addScriptResponse>(url, payload)
      .pipe(map((e: addScriptResponse) => e));
  }

  ////////// update Script /////////
  updateScript(payload: editScriptPayload) {
    let url = `${this.rootURL}${this.endPointUrl.update}`;
    url = url.replace('{id}', (payload?.id ?? '').toString());
    return this.http
      .put<editScriptResponse>(url, { name: payload?.name })
      .pipe(map((e: editScriptResponse) => e));
  }

  ////////// update Script status/////////
  updateScriptStatus(payload: updateScriptStatusPayload) {
    const url = `${this.rootURL}${this.endPointUrl.script}`;
    return this.http
      .patch<updateScriptStatusResponse>(url, payload)
      .pipe(map((e: updateScriptStatusResponse) => e));
  }

  ////////// delete Script/////////
  deleteScript(payload: deleteScriptPayload) {
    const url = `${this.rootURL}${this.endPointUrl.script}`;
    return this.http
      .delete<deleteScriptResponse>(url, { body: payload })
      .pipe(map((e: deleteScriptResponse) => e));
  }

  ////////// download script as excel /////////
  download() {
    return this.http
      .get(`${this.rootURL}${this.endPointUrl.download}`, {
        responseType: 'blob',
      })
      .pipe(map((data: Blob) => data));
  }

  ////////// merge Scripts /////////
  mergeScript(payload: mergeScriptPayload) {
    const url = `${this.rootURL}${this.endPointUrl.merge}`;
    return this.http
      .post<mergeScriptsResponse>(url, payload)
      .pipe(map((e: mergeScriptsResponse) => e));
  }
}
