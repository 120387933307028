<div class="filter-block">
   <div class="filter-title">
      <h5 class="medium-font">Saved Search</h5>
   </div>
   <div class="filter-tags filter-padding d-flex align-items-center justify-content-start flex-wrap">
      @for(search of filters['SavedSearches'].records;track search.SearchID) {
      <a (click)="openSavedSearch(search.Path)" class="filter-tag cursor-pointer"
         [ngClass]="{'active': search.Path === savedSearch}">
         {{search.Name}}
         @if( search.Path !== savedSearch) {
         <img alt="icon" src="assets/images/icons/remove-tag.svg" (click)="deleteSavedSearch(search.SearchID,$event)">
         }
      </a>
      } @empty {
      <span> No saved search yet</span>
      }
   </div>
   <div class="py-2">
      <label class="category-label ms-3 mt-2 mb-2">Select Catgeories</label>
      <div class="cat_dpdwn">
         <select class="form-control ms-3 category-dropdown" (change)="updateCategory($event)">
            <option [value]="0">All Catgeories</option>
            @for(category of filters['Categories'].records;track category.categoryID) {
            <option [value]="category?.categoryID" [selected]="isSelected(category?.categoryID)">{{category?.Name}}
            </option>
            }
         </select>
         <svg class="dp_arr" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4"
               d="M10 13.375C9.82422 13.375 9.66797 13.3164 9.55078 13.1992L5.80078 9.44922C5.54688 9.21484 5.54688 8.80469 5.80078 8.57031C6.03516 8.31641 6.44531 8.31641 6.67969 8.57031L10 11.8711L13.3008 8.57031C13.5352 8.31641 13.9453 8.31641 14.1797 8.57031C14.4336 8.80469 14.4336 9.21484 14.1797 9.44922L10.4297 13.1992C10.3125 13.3164 10.1562 13.375 10 13.375Z"
               fill="#7A8699" />
         </svg>
      </div>
   </div>
   <div class="border-div"></div>
</div>
<div class="filter-block">
   <div class="filter-title">
      <h5 class="medium-font">Advance Search</h5>
   </div>
   <div class="filter-radios filter-padding d-flex align-items-start justify-content-center flex-wrap flex-column">
      <div class="form-check">
         <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
            (change)="includeTextSearch(false)" [checked]="!textSearch">
         <label class="form-check-label" for="flexRadioDefault1">
            Search in Metadata Only
         </label>
      </div>
      @if(filters['Categories'] && filters['Categories'].allowedOCR) {
      <div class="form-check">
         <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
            (change)="includeTextSearch(true)" [checked]="textSearch">
         <label class="form-check-label" for="flexRadioDefault2">
            Include OCR Data
         </label>
      </div>
      }
   </div>
   <div class="border-div"></div>

</div>
<div class="filter-block">
   <div class="filter-expanding filter-padding d-flex  justify-content-start  w-100">
      @for(key of getFilterKeys();track key) {
      @if(key !== 'Categories' && key !== 'SavedSearches') {
      @if((key == 'Content search for' || key == 'ExcludedWords') && !textSearch) {
      } @else {
      <div class="accordion" [id]="removeSpace(key)">
         <div class="accordion-item">
            <h2 class="accordion-header">

               @if((key == 'Content search for' || key == 'ExcludedWords') && textSearch) {
               <button class="accordion-button 1" type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse-'+ (key === 'Content search for' ?  'content' : removeSpace(key))"
                  aria-expanded="true" [attr.aria-controls]="'collapse-content'">
                  {{key}}
               </button> }
               @else if(key != 'Content search for' && key != 'ExcludedWords') {
               <button class="accordion-button" type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse-' + removeSpace(key)" aria-expanded="true"
                  [attr.aria-controls]="'collapse-' + removeSpace(key)">
                  {{key}}
                  <!-- {{filters[key]?.type === 'number' ? getFilterCountText(filters[key]?.records || 0) : ''}} -->
               </button>
               }

            </h2>
            <div [id]="key === 'Content search for' ?  'collapse-content' : 'collapse-' + removeSpace(key)"
               class="accordion-collapse collapse show"
               [attr.data-bs-parent]="'#' + key === 'Content search for' ?  'content' : removeSpace(key)">

               <div class="accordion-body">
                  @if(filters[key]?.type === 'text') {
                  <ng-container>
                     @if((key == 'Content search for' || key == 'ExcludedWords') && textSearch) {
                     <input type="text" class="form-control" [id]="'content-text'" aria-describedby="textHelp"
                        [value]="filters[key].records" #textInput (blur)="filterUpdation(key, textInput.value)">
                     }
                     @else if((key != 'Content search for' && key != 'ExcludedWords')) {
                     <input type="text" class="form-control" [id]="key + '-text'" aria-describedby="textHelp"
                        [value]="filters[key].records" #textInput (blur)="filterUpdation(key, textInput.value)">
                     }
                  </ng-container>

                  }
                  @if(filters[key]?.type === 'number') {
                  <ng-container>
                     <div class="slider-container">
                        <div class="slider-value-container">
                           <div class="slider-value custom-slider"
                              [ngStyle]="{ left: (((filters[key]?.records && isArray(filters[key]?.records) ? filters[key]?.records[1] : (filters[key]?.records || 0)) / ((filters[key]?.range[1] || 0) - (filters[key]?.range[0] || 0))) * 100) + '%'  }">
                              {{
                              (filters[key]?.records ? setRangeValue(filters[key]?.records) : 0)
                              }} {{key!== 'Total Pages' ? 'cm' :''}} 
                           </div>
                        </div>
                        <div class="d-flex gap-20 align-items-center">
                           <input type="range" class="custom-slider" [id]="key + '-range'"
                              [min]="filters[key]?.range[0] || 0"
                              [max]="filters[key]?.range[1] || 100"
                              [value]="filters[key]?.records && isArray(filters[key]?.records) ? filters[key]?.records[1] : (filters[key]?.records || 0)"
                              [style.background]="
                                 'linear-gradient(to right, #94B19E ' +
                                 (((filters[key]?.records && isArray(filters[key]?.records) ? filters[key]?.records[1] : (filters[key]?.records || 0)) - (filters[key]?.range[0] || 0)) /
                                 ((filters[key]?.range[1] || 100) - (filters[key]?.range[0] || 0)) * 100) +
                                 '%, #ddd ' +
                                 (((filters[key]?.records && isArray(filters[key]?.records) ? filters[key]?.records[1] : (filters[key]?.records || 0)) - (filters[key]?.range[0] || 0)) /
                                 ((filters[key]?.range[1] || 100) - (filters[key]?.range[0] || 0)) * 100) +
                                 '%)'
                              "
                              (input)="onRangeChange(key, $event)">

                           <img class="cross-icon" (click)="removeKeyFromUrl(key)"
                              src="assets/images/icons/cross-close-icon.svg">
                        </div>
                     </div>
                  </ng-container>
                  }
                  @if(filters[key]?.type === 'date') {
                  <ng-container>
                     <div class="d-flex mt-2">
                        <select class="form-control dropdown-text" (change)="onFromYearChange($event, key)"
                           [value]="dateSelections[key] ? dateSelections[key].fromYear : null">
                           <option value="null">From Year</option>
                           <option *ngFor="let year of years" [value]="year"
                              [selected]="dateSelections[key] && year === dateSelections[key].fromYear">{{ year }}
                           </option>
                        </select>
                        <select class="form-control ms-3 dropdown-text" (change)="onToYearChange($event, key)"
                           [value]="dateSelections[key] ? dateSelections[key].toYear : null">
                           <option value="null">To Year</option>
                           <option *ngFor="let year of years" [value]="year"
                              [selected]="dateSelections[key] && year === dateSelections[key].toYear">
                              {{ year }}
                           </option>
                        </select>
                     </div>
                  </ng-container>
                  }
                  @if(filters[key]?.type === 'custom' || filters[key]?.type === 'findMapping') {
                  <div class="mt-3">
                     @for(item of filters[key]?.records; track item) {
                     <div class="text-start form-check">
                        <input type="checkbox" class="form-check-input" value="{{item.id ? item.id : item.value}}"
                           [checked]="item?.isSelected"
                           (change)="item.id ? (queryUpdation(key,item?.id,item.value,$event)) : nonDependentQueryUpdation(key, item.value,$event)">
                        <label> {{item?.value}}</label>
                     </div>
                     }
                     @if(filters[key]?.totalCount > 10) {
                     <span class="cursor-pointer see-more" (click)="openModal(key,filters[key])">see more</span>
                     }
                  </div>

                  }
               </div>
            </div>
         </div>
      </div>
      <div class="border-div"></div>
      }
      }
      }
      <div class="fix-section">
         <div class="accordion mb-4" id="typeaccordion">
            <div class="accordion-item">
               <h2 class="accordion-header">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                     data-bs-target="#collapsetype" aria-expanded="true" aria-controls="collapseTwo">
                     Type
                  </button>
               </h2>
               <div id="collapsetype" class="accordion-collapse collapse show" data-bs-parent="#typeaccordion">
                  <div class="accordion-body">
                     <div
                        class="filter-radios checked-radios d-flex align-items-center justify-content-start flex-wrap">
                        <div class="form-check mb-0 me-4">
                           <input class="form-check-input" type="radio" name="flexRadioType" id="flexRadioDefault3"
                              (change)="updateStrictMode('true')" [checked]="strictMode === 'true'">
                           <label class="form-check-label" for="flexRadioDefault3">
                              Exact
                           </label>
                        </div>
                        <div class="form-check mb-0">
                           <input class="form-check-input" type="radio" name="flexRadioType" id="flexRadioDefault4"
                              (change)="updateStrictMode('false')" [checked]="strictMode === 'false'">
                           <label class="form-check-label" for="flexRadioDefault4">
                              Any
                           </label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
            <a (click)="clearAllFilter()"
               class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start me-3 font-14">Clear
               All</a>
            @if(isUserLoggedIn) {
            <a (click)="openSaveSearchModal()"
               class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start font-14">Save</a>
            }
         </div>
      </div>
   </div>
</div>


<ng-template #saveSearchModal>
   <div class="action__form mt-2 m-auto">
      <form [formGroup]="saveSearchForm">
         <div class="d-flex flex-direction-column align-items-start flex-column">
            <label class="semibold mb-2">{{'pdl-website.forms.advancedSearch.name' | translate}}</label>
            <input type="text" maxlength="30" class="form-control search__input" formControlName="name"
               id="exampleFormControlInput1" placeholder="{{'pdl-website.forms.advancedSearch.name' | translate}}" />
            @if(saveSearchForm.get('name')?.errors?.['required'] && savedSearchFormError) {
            <p class="err__msg">{{'pdl-shared.formValidationMsgs.required' | translate}}</p>
            }
            @if(errorMessage && !saveSearchForm.get('name')?.errors?.['required']) {
            <p class="err__msg">{{errorMessage}}</p>
            }
         </div>
      </form>
   </div>
</ng-template>
