/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'shared/src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState, WebHomePageActions } from '@panjab-digi-lib/shared';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { VideoService } from 'shared/src/services/website/videos/video.service';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import { WebVideoWithRelatedVideos } from 'shared/src/interfaces/video-gallery.interface';

@Component({
  selector: 'panjab-digi-lib-video-view',
  standalone: true,
  imports: [CommonModule, TranslateModule,RouterLink],
  templateUrl: './video-view.component.html',
  styleUrl: './video-view.component.scss',
})
export class VideoViewComponent implements OnInit, OnDestroy{
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  subscription = new Subscription();
  videoId!: number;
  videoDetail! : WebVideoWithRelatedVideos;
  lastPlayedVideoId! : number;
  @ViewChild('video') videoElement: any;
  updateCountApi: boolean = false;

  constructor(
    private store: Store<AppState>,
    private router : Router,
    private activatedRoute : ActivatedRoute,
    private service: VideoService,
    readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const newId = params.get('id');
      if(newId) {
        if(!this.videoId) {
          this.videoId = parseInt(newId)
        } else if (parseInt(newId) !== this.videoId) {
        this.videoId = parseInt(newId);
        this.loadVideo(this.videoId);
      }
       }
    });
    this.loadVideo(this.videoId);
  }

  //////////// get video url and related videos ////////////////
  loadVideo(videoId: number) {
    this.subscription.add(this.service.loadVideo(videoId).subscribe({
      next: (data) => {
        this.videoDetail = data;
      }, error: (error) => {
        this.toastService.showError(error || String(APP_CONSTANTS.defaultErrMsg));
      }
    }))
  }


  onVideoPlay(videoId: number) {
    if (this.lastPlayedVideoId === videoId) {
      return;
    }
    this.lastPlayedVideoId = videoId;
  
  }
  ////////////////// update count on video play and if video played for 20 sec ///////////////

  onVideoTimeUpdate(videoId: number) {
    const video = this.videoElement.nativeElement;
    if (video.currentTime >= 20 && !this.updateCountApi) {
      this.subscription.add(
        this.store.dispatch(
          WebHomePageActions.UpdateVideoCount({ payload: videoId })
        )
      );
      this.updateCountApi = true;
   }
  }

  //////////// load video on click on related videos /////////

  loadClickedVideo(videoId:number) {
    this.updateCountApi = false;
    const videoElement: HTMLVideoElement = this.videoElement.nativeElement;
    videoElement.pause();
    videoElement.currentTime = 0; 
    this.router.navigate(['video/' + videoId]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
