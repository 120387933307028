/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { Banner, HomeCategories, Videos, Keywords, Video } from 'shared/src/interfaces/homepage.interface';

export const LoadWebBanners = createAction(
    '[Bannners] Load Website Banners'
);

export const LoadWebBannersSuccess = createAction(
    '[Bannners] Load Website Banners Success',
    props<{ banners: Banner[] }>()
);

export const LoadWebBannersError = createAction(
    '[Bannners] Load Website Banners Error',
    props<{ message: string }>()
);

export const LoadWebTrendingKeywords = createAction(
    '[TrendingKeywords] Load Website Trending Keywords'
);

export const LoadWebTrendingKeywordsSuccess = createAction(
    '[TrendingKeywords] Load Website Trending Keywords Success',
    props<{ trendingKeywords: Keywords[], historicKeywords: Keywords[],userSearches : Keywords[] }>()
);

export const LoadWebTrendingKeywordsError = createAction(
    '[TrendingKeywords] Load Website Trending Keywords Error',
    props<{ message: string }>()
);

export const LoadWebCategories = createAction(
    '[Categories] Load Website Categories'
);

export const LoadWebCategoriesSuccess = createAction(
    '[Categories] Load Website Categories Success',
    props<{ categories: HomeCategories[]  }>()
);

export const LoadWebCategoriesError = createAction(
    '[Categories] Load Website Categories Error',
    props<{ message: string }>()
);

export const LoadWebHomepageVideos = createAction(
    '[Hompage Videos] Load Website Homepage videos'
);

export const LoadWebHomepageVideosSuccess = createAction(
    '[Hompage Videos] Load Website Homepage videos Success',
    props<{ videos: Video[]  }>()
);

export const LoadWebHomepageVideosError = createAction(
    '[Hompage Videos] Load Website Homepage videos Error',
    props<{ message: string }>()
);

export const UpdateVideoCount = createAction(
    '[Hompage Videos] Update video views',
    props<{ payload: number}>()
);

export const UpdateVideoCountSuccess = createAction(
    '[Hompage Videos] Update video views Success',
    props<{ message: string  }>()
);

export const UpdateVideoCountError = createAction(
    '[Hompage Videos] Update video views Error',
    props<{ message: string }>()
);


