import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { loadDocumentFiles, loadDocumentFilesError, loadDocumentFilesSuccess } from '../../actions/website/document-files.actions';
import { DocumentFilesService } from 'shared/src/services/website/metadata/document-files.service';

@Injectable()
export class DocumentFilesEffects {
  constructor(private actions$: Actions, private documentFilesService: DocumentFilesService) {}

  loadDocumentFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDocumentFiles),
      exhaustMap((action) =>
        this.documentFilesService.getFilesByDocId(action.docID, action.systemTime).pipe(
          map((response: any) => loadDocumentFilesSuccess({
            pdfFiles: response.body.data.pdf,
            imageFiles: response.body.data.images,
          })),
          catchError((error) =>
            of(loadDocumentFilesError({ message: error.message }))
          )
        )
      )
    )
  );
}
