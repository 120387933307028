import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { AdminCuratedCollectionActions } from '../actions';
import { GenericListingPayloadWithSearch } from 'shared/src/interfaces/common.interface';
import { CuratedCollection } from "shared/src/interfaces/curated-collection.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayloadWithSearch,
    totalRecords: number,
    isLoading?: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<CuratedCollection>({
    selectId: (e) => e.CollectionID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(AdminCuratedCollectionActions.LoadCuratedCollectionList, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AdminCuratedCollectionActions.LoadCuratedCollectionListSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.curatedCollection, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AdminCuratedCollectionActions.LoadCuratedCollectionListError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(AdminCuratedCollectionActions.ChangeCollectionStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AdminCuratedCollectionActions.ChangeCollectionStatusSuccess, (state, action) =>
    ({
        ...adapter.updateOne({id: action.curatedCollection.CollectionID, changes: action.curatedCollection}, state),
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(AdminCuratedCollectionActions.ChangeCollectionStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(AdminCuratedCollectionActions.AddCuratedCollection, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(AdminCuratedCollectionActions.AddCuratedCollectionSuccess, (state, action) =>
    ({
        ...adapter.addOne(action.curatedCollection, state),
        totalRecords: state.totalRecords + 1,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(AdminCuratedCollectionActions.AddCuratedCollectionError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(AdminCuratedCollectionActions.EditCuratedCollection,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(AdminCuratedCollectionActions.EditCuratedCollectionSuccess,(state, action) =>
     ({
        ...adapter.updateOne({id: action.curatedCollection.CollectionID, changes: action.curatedCollection}, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(AdminCuratedCollectionActions.EditCuratedCollectionError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();