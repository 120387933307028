// @ts-ignore
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  rootURL = '';
  rootAppURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
    this.rootAppURL = this.rootURL + environment.appApiPrefix;
  }

  getMetaData = (DocID: string) => {
    return this.http.get(`${this.rootAppURL}documentInfo/${DocID}`);
  };

  updateDocViewCount = (DocID: number) => {
    return this.http.patch(`${this.rootAppURL}updateDocument`, { DocID });
  };

  getDocumentsByTopics = (
    topics: string,
    excludedAccessionNumbers?: string
  ) => {
    return this.http.get(
      `${this.rootAppURL}getDocumentsByTopics?topic=${topics}${
        excludedAccessionNumbers
          ? '&excludedAccessionNumbers=' + excludedAccessionNumbers
          : ''
      }`
    );
  };

  getDocuments = ({
    publishYear,
    categoryID,
    limit,
    accession_number,
    excludedAccessionNumbers,
  }: {
    publishYear?: string;
    categoryID?: string;
    limit?: string;
    accession_number?: string;
    excludedAccessionNumbers?: string;
  }) => {
    let queryParams = [];
    if (publishYear) {
      queryParams.push(`publishYear=${publishYear}`);
    }
    if (categoryID) {
      queryParams.push(`categoryID=${categoryID}`);
    }
    if (limit) {
      queryParams.push(`limit=${limit}`);
    }
    if (accession_number) {
      queryParams.push(`accession_number=${accession_number}`);
    }
    if (excludedAccessionNumbers) {
      queryParams.push(`excludedAccessionNumbers=${excludedAccessionNumbers}`);
    }
    return this.http.get(
      `${this.rootAppURL}documentInfo/?${queryParams.join('&')}`
    );
  };

  getMetadataComments = (accession_number: string, page_number: number) => {
    return this.http.get(
      `${this.rootAppURL}metadataComments/${accession_number}?orderBy=DateAdded&order=desc&pageNumber=${page_number}&pageSize=3`
    );
  };

  setComment = (comment: string, accession_number: string) => {
    return this.http.post(`${this.rootAppURL}metadataComments/setComment`, {
      comment,
      accession_number,
    });
  };

  setAsResearch = (docID: string, removeFromResearch = false) => {
    if (removeFromResearch) {
      return this.http.delete(
        `${this.rootAppURL}library/removeDocument/${docID}`
      );
    }
    return this.http.post(`${this.rootAppURL}library/addDocument/${docID}`, {});
  };

  getResearches = (docID: string) => {
    return this.http.get(`${this.rootAppURL}library/Document/get/${docID}`);
  };
  generateLeaderFromJson = (jsonData: any) => {
    // https://www.loc.gov/marc/bibliographic/bdleader.html
    const recordLength = 24 + JSON.stringify(jsonData).length; // Estimate record length
    const recordStatus = 'n'; // New record
    const typeOfRecord = 'a'; // Type 'a' for language material (book, etc.)
    const bibliographicLevel = 'm'; // Monograph/item
    const controlType = ' '; // Control type (blank for general)
    const characterCodingScheme = 'a'; // 'a' for UTF-8 encoding
    const indicatorCount = '2'; // MARC standard
    const subfieldCodeLength = '2'; // MARC standard
    const baseAddress = String(24).padStart(5, '0'); // 24 (length of the leader)
    const encodingLevel = ' '; // Undefined
    const descriptiveCatalogingForm = ' '; // Undefined
    const multipartResource = ' '; // Undefined
    const lengthOfFieldPortion = '4500'; // Standard value

    // Build the leader
    const leader =
      String(recordLength).padStart(5, '0') +
      recordStatus +
      typeOfRecord +
      bibliographicLevel +
      controlType +
      characterCodingScheme +
      baseAddress +
      indicatorCount +
      subfieldCodeLength +
      encodingLevel +
      descriptiveCatalogingForm +
      multipartResource +
      lengthOfFieldPortion;

    return leader;
  };

  downloadXML = (marcxml: any, fileName: string = 'output.xml') => {
    const blob = new Blob([marcxml], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  loadMarcJSandConvert = (jsonMetadata: any, json: any) => {
    let marcXML = '<?xml version="1.0" encoding="UTF-8"?>\n';
    marcXML += '<record xmlns="http://www.loc.gov/MARC21/slim">\n';

    // Leader
    marcXML += `  <leader>${this.generateLeaderFromJson(
      jsonMetadata
    )}</leader>\n`;

    // Data fields
    for (const tag in json) {
      const field = json[tag];
      marcXML += `  <datafield tag="${tag}" ind1="${field.ind1}" ind2="${field.ind2}">\n`;
      for (const code in field.subfields) {
        marcXML += `    <subfield code="${code}">${field.subfields[code]}</subfield>\n`;
      }
      marcXML += `  </datafield>\n`;
    }

    marcXML += '</record>';
    this.downloadXML(marcXML, `${jsonMetadata.title}_MARC.xml`);
  };

  getOrdinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  getMLAFormat = (metadata: any, countryList: any) => {
    let mlaFormat = '';
    let authorNames: any = [];

    metadata.Author.forEach((author: any) => {
      let authorNameArr = author.FName.split(-1);
      const LName = authorNameArr.length > 1 ? authorNameArr.splice(-1) : '';
      const FName = authorNameArr.join(' ');
      authorNames.push([
        LName + (metadata.Author.length > 1 ? ' ' : ', ') + FName + '.',
      ]);
    });
    mlaFormat = authorNames.join(', ');
    if (metadata?.PublisherYear) {
      mlaFormat = mlaFormat + ` (${metadata.PublisherYear})`;
    }
    if (metadata?.Title) {
      mlaFormat = mlaFormat + ` <i>${metadata.Title}</i>`;
    }
    if (metadata?.Edition) {
      mlaFormat = mlaFormat + ` ${this.getOrdinal(metadata?.Edition)} ed.`;
    }
    if (metadata?.translatorsInfo?.length) {
      const translatorNames: any = [];
      metadata.translatorsInfo.forEach((translator: any) => {
        let translatorNameArr = translator.FName.split(-1);
        const LName =
          translatorNameArr.length > 1 ? translatorNameArr.splice(-1) : '';
        const FName = translatorNameArr.join(' ').trim();
        translatorNames.push([FName + LName + '.']);
      });
      mlaFormat = mlaFormat + ` (Translated by ${translatorNames.join(', ')})`;
    }
    if (metadata?.publisher?.length) {
      const publishers: any = [];
      metadata.publisher.forEach((publisher: any) => {
        publishers.push([`${publisher.PubName}`]);
      });
      mlaFormat = mlaFormat + ` ${publishers.join(', ')}.`;
    }
    mlaFormat =
      mlaFormat +
      ` Pdf. Retrieved from Panjab Digital Library < ${window.location.href.replace(
        window.location.protocol + '//',
        ''
      )} >`;
    return mlaFormat;
  };

  getChicagoFormat = (metadata: any, countryList: any) => {
    let chicagoFormat = '';
    let authorNames: any = [];
    metadata.Author.forEach((author: any) => {
      let authorNameArr = author.FName.split(-1);
      const LName = authorNameArr.length > 1 ? authorNameArr.splice(-1) : '';
      const FName = authorNameArr.join(' ');
      authorNames.push([
        LName + (metadata.Author.length > 1 ? ' ' : ', ') + FName + '.',
      ]);
    });
    chicagoFormat = authorNames.join(', ');
    if (metadata?.PublisherYear) {
      chicagoFormat = chicagoFormat + ` (${metadata.PublisherYear})`;
    }
    if (metadata?.Title) {
      chicagoFormat = chicagoFormat + ` <i>${metadata.Title}</i>`;
    }
    if (metadata?.Edition) {
      chicagoFormat =
        chicagoFormat + ` ${this.getOrdinal(metadata?.Edition)} ed.`;
    }
    if (metadata?.translatorsInfo?.length) {
      const translatorNames: any = [];
      metadata.translatorsInfo.forEach((translator: any) => {
        let translatorNameArr = translator.FName.split(-1);
        const LName =
          translatorNameArr.length > 1 ? translatorNameArr.splice(-1) : '';
        const FName = translatorNameArr.join(' ').trim();
        translatorNames.push([FName + LName + '.']);
      });
      chicagoFormat =
        chicagoFormat + ` (Translated by ${translatorNames.join(', ')})`;
    }
    if (metadata?.publisher?.length) {
      const publishers: any = [];
      metadata.publisher.forEach((publisher: any) => {
        publishers.push([
          `${publisher.State.trim() ? publisher.State + ', ' : ''} ${
            countryList.find(
              (country: any) => country.CountryID === publisher.CountryID
            ).Name ?? ''
          }: ${publisher.PubName}`,
        ]);
      });
      chicagoFormat = chicagoFormat + ` ${publishers.join(', ')}.`;
    }
    chicagoFormat = chicagoFormat + '  [Pdf] ' + window.location.href;
    return chicagoFormat;
  };
  getAPAFormat = (metadata: any, countryList: any): any => {
    let apaFormat = '';
    let authorNames: any = [];
    metadata.Author.forEach((author: any) => {
      let authorNameArr = author.FName.split(-1);
      const LName = authorNameArr.length > 1 ? authorNameArr.splice(-1) : '';
      const FName = authorNameArr.join(' ');
      authorNames.push([
        LName + (metadata.Author.length > 1 ? ' ' : ', ') + FName[0] + '.',
      ]);
    });
    apaFormat = authorNames.join(', ');
    if (metadata?.PublisherYear) {
      apaFormat = apaFormat + ` (${metadata.PublisherYear})`;
    }
    if (metadata?.Title) {
      apaFormat = apaFormat + ` <i>${metadata.Title}</i>`;
    }
    if (metadata?.Edition) {
      apaFormat = apaFormat + ` (${metadata?.Edition} ed.).`;
    }
    if (metadata?.Translator?.length) {
      const translatorNames: any = [];
      metadata.translatorsInfo.forEach((translator: any) => {
        let translatorNameArr = translator.FName.split(-1);
        const LName =
          translatorNameArr.length > 1 ? translatorNameArr.splice(-1) : '';
        const FName = translatorNameArr.join(' ').trim();
        translatorNames.push([
          LName +
            (metadata.translatorsInfo.length > 1 ? ' ' : ', ') +
            FName[0] +
            '.',
        ]);
      });
      apaFormat = apaFormat + ` (${translatorNames.join(', ')} Trans.)`;
    }
    if (metadata?.publisher?.length) {
      const publishers: any = [];
      metadata.publisher.forEach((publisher: any) => {
        publishers.push([
          `${publisher.State.trim() ? publisher.State + ', ' : ''} ${
            countryList.find(
              (country: any) => country.CountryID === publisher.CountryID
            ).Name ?? ''
          }: ${publisher.PubName}`,
        ]);
      });
      apaFormat = apaFormat + ` ${publishers.join(', ')}.`;
    }
    apaFormat =
      apaFormat +
      '  [Pdf] Retrieved from Panjab Digital Library ' +
      window.location.href;
    return apaFormat;
  };
}
