export const STAFF_API_ENDPOINTS = {
  auth: {
    resetPassword: 'resetPassword',
    updatePassword: 'updatePassword'
  },
  staffList: {
    default: 'staffList',
    download: 'staffList/download'
  },
  document: {
    list: 'document/list',
    getDocumentByDocID: 'document/{DocID}',
    getDocumentByTaskID: 'document/task/{PSID}',
    submitForApproval: 'document/submitForApproval',
  },
  task: {
    list: 'task/list/{DocID}',
    taskByID: 'task/{PSID}',
    staffWork: 'task/pendingApproval',
    assignedTasklist: 'task/assigned',
    staffTaskList: 'task/staff/{StaffID}',
    tasksAssigneeList: 'task/assigneeList/{DocID}',
    assignTask: 'task/assignTask',
    approveTask: 'task/approve',
    submitTask: 'task/submitTask',
    bypassTask: 'task/bypass',
    rejectTask: 'task/reject',
    pendingApprovalTasksDownload: 'task/pendingApproval/download',
    upload: {
      default: 'task/upload',
      getConfig: 'task/upload/config',
      checkPdfPwdExist: 'task/upload/checkPdfPwdExist',
      deleteAllFiles: 'task/upload/deleteAllFiles',
      savePDFPassword: 'task/upload/savePDFPassword'
    }
  },
  accessionNumber: {
    get: 'getAccessionNo?preCode=',
    generateAccessionNumber: 'generateAccessionNumber'
  },
  permission : {
    default: 'permissions'
  },
  metadata : {
    default : 'metaData/detail/{id}',
    search: 'metaData/search?collection={collection}&searchField={searchField}&query={query}',
    edit: 'metaData/{id}',
    addNewFieldData: 'metaData/fieldData/add'

  }
}