import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, AdminCuratedCollectionActions, DonorSelector, AdminCuratedCollectionSelector } from '@panjab-digi-lib/shared';
import { CuratedCollectionService } from 'shared/src/services/admin/curated-collection/curated-collection.service';

@Injectable()
export class AdminCuratedCollectionEffects {
  defaultErrMsg = "Something went wrong! Please try again later.";
  constructor(
    private actions$: Actions,
    private curatedCollectionService: CuratedCollectionService,
    private router: Router,
    private store: Store<AppState>,
  ) {
  }

  LoadCuratedCollectionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminCuratedCollectionActions.LoadCuratedCollectionList),
      exhaustMap((action) => {
        return this.curatedCollectionService.getCuratedCollections(action.payload).pipe(
          map((response) => {
            return AdminCuratedCollectionActions.LoadCuratedCollectionListSuccess({ curatedCollection: response.collections, totalRecords: response.totalRecords });
          }),
          catchError((error: any) => of(AdminCuratedCollectionActions.LoadCuratedCollectionListError({ message: error?.error?.error?.message || this.defaultErrMsg }))
          ))
      })
    )
  );

  ChangeCollectionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminCuratedCollectionActions.ChangeCollectionStatus),
      exhaustMap((action) => {
        return this.curatedCollectionService.changeCollectionStatus(action.CollectionID, action.status).pipe(
          map((response) => {
            return AdminCuratedCollectionActions.ChangeCollectionStatusSuccess({ curatedCollection: response.body.collection, message: response.message });
          }),
          catchError((error: any) => of(AdminCuratedCollectionActions.ChangeCollectionStatusError({ message: error?.error?.error?.message || this.defaultErrMsg }))
          ))
      })
    )
  );

  AddCuratedCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminCuratedCollectionActions.AddCuratedCollection),
      exhaustMap((action) => {
        return this.curatedCollectionService.addCuratedCollection(action.payload).pipe(
          map((response) => {
            return AdminCuratedCollectionActions.AddCuratedCollectionSuccess({ curatedCollection: response.body.collection, message: response.message });
          }),
          catchError((error: any) => of(AdminCuratedCollectionActions.AddCuratedCollectionError({ message: error?.error?.error?.message || this.defaultErrMsg }))
          ))
      })
    )
  );

  EditCuratedCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminCuratedCollectionActions.EditCuratedCollection),
      exhaustMap((action) => {
        return this.curatedCollectionService.editCuratedCollection(action.payload, action.collectionID).pipe(
          map((response) => {
            return AdminCuratedCollectionActions.EditCuratedCollectionSuccess({ curatedCollection: response.body.updatedRecord, message: response.message });
          }),
          catchError((error: any) => of(AdminCuratedCollectionActions.EditCuratedCollectionError({ message: error?.error?.error?.message || this.defaultErrMsg }))
          ))
      })
    )
  );

  AddCollectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminCuratedCollectionActions.AddCuratedCollectionSuccess),
      tap((action) => {
        // Redirect to the add document route
        this.router.navigate(['/curated-collections', 'add-document', action.curatedCollection.CollectionID],
        {
          queryParams: {
            source: 'add-collection'
          }
        });
      }),
      map((action) => {
        return AdminCuratedCollectionActions.AutoLoadCuratedCollectionList();
      })
    )
  );

  EditCuratedCollectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminCuratedCollectionActions.EditCuratedCollectionSuccess),
      tap((action) => {
        this.router.navigate(['/curated-collections']);
      }),
    ),
    {dispatch: false}
  );

  AutoLoadCuratedCollectionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminCuratedCollectionActions.AutoLoadCuratedCollectionList),
      switchMap(() =>
        this.store.select(AdminCuratedCollectionSelector.getListingPayload).pipe(
          take(1),
          map((payload) => AdminCuratedCollectionActions.LoadCuratedCollectionList({ payload }))
        )
      )
    )
  );
}
