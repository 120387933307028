/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import { skip, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, WebUserLibraryActions, WebUserLibrarySelector } from '@panjab-digi-lib/shared';
import { WebUserLibraryService } from 'shared/src/services/website/user-library/user-library.service';
import { TopicDetail, Topics } from 'shared/src/interfaces/website/user-library.interface';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateEditTopicComponent } from '../create-edit-topic/create-edit-topic.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationModalService } from 'shared/src/services/confirmation-modal/confirmation-modal.service';
import { AssignLiteratureComponent } from '../assign-literature/assign-literature.component';
import { SpinnerComponent } from '@panjab-digi-lib/shared';

@Component({
  selector: 'panjab-digi-lib-my-research-topic-detail',
  standalone: true,
  imports: [
    CommonModule, RouterLink, AssignLiteratureComponent, SpinnerComponent,
    RouterOutlet, NgbPaginationModule, CreateEditTopicComponent, TranslateModule],
  templateUrl: './my-research-topic-detail.component.html',
  styleUrl: './my-research-topic-detail.component.scss',
})
export class MyResearchTopicDetailComponent implements OnInit, OnDestroy {
  pageNumber: number = 1;
  pageSize = APP_CONSTANTS.pageSize;
  fromRecords: number = 0;
  toRecords: number = 0;
  topicId!: number;
  topicDetail!: Topics['TopicDetail'];
  subscription = new Subscription();
  totalRecords: number = 0;
  maxPageNumber = APP_CONSTANTS.defaultPaginationSize;
  showNotes: { [key: string]: boolean } = {};
  editTopic: boolean = false;
  deleteTopic: boolean = false;
  addToTopic: boolean = false;
  docId!: number;
  isLoading: boolean = true;
  constructor(
    private store: Store<AppState>,
    private libraryService: WebUserLibraryService,
    private activatedRoute: ActivatedRoute,
    readonly toastService: ToastService,
    private confirmationModalService: ConfirmationModalService,
    private translate: TranslateService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.topicId = params['id'];
      }
    })
    this.fetchTopicDetail(this.pageNumber);
  }

  //////////////// fetch Topic details and its documents ///////////

  fetchTopicDetail(pageNumber: number) {
    const payload = {
      pageNumber: pageNumber,
      pageSize: this.pageSize,
      id: this.topicId
    };
    this.libraryService.researchTopicDetail(payload).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.topicDetail = response?.TopicDetail;
        this.totalRecords = response?.TotalRecords
      },
      error: (error) => {
        this.isLoading = false;
        this.toastService.showError(
          error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
        );
        this.router.navigate(['/user/my-library']);
      },
    });
  }

  //////////////////// calculate and show pagination //////////////////////////
  calculatePagination(data: Array<object>) {
    this.fromRecords =
      this.pageNumber === 1 && data?.length > 0
        ? 1
        : this.pageNumber === 1 && data?.length === 0
          ? 0
          : (this.pageNumber - 1) * this.pageSize + 1;
    this.toRecords = (this.pageNumber - 1) * this.pageSize + data?.length;
  }

  ///////////////// get all of category count /////////////
  getCategoryKeys(): string[] {
    return Object.keys(this.topicDetail?.Count);
  }

  ////////// toggle notes for specific document ///////////
  toggleShowNotes(topicId: number) {
    this.showNotes[topicId] = !this.showNotes[topicId];
  }

  ///////////////////// delete Research Topic ///////////////
  deleteResearchTopic(topicId: number) {
    this.confirmationModalService
      .confirm(
        this.translate.instant('pdl-website.forms.userLibrary.deleteText'),
        this.translate.instant(
          'pdl-website.forms.userLibrary.confirmDeleteText'
        ),
        this.translate.instant('pdl-shared.buttonText.yesSure'),
        this.translate.instant('pdl-shared.buttonText.notSure'),
        'md'
      )
      .then((confirmed) => {
        if (confirmed) {
          this.libraryService.deleteResearchTopic(topicId).subscribe({
            next: (response) => {
              this.router.navigate(['/user/my-library']);
              this.toastService.showSuccess(response?.message);
            },
            error: (error) => {
              this.toastService.showError(
                error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
              );
            },
          });
        }
      });
  }

  ///////////////////// delete assigned docuemnt from topic ///////////////
  removeDocumentFromTopic(topicId: number, docId: number) {
    this.confirmationModalService
      .confirm(
        this.translate.instant('pdl-website.forms.userLibrary.deleteDocumentText'),
        this.translate.instant(
          'pdl-website.forms.userLibrary.confirmDeleteText'
        ),
        this.translate.instant('pdl-shared.buttonText.yesSure'),
        this.translate.instant('pdl-shared.buttonText.notSure'),
        'md'
      )
      .then((confirmed) => {
        if (confirmed) {
          this.libraryService.deleteDocumentFromResearchTopic(topicId, docId).subscribe({
            next: (response) => {
              if (this.topicDetail.Data.length === 1) {
                this.router.navigate(['/user/my-library']);
              } else {
                this.fetchTopicDetail(this.pageNumber);
              }
              this.toastService.showSuccess(response?.message);
            },
            error: (error) => {
              this.toastService.showError(
                error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
              );
            },
          });
        }
      });
  }

  //////////// close create topic modal ////////
  closeCreateTopicModal(event: boolean) {
    this.editTopic = false;
    if (event) {
      this.fetchTopicDetail(this.pageNumber);
    }
  }

  /////////// close assign modal ////////
  closeAssignModal(event: boolean) {
    this.addToTopic = false;
  }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = APP_CONSTANTS.fallbackImage;
  }

    //////////// move to metadata detail page /////////

    moveToMetadata(docId:number) {
      this.router.navigateByUrl(`/metadata/${docId}`);
    }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
