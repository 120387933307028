import { Injectable, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ModalPopupComponent } from 'shared/src/lib/components/modal-popup/modal-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ModalPopupService {

  constructor(private modalService: NgbModal) {}

  public modalBtnsDisabled$ = new BehaviorSubject<boolean>(false);
  public modalBtnsLoading$ = new BehaviorSubject<boolean>(false);

  public open(
    title: string,
    message: string | SafeHtml,
    template: TemplateRef<any>,
    btnOkText : string | 'Submit',
    btnCancelText : string | 'Cancel',
    onSubmitCallback: () => void,
    onCancelCallback?: () => void,
    dialogSize: 'sm' | 'md' | 'lg' | 'xl' = 'lg',
    formGroup?: FormGroup,
    autoClose: boolean=true,
    ): Promise<boolean> {
    const modalRef = this.modalService.open(ModalPopupComponent, { 
      size: dialogSize, 
      backdrop: 'static', // Prevent closing on click outside
      keyboard: false,
      centered: true
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.loadTemplate(template);
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.onSubmitCallback = onSubmitCallback;
    modalRef.componentInstance.onCancelCallback = onCancelCallback;
    modalRef.componentInstance.formGroup = formGroup;
    modalRef.componentInstance.autoClose = autoClose;
    return modalRef.result;
  }

  public dismiss() {
    this.modalService.dismissAll();
  }
}
