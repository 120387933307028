@if (metadata && !isLoading) { @if (loadingImageAndPdf) {
<panjab-digi-lib-grow-spinner></panjab-digi-lib-grow-spinner>
} @else if (!loadingImageAndPdf && imageFiles.length && !hasSystemTimeMismatch)
{
<panjab-digi-lib-image-reader
  [images]="imageFiles"
  [docId]="docId"
  [accessionNo]="
    old_accession_number
      ? accession_number + ',' + old_accession_number
      : accession_number
  "
  [metadata]="metadata"
></panjab-digi-lib-image-reader>
} @else if (!loadingImageAndPdf && pdfFiles.length && !hasSystemTimeMismatch) {
<panjab-digi-lib-reader
  [fileUrl]="pdfFiles[0]?.url || ''"
  [docId]="docId"
></panjab-digi-lib-reader>
} @if(hasSystemTimeMismatch) {
<div class="d-flex systemTimeMismatchError pt-5 bg-pdl-color">
  <p class="alert alert-warning">
    {{ 'pdl-shared.content.timeMismatchError1' | translate }}
    <a href="javascript:void(0)" class="cursor-pointer" (click)="reloadPage()">
      {{ 'pdl-shared.content.reload' | translate }}
    </a>
    {{ 'pdl-shared.content.timeMismatchError2' | translate }}
  </p>
</div>
} }

<section class="innerpage-banner bg-pdl-color no-scrollbar"></section>

@if(isLoading) {
<div class="bg-pdl-color loader-wrapper">
  <panjab-digi-lib-spinner [isFullScreen]="false"></panjab-digi-lib-spinner>
</div>
} @else {
<section class="bg-pdl-color pt-5 pb-3">
  <div class="container">
    <div class="d-flex wrap-conditional">
      <div class="metabook-img">
        <img
          alt="image"
          class="img"
          [src]="metadata?.Thumbnails?.[200]"
          onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
        />
        <!-- <div class="stars">
                    @for (star of getRating(metadata?.WebDocument?.[0]?.UserRating); track star) { @if (star
                    === 1) {
                    <span><img src="assets/images/icons/full-star.svg" alt="icon" /></span>
                    } @if (star === 0.5) {
                    <span><img src="assets/images/icons/half-star.svg" alt="icon" /></span>
                    } @if (star === 0) {
                    <span><img src="assets/images/icons/no-star.svg" alt="icon" /></span>
                    } }
                </div> -->
      </div>
      <div class="metadata-book-title">
        <span class="meta-type-span light-font">{{
          metadata?.Category?.Name
        }}</span>
        <h1 class="pdl-title-color">{{ metadata?.Title || '' }}</h1>
        <p class="metadata-author">
          <span class="heading-span">Author:</span
          ><span class="author-span">
            {{ metadata?.AuthorsfullName || 'Unknown' }}</span
          >
        </p>
        <div
          class="pdl-btns-row d-flex align-items-center justify-content-between flex-wrap gap-20 pb-3"
        >
          <div class="btns-aligned-left d-flex flex-wrap gap-20">
            @if (activeUser && !isLoading) {
            <a
              href="javascript:void(0)"
              (click)="setAssociatedResearch()"
              class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-between"
              [ngClass]="{ 'bg-green': !metadata.Researches }"
              ><span class="mx-2">
                {{
                  metadata?.Researches
                    ? 'Remove from library'
                    : 'Add to my library'
                }}</span
              >
              <img
                [src]="
                  metadata?.Researches
                    ? '/assets/images/icons/heart-only-green.svg'
                    : '/assets/images/icons/heart-only-white.svg'
                "
            /></a>
            } @if(!metadata.AdoptedBy && !loadingCart) { @if(isAddedInCart) {
            <a
              href="javascript:void(0)"
              [routerLink]="['/adopt-a-book/checkout']"
              class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start"
            >
              {{ 'pdl-website.adoptABook.checkout' | translate }}
            </a>
            } @else if(metadata.ListForAdoption && !isAddedInCart) {
            <a
              href="javascript:void(0)"
              (click)="addToCart(metadata)"
              class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start"
            >
              {{ 'pdl-website.metadataPage.adoptThisBook' | translate }}
              <img src="assets/images/icons/visit-ico.svg" />
            </a>
            } }
            <div class="d-flex flex-wrap gap-20">
              <a
                href="javascript:void(0)"
                (click)="openPrintModal()"
                class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start"
              >
                Print on Demand <img src="assets/images/icons/visit-ico.svg" />
              </a>
            </div>
          </div>
          <div class="btns-aligned-right d-flex gap-20 flex-wrap">
            @if(downloadTypes.length > 0) {
            <div class="dropdown page-filter-dropdown">
              <a
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="btn pdl-btn pdl-bg-white medium-font d-flex align-items-center justify-content-start"
              >
                <img
                  src="assets/images/icons/download-large-ico.svg"
                  alt="download"
                />
              </a>

              <ul class="dropdown-menu">
                @for(downloadType of downloadTypes; track downloadType.type) {
                <li>
                  <a
                    class="dropdown-item cursor-pointer"
                    (click)="openDownloadModal(downloadType.type)"
                    >{{ downloadType.label }}</a
                  >
                </li>
                }
              </ul>
            </div>
            }
            <!-- <a href="#"
                            class="btn pdl-btn pdl-bg-white medium-font d-flex align-items-center justify-content-start"><img
                                src="assets/images/icons/quote-ico.svg" /></a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-pdl-color-light container lrborder-mob">
    <div class="d-flex meta-info-wrapper pb-5">
      <!-- // for mobile only -->
      <div class="metadata-book-edit overflow-scroll no-scrollbar mob-only">
        @if (metadata?.otherItemsByAuthorsInSameCategory?.length) {
        <div
          class="edit-title d-flex justify-content-start flex-wrap align-items-center justify-content-center"
        >
          <h6 class="pdl-title-color fullBold mb-3 justify-content-center">
            {{ metadata?.Category?.Name + ' by the Author(s)' }}
          </h6>
        </div>

        <div class="list-wrapper d-flex no-scrollbar">
          @for (item of metadata.otherItemsByAuthorsInSameCategory; track item)
          {
          <a
            href="javascript:void(0)"
            [routerLink]="getMetaDataPath(item.DocID)"
          >
            <img
              class="books_img"
              [src]="item?.Thumbnails?.[200]"
              onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
              [alt]="item?.Title"
            />
          </a>
          }
        </div>
        } @else { @if (metadata?.otherItemsByAuthorsInOtherCategory?.length) {
        <div
          class="edit-title d-flex justify-content-start flex-wrap align-items-center justify-content-center"
        >
          <h6 class="pdl-title-color fullBold mb-3 justify-content-center">
            Other Items by the Author
          </h6>
        </div>

        <div class="list-wrapper d-flex no-scrollbar">
          @for (item of metadata?.otherItemsByAuthorsInOtherCategory; track
          item) {
          <a
            href="javascript:void(0)"
            [routerLink]="'/metadata/' + item.AccessionNo"
          >
            <img
              class="books_img"
              [src]="item?.Thumbnails?.[200]"
              [alt]="item?.Title"
              onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
            />
          </a>
          }
        </div>
        } }
      </div>
      <!-- end -->
      <div class="meta-info">
        <div class="meta-disc-item">
          <h6 class="pdl-title-color semibold">
            {{ 'pdl-website.metadataPage.accessionNo' | translate }}
          </h6>
          <span class="pdl-title-color font-regular">{{
            metadata.AccessionNo
          }}</span>
        </div>
        @if(metadata.AdoptedBy) {
        <div class="meta-disc-item">
          <h6 class="pdl-title-color semibold">
            {{ 'pdl-website.metadataPage.adoptedBy' | translate }}
          </h6>
          @if(metadata.AdoptedBy.AdoptorType == adopterTypes.ORGANIZATION) {
          <a
            class="link"
            [routerLink]="['/adopted-docs', metadata.AdoptedBy.AdoptByID]"
          >
            {{ metadata.AdoptedBy.Name }}
          </a>
          } @else {
          <span class="pdl-title-color font-regular">
            {{ metadata.AdoptedBy.Name }}
          </span>
          }
        </div>
        }
        <div
          *ngFor="let metadata of displayableMetaData | keyvalue"
          class="meta-disc-item"
        >
          <h6 class="pdl-title-color semibold">{{ metadata.key }}</h6>
          <span class="pdl-title-color font-regular">{{ metadata.value }}</span>
        </div>
      </div>
      <div class="content_info">
        <div class="metadata-book-edit">
          <div
            class="edit-title d-flex justify-content-start flex-wrap align-items-center"
          >
            <h6 class="pdl-title-color semibold">
              {{ 'pdl-website.metadataPage.headings.keywords' | translate }}
            </h6>
            <span class="edit-btn font-14 font-regular">Edit</span>
          </div>
          <!-- @for(webdoc of metadata?.WebDocument; track webdoc;) { -->
          <p class="pdl-title-color font-regular">
            {{ metadata?.metaData?.Keywords ?? '-' }}
          </p>
          <!-- } -->
        </div>
        <div class="metadata-book-edit">
          <div
            class="edit-title d-flex justify-content-start flex-wrap align-items-center"
          >
            <h6 class="pdl-title-color semibold">
              {{ 'pdl-website.metadataPage.headings.description' | translate }}
            </h6>
            <span class="edit-btn font-14 font-regular">Edit</span>
          </div>
          @for(webdoc of metadata?.WebDocument; track webdoc;) {
          <p class="pdl-title-color font-regular">
            {{ webdoc?.Description ?? '-' }}
          </p>
          }
        </div>

        <div class="collapsable_item">
          <span class="heading">{{
            'pdl-website.metadataPage.headings.rightsAdvisory' | translate
          }}</span>
          <button class="btn" (click)="toggleCollapsed('advisory')">
            <img
              [src]="
                collapsedMenus.advisory
                  ? '/assets/images/icons/arrowup.svg'
                  : '/assets/images/icons/arrowdown.svg'
              "
            />
          </button>
        </div>
        @if (collapsedMenus.advisory) {
        <div class="metadata-book-edit" id="advisory">
          <p class="pdl-title-color font-regular">
            {{ 'pdl-website.metadataPage.headings.advisory' | translate }}
          </p>
        </div>
        }

        <div class="collapsable_item">
          <span class="heading">{{
            'pdl-website.metadataPage.headings.accessAdvisory' | translate
          }}</span>
          <button class="btn" (click)="toggleCollapsed('access')">
            <img
              [src]="
                collapsedMenus.access
                  ? '/assets/images/icons/arrowup.svg'
                  : '/assets/images/icons/arrowdown.svg'
              "
            />
          </button>
        </div>
        @if (collapsedMenus.access) {
        <div class="metadata-book-edit" id="access">
          <p class="pdl-title-color font-regular">
            {{ metadata?.Restricted ? 'Restricted' : '-' }}
          </p>
        </div>
        }
        <div class="collapsable_item" (click)="onCitationOpen()">
          <span class="heading">{{
            'pdl-website.metadataPage.headings.citeThisItem' | translate
          }}</span>
          <button class="btn" (click)="toggleCollapsed('cite')">
            <img
              [src]="
                collapsedMenus.cite
                  ? '/assets/images/icons/arrowup.svg'
                  : '/assets/images/icons/arrowdown.svg'
              "
            />
          </button>
        </div>
        @if (collapsedMenus.cite) {
        <div class="metadata-book-edit" id="cite">
          <p class="semibold">
            {{ 'pdl-website.metadataPage.headings.apaCitation' | translate }}
          </p>
          <div
            class="pdl-title-color font-regular"
            [innerHTML]="apaFormat"
          ></div>
          <p class="semibold">
            {{
              'pdl-website.metadataPage.headings.chicagoCitation' | translate
            }}
          </p>
          <div
            class="pdl-title-color font-regular"
            [innerHTML]="chicagoFormat"
          ></div>
          <p class="semibold">
            {{ 'pdl-website.metadataPage.headings.mlaCitation' | translate }}
          </p>
          <div
            class="pdl-title-color font-regular"
            [innerHTML]="mlaFormat"
          ></div>
        </div>
        }
        <div class="collapsable_item">
          <span class="heading">{{
            'pdl-website.metadataPage.headings.additionalFormats' | translate
          }}</span>
          <button class="btn" (click)="toggleCollapsed('metadataformats')">
            <img
              [src]="
                collapsedMenus.metadataformats
                  ? '/assets/images/icons/arrowup.svg'
                  : '/assets/images/icons/arrowdown.svg'
              "
            />
          </button>
        </div>
        @if (collapsedMenus.metadataformats) {
        <div class="metadata-book-edit" id="metadataformats">
          <p class="font-regular">
            <a
              href="javascript:void(0)"
              class="link"
              (click)="downloadMarc()"
              >{{
                'pdl-website.metadataPage.headings.marcXMLRecord' | translate
              }}</a
            >
          </p>
          <p class="font-regular link">
            <a
              href="javascript:void(0)"
              class="link"
              (click)="downloadMODS()"
              >{{
                'pdl-website.metadataPage.headings.modsRecord' | translate
              }}</a
            >
          </p>
          <p class="font-regular link">
            <a
              href="javascript:void(0)"
              class="link"
              (click)="downloadDublinCore()"
              >{{ 'pdl-website.metadataPage.headings.DCRecord' | translate }}</a
            >
          </p>
        </div>
        }
        <!-- <div class="collapsable_item">
                    <span class="heading">{{'pdl-website.metadataPage.headings.aboutAuthor' | translate }}</span>
                    <button class="btn" (click)="toggleCollapsed('author')">
                        <img
                            [src]="collapsedMenus.author ? '/assets/images/icons/arrowup.svg' : '/assets/images/icons/arrowdown.svg'" />
                    </button>
                </div>
                @if (collapsedMenus.author) {
                <div class="metadata-book-edit " id="author">
                    @if (metadata?.Author?.length) {
                    @for (Author of metadata?.Author; track Author;) {
                    @if (Author.vBiography) {
                    <p class="pdl-title-color font-regular semibold">
                        {{Author.FName }}
                    </p>
                    <p class="pdl-title-color font-regular">- {{Author.vBiography}}</p>
                    }

                    }
                    }
                </div>
                } -->
        <div class="collapsable_item" id="comments-section">
          <div class="d-flex align-items-center">
            <span class="heading">
              {{ 'pdl-website.metadataPage.comments' | translate }}
            </span>
          </div>
          <button class="btn" (click)="toggleCollapsed('comments')">
            <img
              [src]="
                collapsedMenus.comments
                  ? '/assets/images/icons/arrowup.svg'
                  : '/assets/images/icons/arrowdown.svg'
              "
            />
          </button>
        </div>
        @if(loadingComments) {
        <div class="mt-3 comments-loader">
          <panjab-digi-lib-spinner
            [isFullScreen]="false"
            [size]="1.5"
          ></panjab-digi-lib-spinner>
        </div>
        } @if (collapsedMenus.comments) {
        <div class="metadata-book-edit mt-4" id="comments">
          @if(!loadingComments) {
          <div class="commentWrapper no-scrollbar">
            @for (comment of comments; track comment) {
            <div
              class="comment"
              [id]="'metaComment-' + comment.CommentId"
              [ngClass]="{ disabled: comment.Status !== 'A' }"
            >
              <div class="quote mb-3">
                <img src="/assets/images/icons/quote.svg" />
                {{ comment.Name }} on
                {{ comment.DateAdded | date : 'dd/MMMM/yy HH:mm' | uppercase }}
                {{ comment.Status !== 'A' ? '(waiting Approval)' : '' }}
              </div>
              <div class="comment_text">
                {{ getCommentText(comment.CommentId, comment.Comment) }}

                @if(comment.Comment.length > 350) {
                <span
                  class="see_more_text"
                  (click)="toggleOpenedComment(comment.CommentId)"
                >
                  {{
                    openedComments.includes(comment.CommentId)
                      ? 'less....'
                      : 'more....'
                  }}
                </span>
                }
              </div>
            </div>
            }
          </div>
          }
          <div>
            <div
              style="position: relative"
              [ngClass]="{ disabled_write_comment: !activeUser }"
            >
              <textarea
                type="text"
                class="write_comment mt-4"
                [disabled]="!activeUser"
                [value]="comment"
                [placeholder]="
                  !activeUser
                    ? '\nPlease login to post a comment'
                    : '\nWrite Comment'
                "
                (input)="handleCommentChange($event)"
              ></textarea>
              @if(savingComment) {
              <div class="spinner-border send_icon">
                <span class="sr-only"></span>
              </div>
              } @else {
              <svg
                width="21"
                height="25"
                viewBox="0 0 21 25"
                class="send_icon cursor-pointer"
                fill="none"
                (click)="sendComment()"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.0264 6.25L8.51074 17.1875C8.35449 17.3828 8.27637 17.6562 8.27637 17.9297L8.2373 22.5L5.54199 16.6016L17.0264 6.25Z"
                  fill="#42526D"
                />
                <path
                  opacity="0.4"
                  d="M20.7373 3.94531L18.2373 20.1562C18.1982 20.5469 17.9639 20.8984 17.6123 21.0938C17.417 21.1719 17.2217 21.25 16.9873 21.25C16.8311 21.25 16.6748 21.2109 16.5186 21.1328L11.7529 19.1406L8.2373 22.5L8.27637 17.9297C8.27637 17.6172 8.35449 17.3828 8.51074 17.1484L17.0264 6.25L5.54199 16.6016L1.51855 14.9219C1.08887 14.7266 0.776367 14.3359 0.776367 13.8281C0.737305 13.2812 0.97168 12.8906 1.40137 12.6562L18.9014 2.69531C19.292 2.46094 19.8389 2.46094 20.2295 2.73438C20.6201 3.00781 20.8154 3.47656 20.7373 3.94531Z"
                  fill="#42526D"
                />
              </svg>
              }
            </div>
            <p style="color: red; font-size: 8px">{{ commentError }}</p>
          </div>
          <div class="d-flex justify-content-between new-thread-div">
            <a
              href="javascript:void(0)"
              class="btn pdl-btn pdl-bg-white medium-font d-flex align-items-center justify-content-start"
            >
              <span> Associated Discussion Forum </span>
            </a>
            <a
              href="javascript:void(0)"
              role="button"
              (click)="openCreateNewForumModal()"
              class="btn pdl-btn pdl-bg-white medium-font d-flex align-items-center justify-content-start"
            >
               Create New Thread 
            </a>
          </div>
          @if (commentsCount > pageSize) {
          <div
            class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4"
          >
            <ngb-pagination
              class="comments__pagination"
              [collectionSize]="commentsCount"
              [(page)]="pageNo"
              [pageSize]="3"
              [rotate]="true"
              [boundaryLinks]="true"
              [directionLinks]="true"
              [ellipses]="true"
              (pageChange)="getcomments(true)"
            />
          </div>
          }
          <!-- } -->
        </div>
        }
        <div class="metadata-book-edit desktop-only">
          @if (metadata?.otherItemsByAuthorsInSameCategory?.length) {
          <div
            class="edit-title d-flex justify-content-start flex-wrap align-items-center"
          >
            <h6 class="pdl-title-color fullBold mb-5">
              {{ metadata?.Category?.Name }}
              {{ 'pdl-website.metadataPage.headings.bytheAuthor' | translate }}
            </h6>
          </div>
          <div class="list-wrapper d-flex no-scrollbar">
            @for (item of metadata.otherItemsByAuthorsInSameCategory; track
            item) {
            <a
              href="javascript:void(0)"
              [routerLink]="getMetaDataPath(item.DocID)"
            >
              <img
                class="books_img ofc"
                [src]="item?.Thumbnails?.[200]"
                [alt]="item?.Title"
                onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
              />
            </a>
            }
          </div>
          } @else { @if (metadata?.otherItemsByAuthorsInOtherCategory?.length) {
          <div
            class="edit-title d-flex justify-content-start flex-wrap align-items-center"
          >
            <h6 class="pdl-title-color fullBold mb-5">
              {{
                'pdl-website.metadataPage.headings.otherItemsByTheAuthor'
                  | translate
              }}
            </h6>
          </div>
          <div class="list-wrapper d-flex no-scrollbar">
            @for (item of metadata?.otherItemsByAuthorsInOtherCategory; track
            item) {
            <a
              href="javascript:void(0)"
              [routerLink]="getMetaDataPath(item.DocID)"
            >
              <img
                class="books_img"
                [src]="item?.Thumbnails?.[200]"
                [alt]="item?.Title"
                onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
              />
            </a>
            }
          </div>
          } }
        </div>
      </div>
    </div>
  </div>
</section>
} @if(documentsByTopic.length) {
<section class="bg-pdl-color pt-5">
  <div class="container">
    <div class="metadata-book-edit">
      <div
        class="edit-title d-flex justify-content-start flex-wrap align-items-center"
      >
        <h6 class="pdl-title-color fullBold mb-5">
          {{ metadata?.Category?.Name }}
          {{ 'pdl-website.metadataPage.headings.onSameTopic' | translate }}
        </h6>
      </div>
      <div class="list-wrapper d-flex no-scrollbar pb-3">
        @for (item of documentsByTopic; track item) {
        <a
          href="javascript:void(0)"
          [routerLink]="getMetaDataPath(item?.tbl_documentinfo?.DocID)"
        >
          <div class="d-flex flex-column">
            <img
              class="books_img mb-2"
              [src]="item?.Thumbnails?.[200]"
              [alt]="item?.tbl_documentinfo?.Title"
            />
            <span class="text-item-simple">{{
              item?.tbl_documentinfo?.Title
            }}</span>
          </div>
        </a>
        }
      </div>
    </div>
  </div>
</section>
} @if(publishedInSameYear.length) {
<section class="bg-pdl-color pt-5 pb-5">
  <div class="container">
    <div class="metadata-book-edit">
      <div
        class="edit-title d-flex justify-content-start flex-wrap align-items-center"
      >
        <h6 class="pdl-title-color fullBold mb-5">
          {{ metadata?.Category?.Name }}
          {{
            'pdl-website.metadataPage.headings.publishedInSameYear' | translate
          }}
        </h6>
      </div>
      <div class="list-wrapper d-flex no-scrollbar pb-3">
        @for (item of publishedInSameYear; track item) {
        <div class="d-flex flex-column same_year_book_wrapper">
          <a
            href="javascript:void(0)"
            [routerLink]="getMetaDataPath(item?.DocID)"
          >
            <div
              class="d-flex flex-column cursor-pointer"
              style="position: relative"
            >
              <img
                class="books_img_same_year mb-2"
                [src]="item?.Thumbnails?.[200]"
                [alt]="item.Title"
                onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
              />
              <div class="text-item">
                <span>{{ item?.Category?.Name }}</span>
                <div class="stars d-none">
                  @for (star of getRating(item?.WebDocument?.[0]?.UserRating);
                  track star) { @if (star === 1) {
                  <span
                    ><img src="assets/images/icons/full-star.svg" alt="icon"
                  /></span>
                  } @if (star === 0.5) {
                  <span
                    ><img src="assets/images/icons/half-star.svg" alt="icon"
                  /></span>
                  } @if (star === 0) {
                  <span
                    ><img src="assets/images/icons/no-star.svg" alt="icon"
                  /></span>
                  } }
                </div>
              </div>
            </div>
            <div class="br-8 p-12-16">
              <div class="other-info mb-3">
                <span class="other-info-name semibold">{{ item?.Title }}</span>
              </div>
              <div class="other-info-text d-flex justify-content-between">
                <span>{{ item?.ScriptName }}</span>
                <span
                  >{{ item?.TotalPages }}
                  {{
                    'pdl-website.metadataPage.headings.pages' | translate
                  }}</span
                >
              </div>
            </div>
          </a>
        </div>
        }
      </div>
    </div>
  </div>
</section>
}

<section>
  <panjab-digi-lib-curated-collections
    [isForHomePage]="false"
    [collectionDetailsUrl]="'/collection'"
    [heading]="'pdl-website.collectionsSection.sectionTitle' | translate"
  >
  </panjab-digi-lib-curated-collections>
</section>

<panjab-digi-lib-download-modal></panjab-digi-lib-download-modal>

<panjab-digi-lib-print-modal></panjab-digi-lib-print-modal>

<panjab-digi-lib-create-new-forum-modal></panjab-digi-lib-create-new-forum-modal>
