import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgxExtendedPdfViewerService,
  NgxExtendedPdfViewerModule,
  FindState,
  FindResultMatchesCount,
  IPDFViewerApplication,
  pdfDefaultOptions,
} from 'ngx-extended-pdf-viewer';
import { FormsModule } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HighlightTextDirective } from 'apps/panjab-digi-lib/src/common/directives/highlight-text';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'panjab-digi-lib-reader',
  standalone: true,
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
    FormsModule,
    HighlightTextDirective,
    TranslateModule,
  ],
  styleUrl: './reader.component.scss',
  templateUrl: './reader.component.html',
})
export class ReaderComponent {
  @Input() fileUrl: string | undefined;
  @ViewChild('pdfViewer', { static: true }) pdfViewer!: ReaderComponent;
  showBrightness = false;
  showSepia = false;
  showGrayscale = false;
  isSearchActive = false;
  currentSearchTerm = '';

  textPerPage: any = {};
  totalNoOfPages = 0;
  searchResults: any[] = [];

  currentMatchNumber = 0;
  totalMatches = 0;
  findState: FindState = 3;
  searchIndex = 0;
  notes = [
    {
      noteName: '',
      noteContent:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid eum aperiam vero inventore corporis autem beatae temporibus consequuntur ex quos eaque facere soluta reiciendis quaerat, ab laudantium commodi obcaecati vel.',
      id: 1,
    },
    {
      noteName: '',
      noteContent:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid eum aperiam vero inventore corporis autem beatae temporibus consequuntur ex quos eaque facere soluta reiciendis quaerat, ab laudantium commodi obcaecati vel.',
      id: 1,
    },
    {
      noteName: '',
      noteContent:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid eum aperiam vero inventore corporis autem beatae temporibus consequuntur ex quos eaque facere soluta reiciendis quaerat, ab laudantium commodi obcaecati vel.',
      id: 1,
    },
  ];
  bookmarks = (() => {
    const arr = new Array(50);
    arr.fill({
      id: 1,
      name: 'Page 1',
    });
    return arr;
  })();
  public highlightAll = false;
  public matchCase = false;
  public wholeWord = false;
  public ignoreAccents = false;
  public spreadMode: 'off' | 'even' | 'odd' = 'off';

  public searchText = '';
  isHovered = false;
  showSearchContainer = false;
  showNotesContainer = false;
  enableAddNotes = false;
  showBookMarksContainer = false;

  constructor(
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService
  ) {
    pdfDefaultOptions.ignoreDestinationZoom = true;
  }

  public onPageRendered(): void {
    if (!this.ngxExtendedPdfViewerService.isRenderQueueEmpty()) {
      // try again later when the pages requested by the pdf.js core or the user have been rendered
      setTimeout(() => this.onPageRendered(), 100);
    }
    const page = this.ngxExtendedPdfViewerService.currentPageIndex() + 1;
    const pagesBefore = this.spreadMode === 'off' ? 2 : 2;
    const pagesAfter = this.spreadMode === 'off' ? 2 : 5;
    const startPage = Math.max(page - pagesBefore, 1);
    const endPage = Math.min(
      page + pagesAfter,
      this.ngxExtendedPdfViewerService.numberOfPages()
    );
    for (let page = startPage; page <= endPage; page++) {
      const pageIndex = page - 1;
      if (!this.ngxExtendedPdfViewerService.hasPageBeenRendered(pageIndex)) {
        this.ngxExtendedPdfViewerService.addPageToRenderQueue(pageIndex);
        break; // break because you can request only one page at a time
      }
    }
  }

  highlightText() {
    const PDFViewerApplication: IPDFViewerApplication = (window as any)
      .PDFViewerApplication;

    setTimeout(() => {
      for (let i = 0; i < this.totalNoOfPages; i++) {
        const page = i;
        const currentPage = PDFViewerApplication.pdfViewer._pages[page];
        if (currentPage?.textLayer?.highlighter.textDivs) {
          const allSpans = currentPage.div.querySelectorAll(
            '.textLayer > span'
          ) as NodeList;
          allSpans.forEach((span, index) => {
            const text = ((span as HTMLElement).innerText as string).replace(
              /\n/g,
              ''
            );
            for (let j = 0; j < this.searchResults.length; j++) {
              if (this.searchResults[j].pageNo == page + 1) {
                if (text.indexOf(this.searchResults[j]?.text) >= 0) {
                  (span as HTMLElement).classList.add('highlight-all');
                } else {
                  (span as HTMLElement).classList.remove('highlight-all');
                }
              }
            }
          });
        }
      }
    }, 200);
  }
  next() {
    this.ngxExtendedPdfViewerService.findNext();
  }
  prev() {
    this.ngxExtendedPdfViewerService.findPrevious();
  }
  clearSearch() {
    this.ngxExtendedPdfViewerService.find('');
    this.searchText = '';
    this.isSearchActive = false;
    this.currentSearchTerm = '';
  }

  onUpdateFindResult(event: FindResultMatchesCount): void {
    const PDFViewerApplication: IPDFViewerApplication = (window as any)
      .PDFViewerApplication;
    const matchIndexes = event.matches as Array<Array<number>>;
    const matchesLengths = event.matchesLength as Array<Array<number>>;
    this.searchIndex = event.current;

    setTimeout(() => {
      this.searchResults = [];
      matchIndexes.forEach((findings, page) => {
        if (findings?.length > 0) {
          const currentPageText: string = (
            PDFViewerApplication.findController as any
          )?._pageContents[page];
          findings.forEach((findIndex, i) => {
            let text: any = currentPageText
              .substring(
                findIndex - 20 > 0 ? findIndex - 20 : 0,
                findIndex + 70
              )
              .split(' ');
            text.shift();
            text = text.join(' ');
            this.searchResults.push({
              pageNo: page,
              text,
            });
          });
        }
      });
      if (this.currentSearchTerm.length > 0) {
        setTimeout(() => {
          this.isSearchActive = true;
        }, 1000);
      }
    }, 200);
  }

  goToSearchResult(result: any, index: number) {
    if (index > this.searchIndex) {
      this.next();
      setTimeout(() => {
        this.goToSearchResult(result, index);
      }, 200);
    } else if (index < this.searchIndex) {
      this.prev();
      setTimeout(() => {
        this.goToSearchResult(result, index);
      }, 200);
    }
  }

  async search() {
    this.currentSearchTerm = this.searchText;
    this.searchResults = [];
    if (!this.searchText.length) {
      return;
    }
    this.ngxExtendedPdfViewerService.find(this.searchText);
  }

  reset() {
    this.showBrightness = false;
    this.showGrayscale = false;
    this.showSepia = false;
  }

  changeBrightness() {
    this.reset();
    this.showBrightness = true;
  }
  changeGrayscale() {
    this.reset();
    this.showGrayscale = true;
  }
  changeSepia() {
    this.reset();
    this.showSepia = true;
  }
  getClass() {
    if (this.showBrightness) {
      return 'bright';
    }
    if (this.showGrayscale) {
      return 'gray';
    }
    if (this.showSepia) {
      return 'sepia';
    }
    return '';
  }
  showControls() {
    this.isHovered = true;
    this.showSearchContainer = false;
  }
  showSearchView() {
    this.showSearchContainer = true;
    this.isHovered = false;
  }
  showNotesView() {
    this.showNotesContainer = true;
    this.isHovered = false;
  }
  showAddNotes() {
    this.enableAddNotes = true;
  }
  showBookMarksView() {
    this.showBookMarksContainer = true;
    this.isHovered = false;
  }
  closeToggle() {
    this.isHovered = false;
  }
  closeSearch() {
    this.ngxExtendedPdfViewerService.find('');
    this.searchText = '';
    this.showSearchContainer = false;
  }
  closeNotes() {
    this.showNotesContainer = false;
  }
  closeAddNotes() {
    this.enableAddNotes = false;
  }
}
