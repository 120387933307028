import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-topbar',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent {
  sidebarlist: any = {
    aboutUs: [
      // match both keys and array values with en.json
      'policies',
      // 'mediaRoom',
      // 'newsLetters',
      'workingGroups',
      'currentProjects',
      // 'behindTheScenes',
    ],
    services: [
      'forum',
      'digitization',
      'dataMining',
      'interlibrary',
      'exhibitions',
      'preservation',
      'uploadadocument',
      'digitizationTraining',
    ],
    generalInfo: [
      // 'jobs',
      'team',
      'volunteer',
      // 'collections',
      // 'downloads',
      'caseStudies',
      'donorLevels',
    ],
  };
  listKeys = Object.keys(this.sidebarlist);
  constructor(private translate: TranslateService) {}
}
