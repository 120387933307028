import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'panjab-digi-lib-grow-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './grow-spinner.component.html',
  styleUrl: './grow-spinner.component.scss',
})
export class GrowSpinnerComponent implements OnInit{
  showSecond: boolean = false;
  showThird: boolean = false;
  ngOnInit(): void {
      setTimeout(() => {
        this.showSecond = true;
      }, 200)
      setTimeout(() => {
        this.showThird = true;
      }, 400)
  }
}
