import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, exhaustMap, take, tap, finalize } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AuthActions } from '../actions/index';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { UserService } from '../../services/website/user/user.service';
const DEFAULT_REDIRECT_TO = '/home/banners';
@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private socialAuthService: SocialAuthService,
    private userService: UserService
  ) {}
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Login] User Login'),
      switchMap(({ email, password }) =>
        this.authService.login(email, password).pipe(
          map((res) => {
            if (res.body.token) {
              localStorage.setItem('token', res.body.token);
              // set refresh token in the localstorage
              localStorage.setItem('refreshToken', res.body.refreshToken);
              // redirect to previous active url
              const redirectTo = localStorage.getItem('redirectTo');
              this.router.navigateByUrl(redirectTo ?? DEFAULT_REDIRECT_TO);
              // delete saved redirect url
              if (redirectTo) {
                localStorage.removeItem('redirectTo');
              }
              return AuthActions.loginSuccess({
                token: res.body.token,
                refreshToken: res.body.refreshToken,
              });
            } else {
              return AuthActions.loginFailure({
                error: { message: 'Token not received' },
              });
            }
          }),
          catchError((error: any) =>
            of(AuthActions.loginFailure({ error: error?.error?.error }))
          )
        )
      )
    )
  );

  webUserLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[WebLogin] Web User Login'),
      switchMap(({ username, password, login }) =>
        this.authService.loginUser({ username, password, login }).pipe(
          map((res) => {
            if (res.body.token) {
              this.localStorageService.set('webToken', res.body.token);
              // set refresh token in the localstorage
              this.localStorageService.set(
                'webRefreshtoken',
                res.body.refreshToken
              );
              this.localStorageService.set('webUser', res.body.user);
              return AuthActions.webLoginSuccess({
                webToken: res.body.token,
                webRefreshtoken: res.body.refreshToken,
                user: res.body.user,
              });
            } else {
              return AuthActions.webLoginFailure({
                error: { message: 'Token not received' },
              });
            }
          }),
          catchError((error: any) =>
            of(AuthActions.webLoginFailure({ error: error?.error?.error }))
          )
        )
      )
    )
  );

  webUserSocialLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.webSocialLogin),
      map(({ webToken, webRefreshtoken, user }) => {
        if (webToken) {
          this.localStorageService.set('webToken', webToken);
          // set refresh token in the localstorage
          this.localStorageService.set('webRefreshtoken', webRefreshtoken);
          this.localStorageService.set('webUser', user);
          return AuthActions.webLoginSuccess({
            webToken,
            webRefreshtoken,
            user,
          });
        } else {
          return AuthActions.webLoginFailure({
            error: { message: 'Token not received' },
          });
        }
      })
    )
  );

  webUserLogout$ = createEffect(() => 
    this.actions$.pipe(
      ofType(AuthActions.webLogout),
      exhaustMap(() => {
        // trigger logout api to add the bearer token into redis cache
        let logout$ = this.authService.logout().pipe(take(1));
        // if social media user is active, remove social authentication
        // and then trigger logout api
        if (this.localStorageService.get('socialProvider')) {
          logout$ = from(this.socialAuthService.signOut()).pipe(
            switchMap(() => this.authService.logout().pipe(take(1)))
          );
        }
        // clear active user session and redirect to login page
        return logout$.pipe(
          finalize(() => {
            this.userService.clearUserSession();
            this.router.navigate(['/login-user']);
          })
        );
      })
    ),
    { dispatch: false }
  );
}
