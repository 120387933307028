import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterLink } from '@angular/router';
import {
  AppState,
  WebDownloadsSelector,
  WebHomePageActions,
  environment,
} from '@panjab-digi-lib/shared';
import { Store } from '@ngrx/store';
import { HomepageService } from 'shared/src/services/website/home/homepage.service';
import { ToastService } from 'shared/src/services/toast/toast.service';

@Component({
  selector: 'panjab-digi-lib-downloads',
  standalone: true,
  imports: [CommonModule, TranslateModule, SlickCarouselModule, RouterLink],
  templateUrl: './downloads.component.html',
  styleUrl: './downloads.component.scss',
})
export class DownloadsComponent implements OnInit {
  allDownloads: any;
  baseUrl = environment.rootApiPublicUrl;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private homepageService: HomepageService,
    private readonly toastService: ToastService
  ) {}
  
  ngOnInit(): void {
    this.homepageService.loadWebDownloads().subscribe(
      (response: any) => {
        this.allDownloads = response?.body;
      },
      (error: any) => {
        if (error?.error?.error?.message) {
          this.toastService.showError(error?.error?.error?.message);
        }
      }
    );
  }

  slides = [
    {
      img: 'assets/images/download-placeholder.jpg',
    },
    {
      img: 'assets/images/download-placeholder.jpg',
    },
    {
      img: 'assets/images/download-placeholder.jpg',
    },
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  slickInit(e: any) {
    console.log('slick initialized');
  }
}
