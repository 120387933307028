@if(!researchTopicDetail) {
<section class="container">
  <div class="d-flex justify-content-between align-items-center mb-5 flex-wrap">
    <h2 class="semibold f-31">
      {{ 'pdl-website.forms.userLibrary.myResearchTopic' | translate }}
    </h2>
    <button class="btn btn__primary d-flex" (click)="createNewTopic = true">
      {{ 'pdl-website.forms.userLibrary.addNewTopic' | translate }}
      <img src="/assets/images/icons/arrow-right-up.svg" />
    </button>
  </div>
  <div class="researchTopicWrapper">
    @for (topic of researchTopics; track topic.TopicID) {
    <div class="topic mb-5">
      @if (topic.TopicID) {
      <div
        class="topic_title d-flex justify-content-between mb-4 flex-wrap"
        #listItem
      >
        <span class="title">
          <span class="semibold">{{ topic.TopicName }}</span>
          <!-- @if(topic.TopicID) { -->
          <span class="fade_text ms-1">
            ({{ topic.Data.length > 0 ? 1 : 0 }}
            {{ 'pdl-shared.pagination.to' | translate }}
            {{ topic.Data.length }}
            {{ 'pdl-shared.pagination.of' | translate }}
            {{ topic.TotalRecords }}
            {{ 'pdl-shared.pagination.entries' | translate }})</span
          >
          <!-- }@else { -->

          <!-- } -->
        </span>
        @if(topic.TopicID) {
        <button
          class="btn btn__primary"
          [routerLink]="'/user/research-topic/' + topic.TopicID"
        >
          <span>{{
            'pdl-website.forms.userLibrary.viewDetail' | translate
          }}</span>
        </button>
        }
      </div>
      } @else {

      <div class="col-12 mb-5 suggested__reads">
        <h3 class="semibold mb-4">
          {{ 'pdl-website.forms.userLibrary.viewSuggestedReads' | translate }}
        </h3>
        <div class="most-popular-slider">
          <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
          >
            @for (suggestedRead of suggestedReads; track suggestedRead.DocID ) {
            <div
              ngxSlickItem
              class="slide most-popular-slide me-4 position-relative"
            >
              <a [routerLink]="'/metadata/' + suggestedRead.DocID">
                <img
                  class="mostpopular-slide-bg ofc"
                  src="{{ imageBaseURL }}{{ suggestedRead.Thumbnail }}"
                  alt="Thumb"
                  width="100%"
                  onerror="this.src='assets/images/icons/no-image-placeholder.svg'"
                />
                <div
                  class="block-text-bar d-flex justify-content-between align-items-center"
                >
                  <div
                    class="medium-font font-14 text-cut"
                    [title]="suggestedRead.Title"
                  >
                    {{ suggestedRead.Title }}
                  </div>
                </div>
              </a>
            </div>
            }
          </ngx-slick-carousel>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <h2 class="semibold f-31">{{ topic.TopicName }}</h2>
        <span class="fade_text ms-1">
          ({{ topic.Data.length > 0 ? fromRecords : 0 }}
          {{ 'pdl-shared.pagination.to' | translate }} {{ toRecords }}
          {{ 'pdl-shared.pagination.of' | translate }} {{ topic.TotalRecords }}
          {{ 'pdl-shared.pagination.entries' | translate }})</span
        >
      </div>
      }

      <div
        class="d-flex col-gap-12 ofs no-scrollbar"
        [ngClass]="{ flex__direction: !topic?.TopicID }"
      >
        @if(topic?.TopicID) { @for (item of topic.Data; track item.DocID) {
        <img
          class="topic__image"
          [src]="
            item?.Thumbnail
              ? item.Thumbnail
              : 'assets/images/icons/no-image-placeholder.svg'
          "
          (error)="handleImageError($event)"
          alt="image"
        />
        } @empty { @if(!isLoading) {
        <span class="text-center no-record">{{
          'pdl-shared.content.noRecordsFound' | translate
        }}</span>
        } } } @else {
        <div class="searches-in-grid mt-2">
          <div class="row">
            @for (item of topic.Data; track item?.DocID; let i = $index) {
            <div class="col-lg-3 col-md-3 col-sm-3">
              <a class="search-card mb-4">
                <div class="block-img">
                  <img
                    class="white-heart"
                    src="/assets/images/icons/heart-white.svg"
                    (click)="moveToResearchTopic(item.DocID)"
                  />
                  <div class="image-overlay d-flex align-items-center">
                    <span class="font-10 category-title">
                      {{ item?.CategoryName }}
                    </span>
                    <span class="ratings">
                      @for (star of getRating(item.UserRating); track star) {
                      <ng-container>
                        <span>
                          <img [src]="getStarImage(star)" alt="star icon" />
                        </span>
                      </ng-container>
                      }
                    </span>
                  </div>
                  <img
                    class="thumb-imb cursor-pointer"
                    alt="image"
                    (click)="moveToMetadata(item.DocID)"
                    [ngClass]="{ ofc: !item?.Thumbnail }"
                    [src]="
                      item?.Thumbnail
                        ? item.Thumbnail
                        : 'assets/images/icons/no-image-placeholder.svg'
                    "
                    (error)="handleImageError($event)"
                    alt="image"
                  />
                </div>
                <div
                  class="block-text-bar d-flex justify-content-between align-items-center flex-column cursor-pointer"
                  (click)="moveToMetadata(item.DocID)"
                >
                  <h6
                    class="pdl-title-color medium-font font-14 no-scrollbar text-cut"
                    [title]="item.Title"
                  >
                    {{ item?.Title }}
                  </h6>
                  <div
                    class="type-and-numbers d-flex align-items-center justify-content-between w-100"
                  >
                    <span class="font-10 d-flex">{{ item?.LanguageList }}</span>
                    <span class="font-10 d-flex"
                      >{{ item?.TotalPages }} PAGES</span
                    >
                  </div>
                </div>
              </a>
            </div>
            } @empty { @if(!isLoading) {
            <span class="text-center no-record">{{
              'pdl-shared.content.noRecordsFound' | translate
            }}</span>
            } }
          </div>
        </div>
        @if(topic.TotalRecords > pageSize) {
        <div class="row">
          <div class="col-12">
            <div
              class="pdl-pagination d-flex align-items-center justify-content-center webPagination__wrapper mt-5"
            >
              <nav aria-label="Page navigation example">
                <ngb-pagination
                  class="donations__pagination"
                  [collectionSize]="topic.TotalRecords"
                  [(page)]="pageNumber"
                  [pageSize]="pageSize"
                  [rotate]="true"
                  [ellipses]="true"
                  (pageChange)="fetchUserResearchTopics(pageNumber, true)"
                  [maxSize]="maxPageNumber"
                  [boundaryLinks]="true"
                  [directionLinks]="true"
                  [ellipses]="true"
                />
              </nav>
            </div>
          </div>
        </div>
        } }
      </div>
    </div>
    } @empty { @if(!isLoading) {
    <span class="text-center no-record">{{
      'pdl-shared.content.noRecordsFound' | translate
    }}</span>
    } }
  </div>
</section>
}

<!--------------- assign literature --------------------->
@if(assignLiterature) {
<panjab-digi-lib-assign-literature
  [docId]="docId"
  (closeModal)="closeModal($event)"
></panjab-digi-lib-assign-literature>
} @if(createNewTopic) {
<panjab-digi-lib-create-edit-topic
  (closeModal)="closeCreateTopicModal($event)"
></panjab-digi-lib-create-edit-topic>
} @if(isLoading) {
<panjab-digi-lib-spinner></panjab-digi-lib-spinner>
}
<router-outlet></router-outlet>
