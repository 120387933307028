/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AuthActions, StaffAuthActions } from 'shared/src/store/actions';
import { Router } from '@angular/router';
import { environment } from '@panjab-digi-lib/shared';
import { jwtDecode } from 'jwt-decode';
import { IdleUserService } from 'shared/src/services/common/idle-user.service';
import { Idle } from '@ng-idle/core';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'panjab-digi-lib-header',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() title: string = '';
  baseUrl = environment.rootApiPublicUrl;
  activeUser: any;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  private idleSubscription: Subscription = new Subscription();
  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private idleUserService: IdleUserService,
    private _idle: Idle,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    // Subscribe to idle service events
    this.subscribeToIdleEvents();
    const baseHref = this.getBaseHref();
    if (localStorage.getItem('activeStaffUser') && baseHref === 'pdlstaff') {
      const activeUsr = localStorage.getItem('activeStaffUser') ?? '{}';
      this.activeUser = JSON.parse(activeUsr);
    } else if (localStorage.getItem('token') && baseHref === 'pdladmin') {
      this.activeUser = jwtDecode(localStorage.getItem('token') ?? '');
    }
    this.idleUserService.startWatching(baseHref === 'pdlstaff' ? 'staff-idleState' : 'admin-idleState');
  }

  private subscribeToIdleEvents() {
    // Unsubscribe from existing subscriptions
    this.idleSubscription.unsubscribe();

    this.idleSubscription = new Subscription();
    
    this.idleSubscription.add(
      this._idle.onIdleEnd.subscribe(() => {
        /* event ends whenever user moves mouse or touch */
      })
    );

    this.idleSubscription.add(
      this._idle.onTimeoutWarning.subscribe((countdown) => {
        /* it listens to counter how many seconds left */
      })
    );

    this.idleSubscription.add(
      this._idle.onTimeout.subscribe(() => {
        this.signOut(true);
      })
    );
  }

  getBaseHref() {
    const url = window.location.pathname;
    const baseHref = url.split('/')[1];
    return baseHref;
  }

  signOut(inactivity?: boolean) {
    const activebaseHref = this.getBaseHref();
    if (localStorage.getItem('staffToken') && activebaseHref === 'pdlstaff') {
      this.store.dispatch(StaffAuthActions.logout());
      localStorage.removeItem('staffToken');
      localStorage.removeItem('staffRefreshToken');
      localStorage.removeItem('activeStaffUser');
      this.router.navigateByUrl('/login');
    } else if (localStorage.getItem('token') && activebaseHref === 'pdladmin') {
      this.store.dispatch(AuthActions.logout());
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      this.router.navigateByUrl('/');
    }
    this.idleUserService.stopWatching(activebaseHref === 'pdlstaff' ? 'staff-idleState' : 'admin-idleState');
    if (inactivity) {
    this.toastService.showError(this.translate.instant('pdl-shared.inactivityLogout'))
    }
  }

  ngOnDestroy() {
    // Unsubscribe from existing subscriptions
    this.idleSubscription.unsubscribe();
  }
}
