/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.endpoints';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'shared/src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminMetaDataService {
  rootURL = '';
  endPointUrl = API_ENDPOINTS.webUserMgmt.metadata;
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }

  ///////////// get document meta data detail////////

  detail(data: { taskId: number; type?: string }) {
    let url = `${this.rootURL}${this.endPointUrl.default}`.replace(
      '{id}',
      data.taskId.toString()
    );
    if (data.type) {
      url += `?type=${data.type}`;
    }
    return this.http.get(url).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  ///////////// search collection based on keyword ////////

  search(searchCollection: string, searchField: string, query: string) {
    return this.http
      .get(
        `${this.rootURL}${this.endPointUrl.search}`
          .replace('{collection}', searchCollection)
          .replace('{searchField}', searchField)
          .replace('{query}', query)
      )
      .pipe(
        map((e: any) => e.body),
        catchError(this.handleError)
      );
  }

  ///////////// update metadata ////////

  edit(docId: number, payload: any, type: string) {
    payload.submittedType = type;
    return this.http
      .put(
        `${this.rootURL}${this.endPointUrl.edit}`.replace(
          '{id}',
          docId.toString()
        ),
        payload
      )
      .pipe(
        map((e: any) => e),
        catchError(this.handleError)
      );
  }

  ////////////// add new Data in field //////////////

  addMetadataFieldData(payload: any) {
    return this.http
      .post(`${this.rootURL}${this.endPointUrl.addNewFieldData}`, payload)
      .pipe(
        map((e: any) => e),
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;
    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }
    return throwError(() => transformedError);
  }
}


