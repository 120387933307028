import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AppState, DocumentListSelector, environment } from '@panjab-digi-lib/shared';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { STAFF_API_ENDPOINTS } from './app.endpoints';
import { DocumentList } from 'shared/src/interfaces/staff/document.interface';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  staffApiURL = '';
  constructor(private http: HttpClient, private store: Store<AppState>,) {
    this.staffApiURL = environment.rootApiUrl + environment.staffApiPrefix;
  }

  loadDocumentList(payload: GenericListingPayload): Observable<{documentList: DocumentList[], totalRecords: number}> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.document.list}`, {params: {...payload}}).pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  loadDocumentByTaskID(PSID: string): Observable<DocumentList> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.document.getDocumentByTaskID}`.replace('{PSID}', PSID)).pipe(
      map((e: any) => e.body.document),
      catchError(this.handleError)
    );
  }

  loadDocumentByDocID(DocID: string): Observable<DocumentList> {
    return this.http.get(`${this.staffApiURL}${STAFF_API_ENDPOINTS.document.getDocumentByDocID}`.replace('{DocID}', DocID)).pipe(
      map((e: any) => e.body.document),
      catchError(this.handleError)
    );
  }

  loadDocument(documentID: string): Observable<any> {
    return this.store.select(DocumentListSelector.getDocumentByID({ id: documentID })).pipe(
      switchMap((document: DocumentList) => {
        if (document) { // found in store
          return of(document);
        } else { // fetch from API
          return this.loadDocumentByDocID(documentID);
        }
      }),
    );
  }

  submitForApproval(DocID: number) {
    return this.http.patch(`${this.staffApiURL}${STAFF_API_ENDPOINTS.document.submitForApproval}`, {DocID}).pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }
  
  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }

    return throwError(() => transformedError);
  }
}
