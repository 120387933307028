export const WEB_API_ENDPOINTS = {
    homePage: {
        getBanners: 'app/banner',
        getTrendingKeywords: 'app/trending',
        getCategories: 'app/category',
        getDownloads: 'app/downloads',
        videos : 'app/videos?type=homepage',
        search : 'app/suggestion?query={query}&categoryId={categoryId}'

    },
    downloads: {
        allDownloads : 'app/allDownloads?pageNumber={pageNumber}&pageSize={pageSize}&sortBy={sortBy}'
    },
    userDownloads: {
        downloadTypes: 'app/userDownload/types/{id}',
        checkLimit: 'app/userDownload/checkLimit',
        download: 'app/userDownload/download{type}/{id}',
    },
    videos : {
       video : 'app/video/{id}',
       videos : 'app/videos?orderBy={orderBy}&type=all'
    },
    user: {
        donations: 'user/donations',
        getAdoptedBooks: 'user/adoptedBooks'
    },
    volunteer: {
        validateReq: 'volunteer/validate',
        addReq: 'volunteer/add-request'
    },
    search : {
        default : 'app/search',
        filters : 'app/filters',
        searchCollection : 'app/searchCollection',
        save :'app/saveSearch',
        update : 'app/updateSavedSearch/{id}',
        delete: 'app/deleteSearch/{id}'
    },
    userLibrary : {
        addResearchTopic : 'app/library/topic/add',
        editResearchTopic :'app/library/topic/edit/{id}',
        assignDocumentToTopic : 'app/library/topic/assign',
        researchTopics : 'app/library/topic/get?pageSize={pageSize}&pageNumber={pageNumber}',
        topicDetail : 'app/library/topic/get/{id}?pageSize={pageSize}&pageNumber={pageNumber}',
        deleteResearchTopic : 'app/library/topic/delete/{id}',
        deleteDocumentFromResearchTopic : 'app/library/topic/assigned/delete/{id}/{docId}',
        userResearchTopic : 'app/library/topic/myResearchTopics/get'
    }

}