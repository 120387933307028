<panjab-digi-lib-static-page-head [pageName]="'pdl-website.history.titleText' | translate" pagePath="/history">
</panjab-digi-lib-static-page-head>
<section class="static-section section-padding ">
  <div class="container-1000">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/data-mining.png" /></div>
      <div>
        <h1 class="heading mb-3">History</h1>
        <p class="text">
          By the grace of Guru Nanak Sahib, the Panjab Digital Library (PDL) began operations in 2003 as a tiny
          organization with a gigantic mission on its hands. Starting with one agenda, one desktop, and one employee, we
          have come a long way since then.
        </p>
        <p class="text">The project emerged from the concerns shared by a group of individuals for the increasing
          disappearance and loss of heritage of the Sikhs and Panjab. Much has vanished whether through the passage of
          time, accident or political unrest. Despite the losses, it was evident that much could be saved or was worth
          saving. Preservation was our primary motivation and impetus with a focus on archiving "endangered" manuscripts
          and artifacts to preserve the heritage, culture and language of Panjab.</p>
        <p class="text">A digitization project, small or large, requires a big budget. Without any initial
          infrastructure, expertise or funds to support the project, (PDL) has not only survived, but excelled through
          sheer motivation and perseverance. With each passing day, we grew in skill and competence, experience and
          number. Our library acquired the necessary digital equipment, and we began not only the seemingly daunting
          talk of digitizing material, but also developing out skills at data management and archiving.</p>
        <p class="text">We have achieved numerous milestones and witnessed exponential growth in our digitization output
          and resources. We are continuing to grow and evolve, learning to find better ways to reduce our operational
          costs, and further hone and refine the digitization process.</p>
        <p class="text">Finally, we seek to promote an entirely new culture of awareness where you the reader can become
          an active participant in safeguarding and preserving the cultural treasures of Panjab.We welcome all of you
          with open arms.</p>
        <p class="text">The Panjab Digital Library Editorial Team</p>
      </div>
    </div>
    }
  </div>
</section>
