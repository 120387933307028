<div class="modal-wrapper">
  <div class="modal_container">
    <div class="closebtn cursor-pointer" (click)="close()">
      X
    </div>
    <div class="pt-5 pb-3">
      <div class="semibold text-center heading mb-2">{{'pdl-website.forms.userLibrary.assignLiteratureText' | translate }}</div>
      <div class="subheading text-center mb-3">{{'pdl-website.forms.userLibrary.chooseTopic' | translate }}</div>
      <div class="px-5 mb-2">
        <select class="form-select sort__filter" aria-label="Default select example" (change)="selectResearchTopic($event)">
          <option [value]="" [disabled]="true">{{'pdl-website.forms.userLibrary.selectTopic' | translate }}</option>
          @for(topic of topics;track topic.TopicID) {
            @if(topic.TopicID != topicId) {
          <option [value]="topic.TopicID">{{topic.TopicName}}</option>
            }
          }
        </select>
      </div>
      <div class="text-center"><a class="text-center newlink mx-auto cursor-pointer" (click)="createNewTopic = true">
        {{'pdl-website.forms.userLibrary.createTopic' | translate }}
        </a>
      </div>
      <div class="text-center d-flex mx-auto mt-3 justify-content-center col-gap-12">
        <button class="btn btn__secondary" (click)="close()">{{'pdl-shared.content.cancel' | translate}}</button>
        <button class="btn btn__primary" (click)="assign()" [disabled]="!selectedTopicId">{{'pdl-shared.content.save' | translate}}</button>
      </div>
    </div>
  </div>
</div>

@if(createNewTopic) {
  <panjab-digi-lib-create-edit-topic (closeModal)="closeCreateTopicModal($event)" [docId]="docId" [addToNew]="addToNew"></panjab-digi-lib-create-edit-topic>
}
