import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AuthActions, StaffAuthActions } from 'shared/src/store/actions';
import { Router } from '@angular/router';
import { environment } from '@panjab-digi-lib/shared';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'panjab-digi-lib-header',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() title: string = '';
  baseUrl = environment.rootApiPublicUrl;
  activeUser: any;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit() {
    const baseHref = this.getBaseHref();
    if (localStorage.getItem('activeStaffUser') && baseHref === 'pdlstaff') {
      const activeUsr = localStorage.getItem('activeStaffUser') ?? '{}';
      this.activeUser = JSON.parse(activeUsr);
    } else if (localStorage.getItem('token') && baseHref === 'pdladmin') {
      this.activeUser = jwtDecode(localStorage.getItem('token') ?? '');
    }
  }

  getBaseHref() {
    const url = window.location.pathname;
    const baseHref = url.split('/')[1];
    return baseHref;
  }

  signOut() {
    const activebaseHref = this.getBaseHref();
    if(localStorage.getItem('staffToken') && activebaseHref === 'pdlstaff') {
      localStorage.removeItem('staffToken');
      localStorage.removeItem('staffRefreshToken');
      localStorage.removeItem('activeStaffUser');
      this.router.navigateByUrl('/login')
    } else if(localStorage.getItem('token') && activebaseHref === 'pdladmin') {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      this.store.dispatch(AuthActions.logout());
      this.router.navigateByUrl('/');
    }
  }
}
