<section class="innerpage-banner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
                    <a class="breadcrumb-link font-regular" href="#">
                        <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
                        Home
                    </a> 
                    <span class="chevron-arrow font-regular">
                        <img src="assets/images/icons/arrow-right.svg" alt="">
                    </span>
                    <a class="breadcrumb-link font-regular" href="javascript:void(0)" [routerLink]="'/all-categories'">
                        Categories
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="page-title">
                    <h1 class="heading-dark semibold mb-0">All Categories</h1>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="categories section-padding ">
    <div class="container">
        <div class="row">
            
            <div class="col-12">
                <div class="categories-in-grid">
                    <div class="row justify-content-center">
                        @for(category of categories$ | async; track category.categoryID) {
                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                <a href="javascript:void(0)" (click)="category.totalDocs > 0 ? generateSearchUrl(category.categoryID) : null" class="category-card">
                                    <div class="block-text-bar">
                                        <h6 class="mb-0"> {{ category.Name }}</h6>
                                        <span class="number-of-users mb-0">
                                            {{ category.totalDocs > 0 ? category.totalDocs : '' }}
                                        </span>
                                    </div>
                                    <div class="block-img">
                                        <img [src]="imageBaseURL+category.CategoryThumbnail" alt="categoryimg" (error)="handleImageError($event)">
                                    </div>
                                </a>
                            </div>
                        }

                    </div>
                </div>


            </div>
            
        </div>
    </div>
</section>