<div class="modal-header">
    <div class="modal-left-block">
      <h6 class="modal-title font-regular" id="listModalLabel">{{modalData.collection}} list</h6>
      <input type="text" class="form-control modal-input" placeholder="Search by {{modalData.collection}}" [(ngModel)]="searchInput" (keyup)="onSearch($event)">
    </div>
    @if(this.modalData.totalRecords > 100) {
    <div class="alphabet-filter">
      <span class="alphabetically"> View alphabetically </span>
      <span (click)="onLetterSelect('')">#</span>
      @for(letter of alphabet;track letter) {
      <span (click)="onLetterSelect(letter)" [class.selected]="letter === query">
      {{ letter }}
      </span>
      }
    </div>
    }
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">


    <div class="radio-list-items">
      <div class="row custom-scroll" (scroll)="onScroll($event)">
        @if(allData.length > 0) {
        @for(item of allData;track item) { 
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="radio-list-block">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [checked]="item.isSelected || selectedIds.includes(item.id)" (change)="queryUpdation(modalData.collection,item,$event)">
              <label class="form-check-label" [for]="'check' + item.id">
              {{ item.value }}
              </label>
            </div>
          </div>
        </div>
        } 
        }
        @else {
          <p class="no-records"> No records found</p>
        }
      </div>
    </div>
  </div>