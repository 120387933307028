import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookReaderService {
  public fullScreenActiveSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.getFullScreenActive();
  }

  getFullScreenActive() {
    // This is to handle the fullscreen toggle event from the book reader
    const bookReader = window as any as { BookReader: any };
    const originalTrigger = bookReader?.BookReader?.prototype.trigger;
    const self = this;
    if (bookReader?.BookReader) {
      bookReader.BookReader.prototype.trigger = function (
        eventName: any,
        ...args: any[]
      ) {
        if (eventName === bookReader.BookReader.eventNames.fullscreenToggled) {
          self.fullScreenActiveSubject.next(this.isFullscreenActive);
        }
        return originalTrigger?.apply(this, [eventName, ...args]);
      };
    }
    // End of the fullscreen toggle event handler
  }
}
