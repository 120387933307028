import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HomepageService } from 'shared/src/services/website/home/homepage.service';
import { Subscription } from 'rxjs';
import { PopularReadList } from 'shared/src/interfaces/popular-read.interface';
import { environment } from '@panjab-digi-lib/shared';
import { RouterModule } from '@angular/router';
import { GlobalDividerComponent } from '../../divider/global-divider.component';

@Component({
  selector: 'panjab-digi-lib-most-popular',
  standalone: true,
  imports: [
    CommonModule, 
    SlickCarouselModule,
    TranslateModule,
    RouterModule,
    GlobalDividerComponent
  ],
  templateUrl: './most-popular.component.html',
  styleUrl: './most-popular.component.scss',
})
export class MostPopularComponent implements OnInit,OnDestroy{
  subscription = new Subscription();
  popularReads : PopularReadList[] =[];
  imageBaseURL = environment.azureCloudStrorage.publicUrl;
  slideConfig = {
    centerMode: false,
    centerPadding: '40px',
    autoplay: false,
    draggable: true,
    autoplaySpeed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          infinite: true
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      }
    ]
  };
  constructor(
    private translate: TranslateService,
    private homepageService: HomepageService
  ) {

  }

  ngOnInit(): void {
    this.fetchMostPopularReads()
  }

  /////////////// list of most popular reads /////////////////
  fetchMostPopularReads() {
  
    this.subscription.add(this.homepageService.popularReads().subscribe({
     next: (response) => {
       this.popularReads = response?.popularReadList;
     },
     error: (error) => {
       console.log('popular read error',error)
     },
   }));
   }

  slickInit(e: any) {
    console.log('slick initialized');
  }

   
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
