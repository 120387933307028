<panjab-digi-lib-static-page-head
  [pageName]="'pdl-website.FAQs.titleText' | translate"
  pagePath="/faq"
>
</panjab-digi-lib-static-page-head>

<section class="static-section section-padding pt-3">
  @if (getObjectKeys(content?.data ?? [])?.length > 0) {
  <div class="container-header-wrapper">
    <div class="faq-header-wrapper mb-5 no-scrollbar container-header">
      @for (key of getObjectKeys(content.data); track key) {
      <span class="faq-item" [ngClass]="{ 'active-item': key === activeKey }">
        <span (click)="setActiveKey(key)" class="cursor-pointer">{{
          key
        }}</span></span
      >
      }
    </div>
  </div>
  }
  <div class="container container-768">
    @if (getObjectKeys(content?.data ?? [])?.length > 0) {
    <div class="d-flex mb-2">
      <b>FAQ of {{ activeKey }}</b>
    </div>
    <p class="text">
      Every donation goes a long way to preserving and safeguarding the tangible
      and intangible heritage of Panjab. We are blessed and grateful for the
      generosity of all our patrons. Your support really matters.
    </p>

    <div class="FAQ-QA-wrapper">
      @for (QA of getQAList(activeKey); track QA; let index = $index) {
      <div
        class="QNA cursor-pointer"
        [ngClass]="{ 'not-opened': index % 2 === 0 }"
        (click)="openedKeysHandler(index)"
      >
        <div class="mb-2" [innerHTML]="QA.Que | safeHtml"></div>
        <div
          class="ans"
          [innerHTML]="getContent(QA.Ans, index) | safeHtml"
        ></div>
      </div>
      }
    </div>
    } @else { @if(loading) {
    <panjab-digi-lib-spinner></panjab-digi-lib-spinner>
    } @else{
    <div class="d-flex justify-content-center">Something went wrong</div>
    } }
  </div>
</section>
