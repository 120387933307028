<section class="innerpage-banner">
   <div class="container">
     <div class="row">
       <div class="col-12">
         <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
       
         </div>
       </div>
     </div>
 
     <div class="row">
       <div class="col-lg-12">
         <div class="page-title">
           <h1 class="heading-dark semibold mb-0">All Exhibitions</h1>
         </div>
       </div>
     </div>
 
   </div>
 </section>
@if(isLoading) {
<panjab-digi-lib-spinner></panjab-digi-lib-spinner>
} @else {
<!--------------------- current exhibitions ------------------------>
@if(currentExhibitions.length>0) {
<div class="top_section container">
   <ngx-slick-carousel class="carousel mb-0" #slickCarousel="slick-carousel" [config]="slideConfig">
   @for(exhibition of currentExhibitions;track exhibition) {
   <div ngxSlickItem class="slide downloads-slide pe-4">
      <div class="downloads-slide-card">
         <div class="img_div">
            <div class="block-img cursor-pointer">
               <img src="{{imageBaseUrl}}{{exhibition.ItemPath}}" alt="image" (click)="navigateToExhibition(exhibition.ExhibitionID)">
            </div>
         </div>
         <div class="info_div">
            <div class="exhibition-card">
               <div class="block-text ps-4 pe-4">
                  <h5 class="medium-font cursor-pointer" (click)="navigateToExhibition(exhibition.ExhibitionID)"> {{exhibition.Title}}
                  </h5>
                  <p class="description-text clamp-9" [innerHTML]="exhibition.Description"> </p>
                  <div class="d-flex dates_and_place justify-content-between">
                     <div class="item">
                        <span class="heading">
                        Dates
                        </span>
                        <div class="item-info">
                           <span>
                           {{exhibition.Date.startDate | date : 'dd MMMM YYYY'}} -
                           {{exhibition.Date.endDate | date : 'dd MMMM YYYY'}}
                           </span>
                           <span>
                           {{ '1970-01-01T' + exhibition.Time.startTime + ':00' | date: 'h:mm a' }} -
                           {{'1970-01-01T' + exhibition.Time.endTime + ':00' | date: 'h:mm a' }}
                           </span>
                        </div>
                     </div>
                     <div class="item">
                        <span class="heading">
                        Place
                        </span>
                        <div class="item-info">
                           <span>{{exhibition.Location}}</span>
                        </div>
                     </div>
                  </div>
                  <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                     <button href="#"
                        class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.exhibitionDirection.buttonText'
                     | translate }} <img src="assets/images/icons/directions-ico.svg" alt="directions-icon"></button>
                     <button href="#"
                        class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                     {{'pdl-website.exhibitionVisit.buttonText' | translate }} <img
                        src="assets/images/icons/visit-ico.svg" alt="visit-icon"></button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   }
   </ngx-slick-carousel>
   <div class="centered-btn d-flex align-items-center justify-content-center mt-3">
      <a [routerLink]="['/all-exhibitions']" [queryParams]="{ query: 'Current' }" class="btn pdl-btn">{{'pdl-website.exhibitionsmainButton.currentExhibitionText' | translate }}</a>
   </div>
   <div class="d-flex justify-content-center my-2 desktopOnly mt-5">
      <img src="assets/images/icons/divider.svg" alt="">
   </div>
</div>
}
<!------------upcoming exhibition section ---------------->
@if(upcomingExhibitions.length>0) {
<section class="exhibitions section-padding">
   <div class="container">
      <div class="row">
         <div class="col-12">
            <h2 class="heading-style semibold text-center mb-4">Upcoming
               {{'pdl-website.exhibitionsSection.sectionTitle' | translate }}
            </h2>
            <p class="title-description text-center mb-lg-40 pdl-grey">
               {{'pdl-website.exhibitionsDescription.descriptionText' | translate }}
            </p>
         </div>
         <div class="col-12 mb-5">
            <div class="exhibitions-in-grid">
               <div class="row justify-content-center">
                  @for(exhibition of upcomingExhibitions;track exhibition) {
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                     <div class="exhibition-card">
                        <div class="block-img cursor-pointer">
                           <img src="{{imageBaseUrl}}{{exhibition.ItemPath}}" alt="image" (click)="navigateToExhibition(exhibition.ExhibitionID)">
                        </div>
                        <div class="block-text ps-4 pe-4">
                           <h5 class="medium-font cursor-pointer" (click)="navigateToExhibition(exhibition.ExhibitionID)"> {{exhibition.Title }}
                           </h5>
                           <div class="small-tags">
                              <span class="small-tag">
                              <img src="assets/images/icons/calendar-ico.svg" alt="calendar-icon">
                              {{exhibition.Date.startDate | date : 'dd MMMM YYYY'}} -
                              {{exhibition.Date.endDate | date : 'dd MMMM YYYY'}}
                              </span>
                              <span class="small-tag">
                              <img src="assets/images/icons/location-ico.svg" alt="calendar-icon">
                              {{exhibition.Location}}
                              </span>
                           </div>
                           <p class="description-text" [innerHTML]="exhibition.Description"></p>
                           <!-- <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                              <a href="#"
                                 class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.exhibitionDirection.buttonText'
                              | translate }} <img src="assets/images/icons/directions-ico.svg" alt="direction-icon"></a>
                              <a href="#"
                                 class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                              {{'pdl-website.sponsorVisit.buttonText' | translate }} <img
                                 src="assets/images/icons/visit-ico.svg" alt="visit-icon"></a>
                           </div> -->
                        </div>
                     </div>
                  </div>
                  }
               </div>
            </div>
         </div>
         <div class="col-12">
            <div class="centered-btn d-flex align-items-center justify-content-center">
               <a [routerLink]="['/all-exhibitions']" [queryParams]="{ query: 'Upcoming' }" class="btn pdl-btn">{{'pdl-website.exhibitionsmainButton.futureText' | translate }}</a>
            </div>
         </div>
      </div>
      <div class="d-flex justify-content-center my-2 desktopOnly mt-5">
         <img src="assets/images/icons/divider.svg" alt="">
      </div>
   </div>
</section>
}
<!-- past exhibtions -->
@if(pastExhibitions.length>0) {
<section class="exhibitions">
   <div class="container">
      <div class="row">
         <div class="col-12">
            <h2 class="heading-style semibold text-center mb-4">Past
               {{'pdl-website.exhibitionsSection.sectionTitle' | translate }}
            </h2>
            <p class="title-description text-center mb-lg-40 pdl-grey">
               Digital collections that will be made available free of cost
            </p>
         </div>
         <div class="col-12">
            <div class="d-flex no-scrollbar past_item_wrapper">
               @for(exhibition of pastExhibitions;track exhibition) {
               <div class="past_item">
                  <img class="img cursor-pointer" src="{{imageBaseUrl}}{{exhibition.ItemPath}}" alt="" (click)="navigateToExhibition(exhibition.ExhibitionID)">
                  <div class="past_item_desc d-flex flex-column">
                     <div class="d-flex mt-4 date align-items-center">
                        <img src="/assets/images/icons/calendar-ico.svg" alt="">
                        <span class="day px-1">
                        {{exhibition.Date.startDate | date : 'dd MMMM YYYY'}} -
                        {{exhibition.Date.endDate | date : 'dd MMMM YYYY'}}
                        </span>
                     </div>
                     <p class="semibold mb-0 cursor-pointer" (click)="navigateToExhibition(exhibition.ExhibitionID)">
                        {{exhibition.Title }}
                     </p>
                     
                     <p class="desc">
                        <span class="des" [innerHTML]="isExpandedMap[exhibition.ExhibitionID] ? exhibition.Description : getTruncatedDescription(exhibition.Description)"></span>
                        @if(shouldShowReadMore(exhibition.Description)) { 
                        <span class="grey-color cursor-pointer" (click)="toggleDescription(exhibition.ExhibitionID)">
                           {{ isExpandedMap[exhibition.ExhibitionID] ? ('pdl-shared.content.readLessText' | translate) : ('pdl-shared.content.readMoreText' | translate) }}
                         </span>
                        }
                    </p>
                  </div>  
               </div>
               }
            </div>
         </div>
         <div class="centered-btn d-flex align-items-center justify-content-center">
            <a [routerLink]="['/all-exhibitions']" [queryParams]="{ query: 'Past' }" class="btn pdl-btn">{{'pdl-website.exhibitionsmainButton.pastText' | translate }}</a>
         </div>
      </div>
   </div>
   <div class="d-flex justify-content-center mb-2 mt-5 desktopOnly">
      <img src="assets/images/icons/divider.svg" alt="">
   </div>
</section>
}
}
<!-- <div class="d-flex justify-content-around gap-24 container flex-wrap mb-5 mt-5">
   <div class="contact_item">
      <img src="assets/images/icons/header-menu/library-icon.png" alt="library-icon"/>
      <span class="heading">Ask for an Exhibition</span>
      <p class="text">Inspire change: Volunteer your time and be a catalyst for transformation.</p>
   </div>
   <div class="contact_item">
      <img src="assets/images/icons/phone.svg" alt="phone-icon"/>
      <span class="heading">Contact Us</span>
      <p class="text">Gift the power of words: Donate a book and empower someone.</p>
   </div>
   <div class="contact_item">
      <img src="assets/images/icons/share.svg" alt="share-icon"/>
      <span class="heading">Share your experience</span>
      <p class="text">Gift the power of words: Donate a book and empower someone.</p>
   </div>
</div> -->
