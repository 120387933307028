/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @nx/enforce-module-boundaries */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GooglePlace } from 'shared/src/interfaces/common.interface';
import { GoogleMapsService } from 'shared/src/services/common/google-maps.service';
declare const google: any;
@Component({
  selector: 'panjab-digi-lib-google-place-autocomplete',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './google-place-autocomplete.component.html',
  styleUrl: './google-place-autocomplete.component.scss'
})

export class GooglePlaceAutocompleteComponent implements OnInit {
  @Input() initialAddress!: string;
  @Input() addressType!: string;
  @Input() isAllExhibition?:boolean = false
  @Output() setAddress: EventEmitter<object> = new EventEmitter();
  @ViewChild('addressText') addressText!: ElementRef;

  constructor(
    private mapsService: GoogleMapsService
  ) {}
  
  ngOnInit(): void {
    this.mapsService.loadMapsApi().then(() => {
      this.getPlaceAutocomplete();
      this.setInitialValue();

    }).catch(error => {
      console.error('Error loading Google Maps API:', error);
    });
  }

  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        types: ['geocode']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  setInitialValue() {
    this.fetchPlaceDetails(this.initialAddress)
  }

  fetchPlaceDetails(address: string) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results:any, status:any) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const place = results[0];
        this.invokeEvent(this.mapGooglePlace(place));
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  mapGooglePlace(place: any): GooglePlace {
    return {
      formatted_address: place.formatted_address,
      name: place.name,
      geometry: {
        location: {
          lat: () => place.geometry.location.lat(),
          lng: () => place.geometry.location.lng(),
        },
      },
    };
  }

  invokeEvent(place: GooglePlace) {
    this.setAddress.emit(place);
  }
}
