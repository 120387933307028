/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { Router } from '@angular/router';
import { AdoptedByService } from 'shared/src/services/admin/Fundraising/adopted-by.service';
import { AdoptedByActions } from '../actions';
import { Adopter } from 'shared/src/interfaces/fundraising.interface';

@Injectable()
export class AdoptedByEffects {
    defaultErrMsg = "Something went wrong! Please try again later.";
    constructor(
        private actions$: Actions,
        private router: Router,
        private adoptedByService: AdoptedByService
    ) {
    }

    LoadAdoptedBy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdoptedByActions.LoadAdopter),
            exhaustMap((action) => {
                return this.adoptedByService.loadAdopters(action.payload, action.contributor).pipe(
                    map((response) => {
                        return AdoptedByActions.LoadAdopterSuccess({ adoptersList: response.adoptersList, totalRecords: response.totalCount });
                    }),
                    catchError((error: any) => of(AdoptedByActions.LoadAdopterError({ message: error?.error?.error?.message || this.defaultErrMsg }))
                    ))
            })
        )
    );
    
    UpdateAdoptedByStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdoptedByActions.UpdateAdopter),
            exhaustMap((action) => {

                return this.adoptedByService.updateAdopter(action.payload).pipe(
                    map((response: any) => {
                        const id = action.payload.id;
                        const adopterUpdates: Update<Adopter>[] = [{id, changes:{
                            // AdoptByID: action.payload.id,
                            Name: action.payload.name,
                            DisplayName: action.payload.displayName,
                            Add1: action.payload.add1,
                            Add2: action.payload.add2,
                            CountryID: action.payload.countryId,
                            Remarks: action.payload.remarks,
                            Email: action.payload.email,
                            Country: {Name: action.payload.countryName},
                            AboutOrg: action.payload.aboutOrg,
                            Website: action.payload.website,
                            AdoptorType: action.payload.adopterType,
                        }}]
                        
                        return AdoptedByActions.UpdateAdopterSuccess({ adopterUpdates, message: response.message });
                    }),
                    catchError((error: any) => of(AdoptedByActions.UpdateAdopterError({ message: error?.error?.error?.message ? error?.error?.error?.message : error?.message }))
                    ))
            })
        )
    );
    
    OnAddAdopterSuccess$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AdoptedByActions.UpdateAdopterSuccess),
            tap(() => this.router.navigateByUrl('/fundraising/adopt/list-adopters'))
        ), 
        { dispatch: false }
    )
}