/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ExhibitionService } from 'shared/src/services/website/exhibition/exhibition.service';
import { Subscription } from 'rxjs';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { ExhibitionItem } from 'shared/src/interfaces/exhibition.interface';
import { environment, SpinnerComponent } from '@panjab-digi-lib/shared';
import { NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DateRangePickerComponent } from 'shared/src/lib/components/date-range-picker/date-range-picker.component';
import { GooglePlace } from 'shared/src/interfaces/common.interface';
import { GooglePlaceAutocompleteComponent } from 'shared/src/lib/components/google-place-autocomplete/google-place-autocomplete.component';

@Component({
  selector: 'panjab-digi-lib-all-exhibitions',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbPaginationModule, SpinnerComponent, RouterModule, DateRangePickerComponent,GooglePlaceAutocompleteComponent,NgbPopoverModule],
  templateUrl: './all-exhibitions.component.html',
  styleUrl: './all-exhibitions.component.scss',
})
export class AllExhibitionsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  pageSize = APP_CONSTANTS.pageSize;
  pageNumber: number = 1;
  exhibitionList: ExhibitionItem[] = [];
  totalRecords: number = 0;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  maxPageNumber: number = APP_CONSTANTS.maxSize;
  fromRecords: number = 0;
  toRecords: number = 0;
  isLoading: boolean = false;
  payload: any = {};
  startDate!: string;
  endDate!: string;
  showLocationFilter : boolean = false;
  constructor(
    private service: ExhibitionService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
     if(params['query']) {
     this.payload.filters = params['query'];
     }
    });
    this.payload.pageSize = this.pageSize;
    this.getPayload(this.pageNumber);
  }

  /////////////////// payload which need to send in request ////////////////////
  getPayload(pageNumber: number) {
    this.payload.pageNumber = pageNumber;
    this.fetchAllExhibitions();
  }

  //////////////////// all exhibitions ///////////////////
  fetchAllExhibitions() {
    this.isLoading = true;
    this.subscription.add(this.service.get(this.payload).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.exhibitionList = response?.body?.exhibitions;
        this.totalRecords = response?.body?.totalCount;
        this.calculatePagination(this.exhibitionList);
      },
      error: (error) => {
        this.isLoading = false;
        this.toastService.showError(
          error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
        );
      },
    }));
  }
  /////////////// pagination //////////////////////
  calculatePagination(data: Array<object>) {
    if (this.pageNumber === 1) {
      if (data?.length > 0) {
        this.fromRecords = 1;
      } else {
        this.fromRecords = 0;
      }
    } else {
      this.fromRecords = (this.pageNumber - 1) * this.pageSize + 1;
    }
    this.toRecords = (this.pageNumber - 1) * this.pageSize + data?.length;
  }

  ///////////////// add filters ///////////////////

  addFilter(filter: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      if (this.payload.filters) {
        if (!this.payload.filters.split(',').includes(filter)) {
          this.payload.filters += `,${filter}`;
        }
      } else {
        this.payload.filters = filter;
      }
    } else {
      if (this.payload.filters) {
        this.payload.filters = this.payload.filters.split(',').filter((f: any) => f !== filter).join(',');
        if (this.payload.filters === '') {
          this.payload.filters = null;
          this.router.navigateByUrl('/', {skipLocationChange: true})
          .then(()=>this.router.navigate(['all-exhibitions']));
        }
      }
    }
    this.getPayload(1);
  }

  ////////////// date picker start and end date /////////
  selectedRange(event: { startDate: string, endDate: string, checked?: boolean }) {
    if (event.checked === false && event.startDate) {
      this.startDate = '';
      this.endDate = ''
    } else {
      this.startDate = event.startDate;
      this.endDate = event.endDate;
      this.payload.startDate = this.startDate;
      this.payload.endDate = this.endDate;
      this.getPayload(1);
    }

  }

  getAddress(place: GooglePlace) {
    const location = place.geometry?.location;
    const latitude =  location ? location.lat() : null;
    const longitude = location ? location.lng() : null;
    this.payload.latitude = latitude;
    this.payload.longitude = longitude;
    this.getPayload(1);
  }

  ////////////////// show/hide location ///////////

  toggleLocation(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
    this.showLocationFilter = true;
    } else {
      this.showLocationFilter = false;
      this.payload.latitude = '';
      this.payload.longitude = '';
      this.getPayload(1);
    }
  }

  ////////// navigate /////////

  navigateToDetail(exhibition:ExhibitionItem) {
    this.router.navigateByUrl(`/exhibition/${exhibition.ExhibitionID}`);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
