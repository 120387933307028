/* eslint-disable @typescript-eslint/no-inferrable-types */
import {Directive,ElementRef,HostListener,Renderer2} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAllowedCharacters]',
  standalone: true,
})
export class AllowedCharactersDirective {
  private regex: RegExp = /^[0-9a-zA-Z&$,#@().\s]*$/;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private control: NgControl
  ) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue: string = inputElement.value;

    if (!this.regex.test(inputValue)) {
      inputElement.value = inputValue.replace(/[^a-zA-Z0-9&$,#@().\s]/g, '');
    }

    this.updateControlValue();
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const pasteData: string = event.clipboardData?.getData('text') ?? '';
    const sanitizedData: string = pasteData.replace(/[^a-zA-Z0-9&$,#@().\s]/g, '');

    event.preventDefault();

    const startPos = inputElement.selectionStart || 0;
    const endPos = inputElement.selectionEnd || 0;

    inputElement.value =
      inputElement.value.substring(0, startPos) +
      sanitizedData +
      inputElement.value.substring(endPos);

    this.updateControlValue();
  }

  private updateControlValue() {
    if (this.control && this.control.control) {
      this.control.control.setValue(this.el.nativeElement.value);
    }
  }
}
