/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import {
  AuthSelector,
  SharedCommonService,
  WebCategoriesSelector,
  WebHomePageActions,
} from '@panjab-digi-lib/shared';
import {
  debounceTime,
  filter,
  Observable,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { UserService } from 'shared/src/services/website/user/user.service';
import { LoggedInUser } from 'shared/src/interfaces/auth-user.interface';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { FormsModule } from '@angular/forms';
import { HomeCategories } from 'shared/src/interfaces/homepage.interface';
import { HomepageService } from 'shared/src/services/website/home/homepage.service';
import { AllowedCharactersDirective } from 'shared/src/lib/directives/allowed-character.directive';
import { BookReaderService } from 'shared/src/services/common/book-reader.service';

@Component({
  selector: 'panjab-digi-lib-web-header',
  standalone: true,
  imports: [
    CommonModule,
    TopbarComponent,
    TranslateModule,
    RouterLink,
    FormsModule,
    AllowedCharactersDirective
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class WebHeaderComponent implements OnInit, OnDestroy {
  activeUser: any;
  subscription = new Subscription();
  searchTerm = new Subject<string>();
  searchInput: string = '';
  suggestionList: Array<string> = [];
  selectedCategory: string = '';
  pageSize = APP_CONSTANTS.pageSize;
  selectedIndex: number = -1;
  categoryId: number = 0;
  categories$!: Observable<HomeCategories[]>;
  showSuggestions = false;
  fullScreenActive = false;
  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private localStorage: LocalStorageService,
    private userService: UserService,
    private commonService: SharedCommonService,
    private homePageService: HomepageService,
    private bookReaderService: BookReaderService
  ) {}

  ngOnInit() {
    this.store.dispatch(WebHomePageActions.LoadWebCategories());
    
    this.bookReaderService.fullScreenActiveSubject.subscribe((fullScreenActive: boolean) => {
      this.fullScreenActive = fullScreenActive;
    });

    this.categories$ = this.store.select(WebCategoriesSelector.getCategories);
    this.subscription.add(
      this.localStorage.changes$.subscribe((data: any) => {
        if (data && data.key === 'webUser') {
          this.activeUser =
            data.type === 'remove'
              ? null
              : { ...this.activeUser, ...data.value };
        }
      })
    );

    this.subscription.add(
      this.store
        .select(AuthSelector.selectWebUser)
        .subscribe((user: LoggedInUser) => {
          if (user) {
            this.activeUser = user;
          } else if (this.localStorage.get('webUser')) {
            this.activeUser = this.localStorage.get('webUser');
          }
        })
    );
    this.searchTerm
      .pipe(
        filter((term) => term.length >= 3),
        debounceTime(300),
        switchMap(async () => this.findSuggestions())
      )
      .subscribe();
  }
  suggestion(e: Event) {
    const enteredKeyword = (e.target as HTMLInputElement).value;
    if (enteredKeyword === '') {
      this.suggestionList = [];
      this.showSuggestions = false;
    }
    this.selectedIndex = -1;
    this.searchTerm.next(enteredKeyword);
  }

  //////////// search data ///////////////////////////
  search(text: string, strictMode = true) {
    this.searchInput = text;
    this.showSuggestions = false;
    this.router.navigate(['/search'], {
      queryParams: {
        query: this.commonService.encodeToBase64(text),
        categoryId: this.commonService.encodeToBase64(this.categoryId),
        strictMode: this.commonService.encodeToBase64(strictMode),
      },
    });
  }
  ///////////////////// search on enter key ///////////////
  searchOnEnterKey() {
    if (this.searchInput.length >= 3) {
      if (this.selectedIndex > -1) {
        this.search(this.suggestionList[this.selectedIndex], true);
        // this.showSuggestions = true;
      } else {
        this.search(this.searchInput.trim(), false);
      }
    } else {
      console.log('Please enter at least 3 characters to search.');
    }
  }
  handleBlur = () => {
    // e.preventDefault();
    this.showSuggestions = false;
    this.searchInput = '';
    // this.suggestionList = [];
  };
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.selectedIndex = Math.min(
        this.selectedIndex + 1,
        this.suggestionList.length - 1
      );
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
      event.preventDefault();
    } else if (event.key === 'Enter') {
      this.searchOnEnterKey();
    }
  }
  setCategory(categoryId: number, categoryName: string) {
    this.categoryId = categoryId;
    this.selectedCategory = categoryName;
    this.findSuggestions();
  }
  findSuggestions() {
    this.homePageService
      .suggestion(this.categoryId, this.searchInput)
      .subscribe((data) => {
        this.suggestionList = data;
        this.showSuggestions = true;
      });
  }
  isHomePage = () =>
    window.location.pathname === '/' || window.location.pathname === '/search';
  async signOut(): Promise<void> {
    this.userService.logOutWebUser();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.bookReaderService.fullScreenActiveSubject.unsubscribe();
  }
}
