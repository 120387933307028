import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, WebSearchSelector } from '@panjab-digi-lib/shared';
import { Subscription } from 'rxjs';
import { Search } from 'shared/src/interfaces/website/search.interface';

@Component({
  selector: 'panjab-digi-lib-advanced-search',
  standalone: true,
  imports: [CommonModule,RouterLink,TranslateModule],
  templateUrl: './advanced-search.component.html',
  styleUrl: './advanced-search.component.scss',
})
export class AdvancedSearchComponent implements OnInit{
  subscription = new Subscription();
  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    
  ) {

  }

  ngOnInit(): void {
    this.fetchSearchResults();
  }

  //////////////// fetch search results //////////////

  fetchSearchResults() {
    this.subscription.add(
      this.store.select(WebSearchSelector.searchList).subscribe((response: any) => {
      console.log(response)
      })
    );
  }
}
