import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../reducers/website/web-document-files.reducer';

export const selectDocumentFilesState = createFeatureSelector<State>('webDocumentFiles');

export const selectPdfFiles = createSelector(
  selectDocumentFilesState,
  (state) => state?.pdfFiles ?? []
);

export const selectImageFiles = createSelector(
  selectDocumentFilesState,
  (state) => state?.imageFiles ?? []
);

export const selectIsLoading = createSelector(
  selectDocumentFilesState,
  (state) => state?.isLoading
);

export const selectErrorMessage = createSelector(
  selectDocumentFilesState,
  (state) => state?.errorMessage
);

