/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState, SharedCommonService, WebHomePageActions, WebTrendingKeywordsSelector } from '@panjab-digi-lib/shared';
import { Observable } from 'rxjs';
import { Keywords } from 'shared/src/interfaces/homepage.interface';
import { Router } from '@angular/router';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';


@Component({
  selector: 'panjab-digi-lib-trending-keywords',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './trending-keywords.component.html',
  styleUrl: './trending-keywords.component.scss',
})
export class TrendingKeywordsComponent implements OnInit {

  todayKeywords$!: Observable<Keywords[]>;
  historicKeywords$!: Observable<Keywords[]>;
  userSearches$! :Observable<Keywords[]> 
  isUserLoggedIn: boolean = false;

  constructor(
    private translate: TranslateService, private store: Store<AppState>,
    private router : Router,
    private commonService : SharedCommonService,
    private authService: AuthenticationService

  ) {

  }

  ngOnInit(): void {

    this.isUserLoggedIn = this.authService.isTokenExpired('webToken') == false;

    this.store.dispatch(WebHomePageActions.LoadWebTrendingKeywords());

    this.todayKeywords$ = this.store.select(WebTrendingKeywordsSelector.getTrendingKeywords);
    
    this.historicKeywords$ = this.store.select(WebTrendingKeywordsSelector.getHistoricalTrending);

    this.userSearches$ = this.store.select(WebTrendingKeywordsSelector.getUserSearches);


  }

  searchKeyword(keyword:string) {
    this.router.navigate(['/search'], {
      queryParams: { query: this.commonService.encodeToBase64(keyword), categoryId : this.commonService.encodeToBase64(0),strictMode : this.commonService.encodeToBase64(true)}
    });
  }
}
