/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { AddEditResearchTopic, AddResearchTopicDocument, ResearchTopic, TopicDetail } from 'shared/src/interfaces/website/user-library.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebUserLibraryService {

  rootURL = '';
  sharedApiURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
    this.sharedApiURL = environment.rootApiUrl + environment.sharedApiPrefix;

  }

  ///////////////// add research topic ///////////////////////////
  addResearchTopic(payload: AddEditResearchTopic) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.addResearchTopic}`;
    return this.http
      .post(url, payload)
      .pipe(map((e: any) => e));
  }

  ///////////////// edit research topic ///////////////////////////
  editResearchTopic(payload: AddEditResearchTopic) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.editResearchTopic}`.replace('{id}', (payload.topicId ?? '').toString());
    return this.http
      .put(url, payload)
      .pipe(map((e: any) => e));
  }

  ///////////////// user research topic list including unassigned /////////////////
  researchTopics(payload: { pageNumber: number, pageSize: number }) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.researchTopics}`.replace('{pageSize}', (payload.pageSize ?? APP_CONSTANTS.pageSize).toString()).replace('{pageNumber}', (payload.pageNumber ?? 1).toString());
    return this.http
      .get<ResearchTopic>(url)
      .pipe(map((e: ResearchTopic) => e?.body));
  }

  ///////////////// user research topic Detail ///////////////////////////
  researchTopicDetail(payload: { pageNumber: number, pageSize: number, id: number }) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.topicDetail}`.replace('{id}', (payload.id ?? '').toString()).replace('{pageSize}', (payload.pageSize ?? APP_CONSTANTS.pageSize).toString()).replace('{pageNumber}', (payload.pageNumber ?? 1).toString());
    return this.http
      .get<TopicDetail>(url)
      .pipe(map((e: TopicDetail) => e?.body));
  }

  /////////////////////// user research topics excluding unassigned ////////////
  userResearchTopics() {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.userResearchTopic}`;
    return this.http
      .get(url)
      .pipe(map((e:any) => e));
  }
  ///////////////// assign document to topic ///////////////////////////
  assignDocumentToTopic(payload: AddResearchTopicDocument) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.assignDocumentToTopic}`;
    return this.http
      .post(url, payload)
      .pipe(map((e: any) => e));
  }

  ///////////////// delete research topic ///////////////////////////
  deleteResearchTopic(id: number) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.deleteResearchTopic}`.replace('{id}', (id ?? '').toString());
    return this.http
      .delete(url, {})
      .pipe(map((e: any) => e));
  }

  ///////////////// delete research topic ///////////////////////////
  deleteDocumentFromResearchTopic(topicId: number, docId: number) {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.userLibrary.deleteDocumentFromResearchTopic}`.replace('{id}', (topicId ?? '').toString()).replace('{docId}', (docId ?? '').toString());
    return this.http
      .delete(url, {})
      .pipe(map((e: any) => e));
  }

}
