
/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, props } from '@ngrx/store';
import { SearchResponse } from 'shared/src/interfaces/website/search.interface';

export const WebsiteSearch = createAction(
  '[Search] Website Search List',
  props<{ payload: any }>()
);

export const WebsiteSearchSuccess = createAction(
  '[Search] Website Search List Successfully',
  props<{ searchList: SearchResponse[]; totalRecords: number }>()
);

export const WebsiteSearchError = createAction(
  '[Search] Website Search List Error',
  props<{ message: string }>()
);

export const WebsiteGetFilters = createAction(
  '[Search] Website Filters List',
  props<{ payload: any }>()
);

export const WebsiteGetFiltersSuccess = createAction(
  '[Search] Website Filters List Success',
  props<{ filtersList: any }>()
);

export const WebsiteGetFiltersError = createAction(
  '[Search] Website Filters List Error',
  props<{ message: string }>()
);
