<div class="my-comments-page">
  @if(isLoading && CategoryCount.length==0) {
    <div class="loader__wrapper">
      <panjab-digi-lib-spinner [isFullScreen]="false"></panjab-digi-lib-spinner>
    </div>
  } @else {
    @if(UserComments.length > 0) {
      <div class="sidebar">
        <h3 class="mb-4" class="filterByCategory">Filter by Category</h3>
        @if(isMobile) {
          <select class="form-select sort__filter" aria-label="Default select example" [(ngModel)]="selectedCategoryID" (change)="changeCategory(selectedCategoryID)">
            @for(category of CategoryCount; track category.CategoryID) {
              <option [value]="category.CategoryID">{{category.CategoryName}} - {{category.CommentCount}}</option>
            }
          </select>
        } @else {
          <ul>
            @for(category of CategoryCount; track category.CategoryID;) {
              <li (click)="changeCategory(category.CategoryID)">
                <span class="category-name" [ngClass]="{selected: category.CategoryID === selectedCategoryID}">{{category.CategoryName}}</span>
                <span class="comment-count">{{category.CommentCount}}</span>
              </li>
            }
          </ul>
        }
      </div>
      <div class="comment-section">
      @if(isLoading && CategoryCount.length > 0) {
        <div class="loader__wrapper comment-section">
          <panjab-digi-lib-spinner [isFullScreen]="false"></panjab-digi-lib-spinner>
        </div>
      } @else {
        @for(comment of UserComments; track comment.CommentId) {
          <div class="comment-card">
            <div class="comment-header">
              <div class="doc-info">
                <span class="category">{{comment.CategoryName}}</span>
                <a class="comment-title cursor-pointer" [routerLink]="['/metadata', comment.DocID]" [queryParams]="{ commentId: comment.CommentId }">
                  {{comment.Title}}
                </a>
              </div>
              <div class="comment-time">
                <span class="comment-meta">{{comment.DateAdded | date:'dd/MMMM/yy HH:mm'}}</span>
              </div>
            </div>
            <p class="comment-text" [innerHTML]="isExpandedMap[comment.CommentId] ? comment.Comment : getTruncatedText(comment.Comment)">
            </p>
            @if(shouldShowReadMore(comment.Comment)) { 
              <span class="grey-color cursor-pointer" (click)="toggleComment(comment.CommentId)">
                {{ isExpandedMap[comment.CommentId] ? ('pdl-shared.content.readLessText' | translate) : ('pdl-shared.content.readMoreText' | translate) }}
              </span>
            }
          </div>
        }
      }

      @if (totalRecords > pageSize && !isLoading) {
        <div class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4">
            <ngb-pagination class="donations__pagination" [collectionSize]="totalRecords" [(page)]="pageNo"
                [pageSize]="pageSize" [rotate]="true" [ellipses]="true" [boundaryLinks]="true"
                [directionLinks]="true" (pageChange)="loadComments(selectedCategoryID)" />
        </div>
      }
      </div>
    } @else {
      <p class="w-100 text-center mt-5">
        {{'pdl-shared.content.noRecordsFound' | translate}}
      </p>
    }
  }

</div>