import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class DocumentFilesService {
  private rootURL: string;

  constructor(private http: HttpClient) {
    this.rootURL = environment.rootApiUrl;
  }
  /////////////// Fetch files by DocID ///////////////
  getFilesByDocId(docId: number): Observable<any> {
    const url = `${this.rootURL}${WEB_API_ENDPOINTS.metadata.getFiles}`;
    return this.http.get(url, {params:{DocID: docId.toString()}}).pipe(map((response: any) => response));
  }
}
