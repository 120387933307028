import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { environment, SpinnerComponent } from '@panjab-digi-lib/shared';
import { Subject, takeUntil } from 'rxjs';
import { APP_CONSTANTS } from '../../../../common/constants/app.constants';
import { CuratedCollection } from 'shared/src/interfaces/website/curated-collection.interface';
import { WebCuratedCollectionService } from 'shared/src/services/website/curated-collection/curated-collection.service';
import { GenericListingPayload, SortOrder } from 'shared/src/interfaces/common.interface';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'panjab-digi-lib-curated-collection-list',
  standalone: true,
  imports: [
    CommonModule, 
    RouterLink, 
    RouterModule, 
    TranslateModule, 
    NgbPaginationModule, 
    SpinnerComponent
  ],
  templateUrl: './curated-collection-list.component.html',
  styleUrl: './curated-collection-list.component.scss',
})
export class CuratedCollectionListComponent implements OnInit, OnDestroy {

  pageNo = 1;
  pageSize = APP_CONSTANTS.pageSize;
  sortOrder: SortOrder = {orderBy: 'DocumentCount', order: 'DESC'};

  private unsubscribe$: Subject<void> = new Subject();

  curatedCollectionList: CuratedCollection[] = [];
  imageBaseURL = environment.azureCloudStrorage.publicUrl;
  fallbackImage = APP_CONSTANTS.categoryFallbackImage;
  totalRecords: number = 0;
  isLoading: boolean = true;
  sortBy: string = 'documentCount';
  isMyCollectionPage: boolean = false;
  constructor(
    private curatedCollectionService: WebCuratedCollectionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isMyCollectionPage = this.router.url.includes('/my-collections');
    this.loadCuratedCollections();  
  }

  loadCuratedCollections() {
    let payload: GenericListingPayload = { 
      ...this.sortOrder, 
      pageNumber: this.pageNo, 
      pageSize: this.pageSize,
    };
    this.isLoading = true;
    
    const getCollectionMethod = this.isMyCollectionPage 
      ? this.curatedCollectionService.getUserCollections.bind(this.curatedCollectionService) 
      : this.curatedCollectionService.getCuratedCollections.bind(this.curatedCollectionService);

    getCollectionMethod(payload).pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (response) => {
        this.isLoading = false;
        this.curatedCollectionList = response.collections;
        this.totalRecords = response.totalRecords;

      }, error: (error) => {
        this.isLoading = false;
        this.curatedCollectionList = [];
        this.totalRecords = 0;
        console.log('error', error);
      }
    });
  }

  sortCollections(orderBy: string, order: string, sortBy: string) {
    this.sortOrder = {orderBy, order};
    this.sortBy = sortBy;
    this.loadCuratedCollections();
  }
  
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }  
}
