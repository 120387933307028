<div class="home-banner">
    <div class="home-slider">
        <ngx-slick-carousel class="carousel" 
                            #slickModal="slick-carousel" 
                            [config]="slideConfig" 
                            (init)="slickInit($event)">
            @for (slide of bannerSlides$ | async; track slide.BannerOrder) {
                <div ngxSlickItem class="slide homebanner-slide">
                    @if(slide.BannerUrl.length > 0) {
                        <a href="{{slide.BannerUrl | httpFormat}}" target="_blank">
                            <picture>
                              <source media="(min-width:320px) and (max-width: 600px)" [srcset]="imageBasUrl+slide.BannerMobileImage">
                              <img [src]="imageBasUrl+slide.BannerImage" class="homebanner-slide-bg" alt="" width="100%">
                            </picture>
                        </a>
                    } @else {
                        <picture>
                            <source media="(min-width:320px) and (max-width: 600px)" [srcset]="imageBasUrl+slide.BannerMobileImage">
                            <img [src]="imageBasUrl+slide.BannerImage" class="homebanner-slide-bg" alt="" width="100%">
                        </picture>
                    }
                </div>
            }
        </ngx-slick-carousel>
    </div>
                 
    <div class="card homesearch-card">
        <div class="card-body">
            <form class="col-12 col-lg-auto mb-4  homecard-search d-flex " role="search">
                <label class="search-label"> <img src="assets/images/icons/search-icon.svg">
                </label>
                <input type="search" class="form-control form-control-dark homesearch-input" [(ngModel)]="searchInput" autocomplete="off"
                (input)="suggestion($event)" (keydown.enter)="searchOnEnterKey()" (keydown)="handleKeyDown($event)" placeholder="Type title, author..." aria-label="Search" name="search">
                @if(suggestionList.length > 0) {
                <div class="auto__suggested">
                    @for (suggestion of suggestionList; track suggestion;let index=$index) {
                    <p (click)="search(suggestion)" [class.selected]="index === selectedIndex">{{suggestion}}</p>
                    }
                </div>
                }

                <div class="dropdown homesearch-dropdown">
                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{selectedCategory === '' ? ('pdl-website.headerSearch.searchText' | translate) : selectedCategory }}
                    </a>
                    
                    <ul class="dropdown-menu">
                        @if(categoryId !== 0) {
                        <li class="cursor-pointer"><a class="dropdown-item" (click)="setCategory(0,'Search all')"> {{'pdl-website.headerSearch.searchText' |
                                translate }}</a></li>
                        }
                        @for(category of categories$ | async; track category.categoryID) {
                        <li class="cursor-pointer"><a class="dropdown-item" (click)="setCategory(category.categoryID,category.Name)">{{category.Name}}</a></li>
                        } @empty{
                            <li class="ms-2"><a class="">{{'pdl-shared.content.noCategoryEnabled' | translate}}</a></li>
   
                        }
                    </ul>
                </div>
            </form>
            <!-- <div class="home-advanced-link d-flex justify-content-end">
                <a href="javascript:void(0);" [routerLink]="'/advanced-search'" class="pdl-link">{{'pdl-website.advancedsearchText.titleText' | translate }}</a>
            </div>    -->
        </div>
    </div>
</div>
<div class="trending-block">
    <panjab-digi-lib-trending-keywords></panjab-digi-lib-trending-keywords>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="most-popular-reads">
    <panjab-digi-lib-most-popular></panjab-digi-lib-most-popular>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="overview-section">
    <panjab-digi-lib-overview></panjab-digi-lib-overview>
</div>
<div class="categories-block">
    <panjab-digi-lib-categories></panjab-digi-lib-categories>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="curated-collections-block">
    <panjab-digi-lib-curated-collections></panjab-digi-lib-curated-collections>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<!-- <div class="exhibitions-block">
    <panjab-digi-lib-exhibitions></panjab-digi-lib-exhibitions>
</div> -->
<!-- <div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div> -->
<div class="downloads-block">
    <panjab-digi-lib-downloads></panjab-digi-lib-downloads>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="video-gallery-section">
    <panjab-digi-lib-video-gallery></panjab-digi-lib-video-gallery>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="mailinglist-section">
    <panjab-digi-lib-mailing-list></panjab-digi-lib-mailing-list>
</div>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
<div class="adopt-donate">
    <panjab-digi-lib-donate></panjab-digi-lib-donate>
</div>
