/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private mapsApiLoaded = false;

  loadMapsApi(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.mapsApiLoaded) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.getApiKey()}&libraries=places`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.mapsApiLoaded = true;
        resolve();
      };

      script.onerror = (error) => {
        reject(new Error(`Failed to load Google Maps API: ${error}`));
      };

      document.head.appendChild(script);
    });
  }

  private getApiKey(): string {
    return environment.googleMapsApiKey;
  }
  
}
