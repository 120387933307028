// import { Inject } from "@angular/core";
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from './components/spinner/spinner.component';
let appLoader: any;

export function showLoader(modalService: any, loaderText: string = '') {
  appLoader = modalService.open(SpinnerComponent, {
    centered: true,
    animation: false,
    modalDialogClass: 'app-spinner',
  });
  appLoader.componentInstance.name = loaderText;
}

export function hideLoader() { 
  if(appLoader) {
    appLoader.close();
  }
}

export function isTabActive(router: any, url: string, exactMatch: boolean) {
  return router.isActive(url, exactMatch);
}

export const getFirstAlphabetPlace = (value: string): number => {
  const initChar: any = value.charAt(0).toLowerCase();
  const alphabetJson: any = {
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    i: 9,
    j: 10,
    k: 11,
    l: 12,
    m: 13,
    n: 14,
    o: 15,
    p: 16,
    q: 17,
    r: 18,
    s: 19,
    t: 20,
    u: 21,
    v: 22,
    w: 23,
    x: 24,
    y: 25,
    z: 26,
  };
  return alphabetJson[initChar] ?? 1;
};
const getAdminMenu: any[] = [
  {
    name: 'pdl-shared.menus.mainframe',
    children: [
      {
        name: 'pdl-shared.menus.dashboard',
        icon: [
          'assets/images/icons/dashboard-icon.svg',
          'assets/images/icons/dashboard-icon-active.svg',
        ],
        url: 'dashboard',
        path: 'dashboard',
        children: [],
      },
      {
        name: 'pdl-shared.menus.pdlEmployees',
        icon: [
          'assets/images/icons/employee-icon.svg',
          'assets/images/icons/employee-icon-active.svg',
        ],
        url: 'pdl-employees/board-of-directors',
        path: 'pdl-employees',
        children: [
          {
            name: 'pdl-shared.menus.pdlTeam',
            url: 'pdl-employees/board-of-directors',
            path: 'pdl-employees/board-of-directors',
            children: [],
          },
          {
            name: 'pdl-shared.menus.employees',
            url: 'pdl-employees/employees',
            path: 'pdl-employees/employees',
            children: [],
          },
          {
            name: 'pdl-shared.menus.reports',
            url: 'pdl-employees/reports/funder-login-control',
            path: 'pdl-employees/reports',
            children: [
              {
                name: 'pdl-shared.menus.funderLoginControl',
                url: 'pdl-employees/reports/funder-login-control',
                path: 'pdl-employees/reports/funder-login-control',
                children: [],
              },
              {
                name: 'pdl-shared.menus.missingThumbnails',
                url: 'pdl-employees/reports/missing-thumbnail',
                path: 'pdl-employees/reports/missing-thumbnail',
                children: [],
              },
              {
                name: 'pdl-shared.menus.sponsorships',
                url: 'pdl-employees/reports/sponsorships',
                path: 'pdl-employees/reports/sponsorships',
                children: [],
              },
            ],
          },
          {
            name: 'pdl-shared.menus.volunteer',
            url: 'pdl-employees/volunteer',
            path: 'pdl-employees/volunteer',
            children: [],
          },
        ],
      },

      {
        name: 'pdl-shared.menus.masterForms',
        icon: [
          'assets/images/icons/master-forms-icon.svg',
          'assets/images/icons/master-forms-icon-active.svg',
        ],
        url: 'master-forms/manage/pdl-services',
        path: 'master-forms',
        children: [
          {
            name: 'Manage',
            url: 'master-forms/manage/pdl-services',
            path: 'master-forms/manage',
            children: [
              {
                name: 'pdl-shared.menus.pdlservices',
                url: 'master-forms/manage/pdl-services',
                path: 'master-forms/manage/pdl-services',
                children: [],
              },
              {
                name: 'pdl-shared.menus.categories',
                url: 'master-forms/manage/categories',
                path: 'master-forms/manage/categories',
                children: [],
              },
              {
                name: 'pdl-shared.menus.processes',
                url: 'master-forms/manage/processes',
                path: 'master-forms/manage/processes',
                children: [],
              },
              {
                name: 'pdl-shared.menus.metadata',
                url: 'master-forms/manage/metadata',
                path: 'master-forms/manage/metadata',
                children: [],
              },
              {
                name: 'pdl-shared.menus.serverPaths',
                url: 'master-forms/manage/server-paths',
                path: 'master-forms/manage/server-paths',
                children: [],
              },
              {
                name: 'pdl-shared.menus.subjects',
                url: 'master-forms/manage/subjects',
                path: 'master-forms/manage/subjects',
                children: [],
              },
              {
                name: 'pdl-shared.menus.scripts',
                url: 'master-forms/manage/scripts',
                path: 'master-forms/manage/scripts',
                children: [],
              },
              {
                name: 'pdl-shared.menus.languages',
                url: 'master-forms/manage/languages',
                path: 'master-forms/manage/languages',
                children: [],
              },
              {
                name: 'pdl-shared.menus.currency',
                url: 'master-forms/manage/currency',
                path: 'master-forms/manage/currency',
                children: [],
              },
              {
                name: 'pdl-shared.menus.country',
                url: 'master-forms/manage/country',
                path: 'master-forms/manage/country',
                children: [],
              },
              {
                name: 'pdl-shared.menus.sections',
                url: 'master-forms/manage/sections',
                path: 'master-forms/manage/sections',
                children: [],
              },
              {
                name: 'pdl-shared.menus.publishers',
                url: 'master-forms/manage/publishers',
                path: 'master-forms/manage/publishers',
                children: [],
              },
              {
                name: 'pdl-shared.menus.translators',
                url: 'master-forms/manage/translators',
                path: 'master-forms/manage/translators',
                children: [],
              },
              {
                name: 'pdl-shared.menus.authors',
                url: 'master-forms/manage/authors',
                path: 'master-forms/manage/authors',
                children: [],
              },
              {
                name: 'pdl-shared.menus.contributors',
                url: 'master-forms/manage/contributors',
                path: 'master-forms/manage/contributors',
                children: [],
              },
              {
                name: 'pdl-shared.menus.editors',
                url: 'master-forms/manage/editors',
                path: 'master-forms/manage/editors',
                children: [],
              },
              {
                name: 'pdl-shared.menus.designations',
                url: 'master-forms/manage/designations',
                path: 'master-forms/manage/designations',
                children: [],
              },
              {
                name: 'pdl-shared.menus.departments',
                url: 'master-forms/manage/departments',
                path: 'master-forms/manage/departments',
                children: [],
              },
            ],
          },
          {
            name: 'pdl-shared.menus.correlated',
            url: 'master-forms/correlated/subjects-per-category',
            path: 'master-forms/correlated',
            children: [
              {
                name: 'pdl-shared.content.subjectsPerCategory',
                url: 'master-forms/correlated/subjects-per-category',
                path: 'master-forms/correlated/subjects-per-category',
                children: [],
              },
              {
                name: 'pdl-shared.content.scriptsPerLanguage',
                url: 'master-forms/correlated/scripts-per-language',
                path: 'master-forms/correlated/scripts-per-language',
                children: [],
              },
              {
                name: 'pdl-shared.content.metadataPerCategory',
                url: 'master-forms/correlated/metadata-per-category',
                path: 'master-forms/correlated/metadata-per-category',
                children: [],
              },
              {
                name: 'pdl-shared.content.processPerCategory',
                url: 'master-forms/correlated/process-per-category',
                path: 'master-forms/correlated/process-per-category',
                children: [],
              },
            ],
          },
          {
            name: 'pdl-shared.menus.comments',
            url: 'master-forms/comments/articles-comments',
            path: 'master-forms/comments',
            children: [
              {
                name: 'pdl-shared.menus.articlesComments',
                url: 'master-forms/comments/articles-comments',
                path: 'master-forms/comments/articles-comments',
                children: [],
              },
              {
                name: 'pdl-shared.menus.metaDataComments',
                url: 'master-forms/comments/metadata-comments',
                path: 'master-forms/comments/metadata-comments',
                children: [],
              },
            ],
          },
          {
            name: 'pdl-shared.menus.requests',
            url: 'master-forms/requests/metadata-requests',
            path: 'master-forms/requests',
            children: [
              {
                name: 'pdl-shared.menus.metadataRequests',
                url: 'master-forms/requests/metadata-requests',
                path: 'master-forms/requests/metadata-requests',
                children: [],
              }
            ],
          }
        ],
      },
      {
        name: 'pdl-shared.menus.webUserManagement',
        icon: [
          'assets/images/icons/web-user-icon.svg',
          'assets/images/icons/web-user-icon-active.svg',
        ],
        url: 'web-user-management/web-user/access-chart-settings',
        path: 'web-user-management',
        children: [
          {
            name: 'pdl-shared.menus.webUser',
            url: 'web-user-management/web-user/access-chart-settings',
            path: 'web-user-management/web-user',
            children: [
              {
                name: 'pdl-shared.menus.accessChartSettings',
                url: 'web-user-management/web-user/access-chart-settings',
                path: 'web-user-management/web-user/access-chart-settings',
                children: [],
              },
              {
                name: 'pdl-shared.menus.pdlUserSearches',
                url: 'web-user-management/web-user/pdl-user-searches',
                path: 'web-user-management/web-user/pdl-user-searches',
                children: [],
              },
            ],
          },
          {
            name: 'pdl-shared.menus.siteSettings',
            url: 'web-user-management/site-settings/web-doc-manager',
            path: 'web-user-management/site-settings',
            children: [
              {
                name: 'pdl-shared.menus.webDocManager',
                url: 'web-user-management/site-settings/web-doc-manager',
                path: 'web-user-management/site-settings/web-doc-manager',
                children: [],
              },
              {
                name: 'pdl-shared.menus.newsletterSubscriptions',
                url: 'web-user-management/site-settings/newsletter-subscriptions',
                path: 'web-user-management/site-settings/newsletter-subscriptions',
                children: [],
              },
            ],
          },
          {
            name: 'Donors',
            url: 'web-user-management/donors/list-of-donors',
            path: 'web-user-management/donors',
            children: [
              {
                name: 'pdl-shared.menus.listOfDonors',
                url: 'web-user-management/donors/list-of-donors',
                path: 'web-user-management/donors/list-of-donors',
                children: [],
              },
              {
                name: 'pdl-shared.menus.guestBook',
                url: 'web-user-management/donors/guestbook',
                path: 'web-user-management/donors/guestbook',
                children: [],
              },
              {
                name: 'pdl-shared.menus.volunteering',
                url: 'web-user-management/donors/volunteering',
                path: 'web-user-management/donors/volunteering',
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'pdl-shared.menus.fundraising',
        icon: [
          'assets/images/icons/fundraising-icon.svg',
          'assets/images/icons/fundraising-icon-active.svg',
        ],
        url: 'fundraising/adopt/list-adopters',
        path: 'fundraising',
        children: [
          {
            name: 'pdl-shared.menus.adopt',
            url: 'fundraising/adopt/list-adopters',
            path: 'fundraising/adopt',
            children: [
              {
                name: 'pdl-shared.menus.listAdopters',
                url: 'fundraising/adopt/list-adopters',
                path: 'fundraising/adopt/list-adopters',
                children: [],
              },
              {
                name: 'pdl-shared.menus.toAdopt',
                url: 'fundraising/adopt/to-adopt',
                path: 'fundraising/adopt/to-adopt',
                children: [],
              },
              {
                name: 'pdl-shared.menus.setAdoptPrice',
                url: 'fundraising/adopt/set-adopt-price',
                path: 'fundraising/adopt/set-adopt-price',
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'pdl-shared.menus.digitalization',
        icon: [
          'assets/images/icons/digitalization-icon.svg',
          'assets/images/icons/digitalization-icon-active.svg',
        ],
        url: 'digitalization',
        path: 'digitalization',
        children: [],
      },
    ],
  },
  {
    name: 'pdl-shared.menus.homePageHeading',
    children: [
      {
        name: 'pdl-shared.menus.homePage',
        icon: [
          'assets/images/icons/home-icon.svg',
          'assets/images/icons/home-icon-active.svg',
        ],
        url: 'home/banners',
        path: 'home',
        children: [
          {
            name: 'pdl-shared.menus.banners',
            url: 'home/banners',
            path: 'home/banners',
            children: [],
          },
          {
            name: 'pdl-shared.menus.trending',
            url: 'home/trending/today',
            path: 'home/trending',
            children: [
              {
                name: 'pdl-shared.menus.today',
                url: 'home/trending/today',
                path: 'home/trending/today',
                children: [],
              },
              {
                name: 'pdl-shared.menus.historical',
                url: 'home/trending/historical',
                path: 'home/trending/historical',
                children: [],
              },
            ],
          },
          {
            name: 'pdl-shared.menus.mostPopularReads',
            url: 'home/most-popular-reads',
            path: 'home/most-popular-reads',
            children: [],
          },
          {
            name: 'pdl-shared.menus.categoriesOfLiterature',
            url: 'home/categories-of-literature',
            path: 'home/categories-of-literature',
            children: [],
          },
        ],
      },
      {
        name: 'pdl-shared.menus.downloads',
        icon: [
          'assets/images/icons/download-icon.svg',
          'assets/images/icons/download-icon-active.svg',
        ],
        url: 'downloads/templates/template1',
        path: 'downloads',
        children: [
          {
            name: 'pdl-shared.menus.templates',
            url: 'downloads/templates/template1',
            path: 'downloads/templates',
            children: [
              {
                name: 'pdl-shared.menus.template1',
                url: 'downloads/templates/template1',
                path: 'downloads/templates/template1',
                children: [],
              },
              {
                name: 'pdl-shared.menus.template2',
                url: 'downloads/templates/template2',
                path: 'downloads/templates/template2',
                children: [],
              },
            ],
          },
          {
            name: 'pdl-shared.menus.allDownloads',
            url: 'downloads/all-downloads',
            path: 'downloads/all-downloads',
            children: [],
          },
        ],
      },
      {
        name: 'pdl-shared.menus.curatedCollections',
        icon: [
          'assets/images/icons/curated-collection-icon.svg',
          'assets/images/icons/curated-collection-icon-active.svg',
        ],
        url: 'curated-collections/all-collections',
        path: 'curated-collections',
        children: [
          {
            name: 'pdl-shared.menus.allCollections',
            url: 'curated-collections/all-collections',
            path: 'curated-collections/all-collections',
            children: [],
          },
        ],
      },
      {
        name: 'pdl-shared.menus.exhibition',
        icon: [
          'assets/images/icons/exhibitions-icon.svg',
          'assets/images/icons/exhibitions-icon-active.svg',
        ],
        url: 'exhibition/exhibitions',
        path: 'exhibition',
        children: [
          {
            name: 'pdl-shared.menus.exhibition',
            url: 'exhibition/exhibitions',
            path: 'exhibition/exhibitions',
            children: [],
          },
          {
            name: 'pdl-shared.menus.askForExhibition',
            url: 'exhibition/ask-for-exhibition',
            path: 'exhibition/ask-for-exhibition',
            children: [],
          },
          {
            name: 'pdl-shared.menus.contactUs',
            url: 'exhibition/contact-us',
            path: 'exhibition/contact-us',
            children: []
          }
        ],
      },
      {
        name: 'pdl-shared.menus.videogallery',
        icon: [
          'assets/images/icons/video-gallery-icon.svg',
          'assets/images/icons/video-gallery-icon-active.svg',
        ],
        url: 'video-gallery/video-gallery',
        path: 'video-gallery',
        children: [
          {
            name: 'pdl-shared.menus.videogallery',
            url: 'video-gallery/video-gallery',
            path: 'video-gallery/video-gallery',
            children: [],
          },
          {
            name: 'pdl-shared.menus.videogallerytemplate',
            url: 'video-gallery/video-gallery-template',
            path: 'video-gallery/video-gallery-template',
            children: [],
          },
        ],
      },
      {
        name: 'pdl-shared.menus.newsandevents',
        icon: [
          'assets/images/icons/news-events-icon.svg',
          'assets/images/icons/news-events-icon-active.svg',
        ],
        url: 'news-and-events',
        path: 'news-and-events',
        children: [],
      },
    ],
  },
  {
    name: 'pdl-shared.menus.others',
    children: [
      {
        name: 'pdl-shared.menus.changePassword',
        icon: ['assets/images/icons/change-password-icon.svg', ''],
        url: 'change-password',
        path: 'change-password',
        children: [],
      },
    ],
  },
];

const getStaffMenu: any[] = [
  {
    name: 'pdl-shared.menus.mainframe',
    children: [
      {
        name: 'pdl-shared.menus.dashboard',
        icon: [
          'assets/images/icons/dashboard-icon.svg',
          'assets/images/icons/dashboard-icon-active.svg',
        ],
        url: 'dashboard',
        path: 'dashboard',
        children: [],
      },
      {
        name: 'pdl-shared.menus.pdlstaff',
        icon: [
          'assets/images/icons/menu-icon-pdl-staff.svg',
          'assets/images/icons/menu-icon-pdl-staff-active.svg',
        ],
        url: 'staff',
        path: 'staff',
        children: [],
      },
      {
        name: "pdl-shared.menus.verifyLiterature",
        icon: ["assets/images/icons/menu-icon-verify-literature.svg", "assets/images/icons/menu-icon-verify-literature-active.svg"],
        url: "verify-literature",
        path: "verify-literature",
        children: [
          {
            name: "pdl-shared.menus.pdlstaffWork",
            url: "verify-literature/staff-work",
            path: "verify-literature/staff-work",
            children: []
          },
          {
            name: "pdl-shared.menus.volunteerChanges",
            url: "verify-literature/volunteer-changes",
            path: "verify-literature/volunteer-changes",
            children: []
          },
          {
            name: "pdl-shared.menus.custodianUploads",
            url: "verify-literature/custodian-uploads",
            path: "verify-literature/custodian-uploads",
            children: []
          }
        ]
      },
      {
        name: "pdl-shared.menus.documentManagement",
        icon: ['assets/images/icons/menu-icon-document-management.svg', 'assets/images/icons/menu-icon-document-management-active.svg'],
        url: "document-list",
        path: "document-list",
        children: [
        ]
      },
      {
        name: "pdl-shared.menus.myAssignedTasks",
        icon: ['assets/images/icons/menu-icon-assigned-tasks.svg', 'assets/images/icons/menu-icon-assigned-tasks-active.svg'],
        url: "assigned-tasks",
        path: "assigned-tasks",
        children: [
          {
            name: "pdl-shared.menus.currentTasks",
            url: "assigned-tasks/current",
            path: "assigned-tasks/current",
            children: []
          },
          {
            name: "pdl-shared.menus.completedTasks",
            url: "assigned-tasks/completed",
            path: "assigned-tasks/completed",
            children: []
          }
        ]
      },
      {
        name: "pdl-shared.menus.accessionNumber",
        icon: ['assets/images/icons/menu-icon-accession-number.svg', 'assets/images/icons/menu-icon-accession-number-active.svg'],
        url: "generate-accession-number",
        path: "generate-accession-number",
        children: [
        ]
      },
    ],
  },
  {
    name: 'pdl-shared.menus.others',
    children: [
      {
        name: 'pdl-shared.menus.changePassword',
        icon: ['assets/images/icons/change-password-icon.svg', ''],
        url: 'change-password',
        path: 'change-password',
        children: [],
      },
    ],
  },
];

export { getAdminMenu, getStaffMenu };
