import { createReducer, on } from '@ngrx/store';
import { DocumentFilesActions } from '../../actions';

export interface State {
  pdfFiles: any[];
  imageFiles: any[];
  systemTimeMismatch: boolean,
  isLoading: boolean;
  errorMessage: string | null;
}

export const initialState: State = {
  pdfFiles: [],
  imageFiles: [],
  systemTimeMismatch: false,
  isLoading: false,
  errorMessage: null,
};

export const reducer = createReducer(
  initialState,
  on(DocumentFilesActions.loadDocumentFiles, (state) => ({
    ...state,
    isLoading: true,
    errorMessage: null,
  })),
  on(DocumentFilesActions.loadDocumentFilesSuccess, (state, { pdfFiles, imageFiles }) => ({
    ...state,
    pdfFiles,
    imageFiles,
    isLoading: false,
    errorMessage: null,
    systemTimeMismatch: false,
  })),
  on(DocumentFilesActions.loadDocumentFilesError, (state, { message }) => {
    
    const systemTimeMismatch = message === 'SYSTEM_TIME_INCORRECT';
    return ({
      ...state,
      isLoading: false,
      systemTimeMismatch,
      errorMessage: message,
    })
  })
);
