/* eslint-disable no-prototype-builtins */
/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {WebSearchActions} from "../../actions/index";
import { SearchResponse } from 'shared/src/interfaces/website/search.interface';

export interface State extends EntityState<SearchResponse> {
  isLoading?: boolean;
  isSaving?: boolean;
  isError?: boolean;
  errorMessage?: string;
  filters : any;
  totalRecords : number;
}

export const adapter = createEntityAdapter<SearchResponse>({
  selectId: (e) => e.DocID
});

export const initialState = adapter.getInitialState( {
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMessage: '',
  filters : {},
  totalRecords : 0
});

export const reducer = createReducer(
  initialState,
  on(WebSearchActions.WebsiteSearch,(state, action) =>
    ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    })
  ),
  on(WebSearchActions.WebsiteSearchSuccess, (state, action) => ({
    ...adapter.setAll(action?.searchList, {
      ...state,
      totalRecords: action?.totalRecords,
      isLoading: false,
      isError: false,
    })
  })),

  on(WebSearchActions.WebsiteSearchError,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.message,
    })
  ),
  on(WebSearchActions.WebsiteGetFilters, (state, action) => ({
    ...state,
    isLoading: true,
    isError: false,
    errorMessage: ''
  })),
  
  on(WebSearchActions.WebsiteGetFiltersSuccess, (state, action) => {
    const filtersList = action.filtersList;
    const filters :any = {};
    for (const key in filtersList) {
      if (filtersList.hasOwnProperty(key)) {
        const filter = filtersList[key];
        filters[key] = filter;
      }
    }

    return {
      ...state,
      filters,
      isLoading: false,
      isError: false,
      errorMessage: ''
    };
  }),
  
  on(WebSearchActions.WebsiteGetFiltersError, (state, action) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: action.message,
  }))
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();