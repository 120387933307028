<!----------------------------gallery section --------------------->
<section>
   <ngx-slick-carousel class="carousel mb-0" #slickCarousel="slick-carousel" [config]="slideConfig"
      (afterChange)="onCarouselChange($event)">
      @for(item of items;track item) {
      <div ngxSlickItem class="slide downloads-slide pe-4">
         <div class="downloads-slide-card">
            <div class="block-img">
               @if(item.ItemPath) {
               <img src="{{imageBaseUrl}}{{item.ItemPath}}" alt="img" />
               } @else {
               <img src="{{fallbackImage}}" alt="img" />
               }
               <div class="title">{{item.Title}}</div>
            </div>
         </div>

         <div id="exhibition_detail" class="">
            <section class="container exhibition_detail mt-5">
               <p class="heading">Description</p>
               <div [innerHTML]="item.Description"></div>
            </section>
         </div>
      </div>
      }
   </ngx-slick-carousel>
   <div class="d-flex justify-content-center mb-5 mt-3" style="gap: 20px">
      <button class="btn btn__primary" (click)="slickCarousel.slickPrev()">
         <img src="assets/images/icons/arrow-white-left.svg" alt="">
      </button>
      <button class="btn btn__primary" (click)="slickCarousel.slickNext()">
         <img src="assets/images/icons/arrow-white-right.svg" alt="">
      </button>
   </div>

   <div class="d-flex infinite-hr-scroll no-scrollbar mb-5">
      @for(item of items;track item) {
      <div (click)="setActiveSlide(item)">
         @if(item.ItemPath) {
         <img class="cursor-pointer" src="{{imageBaseUrl}}{{item.ItemPath}}" alt="img" />
         } @else {
         <img class="cursor-pointer" src="{{fallbackImage}}" alt="img" />
         }
      </div>
      }
   </div>
</section>
