<section class="innerpage-banner">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="breadcrumb d-flex align-items-center justify-content-start flex-wrap"
        >
          <a class="breadcrumb-link font-regular" href="#">
            <img
              class="home-icon"
              src="assets/images/icons/homepage-ico.svg"
              alt="icon"
            />
            {{ 'pdl-website.homePage.title' | translate }}
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="" />
          </span>
          <a
            class="breadcrumb-link font-regular"
            href="javascript:void(0)"
            [routerLink]="'/all-collections'"
          >
            {{ 'pdl-website.forumPage.title' | translate }}
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="page-title">
          <h1 class="heading-dark semibold mb-0">
            {{ 'pdl-website.forumPage.title' | translate }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-padding">
  <div class="container">
    <div class="col mb-3">
      <div
        class="results-and-filters d-flex flex-wrap align-items-center justify-content-between"
      >
        <div
          class="results-on-page d-flex flex-wrap align-items-center justify-content-start"
        >
          @if(totalRecords) {
          <span class="records__count">{{ fromRecords }}</span>
          <span class="records__count">{{
            'pdl-shared.pagination.to' | translate
          }}</span>
          <span class="records__count">{{ toRecords }}</span>
          <span class="records__count">{{
            'pdl-shared.pagination.of' | translate
          }}</span>
          <span class="records__count">{{
            'pdl-shared.pagination.about' | translate
          }}</span>
          <span class="records__count">{{ totalRecords }}</span>
          <span class="records__count">{{
            'pdl-shared.pagination.entries' | translate
          }}</span>
          } @else {
          <span>{{
            'pdl-website.eventsCampaigns.allEventsCampaigns' | translate
          }}</span>
          }
        </div>
        <div
          class="category-action d-flex flex-wrap align-items-center justify-content-end"
        >
          <div class="d-flex">
            <img src="assets/images/icons/search-icon.svg"/>
            <input type="text" class="form-control form__field" id="search_input" placeholder="Search By Title...">
          </div>

          <div class="page-filters-dropdown">
            <div class="dropdown page-filter-dropdown">
              <a
                class="page-filter-action btn btn-secondary dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="assets/images/icons/filters-ico.svg" alt="icon" />
                {{ 'pdl-website.forumPage.filter.sortBy' | translate }}
              </a>

              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item cursor-pointer"
                    [ngClass]="sortBy === 'title' ? 'active-sort' : ''"
                    (click)="sortCollections('Title', 'ASC', 'title')"
                  >
                    {{ 'pdl-website.forumPage.title' | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <a class="page-filter-action btn" href="#" role="button">
            {{ 'pdl-website.forumPage.filter.relevance' | translate }}
          </a>

          <a class="page-filter-action btn" href="#" role="button">
            {{ 'pdl-website.forumPage.filter.mostViews' | translate }}
          </a>

          <a class="page-filter-action btn" href="#" role="button">
            {{ 'pdl-website.forumPage.filter.title' | translate }}
          </a>

          <a class="page-filter-action btn" href="#" role="button">
            {{ 'pdl-website.forumPage.filter.datePublished' | translate }}
          </a>

          <div class="list-styles">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  [class.active]="viewMode === 'list'"
                  (click)="changeView('list')"
                  id="list-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#list-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="list-tab-pane"
                  aria-selected="true"
                >
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.125 5.8125C3.125 5.30469 3.51562 4.875 4.0625 4.875H5.9375C6.44531 4.875 6.875 5.30469 6.875 5.8125V7.6875C6.875 8.23438 6.44531 8.625 5.9375 8.625H4.0625C3.51562 8.625 3.125 8.23438 3.125 7.6875V5.8125ZM3.125 12.0625C3.125 11.5547 3.51562 11.125 4.0625 11.125H5.9375C6.44531 11.125 6.875 11.5547 6.875 12.0625V13.9375C6.875 14.4844 6.44531 14.875 5.9375 14.875H4.0625C3.51562 14.875 3.125 14.4844 3.125 13.9375V12.0625ZM5.9375 17.375C6.44531 17.375 6.875 17.8047 6.875 18.3125V20.1875C6.875 20.7344 6.44531 21.125 5.9375 21.125H4.0625C3.51562 21.125 3.125 20.7344 3.125 20.1875V18.3125C3.125 17.8047 3.51562 17.375 4.0625 17.375H5.9375Z"
                      fill="#7A8699"
                    />
                    <path
                      opacity="0.4"
                      d="M8.75 6.75C8.75 6.08594 9.29688 5.5 10 5.5H21.25C21.9141 5.5 22.5 6.08594 22.5 6.75C22.5 7.45312 21.9141 8 21.25 8H10C9.29688 8 8.75 7.45312 8.75 6.75ZM8.75 13C8.75 12.3359 9.29688 11.75 10 11.75H21.25C21.9141 11.75 22.5 12.3359 22.5 13C22.5 13.7031 21.9141 14.25 21.25 14.25H10C9.29688 14.25 8.75 13.7031 8.75 13ZM21.25 18C21.9141 18 22.5 18.5859 22.5 19.25C22.5 19.9531 21.9141 20.5 21.25 20.5H10C9.29688 20.5 8.75 19.9531 8.75 19.25C8.75 18.5859 9.29688 18 10 18H21.25Z"
                      fill="#7A8699"
                    />
                  </svg>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  [class.active]="viewMode === 'grid'"
                  (click)="changeView('grid')"
                  id="grid-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#grid-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="grid-tab-pane"
                  aria-selected="false"
                >
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.25 9.875C11.25 10.9297 10.3906 11.75 9.375 11.75H5.625C4.57031 11.75 3.75 10.9297 3.75 9.875V6.125C3.75 5.10938 4.57031 4.25 5.625 4.25H9.375C10.3906 4.25 11.25 5.10938 11.25 6.125V9.875ZM21.25 19.875C21.25 20.9297 20.3906 21.75 19.375 21.75H15.625C14.5703 21.75 13.75 20.9297 13.75 19.875V16.125C13.75 15.1094 14.5703 14.25 15.625 14.25H19.375C20.3906 14.25 21.25 15.1094 21.25 16.125V19.875Z"
                      fill="#7A8699"
                    />
                    <path
                      opacity="0.4"
                      d="M21.25 10.1875C21.25 11.0859 20.5469 11.75 19.6875 11.75H15.3125C14.4141 11.75 13.75 11.0859 13.75 10.1875V5.8125C13.75 4.95312 14.4141 4.25 15.3125 4.25H19.6875C20.5469 4.25 21.25 4.95312 21.25 5.8125V10.1875ZM11.25 20.1875C11.25 21.0859 10.5469 21.75 9.6875 21.75H5.3125C4.41406 21.75 3.75 21.0859 3.75 20.1875V15.8125C3.75 14.9531 4.41406 14.25 5.3125 14.25H9.6875C10.5469 14.25 11.25 14.9531 11.25 15.8125V20.1875Z"
                      fill="#7A8699"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Sidebar with Filters -->
      <aside class="col-md-2">
        <div class="filter-sidebar">
          <!-- DropDown for mobile view -->
          <div class="d-md-none mb-3">
            <h5 class="filter-title">Topic</h5>
            <select
              class="form-select"
              aria-label="Select Topic"
              (change)="onTopicSelect($event)"
            >
              <option value="" disabled selected>Select a topic</option>
              <option *ngFor="let topic of topics" [value]="topic.name">
                {{ topic.name }} ({{ topic.count }})
              </option>
            </select>

            <h5 class="filter-title mt-2">Dates</h5>
            <select
              class="form-select"
              aria-label="Select Date"
              (change)="onTopicSelect($event)"
            >
              <option value="" disabled selected>Select a Date</option>
              <option *ngFor="let date of dates" [value]="date.range">
                {{ date.range }} ({{ date.count }})
              </option>
            </select>
          </div>

          <!-- Regular view for above mobile view -->
          <!-- Topic Section -->
          <div class="filter-section d-none d-md-block">
            <h5 class="filter-title">Topic</h5>
            <ul class="filter-list">
              <li *ngFor="let topic of topics" class="topic-item">
                <span class="filter-name">{{ topic.name }}</span>
                <span class="filter-badge">{{ topic.count }}</span>
              </li>
            </ul>
          </div>

          <!-- Dates Section -->
          <div class="filter-section d-none d-md-block">
            <h5 class="filter-title">Dates</h5>
            <ul class="filter-list">
              <li *ngFor="let date of dates" class="topic-item">
                <span class="filter-name">{{ date.range }}</span>
                <span class="filter-badge">{{ date.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </aside>

      <!-- Main Content Section -->
      <div class="col-md-10 d-flex flex-wrap">
        <div class="forum-message">
          <img src="assets/images/icons/info-icon.svg" alt="Info Icon" class="info-icon">
          <p class="info_message">
            To Create A Forum Thread, Please Navigate To The Relevant Metadata Page And Start Your Discussion From There.  
            <br />
            While Creating The Thread, You Can Also Invite Multiple Users At Once To Participate In The Conversation.
          </p>
        </div>
        @if (viewMode == "list") { @for (post of results; track post.timestamp)
        {
        <a
          class="post-card"
          href="javascript:void(0);"
          [routerLink]="'/forum-details'"
        >
          <!-- Image and Metrics Section -->
          <div
            class="col-md-2 px-2 d-flex flex-column align-items-center justify-content-center"
          >
            <img src="assets/images/icons/no-star.svg" class="post-image" />
            <div class="w-100 mt-2 px-2 d-flex justify-content-between">
              <span class="metric-left">
                <img src="assets/images/icons/eye.svg" class="eye-icon" />
                {{ post.views }}
              </span>
              <span class="metric-right">
                <img src="assets/images/icons/chat-icon.svg" class="eye-icon" />
                {{ post.comments }}
              </span>
            </div>
          </div>
          <!-- Text Content Section -->
          <div class="text-content d-flex flex-column flex-wrap">
            <p class="timestamp">{{ post.timestamp }}</p>
            <h5 class="title">{{ post.title }}</h5>
            <p class="category">{{ post.category }}</p>
            <p class="creator">Created By: {{ post.creator }}</p>
            <p class="description">{{ post.description }}</p>
          </div>
        </a>
        } } @else {
        <div class="row g-4">
          <div class="col-md-4 mb-3" *ngFor="let post of results">
            <!-- Image and Metrics Section -->
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img src="assets/images/icons/no-star.svg" class="post-image" />
              <div class="w-100 mt-2 d-flex">
                <span class="metric-left me-2">
                  <img src="assets/images/icons/eye.svg" class="eye-icon" />
                  {{ post.views }}
                </span>
                <span class="metric-right">
                  <img
                    src="assets/images/icons/chat-icon.svg"
                    class="eye-icon"
                  />
                  {{ post.comments }}
                </span>
              </div>
            </div>
            <!-- Text Content Section -->
            <div class="text-content d-flex flex-column flex-wrap">
              <p class="timestamp">{{ post.timestamp }}</p>
              <h5 class="title">{{ post.title }}</h5>
              <p class="category">{{ post.category }}</p>
              <p class="creator">Created By: {{ post.creator }}</p>
              <p class="description">{{ post.description }}</p>
            </div>
          </div>
        </div>
        }
      </div>
    </div>

    <div
      class="results-and-filters webPagination__wrapper d-flex align-items-center justify-content-center mt-4"
    >
      <ngb-pagination
        class="webPagination__wrapper"
        [collectionSize]="totalRecords"
        [(page)]="pageNo"
        [pageSize]="pageSize"
        [rotate]="true"
        [maxSize]="7"
        [ellipses]="true"
        [boundaryLinks]="true"
        [directionLinks]="true"
        (pageChange)="loadResults()"
      >
      </ngb-pagination>
    </div>
  </div>
</section>
