<div class="slider_section mb-5">
  <img src="assets/images/icons/banner1.svg" class="img" />
  <div class="btns d-flex justify-content-between">
    <span class="prevIcon">
      <img src="assets/images/icons/arrow-left-ico.svg" />
    </span>
    <span class="nextIcon">
      <img src="assets/images/icons/arrow-right-ico.svg" />
    </span>
  </div>
  <div class="position-absolute pill">
    Business & Economy
  </div>
</div>
<div class="container mt-3">
  <section class="innerpage-banner">
    <div class="row mb-3">
      <div class="col-12">
        <div class="breadcrumb d-flex align-items-center justify-content-start flex-wrap">
          <a class="breadcrumb-link font-regular" href="#">
            <img class="home-icon" src="assets/images/icons/homepage-ico.svg" alt="icon">
            Home
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="/news-and-articles">
            {{'pdl-website.newsandarticles.heading' | translate}}
          </a>
          <span class="chevron-arrow font-regular">
            <img src="assets/images/icons/arrow-right.svg" alt="icon">
          </span>
          <a class="breadcrumb-link font-regular" href="/news-and-articles">
            {{'pdl-website.newsandarticles.heading' | translate}}
          </a>
        </div>
      </div>
    </div>
  </section>
  <div class="content align-items-start mb-5">
    <h1 class="heading">About the Facilitator: Taranjit Singh Dhillon</h1>
    <span class="date">Posted On: 15 June 2023</span>
    <span class="pill">Published on: 15 June 2023, IndiaToday</span>
    <p class="text">Following declaration of independence of India and its simultaneous partition, there was a transfer
      of population on the basis of religion from the West to the East and from the East to the West Punjab. This
      large-scale influx of Hindu/Sikh refugees to the East Punjab created a huge problem for the government to settle
      and rehabilitate then on the evacuee land.
      This government publication deals with the settlement and rehabilitation of rural refugees in the rural areas of
      East Punjab. It included providing them with temporary shelters, land holdings, first on temporary and then on
      quasi-temporary and permanent basis and starting several welfare schemes to help the displaced persons. The
      publication provides the statistics about land distribution, allocation of districts and settling the claims. The
      publication highlights the gravity and magnitude of the problem and the steps taken to deal with the problem <span
        class="link">some link</span></p>
  </div>
  <div class="same-topic mt-5">
    <h5 class="heading mb-4">{{'pdl-website.newsandarticles.sameTopic' | translate}}</h5>
    <div class="list_section mb-5 d-flex no-scrollbar">
      @for(count of getRandomNumber(); track count;) {
        <div class="item">
          <img src="assets/images/current-projects.png" class="banner_img" />
          <div class="item_content">
            <span class="date">12:00PM, 15 JUNE 2023</span>
            <span class="heading">Rural Rehabilitation Rehabilitation Rehabilitation in East Punjab</span>
            <span class="pill">Published on: 1st july 1897</span>
            <span class="desc">Progressively incentivize cooperative systems through technically sound functionalities.
              The credibly productivate seamless data.</span>
          </div>
        </div>
        }
    </div>
  </div>
</div>
