/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDatepickerModule,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
export interface SelectedRange {
  startDate: string;
  endDate: string;
  checked?: boolean;
}

@Component({
  selector: 'panjab-digi-lib-date-range-picker',
  standalone: true,
  imports: [CommonModule, NgbDatepickerModule],
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
})
export class DateRangePickerComponent {
  dateRangeForm!: FormGroup;
  calendar = inject(NgbCalendar);
  hoveredDate: NgbDate | null = null;
  startDate: NgbDate | null = this.calendar.getToday();
  endDate: NgbDate | null = null;
  isDatepickerOpen = false;
  startMonth!: NgbDate;
  @Output() selectedRange = new EventEmitter<SelectedRange>();
  @Input() givenRange!: SelectedRange;
  @Input() inputBox: boolean = true;
  @Input() checkBox: boolean = false;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isDatepickerOpen = false; // Close datepicker if click is outside
    }
  }

  constructor(
    private elementRef: ElementRef,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {}

  get dateRange(): string {
    if (this.startDate && this.endDate) {
      return `${this.startDate.day}/${this.startDate.month}/${this.startDate.year} - ${this.endDate.day}/${this.endDate.month}/${this.endDate.year}`;
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['givenRange'] && this.givenRange.startDate) {
      this.startDate = this.convertToNgbDate(this.givenRange.startDate);
      this.endDate = this.convertToNgbDate(this.givenRange.endDate);
      this.updateDisplayMonths();
    }
  }

  toggleDatepicker() {
    this.isDatepickerOpen = !this.isDatepickerOpen;
  }

  onDateSelection(date: NgbDate) {
    if (!this.startDate && !this.endDate) {
      this.startDate = date;
    } else if (this.startDate && !this.endDate && date.after(this.startDate)) {
      this.endDate = date;
    } else {
      this.endDate = null;
      this.startDate = date;
    }
    if (this.startDate && this.endDate) {
      this.isDatepickerOpen = false;
      const selectedRange = {
        startDate: this.ngbDateParserFormatter.format(this.startDate),
        endDate: this.ngbDateParserFormatter.format(this.endDate),
      };
      this.selectedRange.emit(selectedRange);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.startDate &&
      !this.endDate &&
      this.hoveredDate &&
      date.after(this.startDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.endDate && date.after(this.startDate) && date.before(this.endDate)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.startDate) ||
      (this.endDate && date.equals(this.endDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  convertToNgbDate(dateString: string): NgbDate | null {
    const dateParts = dateString.split('-');
    if (dateParts.length === 3) {
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const day = parseInt(dateParts[2], 10);
      return new NgbDate(year, month, day);
    }
    return null;
  }

  ////////////// on checkbox change /////////////////
  showHideDatePicker(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (!isChecked) {
      this.isDatepickerOpen = false;
      const selectedRange = { startDate: '', endDate: '', checked: false };
      this.selectedRange.emit(selectedRange);
    } else {
      this.isDatepickerOpen = true;
    }
  }

  /////////////// show default selected months ////////////////
  updateDisplayMonths() {
    if (this.givenRange && this.givenRange.startDate) {
      const startMonth = this.convertToNgbDate(this.givenRange.startDate);
      if (startMonth) {
        this.startMonth = startMonth;
      }
    } else {
      const today = this.calendar.getToday();
      this.startMonth = new NgbDate(today.year, today.month, 1);
    }
  }
}
