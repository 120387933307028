@if(popularReads.length >0) {
<section class="most-popular-section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-lg-40 font-31">{{'pdl-website.mostPopular.sectionTitle' |
                    translate }}</h2>
            </div>
            <div class="col-12">
                <div class="most-popular-slider">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                        (init)="slickInit($event)">
                        @for (popularRead of popularReads; track popularRead.DocID ) {
                        <div ngxSlickItem class="slide most-popular-slide me-4 position-relative">
                            <a [routerLink]="'/metadata/'+popularRead.DocID">
                                <img class="mostpopular-slide-bg ofc"
                                    src="{{imageBaseURL}}{{popularRead.CategoryName}}/{{popularRead.AccessionNo}}/Thumbnails/{{popularRead.AccessionNo}}_90.jpg"
                                    alt="Thumb" width="100%"
                                    onerror="this.src='assets/images/icons/no-image-placeholder.svg'" />
                                <div
                                    class="block-text-bar d-flex justify-content-between align-items-center">
                                    <div class=" medium-font font-14 text-cut " [title]="popularRead.Title">
                                        {{popularRead.Title }}
                                    </div>
                                </div>
                            </a>

                        </div>
                        }
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
}
