/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminLanguageActions } from '../actions';
import { catchError, exhaustMap, map, of, switchMap, take, withLatestFrom } from 'rxjs';
import { LanguageService } from 'shared/src/services/admin/master-form/language.service';
import { Store, select } from '@ngrx/store';
import { AdminLanguageSelector, AppState } from '@panjab-digi-lib/shared';
import { Router } from '@angular/router';


@Injectable()
export class AdminLanguageEffects {
  constructor(private actions$: Actions, private service: LanguageService, private store: Store<AppState>, private router: Router,) {}

  //////////////// get all languages ////////////
  LoadLanguageList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminLanguageActions.LoadLanguages),
      exhaustMap((action) => {
        return this.service.loadLanguages(action?.payload).pipe(
          map((response) => {
            return AdminLanguageActions.LoadLanguageSuccessfully({
              languageList: response?.data,
              totalRecords: response?.totalRecords,
            });
          }),
          catchError((error) => {
            return of(
              AdminLanguageActions.LoadLanguageError({
                message: error?.error?.error?.message,
              })
            );
          })
        );
      })
    )
  );

  //////////////// add language ////////////
  AddLoadLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminLanguageActions.AddLanguage),
      exhaustMap((action) => {
        return this.service.addLanguage(action?.payload).pipe(
          map((response) => {
            return AdminLanguageActions.AddLanguageSuccessfully({
              language: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminLanguageActions.AddLanguageError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update language ////////////
  UpdateLoadLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminLanguageActions.UpdateLanguage),
      exhaustMap((action) => {
        return this.service.updateLanguage(action?.payload).pipe(
          map((response) => {
            return AdminLanguageActions.UpdateLanguageSuccessfully({
              language: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminLanguageActions.UpdateLanguageError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  //////////////// update language status////////////
  UpdateLoadLanguageStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminLanguageActions.UpdateLanguageStatus),
      exhaustMap((action) => {
        return this.service.updateLanguageStatus(action?.payload).pipe(
          map((response) => {
            return AdminLanguageActions.UpdateLanguageStatusSuccessfully({
              language: response?.body,
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminLanguageActions.UpdateLanguageStatusError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

  ///////////////// delete language ///////////////////////
  DeleteLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminLanguageActions.DeleteLanguage),
      exhaustMap((action) =>
        this.service.deleteLanguage(action?.payload).pipe(
          map((response) => {
            return AdminLanguageActions.DeleteLanguageSuccessfully({
              message: response?.message,
            });
          }),
          catchError((res) =>
            of(
              AdminLanguageActions.DeleteLanguageError({
                message: res.error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

  //////////////// merge Language ////////////
  MergeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminLanguageActions.MergeLanguage),
      exhaustMap((action) => {
        return this.service.mergeLanguage(action?.payload).pipe(
          map((response) => {
            return AdminLanguageActions.MergeLanguageSuccessfully({
              message: response?.message,
            });
          }),
          catchError((error) =>
            of(
              AdminLanguageActions.AddLanguageError({
                message: error?.error?.error?.message,
              })
            )
          )
        );
      })
    )
  );

 ///////////////////// redirect after adding/deleting/merging language /////////////////////     
  AddLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminLanguageActions.AddLanguageSuccessfully, AdminLanguageActions.DeleteLanguageSuccessfully, AdminLanguageActions.MergeLanguageSuccessfully),
      withLatestFrom(this.store.pipe(select(AdminLanguageSelector.loadLanguageList))),
      switchMap(([action, state]) => {
        this.router.navigateByUrl('/master-forms/manage/languages');
        const pageNumber = (action.type === AdminLanguageActions.AddLanguageSuccessfully.type || action.type === AdminLanguageActions.MergeLanguageSuccessfully.type) ? 1 : state?.pageNumber;
        const payload = { pageNumber: pageNumber, pageSize: state?.pageSize, sortBy: state?.sortBy };
        return of(AdminLanguageActions.LoadLanguages({payload}));
      })
    )
  )
}
