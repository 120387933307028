<!-- deactivate or delete account modal-->
<ng-template #downloadModal let-modal>
    <hr>
    @if(checkingDownloadLimit) {
      <panjab-digi-lib-spinner [isAbsolute]="false" [isFullScreen]="false"></panjab-digi-lib-spinner>
    } @else {
      @if(downloadLimits.downloadLimitReached) {
        <div class="download__limits__wrapper p-4">
          <h6 class="download__heading">{{'pdl-website.downloadPopup.limitTitle' | translate}}</h6>
          <p class="mb-0">{{downloadLimitReachedTxt}}</p>
        </div>
      } @else {
        <div class="p-4 alert alert-info">
          <h6 class="download__heading">{{'pdl-website.downloadPopup.limitTitle' | translate}}</h6>
          <p class="mb-0">{{downloadLimitTxt}}</p>
        </div>
        @if(privacyPolicies.length > 0) {
        <div class="mt-4">
          <h6 class="download__heading">{{'pdl-website.downloadPopup.disclaimerTitle' | translate}}</h6>
          @for(policy of privacyPolicies; track policy.title) {
            <div class="mb-4 text-justify">
              <h6 class="download__heading d-inline">{{policy.title}}: </h6>
              <p class="d-inline">{{policy.text}}</p>
            </div>
          }
        </div> 
        }
        
        <div class="d-flex">
          <input type="checkbox" id="privacyPolicy" class="cursor-pointer" (click)="onPrivacyPolicyChange()" [disabled]="isDownloading$ | async">
          <label class="ms-2 cursor-pointer" for="privacyPolicy">
            {{'pdl-website.downloadPopup.acknowledgement' | translate}} 
            <a href="#" target="_blank">{{'pdl-website.downloadPopup.privacyPolicy' | translate}}</a>
          </label>
        </div>
      }

      @if(isDownloading$ | async) {
        <div class="d-flex mt-4">
          <p class="mb-0 alert alert-info w-100">
            {{'pdl-website.downloadPopup.preparingDownload' | translate}}
          </p>
        </div>
      }
    
    }
</ng-template>
<!-- /deactivate or delete account modal -->
