import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, filter, finalize, of, switchMap, take, throwError } from 'rxjs';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';
import { Store } from '@ngrx/store';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { UserService } from '../services/user.service';
import { environment } from '@panjab-digi-lib/shared';
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService {
  private isRefreshing = false; 
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null); 
  constructor(
    private store: Store,
    private toastService: ToastService,
    private router: Router,
    private authService: AuthenticationService,
    private localStorage: LocalStorageService,
    private socialAuthService: SocialAuthService,
    private userService: UserService
  ) {}

  private terminateUserSession(err: any) {
    if (this.localStorage.get('socialProvider')) {
      this.socialAuthService.signOut().then(() => {
        // logged out
      });
    }
    this.userService.clearUserSession();
    // this.store.dispatch(AuthActions.tokenExpired())
    this.toastService.showError('Your session has expired! Please login again');
    this.router.navigateByUrl(`/login-user`);
    return of(err.message);
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 403) {
      this.terminateUserSession(err);
    }
    return throwError(() => err);
  }

  private _setHeaders(token: string) {
    const customHeaders = {
      authorization: `Bearer ${token}`,
    };
    return {
      headers: new HttpHeaders(customHeaders),
    };
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.localStorage.get('webToken') || '';
    
    // Function to escape special characters in URL strings for regex
    function escapeRegExp(url: string): string {
      return url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    // Construct patterns dynamically using environment variables
    const excludedUrlPatterns = [
      new RegExp(`^${escapeRegExp(environment.getIPAddressEndpoint)}$`), // Static URL
      new RegExp(
        `^${escapeRegExp(environment.geoAPIEndpoint).replace(
          '\\{IPAddress\\}',
          '[^?]+'
        )}`
      ), // Replace placeholder
      new RegExp(
        `^${escapeRegExp(environment.countryinfoapi).replace(
          '\\{countryName\\}',
          '[^?]+'
        )}`
      ), // Replace placeholder
    ];

    // Check if the URL matches any pattern
    if (!excludedUrlPatterns.some(pattern => pattern.test(req.url))) {
      if(token) {
        req = req.clone({
          headers: req.headers.set('authorization', `Bearer ${token}`)
        });
      }
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          const refreshToken = this.localStorage.get('webRefreshtoken');
          if (refreshToken) {
            if (this.isRefreshing) {
              return this.refreshTokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap(() => {
                  const newToken = this.localStorage.get('webToken');
                  const clonedRequest = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${newToken}`,
                    },
                  });
                  return next.handle(clonedRequest);
                })
              );
            } else {
              this.isRefreshing = true;
              return this.authService.getWebAccessToken(refreshToken).pipe(
                switchMap((res) => {
                  if (res.body.token) {
                    this.localStorage.set('webToken', res.body.token);
                    this.refreshTokenSubject.next(res.body.token);
                    const clonedRequest = req.clone({
                      setHeaders: {
                        Authorization: `Bearer ${res.body.token}`,
                      },
                    });
                    return next.handle(clonedRequest);
                  } else {
                    return this.terminateUserSession(err);
                  }
                }),
                catchError((error: any) => {
                  return this.terminateUserSession(error);
                }),
                finalize(() => {
                  this.isRefreshing = false;
                })
              );
            }
          } else {
            return this.handleAuthError(err);
          }
        }
        return this.handleAuthError(err); 
      })
    );
  }
}
