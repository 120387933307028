/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '@panjab-digi-lib/shared';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'panjab-digi-lib-exhibition-items',
  standalone: true,
  imports: [SlickCarouselModule],
  templateUrl: './exhibition-items.component.html',
  styleUrl: './exhibition-items.component.scss'
})
export class ExhibitionItemsComponent implements OnInit {
  items :any = [];
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  currentSlideIndex: number = 0;
  fallbackImage = 'assets/images/icons/no-image-placeholder.svg';
  @ViewChild('slickCarousel') slickCarousel!: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      },
      {
        breakpoint: 300,
      }
    ]
  };

  constructor() {

  }

  ngOnInit(): void {
    this.items = history.state.items;
  }


  /////////////// on change of image/video in carousel //////////
  onCarouselChange(event: any): void {
    this.currentSlideIndex = event.slick.currentSlide;
  }

  ///////////// on click set slide in carousel
  setActiveSlide(item: any): void {
    const index = this.items.indexOf(item);
    if (index !== -1) {
      this.slickCarousel.slickGoTo(index); // Navigate carousel to clicked slide
    }
  }
}
