<panjab-digi-lib-static-page-head
  [pageName]="'pdl-website.newsandarticles.heading' | translate"></panjab-digi-lib-static-page-head>
<section class="static-section section-padding pt-3">
  <div class="container">
    <div class="faq-header-wrapper mb-5 no-scrollbar">
      @for (key of sections; track key) {
      <span class="faq-item" [ngClass]="{'active-item' : key === activeKey}"> <span (click)="setActiveKey(key)"
          class="cursor-pointer">{{key}}</span></span>
      }
    </div>
    <div class="list_section d-flex flex-wrap justify-content-center">
      @for(count of getRandomNumber(); track count;) {
      <div class="item">
        <img src="assets/images/current-projects.png" class="banner_img" />
        <div class="item_content">
          <span class="date">12:00PM, 15 JUNE 2023</span>
          <span class="heading">Rural Rehabilitation Rehabilitation Rehabilitation in East Punjab</span>
          <span class="pill">Published on: 1st july 1897</span>
          <span class="desc">Progressively incentivize cooperative systems through technically sound functionalities.
            The credibly productivate seamless data.</span>
        </div>
      </div>
      }
    </div>
    <div class="row">
      <div class="col-12">
        <div class="pdl-pagination d-flex align-items-center justify-content-center webPagination__wrapper mt-5">
          <nav aria-label="Page navigation example">
            <ngb-pagination class="donations__pagination" [collectionSize]="100" [page]='1' [pageSize]="20"
              [rotate]="true" [ellipses]="true" [maxSize]="20" [ellipses]="true" />
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>
