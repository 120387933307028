import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromWebSearch from '../../reducers/website/web-search.reducer';

export const selectWebSearchState = createFeatureSelector<fromWebSearch.State>('webSearch');

// Selector for search list and total records
export const searchList = createSelector(
  selectWebSearchState,
  (state) => ({
    searchList: fromWebSearch.selectAll(state),
    totalRecords: state.totalRecords,
  })
);

// Selector for filters
export const filterList = createSelector(
  selectWebSearchState,
  (state) => state.filters
);

// Selector for loading state
export const isLoading = createSelector(
  selectWebSearchState,
  (state) => state.isLoading
);
