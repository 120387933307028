import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticPageHeadComponent } from '../static/staticPageHead/staticPageHead.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'panjab-digi-lib-news-and-articles',
  standalone: true,
  imports: [CommonModule, StaticPageHeadComponent, TranslateModule, NgbPagination],
  templateUrl: './news-and-articles.component.html',
  styleUrl: './news-and-articles.component.scss',
})
export class NewsAndArticlesComponent {
  sections: any = [
    'Breaking News',
    'Politics & Government',
    'Buisness & Economy',
    'Social Issues & Activism',
    'Health & Wellness',
  ];
  activeKey = '';
  openedIndex: any = [];

  setActiveKey = (key: any) => {
    this.activeKey = key;
    this.openedIndex = [];
  };
  getRandomNumber = () => {
    return new Array(20).fill(1);
  };
}
