export const API_ENDPOINTS = {
  open: {
    country: 'countries',
    contributors: 'contributors',
  },
  static: {
    getContent: 'staticPage',
    getFAQ: 'getFAQ',
    getDonations: 'getDonations',
  },
  homePage: {
    category: {
      getCategories: 'admin/category',
      toggleShowOnHome: 'admin/category/homePage/toggleStatus/',
      reorderCategories: 'admin/category/reorderAll',
    },
    trending: {
      homePageTrendingKeywords:
        'admin/homePageTrendingKeyword?pageNumber={pageNumber}&pageSize={pageSize}&type={type}&sortBy={sortBy}&orderBy={orderBy}',
      allTrendingKeywords:
        'admin/allTrendingKeyword?pageNumber={pageNumber}&pageSize={pageSize}',
      trendingKeywordApplicationStatus: 'admin/updateKeywordStatus',
      trendingKeywordHomePageStatus: 'admin/updateKeywordHomePageStatus',
      keywordDetail: 'admin/keyword/{id}',
      mergeKeywords: 'admin/keyword',
      searchKeyword: 'admin/searchKeyword?query={query}',
    },
    download: {
      getAccessionInfo: 'admin/path?accessionNumber={accessionNumber}',
      downloadList: 'admin/downloadList',
      loadDownLoadList:
        'admin/downloadList?pageNumber={pageNumber}&pageSize={pageSize}',
      addTemplateImage: 'admin/templateImage',
      selectedDownloadImageDetail: 'admin/downloadList/{id}',
      editDownloadItem: 'admin/downloadList/{id}',
      templateImages: 'admin/templateImages?templateType={templateType}',
      activateTemplate: 'admin/active',
      changeStatus: 'admin/status',
      sort: 'admin/sort/{id}',
    },
  },
  fundraising: {
    adoptedBy: {
      default: 'admin/fundraising/adopters',
      getAdoptedBooks: 'admin/fundraising/adopters/adoptedList',
      getPaymentDetails: 'admin/fundraising/adopters/paymentDetails',
      downloadAdopters: 'admin/fundraising/adoptedBy/download',
    },
    adoptPrice: {
      default:
        'admin/fundraising/categories/price?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&order={order}',
      price: 'admin/fundraising/category/price/{id}',
      download: 'admin/fundraising/categories/download',
    },
    toAdopt: {
      default: 'admin/fundraising/toAdopt',
      getDocumentByIds: 'admin/fundraising/toAdopt/getDocumentByIds',
      addAdoption: 'admin/fundraising/toAdopt/addAdoption',
      toggleListOfAdoptionStatus:
        'admin/fundraising/toAdopt/toggleListOfAdoptionStatus',
      downloadDocuments: 'admin/fundraising/toAdopt/download',
    },
  },
  masterForm: {
    category: {
      addCategory: 'admin/category',
      checkPreCode: 'admin/category/preCode',
      uploadTypes: 'admin/category/uploadTypes',
      editCategory: 'admin/category',
      toggleStatus: 'admin/category/toggleStatus',
      downloadCategories: 'admin/category/download',
    },
    service: {
      default: 'admin/service',
      getServiceThumbnails: 'admin/thumbnails',
      updateServiceStatus: 'admin/updateServiceStatus',
      downloadServices: 'admin/service/download',
      enabledServices: 'admin/service/enabled',
    },
    metaData: {
      default: 'admin/metadata',
      updateMetaDataStatus: 'admin/metadata/toggleStatus',
      downloadMetadata: 'admin/metadata/download',
      masterFormLabels: 'admin/metadata/masterFormLabels',
    },
    process: {
      default: 'admin/process',
      updateProcessStatus: 'admin/process/toggleStatus',
      downloadProcess: 'admin/process/download',
      sort: 'admin/process/sort/{id}',
    },
    subject: {
      default: 'admin/subject',
      updateSubjectStatus: 'admin/subject/toggleStatus',
      getParent: 'admin/subject/getParents',
      downloadSubjects: 'admin/subject/download',
    },
    currency: {
      default: 'admin/currency',
      updateCurrencyStatus: 'admin/currency/toggleStatus',
      downloadCurrencies: 'admin/currency/download',
    },
    country: {
      default: 'admin/country',
      updateCountryStatus: 'admin/country/toggleStatus',
      downloadCountries: 'admin/country/download',
    },
    section: {
      default: 'admin/section',
      updateStatus: 'admin/section/toggleStatus',
      download: 'admin/section/download',
    },
    designation: {
      default: 'admin/designation',
      updateStatus: 'admin/designation/toggleStatus',
      download: 'admin/designation/download',
      enabledDesignation: 'admin/designation/enabled',
    },
    language: {
      default:
        'admin/language?pageNumber={pageNumber}&pageSize={pageSize}&sortBy={sortBy}',
      language: 'admin/language',
      update: 'admin/language/{id}',
      download: 'admin/language/download',
      merge: 'admin/language/mergeLanguage',
    },
    script: {
      default:
        'admin/script?pageNumber={pageNumber}&pageSize={pageSize}&sortBy={sortBy}',
      script: 'admin/script',
      update: 'admin/script/{id}',
      download: 'admin/script/download',
      merge: 'admin/script/mergeScript',
    },
    publisher: {
      default: 'admin/publisher?sortBy={sortBy}&orderBy={orderBy}',
      publisher: 'admin/publisher',
      publisherById: 'admin/publisher/{id}',
      merge: 'admin/mergePublisher',
    },
    translator: {
      default: 'admin/translator?sortBy={sortBy}&orderBy={orderBy}',
      translator: 'admin/translator',
      translatorById: 'admin/translator/{id}',
      merge: 'admin/mergeTranslator',
    },
    author: {
      default: 'admin/author?sortBy={sortBy}&orderBy={orderBy}',
      author: 'admin/author',
      authorById: 'admin/author/{id}',
      merge: 'admin/mergeAuthor',
    },
    department: {
      default: 'admin/department',
      updateDepartmentStatus: 'admin/department/toggleStatus',
      downloadDepartments: 'admin/department/download',
      enabledDepartments: 'admin/department/enabled',
    },
    subjectsPerCategory: {
      default: 'admin/subjectsPerCategory',
      getSubjectsForCategory: 'admin/subjectsPerCategory/getSubjects/{id}',
      saveAllocation: 'admin/subjectsPerCategory/saveAllocation/{id}',
      downloadSubjectsOfCategory:
        'admin/subjectsPerCategory/downloadSubjects/{id}',
    },
    scriptsPerLanguage: {
      default: 'admin/scriptsPerLanguage',
      getScriptsForLanguage: 'admin/scriptsPerLanguage/getScripts/{id}',
      saveAllocation: 'admin/scriptsPerLanguage/saveAllocation/{id}',
      downloadScriptsOfLanguage:
        'admin/scriptsPerLanguage/downloadScripts/{id}',
    },
    metadataPerCategory: {
      default: 'admin/metaDataPerCategory',
      getMetadataForCategory: 'admin/metaDataPerCategory/getMetadata/{id}',
      saveAllocation: 'admin/metaDataPerCategory/saveAllocation/{id}',
      saveAccess: 'admin/metaDataPerCategory/saveAccess/{id}',
      getTemplateConfig: 'admin/metaDataPerCategory/getTemplateConfig/{id}',
      saveTemplateConfig: 'admin/metaDataPerCategory/saveTemplateConfig/{id}',
      downloadMetadataOfCategory:
        'admin/metaDataPerCategory/downloadMetadata/{id}',
      isRequiredStatus: 'admin/metaDataPerCategory/isRequiredStatus/{id}',
    },
    contributor: {
      default: 'admin/contributor?sortBy={sortBy}&orderBy={orderBy}',
      contributor: 'admin/contributor',
      contributorById: 'admin/contributor/{id}',
      merge: 'admin/mergeContributor',
    },
    editor: {
      default: 'admin/editor?sortBy={sortBy}&orderBy={orderBy}',
      editor: 'admin/editor',
      editorById: 'admin/editor/{id}',
      merge: 'admin/mergeEditor',
    },
    articleComment: {
      default: 'admin/articleComments',
      updateArticleCommentStatus: 'admin/articleComments/toggleStatus',
      downloadArticleComments: 'admin/articleComments/download',
    },
    metadataComment: {
      default: 'admin/metadataComments',
      updateMetadataCommentStatus: 'admin/metadataComments/toggleStatus',
      downloadMetadataComments: 'admin/metadataComments/download',
    },
    processPerCategory: {
      default: 'admin/processPerCategory',
      getProcessesForCategory: 'admin/processPerCategory/getProcesses/{id}',
      saveAllocation: 'admin/processPerCategory/saveAllocation/{id}',
      downloadProcessesOfCategory:
        'admin/processPerCategory/downloadProcesses/{id}',
    },
    requests: {
      metadataRequests:
        'admin/metaFieldRequests?sortBy={sortBy}&orderBy={orderBy}',
      updateRequestStatus: 'admin/metaFieldRequests/status/{id}',
    },
  },
  videoGallery: {
    default:
      'admin/video?pageNumber={pageNumber}&pageSize={pageSize}&type={type}',
    add: 'admin/video/add',
    videoById: 'admin/video/{id}',
    path: 'admin/videoPath?accessionNumber={accessionNumber}',
    video: 'admin/video',
    homepageVideo: 'admin/homepageVideo',
    sortVideo: 'admin/sortVideo/{id}',
  },
  employee: {
    default:
      'admin/employees/list?pageNumber={pageNumber}&pageSize={pageSize}&type={type}',
    add: 'admin/employee/add',
    get: 'admin/employee/detail/{id}',
    roleStatus: 'admin/employee/role/status',
    status: 'admin/employee/status',
    assignService: 'admin/employee/role/assign',
    download: 'admin/employee/download?type={type}',
    credential: 'admin/employee/detail/send',
    info: 'admin/employee/info/{id}',
    delete: 'admin/employee/delete',
  },
  volunteer: {
    default: 'admin/volunteer',
    updateVolunteerStatus: 'admin/volunteer/toggleStatus',
    downloadVolunteers: 'admin/volunteer/download',
  },
  webUserMgmt: {
    donor: {
      default: 'admin/donor',
      donations: 'admin/donor/donations',
      donationTypes: 'admin/donor/donationTypes',
      download: 'admin/donor/download',
      downloadDonations: 'admin/donor/download/donations',
    },
    webDocManager: {
      default: 'admin/web-doc-manager/documents',
      toggleDocumentStatus: 'admin/web-doc-manager/documents/toggle-status',
      downloadDocuments: 'admin/web-doc-manager/documents/download',
    },
    userService: {
      userList:
        'admin/webUser/get?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&order={order}',
      userDetail:
        'admin/webUser/detail/{id}?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&order={order}',
      keywordDetail:
        'admin/webUser/keyword/{id}?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&order={order}',
      updateStatus: 'admin/webUser/updateStatus',
    },
    volunteerReq: {
      default: 'admin/volunteers-request',
      updateStatus: 'admin/volunteers-request/updateStatus',
    },
    metadata: {
      default: 'admin/metaDataDocument/detail/{id}',
      search:
        'admin/metaDataDocument/search?collection={collection}&searchField={searchField}&query={query}',
      edit: 'admin/metaDataDocument/{id}',
      addNewFieldData: 'admin/metaDataDocument/fieldData/add',
    },
    upload: {
      default: 'admin/documentFiles/upload',
      getConfig: 'admin/documentFiles/upload/config',
      checkPdfPwdExist: 'admin/documentFiles/upload/checkPdfPwdExist',
      savePDFPassword: 'admin/documentFiles/upload/savePDFPassword',
    },
  },
  sponsorship: {
    default:
      'admin/sponsorship/list?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&order={order}',
    enabledParent: 'admin/sponsorship/enabledParent/{id}',
    add: 'admin/sponsorship/add',
    sponsorshipById: 'admin/sponsorship/detail/{id}',
    delete: 'admin/sponsorship/delete',
    status: 'admin/sponsorship/status',
  },
  exhibition: {
    save: 'admin/exhibition/save',
    get: 'admin/exhibitions?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&order={order}',
    addChild: 'admin/childExhibition/add/{id}',
    editChild: 'admin/childExhibition/edit/{id}',
    getById: 'admin/exhibition/{id}',
    status: 'admin/exhibition/status',
    delete: 'admin/exhibition/delete',
    requests:
      'admin/exhibitionRequests?pageNumber={pageNumber}&pageSize={pageSize}',
    requestById: 'admin/exhibitionRequest/{id}',
    requestReply: 'admin/exhibitionRequest/reply/{id}',
    requestStatus: 'admin/exhibitionRequest/status?type={type}',
  },
  contact: {
    requests: 'admin/contactUslist?pageNumber={pageNumber}&pageSize={pageSize}',
    requestById: 'admin/contactUs/{id}',
    requestReply: 'admin/contactUs/reply/{id}',
  },
  digitization: {
    requests:
      'admin/digitizationRequests?pageNumber={pageNumber}&pageSize={pageSize}',
    requestById: 'admin/digitizationRequest/{id}',
    requestReply: 'admin/digitizationRequest/reply/{id}',
    requestStatus: 'admin/digitizationRequest/status?type={type}',
  },
  popularReads: {
    get: 'admin/popularRead/get?pageNumber={pageNumber}&pageSize={pageSize}&type={type}',
    sort: 'admin/popularRead/sort/{id}',
    path: 'admin/popularReadPath?accessionNumber={accessionNumber}',
    add: 'admin/popularRead/add',
    status: 'admin/popularRead/status',
  },
  curatedCollection: {
    default: 'admin/curatedCollections',
    changeStatus: 'admin/curatedCollections/changeCollectionStatus',
    changeDocumentStatus: 'admin/curatedCollections/document/changeStatus',
    document: {
      default: 'admin/curatedCollections/document',
      add: 'admin/curatedCollections/document/add',
      search: 'admin/curatedCollections/document/search',
    },
  },
  newsAndArticles: {
    default:
      'admin/newsAndArticles?pageNumber={pageNumber}&pageSize={pageSize}&type={type}&orderBy={orderBy}&order={order}',
    path: 'admin/newsAndArticles/articlePath?accessionNumber={accessionNumber}',
    add: 'admin/newsAndArticles/add',
    newsArticlesById: 'admin/newsAndArticles/{id}',
    newsArticles: 'admin/newsAndArticles',
    getNewsAndArticlesSubjects: 'admin/newsAndArticles/subjectsList',
  },
  eventsCampaigns: {
    default:
      'admin/eventsCampaigns?pageNumber={pageNumber}&pageSize={pageSize}&type={type}&orderBy={orderBy}&order={order}',
    add: 'admin/eventsCampaigns/save',
    eventsCampaignsById: 'admin/eventsCampaigns/{id}',
    eventsCampaigns: 'admin/eventsCampaigns',
    addChild: 'admin/childEventCampaign/save/{id}',
    editChild: 'admin/childEventCampaign/edit/{id}',
  },
  openRoutes: {
    getActiveCountries: 'countries',
    getLanguages: 'languages',
    userTypes: 'userTypes',
  },
};
