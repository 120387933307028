/* eslint-disable @typescript-eslint/no-inferrable-types */
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
  AppState,
  WebDownloadActions,
  WebDownloadsSelector,
  environment,
} from '@panjab-digi-lib/shared';
import { APP_CONSTANTS } from 'apps/panjab-digi-lib/src/common/constants/app.constants';
import { UtilService } from 'apps/panjab-digi-lib/src/common/services/util.service';
import { Subscription } from 'rxjs';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselComponent, SlickCarouselModule } from 'ngx-slick-carousel';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'panjab-digi-lib-downloads',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbPopoverModule, SlickCarouselModule],
  templateUrl: './downloads.component.html',
  styleUrl: './downloads.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DownloadsComponent implements OnInit,OnDestroy {
  allDownloads: any = [];
  pageNumber = 1;
  pageSize = APP_CONSTANTS.pageSize;
  defaultPaginationSize = APP_CONSTANTS.defaultPaginationSize;
  baseUrl = environment.rootApiPublicUrl;
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  onSaveSelector$: Subscription | undefined;
  pages: any;
  totalNoOfPages = 0;
  totalRecords = 0;
  fromRecords = 0;
  toRecords = 0;
  showSlider = false;
  sliderInfo: any;
  currentSlide = '';
  sortBy : string = 'default';
  routerSubscription: Subscription | undefined;
  fallbackImage = APP_CONSTANTS.categoryFallbackImage;
  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private utilService: UtilService,
    private router: Router
  ) {}

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      },
      {
        breakpoint: 300,
        // settings: "unslick" // destroys slick
      }
    ]
  };

  slickInit(e: any) {
    // console.log('slick initialized');
  }

  initSlider(image:any) {
    setTimeout(() => {
      this.showSlider = true;
      this.sliderInfo = image; 
      this.currentSlide = this.sliderInfo?.ImagePath[0];
    }, 300);

  }

  closeModal() {
    this.showSlider = false;
    this.sliderInfo = null;
  }



  ngOnInit() {
    this.loadDownloadImageRecords(this.pageNumber);
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.cleanUpBackdrop(); 
      }
    });
  }

  ///////////// clear the modal overlay if exist ///////////////////

  cleanUpBackdrop() {
    const backdrops = document.getElementsByClassName('modal-backdrop');
    for (let i = 0; i < backdrops.length; i++) {
      backdrops[i].remove();
    }
  }
  //////////////// get current slide in carousel /////////////
  afterChange(event:any) {
  this.currentSlide = this.sliderInfo?.ImagePath[event?.currentSlide];
  }

  /////////////////// payload which need to send in request ////////////////////
  getPayload(pageNumber: number) {
    return { pageNumber: pageNumber, pageSize: this.pageSize,sortBy : this.sortBy };
  }
  ///////////////// download image ///////////////////
  downloadImage(imagePath: string, sliderDownload: boolean) {
    let fileName: any = imagePath?.split('/')?.pop();
    if(sliderDownload) {
      fileName = decodeURIComponent(fileName.split('?')[0]);
    }
    this.utilService.downloadFile(imagePath, fileName);
  }

  ///////////// get download images /////////////////

  loadDownloadImageRecords(pageNumber: number) {
    const payload = this.getPayload(pageNumber);
    this.store.dispatch(WebDownloadActions.LoadWebDownloads({ payload }));
    this.onSaveSelector$ = this.store
      .select(WebDownloadsSelector.getDownloads)
      .subscribe((response) => {
        this.allDownloads = response?.downloadImages;
        this.totalRecords = response?.totalRecords;
        this.totalNoOfPages = Math.ceil(this.totalRecords/this.pageSize);
        this.fromRecords = pageNumber === 1 ? 1 : (pageNumber - 1) * this.pageSize + 1;
        this.toRecords = (pageNumber - 1) * this.pageSize + this.allDownloads?.length;
        if (this.onSaveSelector$) {
          if (!this.onSaveSelector$.closed) {
            this.loadPagination(pageNumber);
          }
          this.onSaveSelector$.unsubscribe();
        }
      });
  }

  ///////// sort images based on default, title alphabetically or recently added  ///////

  sortImages(by:string) {
    if(this.sortBy === by) {
      return;
    }
    this.sortBy = by;
    this.loadDownloadImageRecords(this.pageNumber);
  }
  ////////////// calculate pagination ///////////////
  loadPagination(pageNumber: number) {
    this.pages = [];
    this.pageNumber = pageNumber;

    // Show all the pagination elements if there are less than 8 pages total
    if (this.totalNoOfPages <= this.defaultPaginationSize) {
      for (let p = 1; p <= this.totalNoOfPages; p++) {
        this.pages.push(p);
      }
    } else {
      this.getPaginationStartAndEnd();
    }
  }

  getPaginationStartAndEnd() {
    let start = this.pageNumber - 1;
    let end = this.pageNumber + 1;

    // Determine how many pages to show after the current page index
    if (this.pageNumber === 1) {
      end += 3;
    } else if (this.pageNumber === 2) {
      end += 2;
    } else if (
      this.pageNumber === 3 ||
      this.pageNumber === this.totalNoOfPages - 3
    ) {
      end += 1;
    }

    // Determine how many pages to show before the current page index
    if (this.pageNumber === this.totalNoOfPages) {
      start -= 3;
    } else if (this.pageNumber === this.totalNoOfPages - 1) {
      start -= 2;
    } else if (
      this.pageNumber === 4 ||
      this.pageNumber === this.totalNoOfPages - 2
    ) {
      start -= 1;
    }
    this.createPaginationWithStartAndEnd(start, end);
  }

  createPaginationWithStartAndEnd(start: number, end: number) {
    // Show the very first page followed by a "..." at the beginning of the pagination section (after the Previous button)
    if (this.pageNumber >= 3) {
      this.pages.push(1);
      if (this.pageNumber > 4) {
        this.pages.push('...');
      }
    }

    // Output the indexes for pages that fall inside the range of start and end
    if (start === 0) start += 1;
    for (let p = start; p <= end; p++) {
      if (p > this.totalNoOfPages || p === -1) {
        continue;
      }
      this.pages.push(p);
    }
    // Show the very last page preceded by a "..." at the end of the pagination
    // section (before the Next button)
    if (this.pageNumber < this.totalNoOfPages - 1) {
      if (this.pageNumber < this.totalNoOfPages - 3) {
        this.pages.push('...');
      }
      this.pages.push(this.totalNoOfPages);
    }
  }

  //////////// remove modal open class and style ////////////////
  resetBody() {
    const body = document.body;
    body.classList.remove('modal-open');
    body.style.overflow = ''; 
    body.style.paddingRight = '';
  }

  ///////////// default thumbnail in case of image/thumbnail error //////////////////
  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.fallbackImage;
  }

  ngOnDestroy(): void {
    this.resetBody();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
