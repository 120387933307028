<div class="container">
  <section class="innerpage-banner mb-4" style="background: none">
    <div
      class="mt-4 col-md-12 breadcrumb d-flex align-items-center justify-content-start flex-wrap"
    >
      <a class="breadcrumb-link font-regular" href="#">
        <img
          class="home-icon"
          src="assets/images/icons/homepage-ico.svg"
          alt="icon"
        />
        {{ 'pdl-website.homePage.title' | translate }}
      </a>
      <span class="chevron-arrow font-regular">
        <img src="assets/images/icons/arrow-right.svg" alt="" />
      </span>
      <a
        class="breadcrumb-link font-regular"
        href="javascript:void(0)"
        [routerLink]="'/forum'"
      >
        {{ 'pdl-website.forumPage.title' | translate }}
      </a>
      <span class="chevron-arrow font-regular">
        <img src="assets/images/icons/arrow-right.svg" alt="" />
      </span>
      <a class="breadcrumb-link font-regular" href="javascript:void(0)">
        Montblanc Boheme : montblanc Professional magasins de stylo,
        montblanc-outlet.top
      </a>
    </div>
  </section>

  <div class="row">
    <!-- Main Content Column -->
    <div class="col-lg-9">
      <div class="forum-detail">
        <div class="forum-content p-4">
          <img
            src="assets/images/adopt-book-2.png"
            alt="Forum Image"
            class="img-fluid rounded logo-img"
          />

          <div class="d-flex flex-column">
            <p class="date-time-text">12:00PM · 15 June 2020</p>
            <h3 class="title">Rural Rehabilitation in East Punjab</h3>

            <span class="category">BOOKS</span>

            <span class="created-by mt-2">Created By: eonline cheap</span>
          </div>
          <div class="forum-description" [innerHTML]="getDesc()"></div>
        </div>

        <div class="comments-section">
          <div class="p-4">
            <div class="comment-header">
              <div class="left-div">
                <img
                  src="assets/images/icons/chat-icon.svg"
                  class="chat-icon"
                />
                Showing 1 to 2 of total 2 posts below
              </div>

              <button class="btn btn-primary btn__primary request-btn">Request to Join</button>
            </div>

            <!-- Comment List -->
            <div class="comment-list">
              @for (comment of comments; track comment.id) {
              <div class="comment-card">
                <div class="d-flex align-items-center">
                  <img src="assets/images/icons/double-inverted-comma.svg" />
                  <span class="comment-meta">
                    {{ comment.username }} on {{ comment.createdAt }} replies as
                  </span>
                </div>
                <p class="ms-2 mt-2">
                  {{ comment.text }}
                </p>
              </div>
              }
            </div>

            <!-- Add Comment Box -->
            <div class="comment-box">
              <input
                type="text"
                class="form-control"
                placeholder="Add comment"
              />
              <button class="send-btn">
                <img src="assets/images/icons/action-send-green.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sidebar Column -->
    <div class="col-lg-3">
      <!-- Related Forum -->
      <div class="related-forum">
        <h5 class="related-forum-title">Related Forum</h5>
        <ul class="related-forum-list">
          @for (item of relatedForums; track item.id) {
          <li>
            <img src="assets/images/icons/chevron-right-ico.svg" class="mt-1" />
            {{ item.title }}
          </li>
          }
        </ul>
      </div>

      <!-- Topics -->
      <div class="topics-container">
        <h5 class="topics-title">Topic</h5>
        <ul class="topics-list">
          <li *ngFor="let topic of topics">
            <span class="topic-name">{{ topic.name }}</span>
            <span class="topic-count">{{ topic.count }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
