import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, Update } from "@ngrx/entity";
import { SubjectActions } from '../actions';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';
import { Subjects } from "shared/src/interfaces/master-form.interface";

export interface State extends EntityState<any> {
    payload: GenericListingPayload,
    totalRecords: number,
    parentSubjects: Subjects[],
    isLoading: boolean;
    isSaving?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

export const adapter = createEntityAdapter<Subjects>({
    selectId: (e) => e.SubjectID
});

export const initialState = adapter.getInitialState({
    totalRecords: 0,
    isLoading: false,
    isSaving: false,
    isError: false,
    errorMessage: '',
    successMessage: ''
});

export const reducer = createReducer(
    initialState,
    on(SubjectActions.LoadSubject, (state, action) =>
    ({
        ...state,
        payload: action.payload,
        isLoading: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(SubjectActions.LoadSubjectSuccess, (state, action) =>
    ({
        ...adapter.setAll(action.subjects, state),
        totalRecords: action.totalRecords,
        isLoading: false,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(SubjectActions.LoadSubjectError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(SubjectActions.LoadParentSubject, (state, action) =>
    ({
        ...state,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(SubjectActions.LoadParentSubjectSuccess, (state, action) =>
    ({
        ...state,
        parentSubjects: action.subjects,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(SubjectActions.LoadParentSubjectError, (state, action) =>
    ({
        ...state,
        isError: true,
        errorMessage: action.message,
        successMessage: '',
    })
    ),
    on(SubjectActions.UpdateSubjectStatus, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(SubjectActions.UpdateSubjectStatusSuccess, (state, action) =>
    {
        let updates: Update<Subjects>[] = [];
        if(action.subject.ParentId) {
            const parentSubject = state.entities[action.subject.ParentId];

            if(parentSubject) {
                // Recreate the parent's children array with the updated child
                const updatedChildren = parentSubject.children.map(child =>
                    child.SubjectID === action.subject.SubjectID ? { ...child, Status: action.status } : child
                );
    
                // Apply parent update in the state
                updates =  [{ id: parentSubject.SubjectID, changes: { children: updatedChildren } }];
            }

        } else {
            updates =  action.subjectIDs.map((id) => {
                return {
                    id, changes: {
                        Status: action.status
                    }
                }
            });
        }
        
        return ({
            ...adapter.updateMany(updates, state),
            isLoading: false,
            isSaving: false,
            isError: false,
            errorMessage: '',
            successMessage: action.message
        })
    }
    ),
    on(SubjectActions.UpdateSubjectStatusError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(SubjectActions.AddSubject, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: true,
        isError: false,
        errorMessage: '',
        successMessage: ''
    })
    ),
    on(SubjectActions.AddSubjectSuccess, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
    })
    ),
    on(SubjectActions.AddSubjectError, (state, action) =>
    ({
        ...state,
        isLoading: false,
        isSaving: false,
        isError: true,
        errorMessage: action.message,
        successMessage: ''
    })
    ),
    on(SubjectActions.EditSubject,(state, action) =>
    ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
    })
  ),
  on(SubjectActions.EditSubjectSuccess,(state, action) =>
     ({
        ...adapter.updateOne({id: action.subject.SubjectID, changes: action.subject}, state),
        isLoading: false,
        isSaving: false,
        isError: false,
        errorMessage: '',
        successMessage: action.message
      })
  ),
  on(SubjectActions.EditSubjectError,(state, action) => 
    ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
    })
  ),
  on(SubjectActions.DeleteSubject, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: true,
      isError: false,
      errorMessage: '',
      successMessage: ''
  })
  ),
  on(SubjectActions.DeleteSubjectSuccess, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: false,
      errorMessage: '',
      successMessage: action.message
  })
  ),
  on(SubjectActions.DeleteSubjectError, (state, action) =>
  ({
      ...state,
      isLoading: false,
      isSaving: false,
      isError: true,
      errorMessage: action.message,
      successMessage: ''
  })
  ),
);

export const {
    selectAll,
    selectEntities
} = adapter.getSelectors();