<div [ngClass]="{'spinner-overlap': isFullScreen}">
    <div class="spinner-border" role="status" [ngClass]="{pos__absolute: isAbsolute}" [style.width.rem]="size" [style.height.rem]="size">
        <span class="sr-only"></span>
    </div>
    @if(name) {
        <div class="loader-msg">
            {{name | translate}}
        </div>
    }
</div>
