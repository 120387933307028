import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  inject,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  ReactiveFormsModule,
  FormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ModalPopupService } from 'shared/src/services/common/modal-popup.service';
import { environment } from '@panjab-digi-lib/shared';
import { QuillModule } from 'ngx-quill';

@Component({
  selector: 'panjab-digi-lib-create-new-forum-modal',
  templateUrl: './create-new-forum-modal.component.html',
  styleUrls: ['./create-new-forum-modal.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    QuillModule,
  ],
})
export class CreateNewForumModalComponent implements OnInit, OnDestroy {
  @ViewChild('createNewForumModal') createNewForumModal!: TemplateRef<any>;

  editorConfigiration = environment.quillEditorConfiguration;
  descriptionMaxLength = 250;
  forumTitleMaxLength = 150;
  items: string[] = [];
  forumForm!: FormGroup;

  private modalPopupService = inject(ModalPopupService);
  private translate = inject(TranslateService);

  ngOnInit(): void {
    this.forumForm = new FormGroup({
      forumTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.forumTitleMaxLength),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.descriptionMaxLength),
      ]),
    });
  }

  openModal() {
    this.modalPopupService.open(
      this.translate.instant('pdl-website.createNewForumPopup.title'),
      '',
      this.createNewForumModal,
      this.translate.instant('pdl-website.createNewForumPopup.submit'),
      this.translate.instant('pdl-website.createNewForumPopup.cancel'),
      this.handleSubmit.bind(this),
      this.handleOnCancel.bind(this),
      'lg',
      undefined,
      true
    );
  }

  addItem(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();
    if (
      (event.key === 'Enter' || event.key === ',') &&
      value &&
      !this.items.includes(value)
    ) {
      this.items.push(value);
      input.value = '';
      event.preventDefault();
    }
  }

  updateCharCount(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.forumForm.patchValue({ forumTitle: inputElement.value });
  }

  onContentChanged(event: any) {
    const editor = event.editor;
    let text = editor.getText();

    let cursorPosition = editor.getSelection()?.index || text.length; 

    if (text.length > this.descriptionMaxLength) {
      editor.deleteText(this.descriptionMaxLength, text.length);
      cursorPosition = Math.min(cursorPosition, this.descriptionMaxLength); 
      text = editor.getText().trim();
    }

    if (this.forumForm.get('description')?.value !== text) {
      this.forumForm
        .get('description')
        ?.patchValue(text, { emitEvent: false });
    }

    setTimeout(() => editor.setSelection(cursorPosition), 0);
  }

  removeItem(index: number) {
    this.items.splice(index, 1);
  }

  handleSubmit(): void {
    if (this.forumForm.valid) {
      console.log('Form Data:', this.forumForm.value);
    } else {
      console.log('Form is invalid');
    }
  }

  handleOnCancel(): void {
    this.forumForm.reset();
  }

  ngOnDestroy(): void {
    this.forumForm.reset();
  }
}
