@if(eventsCampaigns && (eventsCampaigns.event || eventsCampaigns.campaign)) {
<section class="events-campaigns section-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="heading-style semibold text-center mb-4">
                    {{'pdl-website.eventsCampaigns.title' | translate }}
                </h2>
                <p class="title-description text-center mb-lg-40 pdl-grey">
                    {{'pdl-website.eventsCampaigns.description' | translate }}
                </p>
            </div>
            <div class="col-12">
                <div class="events-campaigns-in-grid">
                    <div class="row justify-content-center">
                        @if(eventsCampaigns.event) {
                        <!-- Event -->
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                            <div class="events-campaigns-card position-relative d-flex flex-column">
                                <div class="block-img cursor-pointer">
                                    <img src="{{imageBaseUrl}}{{eventsCampaigns.event.ImagePath}}" alt="image"
                                        (click)="navigateToDetail(eventsCampaigns.event)">
                                    <div class="item-badge">{{'pdl-website.eventsCampaigns.event' | translate}}</div>
                                </div>
                                <div
                                    class="block-text ps-4 pe-4 d-flex flex-column justify-content-between flex-grow-1">
                                    <h5 class="medium-font cursor-pointer"
                                        (click)="navigateToDetail(eventsCampaigns.event)">
                                        {{eventsCampaigns.event.Title }}
                                    </h5>
                                    <div class="small-tags">
                                        <span class="small-tag">
                                            <img src="assets/images/icons/calendar-ico.svg" alt="calendar-icon">
                                            {{eventsCampaigns.event.EventDates[0].Date | date : 'dd MMMM YYYY'}}
                                        </span>
                                        <span class="small-tag">
                                            <img src="assets/images/icons/location-ico.svg" alt="calendar-icon">
                                            {{eventsCampaigns.event.Location}}
                                        </span>
                                    </div>
                                    <p class="description-text events-campaigns_description_ellipses clamp-2"
                                        [innerHTML]="eventsCampaigns.event.Description ? sanitizeHTML(eventsCampaigns.event.Description) : null">
                                    </p>
                                    <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                                        <!-- <a href="javascript:void(0)"
                                            class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.eventsCampaigns.getDirections'
                                            | translate }} <img src="assets/images/icons/directions-ico.svg"
                                                alt="direction-icon"></a> -->
                                        <a href="javascript:void(0)" (click)="navigateToDetail(eventsCampaigns.event)"
                                            class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                            {{'pdl-website.eventsCampaigns.openDetails' | translate }} <img
                                                src="assets/images/icons/visit-ico.svg" alt="visit-icon"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        @if(eventsCampaigns.campaign) {
                        <!-- Campaign -->
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
                            <div class="events-campaigns-card position-relative d-flex flex-column">
                                <div class="block-img cursor-pointer">
                                    <img src="{{imageBaseUrl}}{{eventsCampaigns.campaign.ImagePath}}" alt="image"
                                        (click)="navigateToDetail(eventsCampaigns.campaign)">
                                    <div class="item-badge">{{'pdl-website.eventsCampaigns.campaign' | translate}}</div>
                                </div>
                                <div
                                    class="block-text ps-4 pe-4 d-flex flex-column justify-content-between flex-grow-1">
                                    <h5 class="medium-font cursor-pointer"
                                        (click)="navigateToDetail(eventsCampaigns.campaign)">
                                        {{eventsCampaigns.campaign.Title }}
                                    </h5>
                                    <div class="small-tags">
                                        <span class="small-tag">
                                            <img src="assets/images/icons/calendar-ico.svg" alt="calendar-icon">
                                            {{eventsCampaigns.campaign.EventDates[0].Date | date : 'dd MMMM YYYY'}}
                                        </span>
                                        <span class="small-tag">
                                            <img src="assets/images/icons/location-ico.svg" alt="calendar-icon">
                                            {{eventsCampaigns.campaign.Location}}
                                        </span>
                                    </div>
                                    <p class="description-text events-campaigns_description_ellipses"
                                        [innerHTML]="eventsCampaigns.campaign.Description ? sanitizeHTML(eventsCampaigns.campaign.Description) : null">
                                    </p>
                                    <div class="pdl-btns-row d-flex align-items-center justify-content-start flex-wrap">
                                        <!-- <a href="javascript:void(0)"
                                            class="btn pdl-btn bg-white medium-font d-flex align-items-center justify-content-start">{{'pdl-website.eventsCampaigns.getDirections'
                                            | translate }} <img src="assets/images/icons/directions-ico.svg"
                                                alt="direction-icon"></a> -->
                                        <a href="javascript:void(0)"
                                            (click)="navigateToDetail(eventsCampaigns.campaign)"
                                            class="btn pdl-btn pdl-bg-green medium-font d-flex align-items-center justify-content-start">
                                            {{'pdl-website.eventsCampaigns.openDetails' | translate }} <img
                                                src="assets/images/icons/visit-ico.svg" alt="visit-icon"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="centered-btn d-flex align-items-center justify-content-center">
                    <a [routerLink]="['/events-campaigns']"
                        class="btn pdl-btn">{{'pdl-website.eventsCampaigns.seeAllEventsCampaigns' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="global-divider">
    <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
}
