/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchModalResponse } from 'shared/src/interfaces/website/search.interface';
import { WebSearchService } from 'shared/src/services/website/search/search.service';
import { debounceTime, Subject, Subscription, switchMap } from 'rxjs';
import { SpinnerComponent } from '@panjab-digi-lib/shared';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'apps/panjab-digi-lib/src/common/services/common.service';

interface ModalData {
  collection: string,
  searchField: string,
  firstLetter: string,
  totalRecords: number
}
@Component({
  selector: 'panjab-digi-lib-list-modal',
  standalone: true,
  imports: [CommonModule, SpinnerComponent, FormsModule],
  templateUrl: './list-modal.component.html',
  styleUrl: './list-modal.component.scss',
})
export class ListModalComponent implements OnInit, OnDestroy {
  constructor(
    public activeModal: NgbActiveModal,
    private searchService: WebSearchService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService

  ) {

  }
  @Input() modalData!: ModalData;
  allData: SearchModalResponse[] = [];
  pageNumber = 1;
  pageSize = 100;
  query: string = '';
  loading = false;
  alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  searchTerm = new Subject<string>();
  searchInput: string = '';
  subscription = new Subscription();
  selectedIds: number[] = [];

  ngOnInit(): void {

    this.subscription.add(this.commonService.queryParams$.subscribe(result => {
      if (result[this.modalData?.collection]) {
        this.selectedIds = [...result[this.modalData?.collection]]
      }
    }))

    if (this.modalData?.firstLetter && this.modalData.totalRecords > 100) {
      this.query = '';
    }
    this.subscription.add(
      this.searchTerm
        .pipe(
          debounceTime(300),
          switchMap(async () => this.loadData(true, false))
        )
        .subscribe());
    this.loadData();
  }

  onScroll(event: Event) {
    const element = event.target as HTMLElement;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 5) {
      this.loadData(false);
    }
  }

  //////////////////////// load data based query //////////////
  loadData(clearPreviousData: boolean = true, keepPageNumber: boolean = true) {
    if (this.loading) return;
    this.loading = true;
    const payload = {
      collection: this.modalData?.collection,
      searchField: this.modalData?.searchField,
      pageNumber: keepPageNumber ? this.pageNumber : 1,
      pageSize: this.pageSize,
      selectedItems: [],
      query: this.query
    }

    this.subscription.add(this.searchService.findInCollection(payload).subscribe({
      next: (response) => {
        if (clearPreviousData) {
          this.allData = [];
        }
        this.allData = [...this.allData, ...response.body];
        this.pageNumber++;
        this.loading = false;
      }, error: (error) => {
        console.log(error)
      }
    }))
  }

  /////////////// select letter and show records based on that //////////////
  onLetterSelect(letter: string) {
    this.query = letter;
    this.searchInput = '';
    this.pageNumber = 1;
    this.loadData();
  }

  ////////////////// search on input keyup ////////////////
  onSearch(e: Event) {
    const enteredKeyword = (e.target as HTMLInputElement).value;
    this.query = enteredKeyword;
    this.searchTerm.next(enteredKeyword);
  }

  ////////////////////// close modal ///////////////////
  closeModal() {
    this.activeModal.close('Close click');
  }


  /////////////// checkbox check add/remove to query /////////////////

  queryUpdation(key: string, item:SearchModalResponse, event: Event) {
    const element = event.target as HTMLInputElement;
    const isChecked = element.checked;
    if (isChecked) {
      this.selectedIds.push(item.id);
    } else {
      this.selectedIds = this.selectedIds.filter(existingId => existingId !== item.id);
    }
    item.isSelected = isChecked ? true:  false;
    const updatedParams = {
      [key]: [...this.selectedIds]
    }
    const params = { ...updatedParams };
    const urlParams = new URLSearchParams(window.location.search);
    for (const k in params) {
      if (params[k].length) {
        urlParams.set(k, JSON.stringify(params[k]));
      } else {
        urlParams.delete(k);
      }
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
    this.commonService.modalSelection$.next({key : key, value : item});
    this.commonService.updateQueryParams(params);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.searchTerm.complete();
  }

}
