import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@panjab-digi-lib/shared';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { DateRangePickerComponent } from 'shared/src/lib/components/date-range-picker/date-range-picker.component';

@Component({
  selector: 'panjab-digi-lib-forum',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterModule,
    TranslateModule,
    NgbPaginationModule,
    SpinnerComponent,
    DateRangePickerComponent,
  ],
  templateUrl: './forum.component.html',
  styleUrl: './forum.component.scss',
})
export class ForumComponent {
  sortBy = '';
  pageNo = 1;
  currentPage = 1;
  pageSize = 10;
  totalRecords = 200;
  maxPageNumber: number = APP_CONSTANTS.maxSize;
  fromRecords: number = 1;
  toRecords: number = 10;
  payload: any = {};
  startDate!: string;
  endDate!: string;
  topics = [
    { name: 'Manuscripts', count: 4149 },
    { name: 'Books', count: 46149 },
    { name: 'Magazines', count: 4149 },
    { name: 'Newspapers', count: 234 },
    { name: 'Photographs', count: 678 },
    { name: 'Pamphlets', count: 234 },
    { name: 'Files', count: 1254 },
    { name: 'Coins', count: 2341 },
    { name: 'Diaries', count: 123 },
    { name: 'Fabric Arts', count: 1254 },
    { name: 'Theses', count: 2341 },
    { name: 'Articles', count: 123 },
  ];

  dates = [
    { range: '2020 to 2024', count: 2341 },
    { range: '2010 to 2019', count: 123 },
    { range: '1990 to 2009', count: 2341 },
    { range: '1900 to 1989', count: 123 },
  ];

  sortOptions = [
    { key: 'relevance', label: 'Relevance' },
    { key: 'mostViews', label: 'Most Views' },
    { key: 'date', label: 'Date Published' },
    { key: 'rating', label: '5 Star Rating' },
  ];
  results = [
    {
      timestamp: '12:00PM. 15 June 2020',
      title: 'RURAL REHABILITATION IN EAST PUNJAB',
      category: 'BOOKS',
      creator: 'eonline cheap',
      description:
        'Weekly Vol. 2, no. 1 (June 25, 1954)-v. 17, no. 32 (Feb. 11, 1971). Published as: San Miguel forum, frequently between May 24, 1957-Jan. 21, 1971, with separate numbering, and: Forum and the San Miguel forum, Feb. 11, 1971. Published also for Norwood and Telluride. San Miguel Basin...',
      imageUrl: 'https://placehold.co/400',
      views: 125,
      comments: 15,
    },
    {
      timestamp: '1:00PM, 16 June 2020',
      title: 'SZKOLENIE PLAKAT - FORUM POLONIA',
      category: 'MANUSCRIPTS',
      creator: 'another_user',
      description: 'Details about the manuscript...',
      imageUrl: 'https://placehold.co/400',
      views: 200,
      comments: 20,
    },
    {
      timestamp: '2:00PM, 17 June 2020',
      title: 'BITCHUTE - defending-gibraltar_truther-forum (2023)',
      category: 'PHOTOGRAPHS',
      creator: 'photo_lover',
      description: 'A collection of historical photographs...',
      imageUrl: 'https://placehold.co/400',
      views: 300,
      comments: 25,
    },
    {
      timestamp: '12:00PM. 15 June 2020',
      title: 'RURAL REHABILITATION IN EAST PUNJAB',
      category: 'BOOKS',
      creator: 'eonline cheap',
      description:
        'Weekly Vol. 2, no. 1 (June 25, 1954)-v. 17, no. 32 (Feb. 11, 1971). Published as: San Miguel forum, frequently between May 24, 1957-Jan. 21, 1971, with separate numbering, and: Forum and the San Miguel forum, Feb. 11, 1971. Published also for Norwood and Telluride. San Miguel Basin...',
      imageUrl: 'https://placehold.co/400',
      views: 125,
      comments: 15,
    },
    {
      timestamp: '1:00PM, 16 June 2020',
      title: 'SZKOLENIE PLAKAT - FORUM POLONIA',
      category: 'MANUSCRIPTS',
      creator: 'another_user',
      description: 'Details about the manuscript...',
      imageUrl: 'https://placehold.co/400',
      views: 200,
      comments: 20,
    },
    {
      timestamp: '2:00PM, 17 June 2020',
      title: 'BITCHUTE - defending-gibraltar_truther-forum (2023)',
      category: 'PHOTOGRAPHS',
      creator: 'photo_lover',
      description: 'A collection of historical photographs...',
      imageUrl: 'https://placehold.co/400',
      views: 300,
      comments: 25,
    },
  ];
  selectedSort = this.sortOptions[0];
  viewMode: 'list' | 'grid' = 'list';


  constructor() {}

  ngOnInit() {
    this.loadResults();
  }

  changeView(mode: 'list' | 'grid') {
    this.viewMode = mode;
  }

  sortCollections(orderBy: string, order: string, sortBy: string) {
    // this.sortOrder = {orderBy, order};
    // this.sortBy = sortBy;
    // this.loadResults();
  }

  selectedRange(event: {
    startDate: string;
    endDate: string;
    checked?: boolean;
  }) {
    if (event.checked === false && event.startDate) {
      this.startDate = '';
      this.endDate = '';
    } else {
      this.startDate = event.startDate;
      this.endDate = event.endDate;
      this.payload.startDate = this.startDate;
      this.payload.endDate = this.endDate;
      // this.getPayload(1);
    }
  }

  changeSort(option: any) {
    this.selectedSort = option;
    this.loadResults();
  }

  loadResults() {}

  toggleFilter(type: string, item: any) {
    console.log(`Filter toggled: ${type} - ${item.name || item.range}`);
  }

  onTopicSelect(event: Event) {
    const selectedTopic = (event.target as HTMLSelectElement).value;
    console.log('Selected Topic:', selectedTopic);
    // Add any additional logic you want to handle the selected topic
  }

  get startRecord() {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get endRecord() {
    return Math.min(this.currentPage * this.pageSize, this.totalRecords);
  }
}
