/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'shared/src/environments/environment';
import { WEB_API_ENDPOINTS } from '../app.endpoints';
import { catchError, map, throwError } from 'rxjs';
import { GenericListingPayload } from 'shared/src/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class WebCuratedCollectionService {

  rootURL = environment.rootApiUrl;
  endPointUrl = WEB_API_ENDPOINTS.curatedCollection;
  constructor(private http: HttpClient) { }

  getFeaturedCollection(payload: {pageNumber: number, pageSize: number, excludeID?: number}) {  
    if(typeof payload.excludeID == 'undefined') {
      delete payload.excludeID;
    }
    return this.http.get(`${this.rootURL}${this.endPointUrl.getFeatured}`, {params: {...payload}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  getCuratedCollections(payload: GenericListingPayload) {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.get}`, {params: {...payload}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  getUserCollections(payload: GenericListingPayload) {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.getUserCollections}`, {params: {...payload}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }

  getCollectionDetails(collectionID: number) {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.get}/${collectionID}`)
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  getCollectionDocuments(payload: GenericListingPayload, collectionID: number) {  
    return this.http.get(`${this.rootURL}${this.endPointUrl.document}/${collectionID}`, {params: {...payload}})
    .pipe(
      map((e: any) => e.body),
      catchError(this.handleError)
    );
  }
  
  addToMyCollection(CollectionID: number) {  
    return this.http.post(`${this.rootURL}${this.endPointUrl.addToUserCollection}`, {CollectionID})
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }
  
  removeFromMyCollection(CollectionID: number) {  
    return this.http.delete(`${this.rootURL}${this.endPointUrl.removeFromUserCollection}/${CollectionID}`)
    .pipe(
      map((e: any) => e),
      catchError(this.handleError)
    );
  }

  
  private handleError(error: HttpErrorResponse) {
    let transformedError: HttpErrorResponse;

    if (error.error && error.error.error) {
      transformedError = error.error.error;
    }


    return throwError(() => transformedError);
  }
}
