import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'shared/src/services/admin/authentication/authentication.service';
import { LocalStorageService } from 'shared/src/services/common/local-storage.service';

const DURATION = 4000;
@Component({
  selector: 'panjab-digi-lib-verify-user',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './verify-user.component.html',
  styleUrl: './verify-user.component.scss',
})
export class VerifyUserComponent implements OnInit {
  response: any = {};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private localStorage: LocalStorageService
  ) {}
  
  ngOnInit(): void {
    if(this.localStorage.get('webToken')) {
      this.response = {type: 'error', message: 'Found another active user. Logout and try again'};
      this.navigateTo('/');
    } else {
      if (this.route.snapshot.params['token']) {
        const token = this.route.snapshot.params['token'];
        if(token) {
          this.response = {type: 'info', message: 'Loading...'};
          this.authService.verifyToken(token, 'verify-user').subscribe({
            next: (res:any) => {
              this.response = {type: 'success', message: res.message};
              this.navigateTo('/login-user');
            },
            error: (err) => {
              this.response = {type: 'error', message: err.status === 400 ? 'Something went wrong!' : err?.error?.error?.message};
              this.navigateTo('/');
            }
          });
        } else {
          this.response = {type: 'error', message: 'Something went wrong!'};
          this.navigateTo('/');
        }
      }
    }
  }

  navigateTo(url:string):void {
    setTimeout(() => {
      this.router.navigate([url]);
    }, DURATION);
  }
}
