/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { StaticPageHeadComponent } from '../static/staticPageHead/staticPageHead.component';
import { forkJoin, Subscription } from 'rxjs';
import { ExhibitionService } from 'shared/src/services/website/exhibition/exhibition.service';
import { APP_CONSTANTS } from 'shared/src/common/app.constants';
import { ToastService } from 'shared/src/services/toast/toast.service';
import { ExhibitionItem } from 'shared/src/interfaces/exhibition.interface';
import { environment, SpinnerComponent } from '@panjab-digi-lib/shared';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Router, RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'panjab-digi-lib-exhibitions',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, StaticPageHeadComponent,SpinnerComponent,SlickCarouselModule,NgbPopoverModule],
  templateUrl: './exhibitions.component.html',
  styleUrl: './exhibitions.component.scss',
})
export class ExhibitionsComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  subscription = new Subscription();
  currentExhibitions: ExhibitionItem[] = [];
  upcomingExhibitions: ExhibitionItem[] = [];
  pastExhibitions: ExhibitionItem[] = [];
  imageBaseUrl = environment.azureCloudStrorage.publicUrl;
  maxLength = 350; // Max characters to show before truncating

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      },
      {
        breakpoint: 300,
      }
    ]
  };
  isExpandedMap: { [key: number]: boolean } = {};
  constructor(  
    private translate: TranslateService,
    private service: ExhibitionService,
    readonly toastService: ToastService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.fetchAllExhibitions();
  }

  fetchAllExhibitions() {
    const payload = { pageNumber: 1, pageSize: 4 };
    const payloads = ['Current', 'Upcoming', 'Past'].map((filter) => ({
      ...payload,
      pageSize: filter === 'Current' ? 20 : payload.pageSize,
      filters: filter
    }));

    const [currentPayload, upcomingPayload, pastPayload] = payloads;
    this.subscription.add(forkJoin([
      this.service.get(currentPayload),
      this.service.get(upcomingPayload),
      this.service.get(pastPayload)
    ]).subscribe({
      next: ([currentExhibitionResponse, upcomingExhibitionResponse, pastExhibitionResponse]) => {
        this.isLoading = false;
        this.currentExhibitions = currentExhibitionResponse?.body?.exhibitions;
        this.upcomingExhibitions = upcomingExhibitionResponse?.body?.exhibitions;
        this.pastExhibitions = pastExhibitionResponse?.body?.exhibitions;
      },
      error: (error) => {
        this.isLoading = false;
        this.toastService.showError(
          error?.error?.error?.message || String(APP_CONSTANTS.defaultErrMsg)
        );
      }
    }));
  }
  
  toggleDescription(exhibitionId: number) {
    this.isExpandedMap[exhibitionId] = !this.isExpandedMap[exhibitionId];
  }

  getTruncatedDescription(description: string): string {
    return description.length > this.maxLength
      ? description.substring(0, this.maxLength) + '...'
      : description;
  }

  shouldShowReadMore(description: string): boolean {
    return description.length > this.maxLength;
  }

  navigateToExhibition(exhibitionId: number) {
    this.router.navigate([`/exhibition/${exhibitionId}`]);

  }

  ngOnDestroy(): void {
  this.subscription.unsubscribe();
  }
}
