<panjab-digi-lib-static-page-head [pageName]="'pdl-website.metadataSchema.titleText' | translate"
  pagePath="/metadata-schema">
</panjab-digi-lib-static-page-head>

<section class="static-section section-padding ">
  <div class="container-1000">
    @if(content) {
    <div [innerHTML]="content | safeHtml"></div>
    } @else {
    <div class="d-flex workingGroup">
      <div class="img"><img src="/assets/images/data-mining.png" /></div>
      <div>
        <h1 class="heading mb-3">Panjab Digital Library Metadata Schema</h1>
        <p class="text">
          Metadata is an indispensable part of any digital collection, especially when this digital item is included in
          the online inventory of the digital institutions. This data can provide information about a text's author,
          publishers, copy right and a brief description of the contents. After a long research discussion and
          codification by several digitization institutions at the international level, a broad consensus has been
          arrived at to standardized Metadata Best Practices. The Dublin Core Metadata (DCM) best practice has been
          universally adopted for preparing metadata about digital collections by almost all digitization institutions.
          PDL Metadata Schema (PDLMS) has been developed after studying DCM schema and it consists of all the mandatory
          and optional elements of DCM Metadata Schema as well as a few elements which are required for local and
          regional needs.
        </p>
      </div>
    </div>
    }
  </div>
</section>
