@if(popularReads.length >0) {
<section class="most-popular-section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="heading-style semibold text-center mb-lg-40 font-31">
          {{ 'pdl-website.forumPage.title' | translate }}
        </h2>
      </div>
      <div
        class="row d-flex align-items-center justify-content-center mx-0 px-0"
      >
        <div class="col-12 row">
          <!-- Sidebar with Filters -->
          <aside class="col-md-2">
            <div class="filter-sidebar">
              <!-- DropDown for mobile view -->
              <div class="d-md-none">
                <h5 class="filter-title">Topic</h5>
                <select
                  class="form-select"
                  aria-label="Select Topic"
                  (change)="onTopicSelect($event)"
                >
                  <option value="" disabled selected>Select a topic</option>
                  <option *ngFor="let topic of topics" [value]="topic.name">
                    {{ topic.name }} ({{ topic.count }})
                  </option>
                </select>
              </div>

              <!-- Regular list for larger screens -->
              <ul class="filter-list d-none d-md-block">
                <!-- Topic Section -->
                <h5 class="filter-title">Topic</h5>
                <li *ngFor="let topic of topics" class="topic-item">
                  <span class="filter-name">{{ topic.name }}</span>
                  <span class="filter-badge">{{ topic.count }}</span>
                </li>
              </ul>
            </div>
          </aside>

          <div class="col-md-10">
            <div class="post-card" *ngFor="let post of results">
              <div class="col-md-12 image-section d-flex flex-column">
                <div
                  class="w-100 my-2 d-flex align-items-center justify-content-between"
                >
                  <div class="d-flex flex-wrap align-items-center">
                    <span class="category">{{ post.category }}</span>
                    <h5 class="title">{{ post.title }}</h5>
                    <span class="view-text me-2 d-flex align-items-center">
                      <img src="assets/images/icons/eye.svg" class="eye-icon" />
                      {{ post.views }}
                    </span>
                    <span class="comment-text ms-2 d-flex align-items-center">
                      <img
                        src="assets/images/icons/chat-icon.svg"
                        class="eye-icon"
                      />
                      {{ post.comments }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center">
                    <p class="timestamp">{{ post.timestamp }}</p>
                  </div>
                </div>
                <p class="description mb-0">{{ post.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <div
            class="centered-btn d-flex align-items-center justify-content-center"
          >
            <a
              href="javascript:void(0);"
              [routerLink]="'/forum'"
              class="btn pdl-btn"
              >{{ 'pdl-website.forumPage.buttonText' | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="global-divider">
  <panjab-digi-lib-global-divider></panjab-digi-lib-global-divider>
</div>
}
