/* eslint-disable @nx/enforce-module-boundaries */
import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAssignedTask from "shared/src/store/reducers/staff/assigned-tasks.reducer";

export const selectTaskState =
  createFeatureSelector<fromAssignedTask.State>('assignedTask');

export const getTaskList = createSelector(
    selectTaskState,
    fromAssignedTask.selectAll
);

export const getTotalRecords = createSelector(
  selectTaskState,
  (state) => state.totalRecords
);

export const getTaskByID = (props: {id: string}) => createSelector(
  selectTaskState,
  (state) => state.entities[props.id]
)

export const getListingPayload = createSelector(
  selectTaskState,
  (state) => state.payload
)

export const onSave = createSelector(
  selectTaskState,
  (state) => [state.isError, state.errorMessage, state.successMessage],
);

export const isLoading = createSelector(
  selectTaskState,
  (state) => state.isLoading,
);

export const isSaving = createSelector(
  selectTaskState,
  (state) => state.isSaving,
);