import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromUserLibrary from "../../reducers/website/web-user-library.reducer";

export const selectWebUserLibraryState = createFeatureSelector<fromUserLibrary.State>('userLibrary');

export const topicList = createSelector(
  selectWebUserLibraryState,
  (state) => ({
    topicList: fromUserLibrary.selectAll(state),
  })
);

export const isLoading = createSelector(
  selectWebUserLibraryState,
  (state) => state.isLoading
);
