import { createReducer, on } from '@ngrx/store';
import { DocumentFilesActions } from '../../actions';

export interface State {
  pdfFiles: any[];
  imageFiles: any[];
  isLoading: boolean;
  errorMessage: string | null;
}

export const initialState: State = {
  pdfFiles: [],
  imageFiles: [],
  isLoading: false,
  errorMessage: null,
};

export const reducer = createReducer(
  initialState,
  on(DocumentFilesActions.loadDocumentFiles, (state) => ({
    ...state,
    isLoading: true,
    errorMessage: null,
  })),
  on(DocumentFilesActions.loadDocumentFilesSuccess, (state, { pdfFiles, imageFiles }) => ({
    ...state,
    pdfFiles,
    imageFiles,
    isLoading: false,
    errorMessage: null,
  })),
  on(DocumentFilesActions.loadDocumentFilesError, (state, { message }) => ({
    ...state,
    isLoading: false,
    errorMessage: message,
  }))
);
