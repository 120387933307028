<section class="advancesearch-section">
  <!-- <div class="tab-content" id="myTabContent"> -->
  <!-------------------- list view ------------------------->
  @if ('list' === viewType) {
  <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
    <div class="searched-list mt-2">
      <div class="row">
        @for (item of data; track item?.DocID; let i = $index) {
        <a [href]="'/metadata/'+item?.DocID">
          <div class="col-12 cursor-pointer" [routerLink]="'/metadata/' + item?.DocID">
            <a class="list-search-card even-card d-flex align-items-top justify-content-start"
              [ngClass]="{'odd-card' : (i+1) % 2}">
              <div class="list-thumbnail">
                <img [src]="(item?.Thumbnail) ? item.Thumbnail : 'assets/images/icons/no-image-placeholder.svg'"
                  (error)="handleImageError($event)" alt="image">
              </div>
              <div class="thumb-content">
                @for( view of item.ListView;track view) {
                @for( chip of view.Value;track chip) {
                @if(chip?.value) {
                <span class="category-tag medium-font">
                  {{chip?.value}}
                </span>
                }
                }
                }
                <div class="thumb-rating">
                  @for (star of getRating(item?.UserRating); track star) {
                  <ng-container>
                    <span>
                      <img [src]="getStarImage(star)" alt="star icon" />
                    </span>
                  </ng-container>
                  }
                </div>
                <h5 class="medium-font pdl-title-color">{{item?.Title}}</h5>
                <span class="thumb-language">
                  {{item?.Description}}
                </span>
                @if(item.HighlightedText && item.HighlightedText.length > 0) {
                <p class="thumb-language" [innerHTML]="item.HighlightedText">
                </p>
                }
              </div>
            </a>
          </div>
        </a>
        } @empty {
        <div class="no-results d-flex">
          <img class="search-magnifier" [src]="'/assets/images/icons/search-magnifier.svg'" />
          <p class="no-results-text mt-3"> {{'pdl-website.forms.advancedSearch.noRecordsFound' | translate }}</p>
          <p class="repharasing-text mt-2"> {{'pdl-website.forms.advancedSearch.repharasingText' | translate }}</p>
        </div>
        }
      </div>
    </div>
  </div>
  }
  @else {
  <!-------------------grid view ----------------->
  <div class="tab-pane fade show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
    tabindex="0">
    <div class="searches-in-grid mt-2">
      <div class="row justify-content-center">
        @for (item of data; track item?.DocID; let i = $index) {
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <a [href]="'/metadata/'+item?.DocID" class="search-card mb-4">
            <div class="block-img">
              <div class="image-overlay">
                <span class="category-title">
                  {{item?.CategoryName}}
                </span>
                <span class="ratings">
                  @for (star of getRating(item?.UserRating); track star) {
                  <ng-container>
                    <span>
                      <img [src]="getStarImage(star)" alt="star icon" />
                    </span>
                  </ng-container>
                  }
                </span>
              </div>
              <img class="thumb-imb"
                [src]="(item?.Thumbnail) ? item.Thumbnail : 'assets/images/icons/no-image-placeholder.svg'"
                (error)="handleImageError($event)" alt="image">
            </div>
            <div class="block-text-bar d-flex justify-content-between align-items-center flex-column">
              <h6 class="pdl-title-color medium-font font-14 no-scrollbar" [title]="item.Title"
                style="text-overflow: ellipsis;white-space: nowrap; overflow: hidden;">{{item?.Title}}</h6>
              <div class="type-and-numbers d-flex align-items-center justify-content-between w-100">
                <span class="font-10 d-flex">{{item?.LanguageList}}</span>
                <span class="font-10 d-flex">{{item?.TotalPages}} PAGES</span>
              </div>
            </div>
          </a>
        </div>
        } @empty {
        <div class="no-results d-flex">
          <img class="search-magnifier" [src]="'/assets/images/icons/search-magnifier.svg'" />
          <p class="no-results-text mt-3"> {{'pdl-website.forms.advancedSearch.noRecordsFound' | translate }}</p>
          <p class="repharasing-text mt-2"> {{'pdl-website.forms.advancedSearch.repharasingText' | translate }}</p>
        </div>
        }
      </div>
    </div>
  </div>
  }
  <!-- </div> -->
</section>
